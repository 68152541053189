import { Typography } from '../../style/typography'
import { css } from '@emotion/react'
import { dangerRed, eerieBlack } from '../../style/colors'
import closeCircleIcon from '../../images/icons/close-circle.svg'
import copyIcon from '../../images/input/copy.svg'
import invalidIcon from '../../images/input/invalid.svg'
import validIcon from '../../images/input/valid.svg'

export const inputTextStyle = css`
  box-sizing: border-box;
  font-family: Inter, sans-serif;
  font-style: normal;
  ${Typography.Footnote.semibold}
  letter-spacing: 0.1px;
  color: ${eerieBlack['800']};

  position: relative;
  background-color: ${eerieBlack['100']};
  height: 70px;
  border-radius: 10px;
  padding: 25px 16px;
  border: 2px solid transparent;
  &[data-background='secondary']{
    background-color: ${eerieBlack['0']};
  }
  &[data-has-value='true']{
    .status-icon{
      background-image: url(${closeCircleIcon});
    }
  }
  &[data-status='valid']{
    .status-icon{
      background-image: url(${validIcon});
    }
  }
  &[data-status='invalid']{
    border: 2px solid ${dangerRed};

    label{
      color: ${dangerRed}
    }

    .status-icon{
      cursor: pointer;
      background-image: url(${invalidIcon});
    }
  }
  &[data-status='disabled']{
    opacity: .4
  }
  &[data-has-label='false']{
    input{
      top: 23px
    }
  }
  &[data-copy='true']{
    .status-icon{
      cursor: pointer;
      background-image: url(${copyIcon});
    }
  }

  label {
    color: ${eerieBlack['600']};
    transition: all 180ms ease-in-out;
    position: absolute;
    top: 23px;
    display: block
  }

  input {
    font-family: Inter, sans-serif;
    font-style: normal;
    ${Typography.Footnote.semibold}
    text-align: left;
    border: none;
    padding: 0;
    margin: 0;
    width: calc(100% - 32px);
    outline: none;
    box-shadow: none;
    height: 20px;
    background-color: transparent;
    top: 34px;
    position: absolute;
    color: ${eerieBlack['800']};
  }

  input::placeholder {
    opacity: 0;
    color: ${eerieBlack['400']};
    transition: opacity 180ms ease-in-out;
  }

  input:focus, &[data-has-value='true'] input{
    ::placeholder {
      opacity: 1;
    }
  }
  input:focus + label, &[data-has-value='true'] input + label {
    position: absolute;
    top: 12px;
    font-size: 12px;
    line-height: 18px;
    margin-bottom: 8px;
  }

  .status-icon{
    width: 24px;
    height: 24px;
    position: absolute;
    right: 12px;
    top: 21px;
  }

  @media screen and (max-width: 767px) {
    input{
      font-size: 16px;
    }
  }
`
