import { Colors, eerieBlack } from '../../style/colors'
import { Icon } from '../icon'
import { Layout, Spacing } from '../../style/spacing'
import { Responsive } from '../../style/responsive'
import { Typography } from '../../style/typography'
import { auth } from '../../configuration/firebase'
import { exitStyles } from './index.css'
import { logAndSendToErrorTracker } from '../../services/errorTracker'
import { startCase, trim } from 'lodash'
import { translate } from '../../lib/localization'
import { useGetUserQuery } from '../../lib/apollo/hooks'
import LogOut from '../../images/icons/log-out.svg'
import React from 'react'
import tateLogo from '../../images/header/tate-logo.svg'

export const Header: React.FC = () => {
  const { data } = useGetUserQuery({
    fetchPolicy: 'cache-and-network',
    onError: error => logAndSendToErrorTracker(error)
  })

  const logout = () => {
    auth.signOut()
  }

  return (
    <div css={[Layout.border_box, Layout.flex_1, Layout.horizontal_center, Layout.border_bottom, Colors.BackgroundColor.eerieBlack0]}>
      <div css={[Responsive.sizes.containerMaxWidth, Layout.flex_1, Layout.display_flex, Layout.vertical_center, Spacing.Padding.horizontal_40, Spacing.Padding.vertical_16, { justifyContent: 'space-between' }]}>
        {/* Logo */}
        <div css={[Layout.vertical_center]}>
          <img css={[Spacing.Padding.right_24, Layout.border_right, Colors.BorderColor.eerieBlack300]} height={38} src={tateLogo} alt="Logo"/>
          <span css={[Spacing.Padding.left_24, Typography.label.label4_400, Colors.Color.eerieBlack600]}>{translate('header__title')}</span>
        </div>
        <div css={[Layout.vertical_center]}>
          {
            (data?.Me?.firstName || data?.Me?.lastName) &&
            <div css={[Spacing.Padding.right_16, Layout.border_right, Colors.BorderColor.eerieBlack300]}>
              <div css={[Typography.label.label4_400, Colors.Color.eerieBlack600, Spacing.Text.right]}>{translate('header__greetings')}</div>
              <div css={[Typography.label.label2_600, Colors.Color.eerieBlack800]}>{startCase(trim(`${data?.Me?.firstName || ''} ${data?.Me?.lastName || ''}`))}</div>
            </div>
          }
          <div css={exitStyles} onClick={logout}>
            <div className={'exit-text'} css={[Typography.label.label3_500, Colors.Color.eerieBlack500]}>{translate(('header_logout'))}</div>
            <Icon className={'exit-icon'} color={eerieBlack['500']} src={LogOut} />
          </div>
        </div>
      </div>
    </div>
  )
}
