import {
  Card,
  Icon,
  InputToggle,
  Spacer
} from '../../components'
import { Colors, eerieBlack } from '../../style/colors'
import { Layout, Spacing } from '../../style/spacing'
import { Placeholder } from '../../components/placeholder'
import { PrivacyPolicy, WhereOperator } from '../../lib/apollo/types'
import { Sizes } from '../../style/sizes'
import { Typography } from '../../style/typography'
import { UserNamesForm } from '../UserNamesForm'
import { isBusinessUsageTarget } from '../../configuration/env'
import { logAndSendToErrorTracker } from '../../services/errorTracker'
import { nameSurnameStyles } from './index.css'
import { translate } from '../../lib/localization'
import { trim } from 'lodash'
import {
  useGetPrivaciesQuery,
  useGetUserQuery,
  useUpdatePrivacySettingMutation
} from '../../lib/apollo/hooks'
import React, { useState } from 'react'
import dayJs from '../../lib/dayJs'
import modifyIcon from '../../images/icons/chevron-forward.svg'

const Title = ({ children }) => (
  <div css={[Typography.label.label4_400, { color: eerieBlack['600'] }]}>{children}</div>)
const Content = ({ children }) => (
  <div css={[Typography.paragraph.p3_500, { color: eerieBlack['800'] }]}>{children}</div>)

const PRIVACY_POLICY_URL = 'https://help.tate.it/it/articles/2144564-qual-e-la-privacy-policy-di-tate'

export const UserInfo: React.FC = () => {
  const [showModal, setShowModal] = useState<boolean>(false)
  const [showInputToggle, setShowInputToggle] = useState<boolean>(false)
  const [toggleValue, setToggleValue] = useState<boolean>(false)
  const { data, refetch, loading: dataLoading } = useGetUserQuery({
    fetchPolicy: 'cache-and-network',
    onError: error => logAndSendToErrorTracker(error)
  })
  const [updatePrivacySetting, { loading }] = useUpdatePrivacySettingMutation()
  useGetPrivaciesQuery({
    fetchPolicy: 'no-cache',
    onCompleted: data => {
      setToggleValue(data?.Privacies?.length
        ? data.Privacies.sort((currentPrivacy, nextPrivacy) => dayJs.utc(nextPrivacy.acceptedDate).unix() - dayJs.utc(currentPrivacy.acceptedDate).unix())[0].accepted
        : false)
      setShowInputToggle(true)
    },
    onError: error => logAndSendToErrorTracker(error),
    variables: {
      where: {
        field: 'policy',
        operator: WhereOperator.Equal,
        value: PrivacyPolicy.Marketing
      }
    }
  })
  return (
    <>
      <Card css={[isBusinessUsageTarget && [Layout.flex_row, Layout.display_flex], Layout.flex_2]}>
        <div css={[Layout.flex_column, Layout.flex_1]}>
          <div css={[Typography.label.label4_600, Typography.fonts.upperCase, Colors.Color.eerieBlack500]}>
            {isBusinessUsageTarget ? translate('user_profile_screen__title_business') : translate('user_profile_screen__title')}
          </div>
          <Spacer height={20}/>
          <div css={[Layout.vertical_center, Layout.flex_justify_spaceBetween, nameSurnameStyles]} onClick={() => setShowModal(true)}>
            <div className={'container'}>
              <Title>{translate('user_profile_screen__section_title--name')}</Title>
              {dataLoading && <Placeholder width={'200px'} height={'22px'}/>}
              {!dataLoading && <Content>{trim(`${data?.Me?.firstName || ''} ${data?.Me?.lastName || ''}`)}</Content>}
            </div>
            {
              isBusinessUsageTarget && <div css={[Typography.Caption.caption1_400, Layout.vertical_center, Colors.Color.eerieBlack600, Spacing.Padding.horizontal_12]} onClick={() => setShowModal(true)}>{translate('text__modify')}<Icon src={modifyIcon} css={Spacing.Margin.left_12} color={eerieBlack['400']} size={Sizes.xsmall}/></div>
            }
          </div>

          <Spacer height={16}/>
          <Title>{translate('user_profile_screen__section_title--email')}</Title>
          {dataLoading && <Placeholder width={'50%'} height={'22px'}/>}
          {!dataLoading && <Content>{data?.Me?.email}</Content>}

          <Spacer height={16}/>
          <Title>{translate('user_profile_screen__section_title--phone')}</Title>
          {dataLoading && <Placeholder width={'30%'} height={'22px'}/>}
          {!dataLoading && <Content>{data?.Me?.phone}</Content>}
          <Spacer height={32}/>
        </div>
        <div css={[Layout.flex_column, Layout.flex_1]}>
          <div css={[Typography.label.label4_600, Typography.fonts.upperCase, { color: eerieBlack['500'] }]}>
            {translate('user_profile_screen__section_title--privacy')}
          </div>
          <Spacer height={32}/>

          {showInputToggle &&
        <InputToggle
          clickable={!loading}
          value={toggleValue}
          label={translate('user_profile_screen__input_toggle_label')}
          onValue={async (value: boolean) => {
            setToggleValue(value)
            await updatePrivacySetting({
              variables: {
                privacies: [
                  {
                    accepted: value,
                    policy: PrivacyPolicy.Marketing
                  }
                ]
              }
            })
          }}
        />
          }

          <Spacer height={34} />
          <div css={[Typography.paragraph.p3_500, Colors.Color.eerieBlack800, { cursor: 'pointer' }]}>
            <a
              href={PRIVACY_POLICY_URL}
              target="_blank"
              rel="noreferrer">
              {translate('user_profile_screen__link--privacy')}
            </a>
          </div>
          <Spacer height={53}/>
        </div>
      </Card>
      {
        showModal &&
        <UserNamesForm
          firstName={data?.Me?.firstName}
          lastName={data?.Me?.lastName}
          onClose={() => {
            setShowModal(false)
            refetch()
          }}
        />
      }
    </>
  )
}
