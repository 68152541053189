import { css } from '@emotion/react'
import { eerieBlack, primaryColor, violetBlue } from '../../style/colors'

export const cardSelectStyle = css`    
    color: ${eerieBlack['800']};
    display: flex;
    flex-direction: column;

    & > div{
      display: flex;
      & > div {
        flex: 1 0 0;
      }
    }
  }
`

export const cardItemStyle = css`
  &[data-active='true']{
    border: 2px solid ${primaryColor};
  }
`

export const externalCardStyle = css`
  flex-grow: 1;
  border-radius: 12px;
  border: 3px solid transparent;

  &[data-active='true']{
    border: 3px solid ${violetBlue['50']};
  }
`
