import React from 'react'

export interface SpacerProps {
  /**
   * Height
   */
  height: number
}

export const Spacer: React.FC<SpacerProps> = ({
  height
}) => {
  return (
    <div style={{ flexShrink: 0, height: `${height}px` }}/>
  )
}
