import { Global, css } from '@emotion/react'
import { eerieBlack } from './colors'
import React from 'react'

const dashboardGlobalStyle = css`
  /* Fonts */
  @import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap');
  * {
    box-sizing: border-box;
  }
  /* Body */
  body {
    margin: 0;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
    font-family: Inter, sans-serif;
    font-style: normal;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  body::-webkit-scrollbar {
    display: none;
  }

  a {
    text-decoration: none;
    color: #1C1C1C;
  }

  h1, h2, h3, h4, h5, h6, p, span {
    margin: 0;
    padding: 0
  }
  #root {
    top: 0;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    background-color: ${eerieBlack['50']};
  }
  @keyframes spinner-border {
    to { transform: rotate(360deg); }
  }

  .spinner-border {
    display: inline-block;
    width: 2rem;
    height: 2rem;
    vertical-align: text-bottom;
    border: .25em solid currentColor;
    border-right-color: transparent;
    border-radius: 50%;
    -webkit-animation: .75s linear infinite spinner-border;
    animation: .75s linear infinite spinner-border;
  }

  .spinner-border-sm {
    width: 1rem;
    height: 1rem;
    border-width: .2em;
  }
`

export const GlobalStyle = () => <Global styles={dashboardGlobalStyle} />
