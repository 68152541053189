import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js'
import {
  GetContractPaymentMethodDocument,
  GetInvoiceAndPaymentMethodsDocument,
  GetPaymentMethodsDocument,
  useCreatePaymentMethodMutation,
  useStripeCreateSetupIntentMutation
} from '../lib/apollo/hooks'
import { GraphQLError } from 'graphql'
import { PaymentMethodTarget } from '../lib/apollo/types'
import { SharedEvents } from '@tate-it/tracking'
import { StripeError } from '@stripe/stripe-js'
import { logAndSendToErrorTracker } from '../services/errorTracker'
import { translate } from '../lib/localization'
import { useState } from 'react'
import { useTracking } from '../tracking/useTracking'

export const useCreateCreditCard = ():[createCreditCard: (billingDetails: { name: string; email:string })=> (Promise<[string, readonly GraphQLError[] | StripeError]>), payload: {error: boolean; loading: boolean}] => {
  const [createCreditCard] = useCreatePaymentMethodMutation()
  const { track } = useTracking()
  const [createSetupIntent] = useStripeCreateSetupIntentMutation()
  const elements = useElements()
  const stripe = useStripe()
  const [error, setError] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)

  const createPaymentMethod = async (billingDetails: { name: string; email:string }): Promise<[string, readonly GraphQLError[] | StripeError]> => {
    const stopLoadingAndLogError = (error):[string, readonly GraphQLError[] | StripeError] => {
      setError(true)
      setLoading(false)
      logAndSendToErrorTracker(error)
      return ['', error]
    }

    // Create a setupIntent calling our backend
    setLoading(true)
    const card = elements.getElement(CardElement)
    const { data: { StripeCreateSetupIntent_v1: stripeSetupIntent }, errors: createSetupIntentErrors } = await createSetupIntent()
    if (createSetupIntentErrors) return stopLoadingAndLogError(createSetupIntentErrors)

    // Calls Stripe-SDK for confirming the setupIntent
    // a paymentMethod is automatically created for us
    const { error: confirmCardSetupError, setupIntent } = await stripe.confirmCardSetup(stripeSetupIntent.client_secret, {
      payment_method: {
        billing_details: {
          email: billingDetails.email,
          name: billingDetails.name
        },
        card
      }
    })

    if (confirmCardSetupError) return stopLoadingAndLogError(confirmCardSetupError)

    // Save the paymentMethod in the backend
    const { data: { CreatePaymentMethod_v1: { id: paymentMethodId } }, errors: createCreditCardError } = await createCreditCard({
      awaitRefetchQueries: true,
      refetchQueries: [GetPaymentMethodsDocument, GetInvoiceAndPaymentMethodsDocument, GetContractPaymentMethodDocument],
      variables: {
        name: translate('payment_method--card'),
        stripeId: setupIntent.payment_method,
        target: PaymentMethodTarget.CreditCard
      }
    })
    if (createCreditCardError) return stopLoadingAndLogError(createCreditCardError)

    // Processing completed
    track({ name: SharedEvents.PaymentMethod.Created, payload: { target: PaymentMethodTarget.CreditCard } })
    setLoading(false)
    setError(false)
    return [paymentMethodId, null]
  }

  return [createPaymentMethod, { error, loading }]
}
