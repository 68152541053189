import { eMask, eMaskIv } from '../../configuration/env'

function hexStringToArrayBuffer (hexString: string) {
  const arrayBuffer = new ArrayBuffer(hexString.length / 2)
  const uint8Array = new Uint8Array(arrayBuffer)

  for (let i = 0; i < hexString.length; i += 2) {
    uint8Array[i / 2] = parseInt(hexString.substr(i, 2), 16)
  }

  return arrayBuffer
}

const EMAIL_ALGORITHM_CONFIG = {
  length: 256,
  name: 'AES-CBC'
}

export const decryptEmail = async (encryptedEmail: number[]) => {
  // import aes key from local storage
  const privateKey = await window.crypto.subtle.importKey(
    'raw',
    hexStringToArrayBuffer(eMask),
    EMAIL_ALGORITHM_CONFIG,
    true,
    ['decrypt'])
  const arrayBufferEmail = await window.crypto.subtle.decrypt({ iv: hexStringToArrayBuffer(eMaskIv), name: 'AES-CBC' }, privateKey, new Uint8Array(encryptedEmail))
  return new TextDecoder().decode(arrayBufferEmail)
}
