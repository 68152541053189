import { Colors, eerieBlack } from '../../style/colors'
import { ContractType } from '../../lib/apollo/types'
import { Icon } from '../icon'
import { Layout, Spacing } from '../../style/spacing'
import { Sizes } from '../../style/sizes'
import { Typography } from '../../style/typography'
import { css } from '@emotion/react'
import { numberCounterStyle } from './index.css'
import { translate } from '../../lib/localization'
import React from 'react'
import icon from './icon.svg'

const formatter = new Intl.NumberFormat('it-IT', {
  currency: 'EUR',
  maximumFractionDigits: 0,
  style: 'currency' // (causes 2500.99 to be printed as $2,501)
})

export interface NumberCounterProps {
  noFormat?: boolean
  value: number
  color: string
  subtitle: string
  cta?: Function
  type?: ContractType
}

export const NumberCounter: React.FC<NumberCounterProps> = ({
  noFormat = false,
  type = undefined,
  value,
  color,
  subtitle,
  cta
}) => {
  return (
    <div css={[numberCounterStyle, Colors.BackgroundColor.eerieBlack0, Layout.display_flex]}>
      <div css={[Layout.flex_grow_1]}>
        <h3 css={[Typography.heading.h4_700, Spacing.Margin.vertical_0]}>{ noFormat ? value : formatter.format(value)} { type && translate(`consumption_unit--${type}`)}</h3>
        <div className={'subtitle_box'} css={[css`background-color: ${color}`, Spacing.Margin.right_6]}/>
        <h5
          css={[Typography.label.label4_400, Spacing.Margin.vertical_0, Layout.display_inline_block, Colors.Color.eerieBlack600]}>{subtitle}</h5>
      </div>
      {
        cta && <div className={'cta'} onClick={() => cta()} css={[Layout.vertical_center, Typography.Style.pointer]}>
          <Icon src={icon} size={Sizes.xlarge} color={eerieBlack['400']}/>
        </div>
      }
    </div>
  )
}
