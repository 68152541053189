import { Typography } from '../../style/typography'
import { azul, darkMint, marigold, uaRed } from '../../style/colors'
import { css } from '@emotion/react'

export const labelStyle = css`
  border-radius: 6px;

  ${Typography.Caption.caption1_regular}

  display: inline-flex;
  height: 22px;
  align-items: center;

  padding: 2px 8px;

  &[data-style='primary'] {
    background: ${azul['50']};
    border: 1px solid ${azul['200']};
    color: ${azul['500']};
  }

  &[data-style='success'] {
    background: ${darkMint['50']};
    border: 1px solid ${darkMint['200']};
    color: ${darkMint['500']};
  }

  &[data-style='failed'] {
    background: ${uaRed['50']};
    border: 1px solid ${uaRed['200']};
    color: ${uaRed['500']};
  }

  &[data-style='progress'] {
    background: ${marigold['50']};
    border: 1px solid ${marigold['200']};
    color: ${marigold['500']};
  }
`
