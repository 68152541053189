import { Colors, eerieBlack } from '../../style/colors'
import { Icon } from '../icon'
import { ItemLayout } from '../../layouts/item'
import { Label } from '../../lib/apollo/types'
import { Layout, Spacing } from '../../style/spacing'
import { Tag } from '../tag'
import { Typography } from '../../style/typography'
import React from 'react'

export interface SectionHeaderProps {
  icon: string
  iconColor?: string
  title: string
  path: string
  tags?: Array<Label>
}

export const tagsList = (tags: Array<Label>, type) => {
  return tags?.map(tag => {
    return <Tag title={tag?.name} color={tag?.color} key={tag?.id} type={type}/>
  })
}

const Header: React.FC<SectionHeaderProps> = ({
  tags = [],
  icon,
  title,
  path,
  iconColor = eerieBlack['800']
}) => (
  <ItemLayout
    css={[Spacing.Padding.horizontal_6, Layout.flex_1]}
    leftElement={<div css={[Spacing.Padding.right_12]}><Icon type={'circle'} raised src={icon} backgroundColor={eerieBlack['0']} color={iconColor}/></div>}
    firstTextRow={<div css={[Typography.label.label3_500, Colors.Color.eerieBlack500, Spacing.Margin.bottom_6]}>{path}</div>}
    secondTextRow={
      <>
        <div css={[Typography.heading.h5_700, { whiteSpace: 'nowrap' }]}>{title}</div>
        <div css={[Layout.ColumnGap.sm, Layout.display_flex, Layout.flex_1]}>
          {tagsList(tags, 'medium')}
        </div>
      </>
    }
  />
)

export const SectionHeader = React.memo(Header)
