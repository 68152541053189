/* eslint-disable react/jsx-key */
import { BusinessNavigatorParams, Screens } from '../../main/types'
import { BusinessTable, BusinessTableProps } from '../businessTable'
import { Colors, eerieBlack } from '../../style/colors'
import { Contract } from '../../lib/apollo/types'
import { ContractIcon } from './contractIcon'
import { ContractStatusLabel } from './contractStatusLabel'
import { Icon } from '../index'
import { Layout, Spacing } from '../../style/spacing'
import { Sizes } from '../../style/sizes'
import { StackNavigationProp } from '@react-navigation/stack'
import { Typography } from '../../style/typography'
import { styleHoverIcon } from './index.css'
import { tagsList } from '../sectionHeader'
import { translate } from '../../lib/localization'
import { useNavigation } from '@react-navigation/native'
import React, { useMemo } from 'react'
import actionIcon from '../../images/icons/search.svg'

type BusinessContractTableProps = Omit<BusinessTableProps, 'columns' | 'data'> & {
  contracts: Array<Contract>
}

interface CellProps {
  value: any
  row: {
    original: Contract
    toggleRowSelected: (boolean) => void
    isSelected: boolean
  }
}

export const SelectItem: React.FC<{
  toggleRowSelected: (boolean) => void
  isSelected: boolean
}> = ({
  children,
  toggleRowSelected,
  isSelected
}) => {
  return <div css={[Layout.flex_1, Spacing.Padding.vertical_12, Spacing.Margin.vertical_negative_12]}
    onClick={() => toggleRowSelected(!isSelected)}>
    {children}
  </div>
}

const BusinessContractTable: React.FC<BusinessContractTableProps> = ({
  contracts,
  ...props
}) => {
  const navigation = useNavigation<StackNavigationProp<BusinessNavigatorParams>>()
  const columns = useMemo(() => ([
    {
      Cell: ({ value, row: { original: contract, ...rest } }: CellProps) => {
        return <SelectItem {...rest} >
          <ContractIcon type={contract.type}/>
          <span css={[Typography.label.label3_500, Colors.Color.eerieBlack800]}>{value}</span>
          <div css={[Layout.ColumnGap.sm, Layout.display_flex, Layout.flex_1, Spacing.Margin.top_4]}>
            {tagsList(contract?.Labels, 'small')}
          </div>
        </SelectItem>
      },
      Header: translate('business_contract_table__header__name'),
      accessor: 'name'
    },
    {
      Cell: ({ row: { original: contract, ...rest } }: CellProps) =>
        <SelectItem {...rest}>
          <ContractStatusLabel contract={contract}/>
        </SelectItem>,
      Header: translate('business_contract_table__header__status'),
      accessor: 'status'
    },
    {
      Cell: ({ row: { original: contract, ...rest } }: CellProps) => (
        <SelectItem {...rest}>
          <p css={[Typography.label.label3_400]}>
            {contract?.Items?.[0]?.Contact.address_short}
          </p>
        </SelectItem>),
      Header: translate('business_contract_table__header__address_short'),
      accessor: 'OwnerContact.address_short'
    },
    {
      Cell: ({ row: { original: contract } }: CellProps) => {
        return <div
          css={[Layout.flex_1, Layout.horizontal_center, styleHoverIcon]}
          onClick={() => navigation.navigate(Screens.BusinessContract, { id: contract.id })}><Icon src={actionIcon}
            size={Sizes.xsmall}
            color={eerieBlack['500']}/>
        </div>
      },
      Header: translate('contracts-table__header--detail'),
      accessor: 'action',
      width: '24px'
    }
  ]), [navigation])

  return <BusinessTable columns={columns} data={contracts} {...props}/>
}

export default BusinessContractTable
