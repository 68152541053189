import { css } from '@emotion/react'

export const placeholderStyle = css`
  background-color: rgba(222, 222, 222, .4);
  border-radius: 6px;
  margin: 0;
  cursor: progress;
  position: relative;
  overflow: hidden;

  &[data-animated='true'] {
    :before {
      content: '';
      display: block;
      position: absolute;
      left: -150px;
      top: 0;
      height: 100%;
      width: 50px;
      background: linear-gradient(to right, transparent 0%, rgba(222, 222, 222, .5) 50%, transparent 100%);
      animation: load 3s cubic-bezier(0.4, 0.0, 0.2, 1) infinite;
    }
  }

  @keyframes load {
    from {
      left: -150px;
    }
    to {
      left: 100%;
    }
  }
`
