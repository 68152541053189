import { css } from '@emotion/react'
import { eerieBlack } from '../../style/colors'

export const accordionStyle = css`
  border-radius: 10px;
  padding: 8px 18px;
  font-weight: 400;

  &[data-border='true'] {
    border: 1px solid ${eerieBlack['200']};
  }

  p{
    color: ${eerieBlack['600']};
    margin-top: 8px;
    margin-bottom: 8px;
  }
  strong{
    font-family: Inter, sans-serif;
    font-weight: 400!important;
  }
`
