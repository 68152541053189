import { ActionsTypes, GapSizes, MainScreenLayout } from '../../../layouts/mainScreen'
import { Card, Spacer } from '../../../components'
import { Layout, Spacing } from '../../../style/spacing'
import { NumberCounter } from '../../../components/numberCounter'
import { PageLayout } from '../../../layouts/page'
import { Placeholder } from '../../../components/placeholder'
import { Screens } from '../../../main/types'
import { Typography } from '../../../style/typography'
import { contractAzul, eerieBlack, royalPurple } from '../../../style/colors'
import { logAndSendToErrorTracker } from '../../../services/errorTracker'
import { translate } from '../../../lib/localization'
import { useBusinessFilters } from '../../../hooks/useBusinessFilters'
import { useGetBusinessDashboardHomeSummaryQuery, useGetInvoicesListQuery } from '../../../lib/apollo/hooks'
import { usePagination } from '../../../hooks/usePagination'
import { useSetBusinessFiltersOnMount } from '../../../hooks/useSetBusinessFiltersOnMount'
import BusinessInvoiceTable from '../../../components/businessInvoiceTable'
import DocumentsOutlineIcon from '../../../images/icons/outline/documents.svg'
import React from 'react'

const PlaceholderCards = <div css={[Layout.display_flex, Layout.flex_justify_spaceBetween]}>
  <Placeholder width={'30%'} height={'150px'}/>
  <Placeholder width={'30%'} height={'150px'}/>
  <Placeholder width={'30%'} height={'150px'}/>
</div>

export const BusinessInvoicesScreen = ({ navigation, route }) => {
  useSetBusinessFiltersOnMount()
  const [offset, limit, onPaginationChange] = usePagination()
  const { rawLabels, scopeLabels } = useBusinessFilters(route.name)
  const { data: dataCount, loading: loadingCount } = useGetBusinessDashboardHomeSummaryQuery({
    fetchPolicy: 'cache-and-network',
    onError: error => {
      logAndSendToErrorTracker(error)
    },
    variables: {
      labels: rawLabels
    }
  })
  const { data, loading } = useGetInvoicesListQuery({
    fetchPolicy: 'cache-and-network',
    onError: error => {
      logAndSendToErrorTracker(error)
    },
    variables: {
      limit,
      offset,
      scope: scopeLabels
    }
  })

  const invoices = data?.Invoices ?? []
  let title = translate('screens_title__all_invoices')
  if (rawLabels.length === 1) title = translate('screens_title__all_invoices__one_label', { tag: rawLabels[0] })
  if (rawLabels.length > 1) title = translate('screens_title__all_invoices__multiple_labels', { tagsLength: rawLabels.length })

  return (
    <PageLayout>
      <MainScreenLayout gapSize={GapSizes.lg} sectionHeaderProps={{
        icon: DocumentsOutlineIcon,
        path: `${translate('gestione-invoice__path')} /`,
        title
      }}
      toolBoxConfiguration={{
        actions: true,
        actionsTypes: ActionsTypes.invoice,
        contractTagManager: true,
        tagPicker: true
      }}>
        {loadingCount
          ? PlaceholderCards
          : <div css={[Layout.display_flex, Layout.flex_justify_spaceBetween]}>
            <Card css={[Layout.flex_1, Spacing.Margin.right_12]}>
              <div css={Typography.heading.h6_700}>{translate('business__invoices_page__all_contracts')}</div>
              <Spacer height={24}/>
              <NumberCounter
                value={dataCount?.BusinessDataSum_v1.invoices}
                color={eerieBlack['500']}
                subtitle={translate('business__contracts_page__all_contracts_type')}
                cta={() => {
                  navigation.navigate(Screens.BusinessInvoices)
                }}
              />
            </Card>
            <Card css={[Layout.flex_1, Spacing.Margin.right_12, Spacing.Margin.left_12]}>
              <div css={Typography.heading.h6_700}>{translate('business__invoices_page__ee_contracts')}</div>
              <Spacer height={24}/>
              <NumberCounter
                value={dataCount?.BusinessDataSum_v1.energy_invoices}
                color={contractAzul['500']}
                subtitle={translate('business__contracts_page__ee_contracts_type')}
                cta={() => {
                  navigation.navigate(Screens.BusinessInvoices, { tag: 'Luce' })
                }}
              />
            </Card>
            <Card css={[Layout.flex_1, Spacing.Margin.left_12]}>
              <div css={Typography.heading.h6_700}>{translate('business__invoices_page__gas_contracts')}</div>
              <Spacer height={24}/>
              <NumberCounter
                value={dataCount?.BusinessDataSum_v1.gas_invoices}
                color={royalPurple['500']}
                subtitle={translate('business__contracts_page__gas_contracts_type')}
                cta={() => {
                  navigation.navigate(Screens.BusinessInvoices, { tag: 'Gas' })
                }}
              />
            </Card>
          </div>

        }
        <BusinessInvoiceTable
          loading={loading}
          onPaginationChange={onPaginationChange}
          invoices={invoices}
          pageCount={Math.ceil((data?.count?.value ?? 0) / limit)}
          offset={offset}
          limit={limit}
        />
      </MainScreenLayout>
    </PageLayout>
  )
}
