import * as Sentry from '@sentry/react'
import { GET_USER_QUERY } from '../queries/user'
import { GetUserQuery } from '../lib/apollo/operations'
import { authState } from '../store/state'
import { createApolloClient } from '../configuration/apollo'
import { overmindInstance } from '../store'
import { segmentAnalytics } from '../tracking/init'

export const onLogin = async () => {
  const client = createApolloClient()

  const {
    data: {
      Me: { id, firstName, lastName, fullName, email }
    }
  } = await client.query<GetUserQuery>({ query: GET_USER_QUERY })

  Sentry.setUser({ email, id, username: fullName })
  segmentAnalytics.identify(id, { email, firstName, lastName })

  window.Intercom('boot', {
    app_id: 'e3fkgtlv',
    email: email,
    name: fullName
  })

  overmindInstance.actions.setAuthState(authState.loggedIn)
}

export const onLogout = async () => {
  window.Intercom('shutdown')
  segmentAnalytics.reset()
  Sentry.configureScope(scope => scope.setUser(null))

  overmindInstance.actions.setAuthState(authState.loggedOut)
}
