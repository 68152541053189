import { Typography } from '../../style/typography'
import { captionStyle } from './index.css'
import React from 'react'

export enum CaptionStyles {
  Default = 'default',
  Title = 'title'
}

export interface CaptionProps {
  /**
   * Emotion style override
   */
  className?: string

  /**
   * Content to display
   */
  content?: any

  /**
   * Style
   */
  style?: CaptionStyles
}

export const Caption: React.FC<CaptionProps> = ({
  children,
  content,
  style,
  ...props
}) => {
  return (
    <div {...props} css={[captionStyle, Typography.paragraph.p3_600]} data-style={style}>{content || children}</div>
  )
}
