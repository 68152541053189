import { useCallback } from 'react'
import { useCreateFirebaseCustomTokenMutation } from '../lib/apollo/hooks'
import { webOrderUrl } from '../configuration/env'

export enum Products {
  Scopri = 'scopri'
}

type NavigateToOtherProductProps = {
  queryParamName: string
  product: Products
}

const productMapping = {
  [Products.Scopri]: webOrderUrl
}

export const useNavigateToOtherProduct = ({ queryParamName, product }: NavigateToOtherProductProps) => {
  const [createFirebaseCustomToken, { loading }] = useCreateFirebaseCustomTokenMutation()

  const navigate = useCallback(async () => {
    const { data: { CreateFirebaseCustomToken_v1: firebaseCustomToken } } = await createFirebaseCustomToken()
    window.location.href = `${productMapping[product]}?${queryParamName}=${firebaseCustomToken}`
  }, [createFirebaseCustomToken, product, queryParamName])

  return { loading, navigate }
}
