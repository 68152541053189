import { Card, Spacer } from '../../../components'
import { GapSizes, MainScreenLayout } from '../../../layouts/mainScreen'
import { Layout } from '../../../style/spacing'
import { NumberCounter } from '../../../components/numberCounter'
import { PageLayout } from '../../../layouts/page'
import { Placeholder } from '../../../components/placeholder'
import { Screens } from '../../../main/types'
import { Typography } from '../../../style/typography'
import { contractAzul, eerieBlack, royalPurple } from '../../../style/colors'
import { logAndSendToErrorTracker } from '../../../services/errorTracker'
import { translate } from '../../../lib/localization'
import { useActions } from '../../../store/index'
import { useBusinessFilters } from '../../../hooks/useBusinessFilters'
import {
  useGetBusinessDashboardHomeSummaryQuery,
  useGetContractsQuery
} from '../../../lib/apollo/hooks'
import { usePagination } from '../../../hooks/usePagination'
import { useSetBusinessFiltersOnMount } from '../../../hooks/useSetBusinessFiltersOnMount'
import BusinessContractTable from '../../../components/businessContractTable'
import ListIcon from '../../../images/icons/list.svg'
import React, { useCallback } from 'react'
import dayjs from 'dayjs'

const PlaceholderCards = <div css={[Layout.display_flex, Layout.flex_justify_spaceBetween, Layout.ColumnGap.md]}>
  <Placeholder width={'30%'} height={'150px'}/>
  <Placeholder width={'30%'} height={'150px'}/>
  <Placeholder width={'30%'} height={'150px'}/>
</div>

export const BusinessContractsScreen = ({ navigation, route }) => {
  useSetBusinessFiltersOnMount()
  const { setSelectedContracts } = useActions()
  const [offset, limit, onPaginationChange] = usePagination()
  const { scopeLabels, rawLabels } = useBusinessFilters(route.name)

  const { data: dataDashboardSummary, loading: loadingSummary, refetch: refetchSummary } = useGetBusinessDashboardHomeSummaryQuery({
    fetchPolicy: 'cache-and-network',
    onError: error => {
      logAndSendToErrorTracker(error)
    },
    variables: {
      end_date: dayjs().endOf('month').toISOString(),
      labels: rawLabels,
      start_date: dayjs().subtract(5, 'year').startOf('month').toISOString()
    }
  })
  const { data, loading, refetch: refetchContracts } = useGetContractsQuery({
    fetchPolicy: 'cache-and-network',
    variables: {
      limit,
      offset,
      scope: scopeLabels
    }
  })
  const contracts = data?.Contracts ?? []
  const onMutation = () => {
    refetchSummary()
    refetchContracts()
  }
  const {
    contracts: contractsCount,
    energy_contracts: energyContracts,
    gas_contracts: gasContracts
  } = dataDashboardSummary?.BusinessDataSum_v1 ?? {}

  let title = translate('screens_title__all_contracts')
  if (rawLabels.length === 1) title = translate('screens_title__all_contracts__one_label', { tag: rawLabels[0] })
  if (rawLabels.length > 1) title = translate('screens_title__all_contracts__multiple_labels', { tagsLength: rawLabels.length })
  const onSelectionChanges = useCallback((selectedContracts) => {
    setSelectedContracts(
      selectedContracts.map(contract => {
        return { Labels: contract?.Labels.map(label => ({ ...label })), id: contract.id }
      }))
  }, [setSelectedContracts])
  return (
    <PageLayout>
      <MainScreenLayout gapSize={GapSizes.lg} sectionHeaderProps={{
        icon: ListIcon,
        path: `${translate('gestione-utenza__path')} /`,
        title
      }}
      toolBoxConfiguration={{
        actions: true,
        contractTagManager: true,
        onMutation,
        tagPicker: true
      }}>
        {loadingSummary
          ? PlaceholderCards
          : <div css={[Layout.display_flex, Layout.flex_justify_spaceBetween, Layout.ColumnGap.md]}>
            <Card css={[Layout.flex_1]}>
              <div css={Typography.heading.h6_700}>{translate('business__contracts_page__all_contracts')}</div>
              <Spacer height={24}/>
              <NumberCounter
                noFormat
                value={contractsCount}
                color={eerieBlack['500']}
                subtitle={translate('business__contracts_page__all_contracts_type')}
                cta={() => {
                  navigation.navigate(Screens.BusinessContracts)
                }}
              />
            </Card>
            <Card css={[Layout.flex_1]}>
              <div css={Typography.heading.h6_700}>{translate('business__contracts_page__ee_contracts')}</div>
              <Spacer height={24}/>
              <NumberCounter
                noFormat
                value={energyContracts}
                color={contractAzul['500']}
                subtitle={translate('business__contracts_page__ee_contracts_type')}
                cta={() => {
                  navigation.navigate(Screens.BusinessContracts, { tag: 'Luce' })
                }}
              />
            </Card>
            <Card css={[Layout.flex_1]}>
              <div css={Typography.heading.h6_700}>{translate('business__contracts_page__gas_contracts')}</div>
              <Spacer height={24}/>
              <NumberCounter
                noFormat
                value={gasContracts}
                color={royalPurple['500']}
                subtitle={translate('business__contracts_page__gas_contracts_type')}
                cta={() => {
                  navigation.navigate(Screens.BusinessContracts, { tag: 'Gas' })
                }}
              />
            </Card>
          </div>

        }
        <BusinessContractTable
          loading={loading}
          contracts={contracts}
          onPaginationChange={onPaginationChange}
          onSelectionChanges={onSelectionChanges}
          pageCount={Math.ceil((data?.count?.value ?? 0) / limit)}
          offset={offset}
          limit={limit}
        />
      </MainScreenLayout>
    </PageLayout>
  )
}
