import { envLocal, envProduction, envStaging } from '../constants'
import { getAuth } from 'firebase/auth'
import { initializeApp as initializeFirebaseApp } from 'firebase/app'

const initializeApp = () => {
  switch (process.env.REACT_APP_STAGE) {
    case envLocal:
      return initializeFirebaseApp({
        apiKey: 'AIzaSyBlXXz8f7Cdyhi8ndELR3q_Ddl94hyAxIg',
        authDomain: 'tate-nb.firebaseapp.com',
        databaseURL: 'https://tate-nb.firebaseio.com',
        messagingSenderId: '514874185662',
        projectId: 'tate-nb',
        storageBucket: 'tate-nb.appspot.com'
      })

    case envStaging:
      return initializeFirebaseApp({
        apiKey: 'AIzaSyBlXXz8f7Cdyhi8ndELR3q_Ddl94hyAxIg',
        authDomain: 'tate-nb.firebaseapp.com',
        databaseURL: 'https://tate-nb.firebaseio.com',
        messagingSenderId: '514874185662',
        projectId: 'tate-nb',
        storageBucket: 'tate-nb.appspot.com'
      })

    case envProduction:
      return initializeFirebaseApp({
        apiKey: 'AIzaSyDo2qEP8Tzwz2ECKz839r2gDPhA8YoS77g',
        authDomain: 'tate-production.firebaseapp.com',
        databaseURL: 'https://tate-production.firebaseio.com',
        messagingSenderId: '613458347598',
        projectId: 'tate-production',
        storageBucket: 'tate-production.appspot.com'
      })

    default:
      return initializeFirebaseApp({
        apiKey: 'AIzaSyBlXXz8f7Cdyhi8ndELR3q_Ddl94hyAxIg',
        authDomain: 'tate-nb.firebaseapp.com',
        databaseURL: 'https://tate-nb.firebaseio.com',
        messagingSenderId: '514874185662',
        projectId: 'tate-nb',
        storageBucket: 'tate-nb.appspot.com'
      })
  }
}

export const firebaseApp = initializeApp()
export const auth = getAuth(firebaseApp)
