import { css } from '@emotion/react'
import { eerieBlack } from '../../style/colors'

export const captionStyle = css`
  font-family: Inter, sans-serif;
  font-style: normal;
  letter-spacing: 0.1px;
  color: ${eerieBlack['800']};
  padding: 0;
  margin: 0;

  strong {
    font-weight: 600;
  }

  a {
    text-decoration: underline;
    color: ${eerieBlack['800']};
  }

  a:visited {
    text-decoration: underline;
    color: ${eerieBlack['800']};
  }

  p{
    padding: 0;
    margin: 0;
  }

  &[data-style='title']{
    font-size: 17px;
    font-weight: 600;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: left;
  }
  &[data-style='footNote']{
    color: ${eerieBlack['600']};
  }
`
