import { ActionsTypes } from '../../layouts/mainScreen'
import { Colors, eerieBlack } from '../../style/colors'
import { Icon } from '../icon'
import { ItemLayout } from '../../layouts/item'
import { Layout, Spacing } from '../../style/spacing'
import { PickerContainer } from '../pickerContainer'
import { Sizes } from '../../style/sizes'
import { Typography } from '../../style/typography'
import { actionState } from '../../store/state'
import { translate } from '../../lib/localization'
import { useActions } from '../../store'
import React from 'react'
import cardIcon from '../../images/icons/outline/card.svg'
import documentsIcon from '../../images/icons/outline/documents.svg'

export interface ActionPopUpProps {
  onToggle?: Function
  actionsTypes: ActionsTypes
}

export const ActionPopUp: React.FC<ActionPopUpProps> = ({ onToggle, actionsTypes }) => {
  const { setAction } = useActions()

  return (
    <PickerContainer onToggle={onToggle}>
      {
        actionsTypes === 'contract' &&
        <div onClick={() => setAction(actionState.changePaymentMethod)}
          css={[Colors.Hover.BackgroundColor.eerieBlack100, Spacing.Padding.all_8, Layout.rounded_4, Spacing.Margin.bottom_12]}>
          <ItemLayout
            css={[Typography.Style.pointer]}
            leftElement={<Icon src={cardIcon} color={eerieBlack['700']} css={Spacing.Margin.right_8}
              size={Sizes.xsmall}/>}
            firstTextRow={<div
              css={[Typography.label.label3_500, { whiteSpace: 'nowrap' }]}>{translate('business__popup__change_payment_method')}</div>}
          />
        </div>
      }
      <div onClick={() => setAction(actionState.downloadCsv)}
        css={[Colors.Hover.BackgroundColor.eerieBlack100, Spacing.Padding.all_8, Layout.rounded_4]}>
        <ItemLayout
          css={[Typography.Style.pointer]}
          leftElement={<Icon src={documentsIcon} color={eerieBlack['700']} css={Spacing.Margin.right_8}
            size={Sizes.xsmall}/>}
          firstTextRow={<div
            css={[Typography.label.label3_500, { whiteSpace: 'nowrap' }]}>{translate('business__popup__download_csv')}</div>}
        />
      </div>
      {
        // TODO:: ESPORTAZIONE CSV
        /*
            <Spacer height={8} />
        <div css={Layout.border_bottom}/>
        <Spacer height={8} />
        <ItemLayout
          css={Typography.Style.pointer}
          leftElement={<Icon src={shareIcon} color={eerieBlack['700']} css={Spacing.Margin.right_8} size={Sizes.xsmall}/>}
          firstTextRow={<div css={Typography.label.label3_500}>Esporta in .csv</div>}
        />
          */
      }
    </PickerContainer>
  )
}
