import { css } from '@emotion/react'
import { placeholderStyle } from './index.css'
import React from 'react'

interface PlaceholderProps {
  width: string
  height: string
  radius?: number
  padding?: number
  className?: string
  animated?: boolean
}

export const Placeholder: React.FC<PlaceholderProps> = ({
  width,
  height,
  radius,
  padding,
  className,
  animated = true,
  children
}) => {
  let additionalStyle = ''
  if (radius) {
    additionalStyle += `border-radius: ${radius}px;`
  }
  if (padding) {
    additionalStyle += `padding: ${padding}px;`
  }

  const additionalCss = css`
    width: ${width};
    height: ${height};
    ${additionalStyle}
  `

  return (
    <div className={className} css={[placeholderStyle, additionalCss]} data-animated={String(animated)}>{children}</div>
  )
}
