import { Button, Spacer } from '../index'
import { Colors } from '../../style/colors'
import { ContractTypeMap } from '../../enums'
import { Layout, Spacing } from '../../style/spacing'
import { Placeholder } from '../placeholder'
import { Products, useNavigateToOtherProduct } from '../../hooks/useNavigateToOtherProduct'
import { Screens } from '../../main/types'
import { SidebarContractDropdown, SidebarContractItem } from './components/contractDropdown'
import { SidebarItem } from './components/item'
import { SidebarReferralItem } from './components/referralItem'
import { Typography } from '../../style/typography'
import { WidgetTitle } from '../../widgets/widgetTitle'
import { sideNavStyle } from './index.css'
import { translate } from '../../lib/localization'
import { useGetContractsQuery, useGetEnabledLabelsQuery } from '../../lib/apollo/hooks'
import { useRoute } from '@react-navigation/native'
import Card from '../../images/icons/card.svg'
import CardOutlineIcon from '../../images/icons/outline/card.svg'
import DocumentsOutlineIcon from '../../images/icons/outline/documents.svg'
import IdCardOutlineIcon from '../../images/icons/outline/id-card.svg'
import LeftArrowIcon from '../../images/icons/arrow-forward.svg'
import ListOutlineIcon from '../../images/icons/outline/list.svg'
import Person from '../../images/icons/person.svg'
import PersonOutlineIcon from '../../images/icons/outline/person.svg'
import PriceTagOutlineIcon from '../../images/icons/outline/pricetag.svg'
import React from 'react'
import SettingsOutlineIcon from '../../images/icons/outline/settings.svg'
import SpeedometerOutlineIcon from '../../images/icons/outline/speedometer.svg'
import packageInfo from '../../../package.json'

export const SideNav = () => {
  const route = useRoute<any>()
  const { data, loading } = useGetContractsQuery({
    fetchPolicy: 'cache-first'
  })
  const contracts = data?.Contracts ?? []

  const { navigate, loading: navigationLoading } = useNavigateToOtherProduct({
    product: Products.Scopri,
    queryParamName: 'tateCustomToken'
  })

  return (
    <nav css={[sideNavStyle]}>
      <div className={'widget-container'}>
        <div css={[Spacing.Margin.left_34, Spacing.Margin.top_16, Spacing.Margin.bottom_24]}>
          <Button
            style='primary'
            disabled={navigationLoading}
            loading={navigationLoading}
            size={'create-contract'}
            title={translate(contracts.length ? 'sidenav_button__activate_another_contract' : 'sidenav_button__activate_new_contract')}
            onClick={navigate}
          />
        </div>
        {/* Gestione utenze */}
        <WidgetTitle css={[Spacing.Padding.left_34, Spacing.Margin.bottom_8, Spacing.Margin.top_16]}
          title={(translate('gestione-utenza__path')).toUpperCase()}/>
        {
          loading && <>
            <Placeholder width={'100%'} height={'44px'}/>
            <Spacer height={8}/>
            <div style={{ paddingLeft: '34px' }}>
              <Placeholder width={'80%'} height={'16px'}/>
              <Spacer height={8}/>
              <Placeholder width={'80%'} height={'16px'}/>
              <Spacer height={8}/>
              <Placeholder width={'80%'} height={'16px'}/>
            </div>
            <Spacer height={16}/>
            <Placeholder width={'100%'} height={'44px'}/>
          </>
        }
        {(!loading && contracts.length === 0) &&
        <SidebarContractItem
          icon={LeftArrowIcon}
          title={translate('screens_title__no_contracts')}
          active={true}
          path={Screens.Home}/>
        }
        {(!loading && contracts.length > 0) &&
        <section css={{ maxHeight: '300px', overflow: 'scroll' }} className='menu-utenze'>
          {contracts.map(({ id, name, type }) => (
            <SidebarContractDropdown
              currentActiveContract={route.params?.contractId}
              key={id}
              contractType={ContractTypeMap[type]}
              contractName={name}
              contractId={id}
            />
          ))}
        </section>
        }
        {/* Gestione account */}
        <WidgetTitle
          css={[Spacing.Padding.left_34, Spacing.Margin.bottom_8, Spacing.Margin.top_24, Typography.fonts.upperCase]}
          title={translate('screens_title__account_settings')}/>
        <section className="menu-account">
          <SidebarItem path={Screens.Profilo} icon={Person} title={translate('sidenav_menu__title_user')}/>
          <SidebarItem path={Screens.Pagamenti} icon={Card} title={translate('sidenav_menu__title_payment_methods')}/>
        </section>
        <SidebarReferralItem/>
      </div>
    </nav>)
}

export const BusinessSideNav = () => {
  const { data } = useGetEnabledLabelsQuery()

  return (
    <nav css={[sideNavStyle, Layout.hide_scrollbar, Layout.scrollYEnabled]}>
      <SidebarItem path={Screens.BusinessHome} icon={SpeedometerOutlineIcon}
        title={translate('sidenav_menu__title_dashboard')}/>
      <SidebarItem path={Screens.BusinessContracts} icon={ListOutlineIcon}
        title={translate('sidenav_menu__title_business_contracts')}/>
      <SidebarItem path={Screens.BusinessInvoices} icon={DocumentsOutlineIcon}
        title={translate('sidenav_menu__title_business_invoices')}/>

      {
        (data?.Labels.length > 0) && <>
          <WidgetTitle
            css={[Spacing.Padding.left_34, Spacing.Margin.bottom_8, Spacing.Margin.top_24, Typography.fonts.upperCase]}
            title={translate('screens_title__contract_labels')}/>
          {
            data?.Labels?.map(label => <SidebarItem path={Screens.BusinessContracts} tag={label.name}
              icon={PriceTagOutlineIcon} key={label.id}
              title={`Utenze ${label.name}`}/>)
          }
        </>
      }

      {
        (data?.Labels.length > 0) && <>
          <WidgetTitle
            css={[Spacing.Padding.left_34, Spacing.Margin.bottom_8, Spacing.Margin.top_24, Typography.fonts.upperCase]}
            title={translate('screens_title__invoice_labels')}/>
          {
            data?.Labels?.map(label => <SidebarItem path={Screens.BusinessInvoices} tag={label.name}
              icon={PriceTagOutlineIcon} key={label.id}
              title={`Bollette ${label.name}`}/>)
          }
        </>
      }

      <WidgetTitle
        css={[Spacing.Padding.left_34, Spacing.Margin.bottom_8, Spacing.Margin.top_24, Typography.fonts.upperCase]}
        title={translate('screens_title__account_settings')}/>
      <section className="menu-account">
        <SidebarItem path={Screens.BusinessProfile} icon={PersonOutlineIcon}
          title={translate('sidenav_menu__title_user')}/>
        <SidebarItem path={Screens.BusinessCustomers} icon={IdCardOutlineIcon}
          title={translate('sidenav_menu__title_customers')}/>
        <SidebarItem path={Screens.BusinessPaymentMethods} icon={CardOutlineIcon}
          title={translate('sidenav_menu__title_payment_methods')}/>
        <SidebarItem path={Screens.BusinessLabel} icon={SettingsOutlineIcon}
          title={translate('sidenav_menu__title_labels')}/>
      </section>
      <p
        css={[Typography.label.label4_400, Colors.Color.eerieBlack500, Spacing.Padding.top_16, Typography.Align.center]}>
        Tate Business, v{packageInfo.version}
      </p>
    </nav>
  )
}
