import { INITIAL_TOOLBOX_STATE } from '../store/state'
import { useActions } from '../store'
import { useEffect } from 'react'
import { useGetLabelsQuery } from '../lib/apollo/hooks'
import { useIsFocused, useRoute } from '@react-navigation/native'

const EMPTY_FILTERS = { monthFilter: {} }
/**
 *   On page mount, this hook recovers the labels from route params object
 *   and resets page filters using default monthFilters
 **/
export const useSetBusinessFiltersOnMount = () => {
  const { name, params } = useRoute<any>()
  const isFocused = useIsFocused()
  const { monthFilter } = INITIAL_TOOLBOX_STATE[name] ?? EMPTY_FILTERS
  const { updatePageFilter } = useActions()
  const { data, loading } = useGetLabelsQuery({
    fetchPolicy: 'cache-and-network'
  })
  // We get filters from url and reset them on page mount
  useEffect(() => {
    if (loading) return // se sta caricando i dati ignora
    if (!isFocused) return // Se non e' in schermo ignora

    // se non c'e' una variabile toglie i tag
    if (!params?.tag || params?.tag === 'undefined') {
      return updatePageFilter({
        filter: {
          monthFilter,
          tagsFilter: {}
        },
        page: name
      })
    }

    const label = data?.Labels.find(tag => tag.name === params?.tag)
    // se la label fornita non esiste non funziona
    if (!label) {
      return updatePageFilter({
        filter: {
          monthFilter,
          tagsFilter: {}
        },
        page: name
      })
    }

    // aggiorna i filtri della pagina
    updatePageFilter({
      filter: {
        monthFilter,
        tagsFilter: params?.tag ? { andFilter: [{ ...label }], orFilter: [] } : {}
      },
      page: name
    })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading, params, isFocused])
}
