import { BusinessNavigatorParams } from '../main/types'
import { Context } from '.'
import { Contract, Invoice } from '../lib/apollo/types'
import { IAction } from 'overmind'
import { PAGE_SIZE } from '../components/businessTable/index'
import { PagePagination, State, ToolBoxFilter, actionState, authState, state as defaultState } from './state'

const RESET_STATE_ON_RESUME_KEY_LIST = ['toolBox', 'selectedContracts']

export const resumeState = ({ state, effects }: Context): void => {
  const stateData = effects.storage.retrieveState()
  Object.keys(stateData).map(key => {
    if (RESET_STATE_ON_RESUME_KEY_LIST.indexOf(key) === -1) state[key] = stateData[key]
    return null
  })
}

interface updateFilterObject {
  page: keyof BusinessNavigatorParams
  filter: ToolBoxFilter
}
interface updatePaginationObject {
  page: keyof BusinessNavigatorParams
  pagination: PagePagination
}
/**
 * We need to reset page pagination on page filter reset
 ***/
export const updatePageFilter = ({ state, actions }: Context, { page, filter }: updateFilterObject) => {
  state.toolBox[page] = { ...state.toolBox[page], ...filter }
  actions.updatePagePagination({
    page,
    pagination: {
      ...(state.pagination[page] ?? { limit: PAGE_SIZE }),
      offset: 0
    }
  })
}

export const resetPageFilter = ({ state }: Context) => {
  state.toolBox = {}
}

export const resetState = ({ state }: Context): void => {
  Object.keys(defaultState).forEach(key => {
    state[key] = defaultState[key]
  })
}

export const onInitializeOvermind = (
  { effects, actions }: Context,
  overmind
): void => {
  actions.resumeState()
  overmind.reaction(
    (state: State): State => state,
    (state: State): void => effects.storage.saveState(state),
    {
      immediate: false,
      nested: true
    }
  )
}

export const setAuthState = ({ state }: Context, currentState: authState): void => {
  state.auth = currentState
}

export const setSelectedContracts = ({ state }: Context, selectedContracs: Array<Contract>): void => {
  state.selectedContracts = selectedContracs
}

export const setAction = ({ state }: Context, action: actionState): void => {
  state.action = action
}

export const updatePagePagination = ({ state }: Context, { page, pagination }: updatePaginationObject) => {
  state.pagination[page] = { ...state.pagination[page], ...pagination }
}

export const setSelectedInvoices = ({ state }: Context, selectedInvoices: Array<Invoice>): void => {
  state.selectedInvoices = selectedInvoices
}

export const setBranchData: IAction<{value?: object}, void> = ({ state }: Context, { value }) => {
  state.branchSessionData = value
}

export const setRedirectUrl: IAction<{value: string}, void> = ({ state }: Context, { value }) => {
  state.redirectUrl = value
}
