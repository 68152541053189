import { CardElement } from '@stripe/react-stripe-js'
import { Control, FieldErrors } from 'react-hook-form'
import { HookFormInputText, TypographyError } from '../../../../../components'
import { Spacing } from '../../../../../style/spacing'
import { StripeCardElementChangeEvent, StripeCardElementOptions } from '@stripe/stripe-js'
import { eerieBlack } from '../../../../../style/colors'
import { translate } from '../../../../../lib/localization'
import React from 'react'

const CARD_ELEMENT_OPTIONS: StripeCardElementOptions = {
  hidePostalCode: true,
  style: {
    base: {
      '::placeholder': {
        color: '#aab7c4'
      },
      color: eerieBlack['800'],
      fontFamily: 'Inter, sans-serif',
      fontSize: '16px',
      lineHeight: '20px'
    },
    invalid: {
      color: '#9e2146'
    }
  }
}

interface CreditCardFormProps {
  formControl: Control
  formErrors: FieldErrors
  onChange: (event: StripeCardElementChangeEvent) => any
  ccError: string
}

const CreditCardForm: React.FC<CreditCardFormProps> = ({ formControl, formErrors, onChange, ccError }) => {
  return <>
    <HookFormInputText
      name="name"
      error={formErrors.name}
      control={formControl}
      rules={{
        required: translate('input_required--name')
      }}
      inputProps={{
        inputMode: 'text',
        label: translate('input_owner_name')
      }}
    />
    <div style={{ background: eerieBlack['100'], borderRadius: '10px', padding: '25px 16px' }} css={Spacing.Margin.top_8}>
      <CardElement
        options={CARD_ELEMENT_OPTIONS}
        className='tate-stripe-input'
        onChange={onChange}
      />
    </div>
    <TypographyError error={ccError} />
  </>
}

export default CreditCardForm
