import { Colors } from '../style/colors'
import { Layout, Spacing } from '../style/spacing'
import { Typography } from '../style/typography'
import { translate } from '../lib/localization'
import React from 'react'

const FooterItem = ({ children, path }) => (
  <a href={path} target={'_blank'} rel={'noopener noreferrer'}>
    <span css={[Typography.label.label3_500, Typography.Style.pointer, Colors.Color.eerieBlack0]}>
      {children}
    </span>
  </a>
)
export const GuestFooter = () => {
  return (
    <footer css={[Layout.display_flex, Spacing.Margin.top_24, { justifyContent: 'center' }]}>
      <FooterItem path={'https://tate.it/legal/privacy-policy'}>
        {translate('text--privacy-policy')}
      </FooterItem>
      <div css={[{ width: 8 }, Spacing.Margin.horizontal_12, Colors.Color.violetBlue300]}>•</div>
      <FooterItem path={'https://tate.it/legal/note-legali'}>
        {translate('text--terms-conditions')}
      </FooterItem>
    </footer>
  )
}
