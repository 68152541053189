import { ChangePaymentMethod } from '../../components/changePaymentMethodPopup'
import { Layout, Spacing } from '../../style/spacing'
import { SectionHeader, SectionHeaderProps } from '../../components/sectionHeader'
import { Spacer } from '../../components'
import { ToolBox } from '../../components/toolBox'
import { css } from '@emotion/react'
import { isBusinessUsageTarget } from '../../configuration/env'
import { useAppState } from '../../store'
import React from 'react'

export enum GapSizes {
  sm = '8px',
  md = '16px',
  lg = '24px'
}

export enum ActionsTypes {
  contract = 'contract',
  invoice = 'invoice'
}

interface MainScreenLayoutProps {
  sectionHeaderProps: SectionHeaderProps
  gapSize?: GapSizes
  className?: string
  showToolbox?: boolean
  toolBoxConfiguration?: {
    tagPicker?: boolean
    calendar?: boolean
    isDoubleDatePicker?: boolean
    contractTagManager?: boolean
    onMutation?: Function
    actions?: boolean
    actionsTypes: ActionsTypes
  }
}

export const MainScreenLayout: React.FC<MainScreenLayoutProps> =
  ({
    sectionHeaderProps,
    children,
    className = '',
    gapSize = GapSizes.md,
    showToolbox = isBusinessUsageTarget,
    toolBoxConfiguration = {
      actions: false,
      calendar: true,
      contractTagManager: false,
      isDoubleDatePicker: true,
      tagPicker: true
    }
  }) => {
    const { action } = useAppState()
    return (
      <>
        <div>
          <Spacer height={48}/>
          {/* TODO: NAVIGATION + ADDRESS OF THE CONTRACT */}
          <div css={[Layout.flex_justify_spaceBetween, Layout.display_flex]}>
            <SectionHeader {...sectionHeaderProps}/>
            {showToolbox && <ToolBox {...toolBoxConfiguration}/>}
          </div>
          <Spacer height={22}/>
          <div
            className={className}
            css={[
              Layout.display_flex,
              Layout.flex_column,
              Layout.hide_scrollbar,
              Spacing.Padding.all_6,
              Layout.scrollYEnabled,
              Spacing.Padding.bottom_24,
              css`border-radius: 10px;
                row-gap: ${gapSize};`]}>
            {children}
            {
              action === 'changePaymentMethod' && <ChangePaymentMethod/>
            }
          </div>
        </div>
      </>
    )
  }
