import { Typography } from '../../style/typography'
import { css } from '@emotion/react'
import { dangerRed, eerieBlack, marigold } from '../../style/colors'

export const typographyErrorStyle = css`
  ${Typography.Footnote.semibold}
  text-align: left;
  margin: 8px 8px 0;

  &[data-style='error'] {
    color: ${dangerRed};
  }

  &[data-style='warning'] {
    color: ${marigold['500']};
  }

  &[data-style='info'] {
    color: ${eerieBlack['800']};
  }
`
