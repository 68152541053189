import { css } from '@emotion/react'

export const sideNavStyle = css`
  max-width: 257px;
  flex: 0 0 257px;
  padding: 16px 24px 16px 8px;
  display: flex;
  flex-direction: column;

  .widget-container {
    overflow-y: auto;
    -ms-overflow-style: none;
    scrollbar-width: 0;
  }

  .widget-container::-webkit-scrollbar {
    display: none;
  }

  section {
    padding: 0 0 24px 0;
    box-shadow: 0px -1px 0px 0px #EBEBEB inset;
  }
`
