import { Badge } from '../badge'
import { Icon } from '../icon'
import { Invoice, InvoiceStatus } from '../../lib/apollo/types'
import { Sizes } from '../../style/sizes'
import { Spacing } from '../../style/spacing'
import { darkMint, marigold, uaRed } from '../../style/colors'
import { translate } from '../../lib/localization'
import React from 'react'
import alarmIcon from '../../images/icons/alarm.svg'
import alertCircle from '../../images/icons/alert-circle.svg'
import checkmark from '../../images/icons/checkmark-circle.svg'

interface InvoiceStatusLabelProps {
    invoice: Invoice
}

export const InvoiceStatusLabel: React.FC<InvoiceStatusLabelProps> = ({ invoice }): any => {
  if (invoice.userActionRequired) {
    return {
      icon: <Icon src={alertCircle} size={Sizes.xsmall} color={uaRed['500']} css={Spacing.Padding.right_4}/>,
      style: 'failed',
      title: translate('invoices_screen__badge_status--payment-failure')
    }
  }

  const invoiceStatusMap = {
    [InvoiceStatus.Open]: {
      icon: <Icon src={alarmIcon} size={Sizes.xsmall} color={marigold['500']} css={Spacing.Padding.right_4}/>,
      style: 'pending',
      title: translate('invoices_screen__badge_status--pending')
    },
    [InvoiceStatus.Payed]: {
      icon: <Icon src={checkmark} size={Sizes.xsmall} color={darkMint['500']} css={Spacing.Padding.right_4}/>,
      style: 'success',
      title: translate('invoices_screen__badge_status--success')
    },
    [InvoiceStatus.NotPayed]: {
      icon: <Icon src={alertCircle} size={Sizes.xsmall} color={uaRed['500']} css={Spacing.Padding.right_4}/>,
      style: 'failed',
      title: translate('invoices_screen__badge_status--not-payed')
    },
    [InvoiceStatus.Drafted]: {
      icon: <Icon src={alarmIcon} size={Sizes.xsmall} color={marigold['500']} css={Spacing.Padding.right_4}/>,
      style: 'pending',
      title: translate('invoices_screen__badge_status--drafted')
    }
  }[invoice.status] || {
    icon: <Icon src={alarmIcon} size={Sizes.xsmall} color={marigold['500']} css={Spacing.Padding.right_4}/>,
    style: 'pending',
    title: translate('invoices_screen__badge_status--drafted')
  }

  return <Badge {...invoiceStatusMap} />
}
