import { CSVLink } from 'react-csv'
import { Colors, eerieBlack } from '../../style/colors'
import { Icon } from '../icon'
import { ItemLayout } from '../../layouts/item'
import { Layout, Spacing } from '../../style/spacing'
import { Sizes } from '../../style/sizes'
import { Typography } from '../../style/typography'
import { translate } from '../../lib/localization'
import React from 'react'
import documentsIcon from '../../images/icons/outline/documents.svg'

interface DownloadCsvButtonProps {
    fileName: string
    rows: object[]
    headers?: {label: string; key: string}[]
    disabled?: boolean
}

export const DownloadCsvButton: React.FC<DownloadCsvButtonProps> = ({ fileName, rows, headers, disabled = false }) => {
  return <CSVLink data={rows} headers={headers} filename={fileName}
    onClick={() => {
      if (disabled) return false
    }}>
    <div css={[Spacing.Padding.all_8, Colors.BackgroundColor.eerieBlack0, Spacing.Margin.bottom_8, Layout.rounded_8, Layout.display_inline_block]}>
      <ItemLayout
        css={[disabled ? Typography.Style.disabled : Typography.Style.pointer]}
        leftElement={<Icon src={documentsIcon} color={eerieBlack['700']} css={Spacing.Margin.right_8}
          size={Sizes.xsmall}/>}
        firstTextRow={<div
          css={[Typography.label.label3_500, { whiteSpace: 'nowrap' }]}>{translate('business__popup__download_csv')}</div>}
      />
    </div>
  </CSVLink>
}
