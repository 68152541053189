import { gql } from '@apollo/client'

export const CREATE_FIREBASE_CUSTOM_TOKEN = gql`
  mutation createFirebaseCustomToken { 
    CreateFirebaseCustomToken_v1
  }
`

export const UPDATE_USER_INFO = gql`
    mutation updateUserInfo(
        $firstName: String
        $lastName: String
    ) {
        UpdateUserInfo_v1(
            firstName: $firstName
            lastName: $lastName
        ) {
            id
            firstName
            lastName
        }
    }
`

export const UPDATE_PRIVACY_SETTING = gql`
mutation updatePrivacySetting($privacies: [PrivacyInput]!) {
    CreateOrUpdatePrivacy_v1(privacies: $privacies) {
        id
        policy
        accepted
    }
}
`

export const GET_USER_QUERY = gql`
    query getUser {
        Me {
            id
            phone
            firstName
            lastName
            fullName
            email
        }
    }
`

export const GET_ATTRIBUTES = gql`
    query getAttributes($where: [WhereClause]) {
        Attributes(where: $where) {
            key
            value
        }
    }
`
export const GET_PRIVACIES = gql`
    query getPrivacies($where: [WhereClause]) {
        Privacies (where: $where) {
                id
                policy
                accepted
                acceptedDate
            }
    }
`

export const GET_REFERRAL_INFO = gql`
    query getReferralInfo{
        ReferralInformation_v2 {
            promoCode{
              id
              code
              banned
            }
            landingUrl
        }
        countContracts {
            value
        }
    }
`
