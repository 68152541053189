import { Colors, eerieBlack, primaryColor } from '../../style/colors'
import { Typography } from '../../style/typography'
import { css } from '@emotion/react'
import {
  transitionMedium
} from '../constants'

export const ctaStyle = css`
  white-space: nowrap;
  padding: 5px 8px;
  border-radius: 32px;
  text-align: center;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  transition: background ${transitionMedium} ease-in-out, color ${transitionMedium} ease-in-out;
  :hover {
    ${Colors.BackgroundColor.eerieBlack50}
  }
  &[data-style='primary'] {
    background: ${primaryColor};
    color: ${eerieBlack['0']};
    :hover {
      ${Colors.BackgroundColor.violetBlue600}
    }
  }

  &[data-style='secondary'] {
    ${Colors.BackgroundColor.violetBlue50}
    color: ${primaryColor};
    :hover {
      ${Colors.BackgroundColor.violetBlue100}
    }
  }

  // TODO: How to multiple style
  &[data-style='disabled'] {
    background: ${eerieBlack['200']};
    color: ${eerieBlack['500']};
    cursor: not-allowed;
    box-shadow: none;
  }

  &[data-style='alert'] {
    ${Colors.BackgroundColor.uaRed500}
    color: ${eerieBlack['0']};
    :hover {
      ${Colors.BackgroundColor.uaRed600}
    }
  }

  &[data-style='border-only'] {
    ${Colors.BackgroundColor.eerieBlack0}
    color: ${eerieBlack['800']};
    border: 1px solid ${eerieBlack['400']};
    :hover {
      ${Colors.BackgroundColor.eerieBlack50}
    }
  }

  &[data-loading='true'] {
    cursor: not-allowed;
  }

  &[data-block='true'] {
    padding: 16px 24px;
    display: block;
  }

  &[data-size='small'] {
    padding: 5px 8px;
    display: inline;
    ${Typography.label.label4_600}
  }

  &[data-size='medium'] {
    padding: 12px 37px;
    display: inline;
    ${Typography.label.label3_600}
  }

  &[data-size='create-contract'] {
    padding: 12px 29px;
    display: inline;
    ${Typography.label.label3_600}
  }

  &[data-size='large'] {
    padding: 12px 60px;
    display: inline;
    ${Typography.label.label3_600}
  }

  &[data-raised='true'] {
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.08), 0 4px 8px rgba(0, 0, 0, 0.08);
  }
`
