import * as Sentry from '@sentry/react'

export const logAndSendToErrorTracker = (error: Readonly<Error> | Readonly<Error[]>) => {
  console.error(error)

  if (Array.isArray(error)) {
    error.map(e => Sentry.captureException(e))
  } else {
    return Sentry.captureException(error)
  }
}
