import { BarDatum, ResponsiveBar } from '@nivo/bar'
import { BusinessDataTrend, ContractType } from '../../lib/apollo/types'
import { TooltipContracts } from '../businessTooltipContractsChart'
import { contractAzul, royalPurple } from '../../style/colors'
import { formatter } from '../../style/utils'
import React from 'react'
import dayjs from '../../lib/dayJs'

interface ContractsChartDatum extends BarDatum {
  date: string
  ee: number
  gas: number
  eeColor: string
  gasColor: string
  longDate: string
}

interface ContractsChartProps {
  data: Array<ContractsChartDatum>
}

const keys = [ContractType.Ee, ContractType.Gas]
const commonProps = {
  height: 300,
  indexBy: 'date',
  keys,
  labelSkipHeight: 16,
  labelSkipWidth: 16,
  labelTextColor: 'inherit:darker(1.4)',
  margin: { bottom: 25, left: 25, right: 0, top: 0 },
  padding: 0.2
}

export const graphqlDataToSeriesContracts = (data: BusinessDataTrend[]): ContractsChartDatum[] => data.map(
  ({ energy_contracts: eeContracts, date, gas_contracts: gasContracts }, index) => {
    let ee
    let gas
    if (eeContracts?.[0]) ee = eeContracts[0]
    if (gasContracts?.[0]) gas = gasContracts[0]
    return ({ date: formatter(date), ee: ee ?? 0, eeColor: contractAzul['500'], gas: gas ?? 0, gasColor: royalPurple['500'], key: index, longDate: dayjs(date).format('MMMM YY') })
  })

export const ContractsChart: React.FC<ContractsChartProps> = ({ data }) => {
  return (
    <ResponsiveBar
      {...commonProps}
      data={data}
      innerPadding={1}
      borderRadius={4}
      enableLabel={false}
      colors={({ id, data }) => String(data[`${id}Color`])}
      tooltip={(bar: any) => {
        let translateX = 0
        if (bar.x < 42) translateX = 42 - bar.x
        if (bar.x > 600) translateX = 42 - bar.width
        return (
          <TooltipContracts date={bar.data.longDate} ee={bar.data.ee} gas={bar.data.gas} translateX={translateX}/>
        )
      }
      }
    />
  )
}
