import { AddLabel } from './addLabel'
import { Card, Icon, InputToggle, Spacer } from '../../../components'
import { Colors } from '../../../style/colors'
import { DeleteLabel } from './deleteLabel'
import {
  GetEnabledLabelsDocument,
  GetLabelsDocument,
  useGetLabelsQuery,
  useUpdateLabelMutation
} from '../../../lib/apollo/hooks'
import { LabelType } from '../../../lib/apollo/types'
import { Layout, Spacing } from '../../../style/spacing'
import { MainScreenLayout } from '../../../layouts/mainScreen'
import { ModifyLabel } from './modifyLabel'
import { PageLayout } from '../../../layouts/page'
import { Placeholder } from '../../../components/placeholder'
import { Separator } from '../../../components/separator'
import { Sizes } from '../../../style/sizes'
import { Typography } from '../../../style/typography'
import { WidgetTitle } from '../../../widgets/widgetTitle'
import { logAndSendToErrorTracker } from '../../../services/errorTracker'
import { translate } from '../../../lib/localization'
import React, { useState } from 'react'
import SettingsOutlineIcon from '../../../images/icons/outline/settings.svg'
import addCircleIcon from '../../../images/icons/add-circle.svg'
import deleteIcon from '../../../images/icons/trash.svg'
import modifyIcon from '../../../images/icons/create.svg'

export const LabelRow = ({ label, target }) => {
  const [enabled, setEnabled] = useState<boolean>(label.enabled)
  const [showModalDelete, setShowModalDelete] = useState(false)
  const [showModalModify, setShowModalModify] = useState(false)
  const [updateLabel] = useUpdateLabelMutation({
    onError: error => {
      logAndSendToErrorTracker(error)
    },
    refetchQueries: [GetLabelsDocument, GetEnabledLabelsDocument]
  })

  return (
    <>
      <div css={[Layout.display_flex, Layout.flex_row, Layout.vertical_center]}>
        <div css={[Layout.flex_1]}>
          <h5 css={[Typography.paragraph.p3_500, Colors.Color.eerieBlack800]}>{label.name}</h5>
          <p css={[Typography.paragraph.p4_400, Colors.Color.eerieBlack600]}>{label.contractsCount} Utenze</p>
        </div>
        <div css={[Layout.flex_2, Layout.display_flex]}>
          <div css={[target === LabelType.User ? Layout.flex_1 : Layout.flex_2, Layout.flex_justify_start]}>
            <InputToggle label={''} value={enabled} noJustify={true} onValue={async (newEnabledValue) => {
              setEnabled(newEnabledValue)
              await updateLabel({
                variables: {
                  color: label.color,
                  enabled: newEnabledValue,
                  id: label.id,
                  name: label.name
                }
              })
            }}/>
          </div>
          {
            target === LabelType.User &&
          <div css={[Layout.flex_2, Spacing.Margin.left_50, Layout.display_flex, Layout.ColumnGap.md]}>
            <div css={[Typography.paragraph.p3_500, Colors.Color.violetBlue500, Layout.display_flex, Layout.ColumnGap.sm, Layout.vertical_center, Typography.Style.pointer]} onClick={() => setShowModalModify(true)}>
              <Icon src={modifyIcon} size={Sizes.small}/>
              {translate('text__modify')}
            </div>
            <div css={[Typography.paragraph.p3_500, Colors.Color.violetBlue500, Layout.display_flex, Layout.ColumnGap.sm, Layout.vertical_center, Typography.Style.pointer]} onClick={() => setShowModalDelete(true)}>
              <Icon src={deleteIcon} size={Sizes.small}/>
              {translate('button_delete')}
            </div>
          </div>
          }
        </div>
      </div>
      <ModifyLabel showModal={showModalModify} setShowModal={setShowModalModify} id={label.id} colorTag={label.color} name={label.name}/>
      <DeleteLabel showModal={showModalDelete} setShowModal={setShowModalDelete} id={label.id}/>
    </>
  )
}
export const LabelRowPlaceholder = () => {
  return (
    <>
      <div css={[Layout.display_flex, Layout.flex_row]}>
        <div css={[Layout.width_250]}>
          <h5 css={[Typography.paragraph.p3_500, Colors.Color.eerieBlack800]}>
            <Placeholder width={'160px'} height={'21px'}/>
          </h5>
          <p css={[Typography.paragraph.p4_400, Colors.Color.eerieBlack600, Spacing.Margin.top_4]}>
            <Placeholder width={'110px'} height={'14px'}/>
          </p>
        </div>
        <Placeholder width={'48px'} height={'26px'}/>
      </div>
    </>
  )
}

export const BusinessLabelsScreen = () => {
  const { data, loading } = useGetLabelsQuery({
    fetchPolicy: 'cache-and-network',
    onError: error => {
      logAndSendToErrorTracker(error)
    }
  })
  const [showModalCreation, setShowModalCreation] = useState(false)

  const LabelList = ({ labels, target }) => labels
    .filter(label => label.type === target)
    .map(label =>
      <div key={label.id} css={[Spacing.Margin.bottom_16]}>
        <LabelRow label={label} target={target}/>
      </div>
    )

  return (
    <PageLayout>
      <MainScreenLayout showToolbox={false} sectionHeaderProps={{
        icon: SettingsOutlineIcon,
        path: `${translate('gestione-account__path')} /`,
        title: translate('screens_title__labels')
      }}>
        <Card>
          <div css={[Layout.display_flex, Layout.flex_row]}>
            <div css={Layout.flex_1}>
              <WidgetTitle
                css={[Spacing.Margin.bottom_8, Typography.fonts.upperCase]}
                title={translate('labels_screens__system-labels__title')}/>
            </div>
            <div css={Layout.flex_2}>
              <WidgetTitle
                css={[Spacing.Margin.bottom_8, Typography.fonts.upperCase]}
                title={translate('labels_screens__show-sidenav__title')}/>
            </div>
          </div>
          <Spacer height={16}/>

          {
            (!data?.Labels && loading) && <>
              <LabelRowPlaceholder/>
              <Spacer height={16}/>
              <LabelRowPlaceholder/>
              <Spacer height={16}/>
              <LabelRowPlaceholder/>
            </>
          }
          {data?.Labels && <LabelList labels={data.Labels} target={LabelType.System}/>}

          <Separator margin={32}/>

          <div css={[Layout.display_flex, Layout.flex_row]}>
            <div css={Layout.flex_1}>
              <WidgetTitle
                css={[Spacing.Margin.bottom_8, Typography.fonts.upperCase]}
                title={translate('labels_screens__personal-labels__title')}/>
            </div>
            <div css={[Layout.ColumnGap.lg, Layout.display_flex, Layout.flex_2]}>
              <div css={Layout.flex_1}>
                <WidgetTitle
                  css={[Spacing.Margin.bottom_8, Typography.fonts.upperCase]}
                  title={translate('labels_screens__show-sidenav__title')}/>
              </div>
              <div css={Layout.flex_2}>
                <WidgetTitle
                  css={[Spacing.Margin.bottom_8, Typography.fonts.upperCase]}
                  title={translate('labels_screens__actions-sidenav__title')}/>
              </div>
            </div>
          </div>

          <Spacer height={16}/>

          {
            data?.Labels && <LabelList labels={data.Labels} target={LabelType.User}/>
          }
          {
            !loading && <>
              <div css={[Colors.Color.violetBlue500, Layout.display_flex, Typography.Style.pointer]}
                onClick={() => setShowModalCreation(true)}>
                <img css={[Spacing.Margin.right_6]} src={addCircleIcon} alt=""/>
                <p css={[Typography.paragraph.p3_500]}>{translate('labels_screens__add_label__title')}</p>
              </div>
              <AddLabel showModal={showModalCreation} setShowModal={setShowModalCreation}/>
            </>
          }
          {
            (!data?.Labels && loading) && <>
              <LabelRowPlaceholder/>
              <Spacer height={16}/>
              <LabelRowPlaceholder/>
            </>
          }
        </Card>
      </MainScreenLayout>
    </PageLayout>
  )
}
