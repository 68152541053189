import { Control, FieldErrors } from 'react-hook-form'
import { HookFormInputText, TypographyError } from '../../../../../components'
import { IbanElement } from '@stripe/react-stripe-js'
import { Spacing } from '../../../../../style/spacing'
import { StripeIbanElementChangeEvent, StripeIbanElementOptions } from '@stripe/stripe-js'
import { eerieBlack } from '../../../../../style/colors'
import { translate } from '../../../../../lib/localization'
import React from 'react'

const IBAN_ELEMENT_OPTIONS: StripeIbanElementOptions = {
  style: {
    base: {
      '::placeholder': {
        color: '#aab7c4'
      },
      ':-webkit-autofill': {
        color: '#32325d'
      },
      color: eerieBlack['800'],
      fontFamily: 'Inter, sans-serif',
      fontSize: '16px',
      lineHeight: '20px'
    },
    invalid: {
      ':-webkit-autofill': {
        color: '#fa755a'
      },
      color: '#fa755a',
      iconColor: '#fa755a'
    }
  },
  supportedCountries: ['SEPA']
}

interface SepaFormProps {
  formControl: Control
  formErrors: FieldErrors
  onChange: (event: StripeIbanElementChangeEvent) => any
  ibanError: string
}

const SepaForm: React.FC<SepaFormProps> = ({ formControl, formErrors, onChange, ibanError }) => {
  return <>
    <HookFormInputText
      name="name"
      error={formErrors.name}
      control={formControl}
      rules={{
        required: translate('input_required--name')
      }}
      inputProps={{
        inputMode: 'text',
        label: translate('input_owner_name')
      }}
    />
    <div style={{ background: eerieBlack['100'], borderRadius: '10px', padding: '25px 16px' }} css={Spacing.Margin.top_8}>
      <IbanElement
        options={IBAN_ELEMENT_OPTIONS}
        onChange={onChange}
      />
    </div>
    <TypographyError error={ibanError} />
  </>
}

export default SepaForm
