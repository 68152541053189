import { Caption } from '../caption'
import { Layout } from '../../style/spacing'
import { TypographyError } from '../typographyError'
import { inputToggleStyle } from './index.css'
import React from 'react'

export interface InputToggleProps {
  /**
   * Label
   */
  label: React.ReactNode

  /**
   * the value
   */
  value: boolean

  /**
   * status
   * TODO: Implement invalid, disabled status (check with Mastro)
   */
  status?: 'none' | 'invalid' | 'disabled' | 'active'

  clickable?: boolean

  /**
   * Method onSelect
   */
  onValue?: Function

  error?: string
  errorDescriptions?: any
  errorType?: string
  noJustify?: boolean
}

/**
 * Primary UI component
 */
export const InputToggle: React.FC<InputToggleProps> = ({
  noJustify = false,
  label,
  onValue,
  value,
  errorDescriptions = {},
  errorType,
  error,
  clickable = true,
  ...props
}) => {
  return (
    <>
      <div css={[inputToggleStyle, { PointerEvents: clickable ? 'auto;' : 'none;' }]}
        onClick={() => onValue?.(!value)} {...props}>
        { label &&
          <div className={'content'}>
            <Caption content={label}/>
          </div>
        }

        <div className={'checkbox'} css={!noJustify && [Layout.flex_justify_end, Layout.display_flex]}>
          <div className={'value'} data-active={Boolean(value)}>
            <div className={'toggle-selector'} data-active={Boolean(value)}/>
          </div>
        </div>
      </div>
      {errorDescriptions[errorType] && <TypographyError error={errorDescriptions[errorType]}/>}
      {(!errorDescriptions[errorType] && error) && <TypographyError error={error}/>}
    </>
  )
}
