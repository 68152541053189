import { Button, Card, CardSelect, Modal, Spacer } from '../../../components'
import { Colors } from '../../../style/colors'
import { Elements } from '@stripe/react-stripe-js'
import { Layout, Spacing } from '../../../style/spacing'
import { MainScreenLayout } from '../../../layouts/mainScreen'
import { PageLayout } from '../../../layouts/page'
import { Sizes } from '../../../style/sizes'
import { Typography } from '../../../style/typography'
import { WidgetReferral } from '../../../widgets/widgetReferral'
import { isBusinessUsageTarget, stripeKey } from '../../../configuration/env'
import { loadStripe } from '@stripe/stripe-js'
import { translate } from '../../../lib/localization'
import { useCreateCreditCard } from '../../../hooks/useCreateCreditCard'
import { useCreateSepa } from '../../../hooks/useCreateSepa'
import { useForm } from 'react-hook-form'
import { useGetUserQuery } from '../../../lib/apollo/hooks'
import { useNavigation } from '@react-navigation/native'
import CreditCardForm from './components/CreditCardForm'
import React, { useCallback, useEffect, useState } from 'react'
import SepaForm from './components/SepaForm'
import cardIcon from '../../../images/icons/card.svg'
import cardOutline from '../../../images/icons/outline/card.svg'

const stripePromise = loadStripe(stripeKey)

const AddPaymentMethod = () => {
  const navigation = useNavigation<any>()
  const routes = navigation.getState().routes
  const previousScreen = routes[routes.length - 2]
  const [activeCard, setActiveCard] = useState('')
  const [isValidSepa, setIsValidSepa] = useState<boolean>(false)
  const [isValidCredit, setIsValidCredit] = useState<boolean>(false)
  const [showSepaMandate, setShowSepaMandate] = useState<boolean>(false)
  const [createCreditCard, { error: ccError, loading: ccLoading }] = useCreateCreditCard()
  const [createSepa, acceptMandates, { error: sepaError, loading: sepaLoading, stripeLoading, stripeError }] = useCreateSepa()

  const loading = ccLoading || sepaLoading || stripeLoading

  const { data } = useGetUserQuery()

  const onChangeCredit = event => setIsValidCredit(event.complete)
  const onChangeSepa = event => setIsValidSepa(event.complete)

  const { control: controlCredit, errors: errorsCredit, setValue: setCardFromValue, getValues: getCardFormValues } = useForm({
    defaultValues: {
      name: ''
    },
    mode: 'onBlur'
  })

  const { control: controlSepa, errors: errorsSepa, setValue: setSepaFromValue, getValues: getSepaFormValues } = useForm({
    defaultValues: {
      name: ''
    },
    mode: 'onBlur'
  })

  const confirmSepaMandate = useCallback(async () => {
    const [paymentMethodId, acceptMandatesError] = await acceptMandates()
    setShowSepaMandate(false)
    if (!acceptMandatesError) navigation.navigate(previousScreen.name, { ...previousScreen.params, paymentMethodId })
  }, [acceptMandates, navigation, previousScreen])

  const ADD_PAYMENT_METHOD_MAP = {
    credit: async () => {
      const [paymentMethodId, operationError] = await createCreditCard({ email: data?.Me?.email, name: getCardFormValues('name') })
      if (!operationError) navigation.navigate(previousScreen.name, { ...previousScreen.params, paymentMethodId })
    },
    sepa: async () => {
      const createSepaError = await createSepa({ email: data?.Me?.email, name: getSepaFormValues('name') })
      if (createSepaError) return
      setShowSepaMandate(true)
    }
  }

  useEffect(() => {
    const setValue = activeCard === 'sepa' ? setSepaFromValue : setCardFromValue
    const getValues = activeCard === 'sepa' ? getSepaFormValues : getCardFormValues
    const suggestedName = `${data?.Me?.firstName ?? ''} ${data?.Me?.lastName ?? ''}`.trim()
    if (getValues('name') === '' && !!suggestedName) setValue('name', suggestedName)
  }, [activeCard, data?.Me?.firstName, data?.Me?.lastName, getCardFormValues, getSepaFormValues, setCardFromValue, setSepaFromValue])

  const cards = [
    [
      {
        label: translate('payment_method--card'),
        value: 'credit'
      },
      {
        label: translate('payment_method--sepa'),
        value: 'sepa'
      }
    ]
  ]

  const checkButton = activeCard === 'sepa' ? isValidSepa : isValidCredit

  return (
    <PageLayout>
      <MainScreenLayout showToolbox={false} sectionHeaderProps={{
        icon: isBusinessUsageTarget ? cardOutline : cardIcon,
        path: `${translate('screens_title__payment_methods')} /`,
        title: translate('screens_title__add_payment_method')
      }} >
        <div css={[Layout.display_flex, Layout.ColumnGap.sm]}>
          <Card css={Layout.flex_2}>
            <div css={[Typography.paragraph.p3_400, Colors.Color.eerieBlack600]}>{translate('screens_subtitle__add_payment_method')}</div>
            <hr css={[Layout.border_top, Spacing.Margin.top_16, Spacing.Margin.bottom_16]} />
            <CardSelect values={[activeCard]} cards={cards} onSelect={setActiveCard}/>
            <Spacer height={16} />
            { activeCard === 'sepa' &&
            <div>
              <div css={Spacing.Margin.bottom_16}>
                <SepaForm
                  formControl={controlSepa}
                  formErrors={errorsSepa}
                  onChange={onChangeSepa}
                  ibanError={sepaError || stripeError ? translate('error_payment_method_creation--sepa') : ''} />
              </div>
            </div>
            }
            { activeCard === 'credit' &&
            <div>
              <div css={Spacing.Margin.bottom_16}>
                <CreditCardForm
                  formControl={controlCredit}
                  formErrors={errorsCredit}
                  onChange={onChangeCredit}
                  ccError={ccError ? translate('error_payment_method_creation--credit') : ''}
                />
              </div>
            </div>
            }
            <div css={[Layout.display_flex, Layout.row_reverse]}>
              <Button
                title={translate('button_add')}
                onClick={ADD_PAYMENT_METHOD_MAP[activeCard]}
                style={checkButton ? 'primary' : 'disabled'}
                block={false}
                loading={loading}
                size={Sizes.large}
                disabled={!checkButton}/>
            </div>
          </Card>
          {
            !isBusinessUsageTarget && <WidgetReferral/>
          }
        </div>
        { showSepaMandate &&
        <Modal
          title={<div css={Typography.heading.h5_700}>Mandato SEPA</div>}
          hideModal={() => setShowSepaMandate(false)}
          hideBackground={true}
        >
          <div>
            La sottoscrizione del presente mandato comporta (A) l’autorizzazione a Tate e Stripe, il nostro fornitore di
            servizi di pagamento, a richiedere alla banca del debitore l’addebito del suo conto e (B) l’autorizzazione alla
            banca del debitore di procedere a tale addebito conformemente alle disposizioni impartite da Tate e Stripe. Il
            debitore ha diritto di ottenere il rimborso della propria banca secondo gli accordi ed alle condizioni che
            regolano il rapporto con quest’ultima. Se del caso, il rimborso deve essere richiesto nel termine di 8 settimane
            a decorrere dalla data di addebito in conto.</div>
          <div css={[Layout.display_flex, Layout.row_reverse, Spacing.Margin.top_16]}>
            <Button title={translate('payment_method--accept-sepa-mandate')} style={'primary'} onClick={confirmSepaMandate} size={Sizes.medium} />
          </div>
        </Modal>
        }
      </MainScreenLayout>
    </PageLayout>
  )
}

export const AddPaymentMethodScreen = () => <Elements stripe={stripePromise}>
  <AddPaymentMethod/>
</Elements>
