import { Colors, eerieBlack } from '../../style/colors'
import { Dayjs } from 'dayjs'
import { Icon } from '../icon'
import { Layout, Spacing } from '../../style/spacing'
import { PickerContainer } from '../pickerContainer'
import { Sizes } from '../../style/sizes'
import { Spacer } from '../index'
import { Typography } from '../../style/typography'
import { css } from '@emotion/react'
import { style } from './index.css'
import { translate } from '../../lib/localization'
import React, { useState } from 'react'
import backIcon from '../../images/icons/chevron-back.svg'
import dayjs from '../../lib/dayJs'
import forwardIcon from '../../images/icons/chevron-forward.svg'

export interface DatePickerProps {
  isDoubleDatePicker?: boolean
  placeholder?: string
  onToggle?: Function
  monthFilter?: { startMonth: string; endMonth?: string}
  updateFilter?: Function
}

enum filters {
  endDate = 'endDate',
  startDate = 'startDate'
}

const checkSelectedMonth = (activeDate: Dayjs, index: number, year: number) => activeDate && activeDate.month() === index && activeDate.year() === year

export const DatePicker: React.FC<DatePickerProps> = ({
  isDoubleDatePicker = false,
  placeholder = 'MM/YYYY',
  monthFilter,
  updateFilter,
  onToggle
}) => {
  const { startMonth, endMonth } = monthFilter ?? {}
  const startDate = dayjs(startMonth).startOf('month')
  const endDate = endMonth && dayjs(endMonth).endOf('month')
  const [year, setYear] = useState(dayjs().year())

  const [selectFilter, setSelectFilter] = useState<filters>(filters.startDate)
  const checkFilter = (filterType: filters): boolean => selectFilter === filterType

  const setDates = (startMonth: string, endMonth: string) => {
    updateFilter({ endMonth, startMonth })
  }

  const selectMonth = (index: number) => {
    const newDate = dayjs().month(index).year(year)
    // When selecting the start and end date, check if the dates are in the correct chronological order
    // if not, invert them
    switch (selectFilter) {
      case filters.startDate:
        if (!endDate.isSame(newDate, 'month') || !endDate.isSame(newDate, 'year')) {
          setSelectFilter(filters.endDate)
          if (newDate.diff(endDate, 'month') < -12) {
            setDates(newDate.format('YYYY-MM'), newDate.add(12, 'month').format('YYYY-MM'))
            break
          }
          if (endDate && newDate.isBefore(endDate)) {
            setDates(newDate.format('YYYY-MM'), endDate.format('YYYY-MM'))
            break
          }
          setDates(endDate?.format('YYYY-MM'), newDate.format('YYYY-MM'))
        }
        break
      case filters.endDate:
        if (!startDate.isSame(newDate, 'month') || !startDate.isSame(newDate, 'year')) {
          if (newDate.diff(startDate, 'month') > 12) {
            setDates(newDate.subtract(12, 'month').format('YYYY-MM'), newDate.format('YYYY-MM'))
            break
          }
          if (newDate.isAfter(startDate)) {
            setDates(startDate.format('YYYY-MM'), newDate.format('YYYY-MM'))
            break
          }
          setDates(newDate.format('YYYY-MM'), startDate.format('YYYY-MM'))
        }
        break
    }
  }

  const activeDate: Dayjs = selectFilter === filters.endDate ? endDate : startDate
  const checkIfBefore = (year: number, index: number) => dayjs().isBefore(dayjs().month(index).year(year), 'month')
  const checkIfInRange = (year: number, index: number) => {
    const date = dayjs().year(year).month(index)
    return date.isBetween(startDate, endDate, 'month', '[]')
  }
  const checkIfFirstOfRange = (year: number, index: number) => startDate && dayjs().year(year).month(index).isSame(startDate, 'month')
  const checkIfLastOfRange = (year: number, index: number) => endDate && dayjs().year(year).month(index).isSame(endDate, 'month')

  const monthsList = () => {
    const rows = []
    for (let month = 0; month < 12; month++) {
      rows.push(<div css={[Layout.flex_justify_spaceBetween, Layout.display_flex, Layout.flex_1]} key={month}>
        <div
          css={[
            checkIfFirstOfRange(year, month) ? [css`border-top-left-radius: 10px;border-bottom-left-radius: 10px`] : [],
            checkIfLastOfRange(year, month) ? [css`border-top-right-radius: 10px;border-bottom-right-radius: 10px`] : [],
            checkIfInRange(year, month) ? [Colors.BackgroundColor.violetBlue50] : [Colors.Color.eerieBlack500],
            checkSelectedMonth(activeDate, month, year) ? [Colors.Color.eerieBlack0, Colors.BackgroundColor.violetBlue500] : Colors.Color.eerieBlack500,
            checkIfBefore(year, month) ? [Colors.Color.eerieBlack300, Typography.Style.disabled] : Typography.Style.pointer,
            Typography.label.label3_500,
            Spacing.Padding.vertical_6,
            Spacing.Margin.vertical_2,
            Layout.display_flex,
            Layout.flex_1,
            Layout.horizontal_center
          ]}
          className={`month-box ${!checkIfBefore(year, month) && !checkSelectedMonth(activeDate, month, year) && 'available-month'}`}
          onClick={() => !checkIfBefore(year, month) && selectMonth(month)}
        >
          {dayjs().month(month).format('MMMM')}
        </div>
      </div>)
    }
    return rows
  }
  return (
    <PickerContainer onToggle={onToggle}>
      <div css={[Layout.display_flex, Layout.flex_column, style]}>
        <div css={[Typography.label.label4_500, Colors.Color.eerieBlack500]}>{translate('business__date_picker_header')}</div>
        <Spacer height={8}/>
        <div css={[Layout.flex_row, Layout.display_flex, Layout.flex_1]}>
          <div
            css={[
              Typography.Style.pointer,
              Layout.border,
              checkFilter(filters.startDate) ? [Colors.BackgroundColor.violetBlue50, Colors.BorderColor.violetBlue500, Colors.Color.eerieBlack800] : [Colors.BackgroundColor.eerieBlack100, Colors.Color.eerieBlack600, Colors.BorderColor.none],
              Spacing.Padding.horizontal_12,
              Layout.flex_1,
              Typography.label.label3_500
            ]}
            className={'filter-box'}
            onClick={() => setSelectFilter(filters.startDate)}
          >{startDate ? startDate.format('MM/YYYY') : placeholder}</div>
          { isDoubleDatePicker &&
          <>
            <div css={{ width: 8 }}/>
            <div key={0} css={[
              Typography.Style.pointer,
              Layout.border,
              checkFilter(filters.endDate) ? [Colors.BackgroundColor.violetBlue50, Colors.BorderColor.violetBlue500, Colors.Color.eerieBlack800] : [Colors.BackgroundColor.eerieBlack100, Colors.Color.eerieBlack600, Colors.BorderColor.none],
              Spacing.Padding.horizontal_12,
              Layout.flex_1,
              Typography.label.label3_500
            ]}
            className={'filter-box'}
            onClick={() => setSelectFilter(filters.endDate)}
            >{endDate ? endDate.format('MM/YYYY') : placeholder}</div>
          </>
          }
        </div>
        <Spacer height={24}/>
        <div css={[Layout.flex_justify_spaceBetween, Layout.display_flex, Layout.flex_1]}>
          <div css={Typography.Style.pointer} onClick={() => setYear(year - 1)}><Icon src={backIcon} color={eerieBlack['500']} size={Sizes.small} css={Spacing.Margin.right_12} className='input-icon'/></div>
          <div css={Typography.label.label2_500}>{year}</div>
          <div css={dayjs().year() > year ? Typography.Style.pointer : Typography.Style.disabled} onClick={() => dayjs().year() > year && setYear(year + 1)}>
            <Icon src={forwardIcon} color={dayjs().year() > year ? eerieBlack['500'] : eerieBlack['300']} size={Sizes.small} css={Spacing.Margin.right_12} className='input-icon'/>
          </div>
        </div>
        <Spacer height={16}/>
        <div className={'months-container'}>
          {monthsList()}
        </div>
      </div>
    </PickerContainer>
  )
}
