import { Colors } from './colors'
import { DESKTOP_MIN_WIDTH, cssOnlyDesktop, cssOnlyMobile } from './utils'
import { css } from '@emotion/react'

export const Responsive = {
  flexbox: {
    breakDesktop: css([cssOnlyDesktop('flex-basis: 100%; height: 0'), cssOnlyMobile('')]),
    breakMobile: css([cssOnlyDesktop(''), cssOnlyMobile('flex-basis: 100%; height: 0')])
  },
  sizes: {
    containerMaxWidth: css`max-width: ${DESKTOP_MIN_WIDTH}`,
    maxWidth: css`max-width: 100%`
  },
  utils: {
    disableDesktop: css([cssOnlyDesktop('display: none'), cssOnlyMobile('')]),
    disableMobile: css([cssOnlyDesktop(''), cssOnlyMobile('display: none')])
  }
}

export const hoverClicker = css`
  background: transparent;
  transition: background .5s;
  border-radius: 8px;

  :hover {
    ${Colors.BackgroundColor.eerieBlack100}
    transition: background .5s;
  }
`

export const hoverClickerSideNav = css`
  background: transparent;
  transition: background .5s;
  border-radius: 8px;

  :hover {
    ${Colors.BackgroundColor.violetBlue50}
    transition: background .5s;
  }
`

export const hoverClickerArrowLink = css`
  background: transparent;
  transition: background .5s;
  margin: -8px;
  padding: 8px;

  :hover {
    ${Colors.BackgroundColor.violetBlue50}
    transition: background .5s;
  }
`
