import { css } from '@emotion/react'
import { eerieBlack } from '../../style/colors'

export const style = css`
  .input-text {
      border: 0px;
      background: transparent;
  }

  .input-text:focus {
    outline-width: 0;
  }

  .navigator:hover {
    background-color: ${eerieBlack['100']};
  }
`
