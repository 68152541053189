import { Colors } from '../../../style/colors'
import { css } from '@emotion/react'

export const iconStyle = css`
  box-sizing: border-box;
  border-radius: 20px;
  height: 38px;
  width: 38px;
  display: flex;
  justify-content: center;
  align-items: center;

  ${Colors.BackgroundColor.eerieBlack100}
  ${Colors.Color.violetBlue500}

  font-style: normal;
  font-weight: bold;
  font-size: 13.1122px;
  line-height: 17px;
`
