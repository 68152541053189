import { Layout } from '../../style/spacing'
import { Link as LinkRouting } from '@react-navigation/native'
import { Typography } from '../../style/typography'
import React, { ReactNode } from 'react'

export interface NavigationProps {
  path: string
  params? : Object
}

interface LinkProps {
  leftElement?: ReactNode
  rightElement?: ReactNode
  firstTextRow?: ReactNode
  secondTextRow?: ReactNode
  className?: string
  textRow: ReactNode
  navigationProps?: NavigationProps
}

/**
 * Generic Item Layout, It just manage the positioning of the Item elements in a row filling all horizontal available space
 * The Elements are vertically aligned and the central block expand itself to horizontally fill.
 * The highest elements gives the height
 */

export const Link: React.FC<LinkProps> = ({
  className,
  leftElement,
  rightElement,
  textRow,
  navigationProps
}) => {
  return !navigationProps
    ? <div className={className} css={[Layout.display_flex, Layout.flex_0, Layout.vertical_center, Typography.Style.pointer]}>
      <div css={[Layout.flex_0]}>{leftElement}</div>
      <div css={[Layout.flex_1, { overflow: 'hidden' }]}>
        <div>{textRow}</div>
      </div>
      <div css={[Layout.flex_0]}>{rightElement}</div>
    </div>
    : <LinkRouting to={{ params: navigationProps?.params, screen: navigationProps?.path }}>
      <div className={className} css={[Layout.display_flex, Layout.flex_0, Layout.vertical_center, Typography.Style.pointer]}>
        <div css={[Layout.flex_0]}>{leftElement}</div>
        <div css={[Layout.flex_1, { overflow: 'hidden' }]}>
          <div>{textRow}</div>
        </div>
        <div css={[Layout.flex_0]}>{rightElement}</div>
      </div>
    </LinkRouting>
}
