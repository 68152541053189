import { BusinessNavigatorParams } from '../main/types'
import { PAGE_SIZE } from '../components/businessTable/index'
import { useActions, useAppState } from '../store/index'
import { useCallback } from 'react'
import { useRoute } from '@react-navigation/native'

type OnPaginationChange = (pageIndex: number, pageSize: number) => void
export const usePagination = (): [offset: number, limit: number, onPaginationChange: OnPaginationChange] => {
  const { name } = useRoute()
  const { pagination } = useAppState()
  const { offset = 0, limit = PAGE_SIZE } = pagination[name] ?? {}
  const { updatePagePagination } = useActions()
  const onPaginationChange = useCallback((pageIndex, pageSize) => {
    updatePagePagination({ page: name as keyof BusinessNavigatorParams, pagination: { limit: pageSize, offset: pageIndex * pageSize } })
  }, [name, updatePagePagination])
  return [offset, limit, onPaginationChange]
}
