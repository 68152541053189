/* eslint-disable camelcase */
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string
  String: string
  Boolean: boolean
  Int: number
  Float: number
  /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSON: any
};

export type Addendum = {
  __typename?: 'Addendum'
  post?: Maybe<Scalars['JSON']>
  pre?: Maybe<Scalars['JSON']>
};

export type AirtableRecord = {
  __typename?: 'AirtableRecord'
  airtableId?: Maybe<Scalars['String']>
  coredataId?: Maybe<Scalars['String']>
  createdAt?: Maybe<Scalars['String']>
  entityType?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['ID']>
  updatedAt?: Maybe<Scalars['String']>
};

export type AirtableRecordInput = {
  airtableId?: Maybe<Scalars['String']>
  coredataId?: Maybe<Scalars['String']>
  createdAt?: Maybe<Scalars['String']>
  entityType?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['ID']>
  updatedAt?: Maybe<Scalars['String']>
};

export type AppUsage = {
  __typename?: 'AppUsage'
  User?: Maybe<User>
  UserId?: Maybe<Scalars['Int']>
  createdAt?: Maybe<Scalars['String']>
  firstSeenAt?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['ID']>
  lastSeenAt?: Maybe<Scalars['String']>
  name?: Maybe<AppUsageName>
  totalTimesSeen?: Maybe<Scalars['Int']>
  updatedAt?: Maybe<Scalars['String']>
};

export type AppUsageInput = {
  User?: Maybe<UserInput>
  UserId?: Maybe<Scalars['Int']>
  createdAt?: Maybe<Scalars['String']>
  firstSeenAt?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['ID']>
  lastSeenAt?: Maybe<Scalars['String']>
  name?: Maybe<AppUsageName>
  totalTimesSeen?: Maybe<Scalars['Int']>
  updatedAt?: Maybe<Scalars['String']>
};

export enum AppUsageName {
  EnergyApp = 'energy_app',
  EnergyBusiness = 'energy_business',
  EnergyClienti = 'energy_clienti',
  EnergyOffice = 'energy_office',
  EnergyScopri = 'energy_scopri',
  EnergyTeleselling = 'energy_teleselling',
  MobilityApp = 'mobility_app',
  MobilityOffice = 'mobility_office',
  SharedAreaPersonale = 'shared_area_personale'
}

export type Article = {
  __typename?: 'Article'
  Collection?: Maybe<Collection>
  CollectionId?: Maybe<Scalars['Int']>
  IntercomId?: Maybe<Scalars['Int']>
  body?: Maybe<Scalars['String']>
  createdAt?: Maybe<Scalars['String']>
  description?: Maybe<Scalars['String']>
  dislikeCount?: Maybe<Scalars['Int']>
  fragment?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['ID']>
  likeCount?: Maybe<Scalars['Int']>
  searchable?: Maybe<Scalars['String']>
  slug?: Maybe<Scalars['String']>
  sourceCreatedAt?: Maybe<Scalars['String']>
  sourceUpdatedAt?: Maybe<Scalars['String']>
  sourceUrl?: Maybe<Scalars['String']>
  status?: Maybe<ArticleStatus>
  title?: Maybe<Scalars['String']>
  updatedAt?: Maybe<Scalars['String']>
  viewsCount?: Maybe<Scalars['Int']>
};

export type ArticleInput = {
  Collection?: Maybe<CollectionInput>
  CollectionId?: Maybe<Scalars['Int']>
  IntercomId?: Maybe<Scalars['Int']>
  body?: Maybe<Scalars['String']>
  createdAt?: Maybe<Scalars['String']>
  description?: Maybe<Scalars['String']>
  dislikeCount?: Maybe<Scalars['Int']>
  fragment?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['ID']>
  likeCount?: Maybe<Scalars['Int']>
  searchable?: Maybe<Scalars['String']>
  slug?: Maybe<Scalars['String']>
  sourceCreatedAt?: Maybe<Scalars['String']>
  sourceUpdatedAt?: Maybe<Scalars['String']>
  sourceUrl?: Maybe<Scalars['String']>
  status?: Maybe<ArticleStatus>
  title?: Maybe<Scalars['String']>
  updatedAt?: Maybe<Scalars['String']>
  viewsCount?: Maybe<Scalars['Int']>
};

export enum ArticleStatus {
  Deleted = 'deleted',
  Drafted = 'drafted',
  Published = 'published'
}

export enum AssignErrors {
  AlreadyUsed = 'AlreadyUsed',
  Expired = 'Expired',
  NotBenefit = 'NotBenefit',
  NotFound = 'NotFound'
}

export type Attribute = {
  __typename?: 'Attribute'
  Contract?: Maybe<Contract>
  ContractId?: Maybe<Scalars['Int']>
  Customer?: Maybe<Customer>
  CustomerId?: Maybe<Scalars['Int']>
  Label?: Maybe<Label>
  LabelId?: Maybe<Scalars['String']>
  Owner?: Maybe<User>
  OwnerId?: Maybe<Scalars['Int']>
  PhoneLead?: Maybe<PhoneLead>
  PhoneLeadId?: Maybe<Scalars['String']>
  User?: Maybe<User>
  UserId?: Maybe<Scalars['Int']>
  createdAt?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['ID']>
  key?: Maybe<Scalars['String']>
  updatedAt?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['String']>
};

export type AttributeInput = {
  Contract?: Maybe<ContractInput>
  ContractId?: Maybe<Scalars['Int']>
  Customer?: Maybe<CustomerInput>
  CustomerId?: Maybe<Scalars['Int']>
  Label?: Maybe<LabelInput>
  LabelId?: Maybe<Scalars['String']>
  Owner?: Maybe<UserInput>
  PhoneLead?: Maybe<PhoneLeadInput>
  PhoneLeadId?: Maybe<Scalars['String']>
  User?: Maybe<UserInput>
  UserId?: Maybe<Scalars['Int']>
  createdAt?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['ID']>
  key?: Maybe<Scalars['String']>
  updatedAt?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['String']>
};

export type Audit = {
  __typename?: 'Audit'
  Owner?: Maybe<User>
  OwnerId?: Maybe<Scalars['Int']>
  User?: Maybe<User>
  UserId?: Maybe<Scalars['Int']>
  args?: Maybe<Scalars['JSON']>
  context?: Maybe<Scalars['JSON']>
  createdAt?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['ID']>
  mutation?: Maybe<Scalars['String']>
  results?: Maybe<Scalars['JSON']>
  status?: Maybe<Scalars['String']>
  updatedAt?: Maybe<Scalars['String']>
};

export type AuditInput = {
  Owner?: Maybe<UserInput>
  User?: Maybe<UserInput>
  UserId?: Maybe<Scalars['Int']>
  args?: Maybe<Scalars['JSON']>
  context?: Maybe<Scalars['JSON']>
  createdAt?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['ID']>
  mutation?: Maybe<Scalars['String']>
  results?: Maybe<Scalars['JSON']>
  status?: Maybe<Scalars['String']>
  updatedAt?: Maybe<Scalars['String']>
};

export type AvailableTariff = {
  __typename?: 'AvailableTariff'
  suggestionData?: Maybe<SuggestionData>
  tariffData: TariffInfo
};

export type AvailableTariffs = {
  __typename?: 'AvailableTariffs'
  enabledTariffs?: Maybe<Array<Maybe<Price>>>
  visibleTariffs?: Maybe<Array<Maybe<Price>>>
};

export type Balance = {
  __typename?: 'Balance'
  Contract?: Maybe<Contract>
  ContractId?: Maybe<Scalars['Int']>
  SourceInvoice?: Maybe<Invoice>
  SourceInvoiceId?: Maybe<Scalars['Int']>
  TargetInvoice?: Maybe<Invoice>
  TargetInvoiceId?: Maybe<Scalars['Int']>
  amount?: Maybe<Scalars['Float']>
  createdAt?: Maybe<Scalars['String']>
  date?: Maybe<Scalars['String']>
  decimalAmount?: Maybe<Scalars['String']>
  description?: Maybe<Scalars['String']>
  exclusive?: Maybe<Scalars['Boolean']>
  id?: Maybe<Scalars['ID']>
  type?: Maybe<BalanceType>
  updatedAt?: Maybe<Scalars['String']>
  used?: Maybe<Scalars['Boolean']>
};

export type BalanceHistory = {
  __typename?: 'BalanceHistory'
  balance?: Maybe<Scalars['Float']>
  rows?: Maybe<Array<Maybe<BalanceHistoryRecord>>>
};

export type BalanceHistoryRecord = {
  __typename?: 'BalanceHistoryRecord'
  generatedBalanceAmount?: Maybe<Scalars['Float']>
  invoice?: Maybe<Invoice>
  type?: Maybe<BalanceHistoryRecordType>
};

export enum BalanceHistoryRecordType {
  Balance = 'balance',
  Invoice = 'invoice'
}

export type BalanceInput = {
  Contract?: Maybe<ContractInput>
  ContractId?: Maybe<Scalars['Int']>
  SourceInvoice?: Maybe<InvoiceInput>
  SourceInvoiceId?: Maybe<Scalars['Int']>
  TargetInvoice?: Maybe<InvoiceInput>
  TargetInvoiceId?: Maybe<Scalars['Int']>
  amount?: Maybe<Scalars['Float']>
  createdAt?: Maybe<Scalars['String']>
  date?: Maybe<Scalars['String']>
  description?: Maybe<Scalars['String']>
  exclusive?: Maybe<Scalars['Boolean']>
  id?: Maybe<Scalars['ID']>
  type?: Maybe<BalanceType>
  updatedAt?: Maybe<Scalars['String']>
  used?: Maybe<Scalars['Boolean']>
};

export enum BalanceType {
  Installment = 'installment',
  Invoice = 'invoice',
  Manual = 'manual'
}

export type BannedItem = {
  __typename?: 'BannedItem'
  createdAt?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['ID']>
  reason?: Maybe<Scalars['String']>
  reference?: Maybe<Scalars['String']>
  type?: Maybe<BannedItemType>
  updatedAt?: Maybe<Scalars['String']>
};

export type BannedItemInput = {
  createdAt?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['ID']>
  reason?: Maybe<Scalars['String']>
  reference?: Maybe<Scalars['String']>
  type?: Maybe<BannedItemType>
  updatedAt?: Maybe<Scalars['String']>
};

export enum BannedItemType {
  Ip = 'ip',
  Pdr = 'pdr',
  Pod = 'pod'
}

export type BenefitCode = {
  __typename?: 'BenefitCode'
  amount?: Maybe<Scalars['Float']>
  claimedAt?: Maybe<Scalars['String']>
  code?: Maybe<Scalars['String']>
  promoCodeId?: Maybe<Scalars['String']>
};

export enum BenefitsStatus {
  Completed = 'Completed',
  None = 'None',
  Ongoing = 'Ongoing'
}

export type BlockedPayload = {
  __typename?: 'BlockedPayload'
  limit?: Maybe<Scalars['Int']>
  months?: Maybe<Scalars['Int']>
  tariffStatus?: Maybe<TariffStatus>
};

export type BusinessDataSum = {
  __typename?: 'BusinessDataSum'
  contracts?: Maybe<Scalars['Float']>
  energy_consumption?: Maybe<Scalars['Float']>
  energy_contracts?: Maybe<Scalars['Float']>
  energy_invoices?: Maybe<Scalars['Float']>
  gas_consumption?: Maybe<Scalars['Float']>
  gas_contracts?: Maybe<Scalars['Float']>
  gas_invoices?: Maybe<Scalars['Float']>
  invoices?: Maybe<Scalars['Float']>
};

export type BusinessDataTrend = {
  __typename?: 'BusinessDataTrend'
  contracts?: Maybe<Array<Maybe<Scalars['Float']>>>
  date?: Maybe<Scalars['String']>
  energy_consumption?: Maybe<Array<Maybe<Scalars['Float']>>>
  energy_contracts?: Maybe<Array<Maybe<Scalars['Float']>>>
  energy_invoices?: Maybe<Array<Maybe<Scalars['Float']>>>
  gas_consumption?: Maybe<Array<Maybe<Scalars['Float']>>>
  gas_contracts?: Maybe<Array<Maybe<Scalars['Float']>>>
  gas_invoices?: Maybe<Array<Maybe<Scalars['Float']>>>
  invoices?: Maybe<Array<Maybe<Scalars['Float']>>>
};

export type Category = {
  __typename?: 'Category'
  Events?: Maybe<Array<Maybe<Event>>>
  Owner?: Maybe<User>
  OwnerId?: Maybe<Scalars['Int']>
  StyleConfiguration?: Maybe<StyleConfiguration>
  StyleConfigurationId?: Maybe<Scalars['String']>
  Templates?: Maybe<Array<Maybe<Template>>>
  User?: Maybe<User>
  UserId?: Maybe<Scalars['Int']>
  createdAt?: Maybe<Scalars['String']>
  generatedFrom?: Maybe<CategoryGeneratedFrom>
  id?: Maybe<Scalars['ID']>
  name?: Maybe<Scalars['String']>
  order?: Maybe<Scalars['Int']>
  status?: Maybe<CategoryStatus>
  updatedAt?: Maybe<Scalars['String']>
};

export type CategoryEventsArgs = {
  id?: Maybe<Array<Maybe<Scalars['ID']>>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  sort?: Maybe<Array<Maybe<SortInput>>>
  where?: Maybe<Array<Maybe<WhereClause>>>
};

export type CategoryTemplatesArgs = {
  id?: Maybe<Array<Maybe<Scalars['ID']>>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  sort?: Maybe<Array<Maybe<SortInput>>>
  where?: Maybe<Array<Maybe<WhereClause>>>
};

export enum CategoryGeneratedFrom {
  System = 'system',
  User = 'user'
}

export type CategoryInput = {
  Events?: Maybe<Array<Maybe<EventInput>>>
  Owner?: Maybe<UserInput>
  StyleConfiguration?: Maybe<StyleConfigurationInput>
  StyleConfigurationId?: Maybe<Scalars['String']>
  Templates?: Maybe<Array<Maybe<TemplateInput>>>
  User?: Maybe<UserInput>
  UserId?: Maybe<Scalars['Int']>
  createdAt?: Maybe<Scalars['String']>
  generatedFrom?: Maybe<CategoryGeneratedFrom>
  id?: Maybe<Scalars['ID']>
  name?: Maybe<Scalars['String']>
  order?: Maybe<Scalars['Int']>
  status?: Maybe<CategoryStatus>
  updatedAt?: Maybe<Scalars['String']>
};

export enum CategoryStatus {
  Deprecated = 'deprecated',
  Drafted = 'drafted',
  Published = 'published'
}

export type Collection = {
  __typename?: 'Collection'
  Articles?: Maybe<Array<Maybe<Article>>>
  IntercomId?: Maybe<Scalars['Int']>
  color?: Maybe<Scalars['String']>
  createdAt?: Maybe<Scalars['String']>
  description?: Maybe<Scalars['String']>
  icon?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['ID']>
  name?: Maybe<Scalars['String']>
  order?: Maybe<Scalars['Int']>
  slug?: Maybe<Scalars['String']>
  sourceCreatedAt?: Maybe<Scalars['String']>
  sourceUpdatedAt?: Maybe<Scalars['String']>
  sourceUrl?: Maybe<Scalars['String']>
  status?: Maybe<CollectionStatus>
  updatedAt?: Maybe<Scalars['String']>
};

export type CollectionArticlesArgs = {
  id?: Maybe<Array<Maybe<Scalars['ID']>>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  sort?: Maybe<Array<Maybe<SortInput>>>
  where?: Maybe<Array<Maybe<WhereClause>>>
};

export type CollectionInput = {
  Articles?: Maybe<Array<Maybe<ArticleInput>>>
  IntercomId?: Maybe<Scalars['Int']>
  color?: Maybe<Scalars['String']>
  createdAt?: Maybe<Scalars['String']>
  description?: Maybe<Scalars['String']>
  icon?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['ID']>
  name?: Maybe<Scalars['String']>
  order?: Maybe<Scalars['Int']>
  slug?: Maybe<Scalars['String']>
  sourceCreatedAt?: Maybe<Scalars['String']>
  sourceUpdatedAt?: Maybe<Scalars['String']>
  sourceUrl?: Maybe<Scalars['String']>
  status?: Maybe<CollectionStatus>
  updatedAt?: Maybe<Scalars['String']>
};

export enum CollectionStatus {
  Deleted = 'deleted',
  Drafted = 'drafted',
  Published = 'published'
}

export type Configuration = {
  __typename?: 'Configuration'
  ConfigurationHistories?: Maybe<Array<Maybe<ConfigurationHistory>>>
  createdAt?: Maybe<Scalars['String']>
  description?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['ID']>
  key?: Maybe<Scalars['String']>
  namespace?: Maybe<Scalars['String']>
  tenant?: Maybe<ConfigurationTenant>
  updatedAt?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['String']>
};

export type ConfigurationConfigurationHistoriesArgs = {
  id?: Maybe<Array<Maybe<Scalars['ID']>>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  sort?: Maybe<Array<Maybe<SortInput>>>
  where?: Maybe<Array<Maybe<WhereClause>>>
};

export type ConfigurationHistory = {
  __typename?: 'ConfigurationHistory'
  Configuration?: Maybe<Configuration>
  ConfigurationId?: Maybe<Scalars['String']>
  createdAt?: Maybe<Scalars['String']>
  date?: Maybe<Scalars['String']>
  description?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['ID']>
  key?: Maybe<Scalars['String']>
  updatedAt?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['String']>
};

export type ConfigurationHistoryInput = {
  Configuration?: Maybe<ConfigurationInput>
  ConfigurationId?: Maybe<Scalars['String']>
  createdAt?: Maybe<Scalars['String']>
  date?: Maybe<Scalars['String']>
  description?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['ID']>
  key?: Maybe<Scalars['String']>
  updatedAt?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['String']>
};

export type ConfigurationInput = {
  ConfigurationHistories?: Maybe<Array<Maybe<ConfigurationHistoryInput>>>
  createdAt?: Maybe<Scalars['String']>
  description?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['ID']>
  key?: Maybe<Scalars['String']>
  namespace?: Maybe<Scalars['String']>
  tenant?: Maybe<ConfigurationTenant>
  updatedAt?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['String']>
};

export enum ConfigurationTenant {
  Energy = 'energy',
  Mobility = 'mobility',
  Shared = 'shared'
}

export enum ConsumptionsDataStatus {
  MissingData = 'missing_data',
  Ok = 'ok',
  PendingData = 'pending_data'
}

export type Contact = {
  __typename?: 'Contact'
  Owner?: Maybe<User>
  OwnerId?: Maybe<Scalars['Int']>
  addressFullReadable?: Maybe<Scalars['String']>
  address_1?: Maybe<Scalars['String']>
  address_2?: Maybe<Scalars['String']>
  address_full?: Maybe<Scalars['String']>
  address_number?: Maybe<Scalars['String']>
  address_short?: Maybe<Scalars['String']>
  birth_date?: Maybe<Scalars['String']>
  birth_place?: Maybe<Scalars['String']>
  business_name?: Maybe<Scalars['String']>
  cadastralCode?: Maybe<Scalars['String']>
  city?: Maybe<Scalars['String']>
  city_short?: Maybe<Scalars['String']>
  country?: Maybe<Scalars['String']>
  createdAt?: Maybe<Scalars['String']>
  email?: Maybe<Scalars['String']>
  first_name?: Maybe<Scalars['String']>
  fullname?: Maybe<Scalars['String']>
  gender?: Maybe<ContactGender>
  id?: Maybe<Scalars['ID']>
  last_name?: Maybe<Scalars['String']>
  mobile_first_phone?: Maybe<Scalars['String']>
  mobile_phone?: Maybe<Scalars['String']>
  note?: Maybe<Scalars['String']>
  phone?: Maybe<Scalars['String']>
  place_id?: Maybe<Scalars['String']>
  postal_code?: Maybe<Scalars['String']>
  province?: Maybe<Scalars['String']>
  sdi_code?: Maybe<Scalars['String']>
  social_security_number?: Maybe<Scalars['String']>
  type?: Maybe<ContactType>
  updatedAt?: Maybe<Scalars['String']>
  vat_code?: Maybe<Scalars['String']>
};

export enum ContactGender {
  Female = 'female',
  Male = 'male'
}

export type ContactInput = {
  Owner?: Maybe<UserInput>
  address_1?: Maybe<Scalars['String']>
  address_2?: Maybe<Scalars['String']>
  address_number?: Maybe<Scalars['String']>
  birth_date?: Maybe<Scalars['String']>
  birth_place?: Maybe<Scalars['String']>
  business_name?: Maybe<Scalars['String']>
  city?: Maybe<Scalars['String']>
  country?: Maybe<Scalars['String']>
  createdAt?: Maybe<Scalars['String']>
  email?: Maybe<Scalars['String']>
  first_name?: Maybe<Scalars['String']>
  gender?: Maybe<ContactGender>
  id?: Maybe<Scalars['ID']>
  last_name?: Maybe<Scalars['String']>
  mobile_phone?: Maybe<Scalars['String']>
  note?: Maybe<Scalars['String']>
  phone?: Maybe<Scalars['String']>
  place_id?: Maybe<Scalars['String']>
  postal_code?: Maybe<Scalars['String']>
  province?: Maybe<Scalars['String']>
  sdi_code?: Maybe<Scalars['String']>
  social_security_number?: Maybe<Scalars['String']>
  type?: Maybe<ContactType>
  updatedAt?: Maybe<Scalars['String']>
  vat_code?: Maybe<Scalars['String']>
};

export enum ContactType {
  Business = 'business',
  Private = 'private'
}

export type Contract = {
  __typename?: 'Contract'
  Attributes?: Maybe<Array<Maybe<Attribute>>>
  Balances?: Maybe<Array<Maybe<Balance>>>
  BillingContact?: Maybe<Contact>
  BillingContactId?: Maybe<Scalars['Int']>
  ContractHistories?: Maybe<Array<Maybe<ContractHistory>>>
  ContractTransferFrom?: Maybe<ContractTransfer>
  ContractTransferTo?: Maybe<ContractTransfer>
  Customer?: Maybe<Customer>
  CustomerId?: Maybe<Scalars['Int']>
  Discounts?: Maybe<Array<Maybe<Discount>>>
  Files?: Maybe<Array<Maybe<File>>>
  Invoices?: Maybe<Array<Maybe<Invoice>>>
  Items?: Maybe<Array<Maybe<Item>>>
  Labels?: Maybe<Array<Maybe<Label>>>
  OTPCodes?: Maybe<Array<Maybe<OtpCode>>>
  Owner?: Maybe<User>
  OwnerContact?: Maybe<Contact>
  OwnerContactId?: Maybe<Scalars['Int']>
  OwnerId?: Maybe<Scalars['Int']>
  PaymentMethod?: Maybe<PaymentMethod>
  PaymentMethodId?: Maybe<Scalars['Int']>
  PromoCode?: Maybe<PromoCode>
  PromoCodeId?: Maybe<Scalars['Int']>
  PromoCodeRelation?: Maybe<Array<Maybe<PromoCode>>>
  SubStatuses?: Maybe<Array<Maybe<SubStatus>>>
  activationType?: Maybe<ContractActivationType>
  attributeMap?: Maybe<Scalars['String']>
  balanceTotal?: Maybe<Scalars['Float']>
  contractCodeName?: Maybe<Scalars['String']>
  contractCodeType?: Maybe<Scalars['String']>
  contractPromocode?: Maybe<Scalars['JSON']>
  createdAt?: Maybe<Scalars['String']>
  creation_date?: Maybe<Scalars['String']>
  displayStatus?: Maybe<Scalars['String']>
  end_date?: Maybe<Scalars['String']>
  enerpNumeroContrattoInterno?: Maybe<Scalars['String']>
  enerpStatus?: Maybe<Scalars['String']>
  enerp_id?: Maybe<Scalars['String']>
  getBenefitCodes?: Maybe<Array<Maybe<BenefitCode>>>
  getCurrentTariff?: Maybe<TariffInfo>
  getMeterReadingSettingsNotification?: Maybe<ContractMeterReadingsNotificationSettings>
  hasLastEstimatedInvoices?: Maybe<Scalars['Boolean']>
  hasPendingPayments?: Maybe<Scalars['Boolean']>
  hasTransferCode?: Maybe<Scalars['Boolean']>
  hasVariableTariff?: Maybe<Scalars['String']>
  healthStatus?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['ID']>
  isOrWasSupplied?: Maybe<Scalars['Boolean']>
  isSupplied?: Maybe<Scalars['String']>
  issueStartDate?: Maybe<Scalars['String']>
  labels?: Maybe<Array<Maybe<Scalars['String']>>>
  name?: Maybe<Scalars['String']>
  originalStartDate?: Maybe<Scalars['String']>
  parsedEnerpId?: Maybe<Scalars['String']>
  payload?: Maybe<Scalars['JSON']>
  pendingNotes?: Maybe<Array<Maybe<Note>>>
  promoCodeProcessed?: Maybe<Scalars['Boolean']>
  renewalDisplayStatus?: Maybe<Scalars['String']>
  renewalStatus?: Maybe<Scalars['String']>
  sameCadastralContracts?: Maybe<SameCadastralContract>
  signatureDate?: Maybe<Scalars['String']>
  source?: Maybe<ContractSource>
  startDateReadable?: Maybe<Scalars['String']>
  start_date?: Maybe<Scalars['String']>
  status?: Maybe<ContractStatus>
  target?: Maybe<ContractTarget>
  tariffEvents?: Maybe<Array<Maybe<TariffEvent>>>
  tariffHistory?: Maybe<Array<Maybe<TariffHistory>>>
  tariffStatusPayload?: Maybe<TariffStatusPayload>
  transferStartDate?: Maybe<Scalars['String']>
  type?: Maybe<ContractType>
  typeReadable?: Maybe<Scalars['String']>
  updatedAt?: Maybe<Scalars['String']>
  userEnrichedStatus?: Maybe<ContractUserEnrichedStatus>
};

export type ContractAttributesArgs = {
  id?: Maybe<Array<Maybe<Scalars['ID']>>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  sort?: Maybe<Array<Maybe<SortInput>>>
  where?: Maybe<Array<Maybe<WhereClause>>>
};

export type ContractBalancesArgs = {
  id?: Maybe<Array<Maybe<Scalars['ID']>>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  sort?: Maybe<Array<Maybe<SortInput>>>
  where?: Maybe<Array<Maybe<WhereClause>>>
};

export type ContractContractHistoriesArgs = {
  id?: Maybe<Array<Maybe<Scalars['ID']>>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  sort?: Maybe<Array<Maybe<SortInput>>>
  where?: Maybe<Array<Maybe<WhereClause>>>
};

export type ContractDiscountsArgs = {
  id?: Maybe<Array<Maybe<Scalars['ID']>>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  sort?: Maybe<Array<Maybe<SortInput>>>
  where?: Maybe<Array<Maybe<WhereClause>>>
};

export type ContractFilesArgs = {
  id?: Maybe<Array<Maybe<Scalars['ID']>>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  sort?: Maybe<Array<Maybe<SortInput>>>
  where?: Maybe<Array<Maybe<WhereClause>>>
};

export type ContractInvoicesArgs = {
  id?: Maybe<Array<Maybe<Scalars['ID']>>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  sort?: Maybe<Array<Maybe<SortInput>>>
  where?: Maybe<Array<Maybe<WhereClause>>>
};

export type ContractItemsArgs = {
  id?: Maybe<Array<Maybe<Scalars['ID']>>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  sort?: Maybe<Array<Maybe<SortInput>>>
  where?: Maybe<Array<Maybe<WhereClause>>>
};

export type ContractLabelsArgs = {
  id?: Maybe<Array<Maybe<Scalars['ID']>>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  sort?: Maybe<Array<Maybe<SortInput>>>
  where?: Maybe<Array<Maybe<WhereClause>>>
};

export type ContractOtpCodesArgs = {
  id?: Maybe<Array<Maybe<Scalars['ID']>>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  sort?: Maybe<Array<Maybe<SortInput>>>
  where?: Maybe<Array<Maybe<WhereClause>>>
};

export type ContractPromoCodeRelationArgs = {
  id?: Maybe<Array<Maybe<Scalars['ID']>>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  sort?: Maybe<Array<Maybe<SortInput>>>
  where?: Maybe<Array<Maybe<WhereClause>>>
};

export type ContractSubStatusesArgs = {
  id?: Maybe<Array<Maybe<Scalars['ID']>>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  sort?: Maybe<Array<Maybe<SortInput>>>
  where?: Maybe<Array<Maybe<WhereClause>>>
};

export type ContractTariffStatusPayloadArgs = {
  version?: Maybe<TariffStatusPayloadVersion>
};

export type ContractUserEnrichedStatusArgs = {
  version: UiVersion
};

export enum ContractActivationType {
  Activation = 'activation',
  Renewal = 'renewal',
  Switch = 'switch',
  SwitchTransfer = 'switch_transfer',
  Takeover = 'takeover',
  Transfer = 'transfer'
}

export enum ContractAllocationStatus {
  Completed = 'completed',
  Interrupted = 'interrupted',
  Ongoing = 'ongoing'
}

export type ContractBenefitsInfo = {
  __typename?: 'ContractBenefitsInfo'
  discountQuote?: Maybe<Scalars['Float']>
  givenDiscounts?: Maybe<Scalars['Int']>
  percentage?: Maybe<Scalars['Float']>
  remainingAmount?: Maybe<Scalars['Float']>
  status?: Maybe<BenefitsStatus>
  totalDiscounts?: Maybe<Scalars['Int']>
};

export type ContractHistory = {
  __typename?: 'ContractHistory'
  Contract?: Maybe<Contract>
  ContractId?: Maybe<Scalars['Int']>
  Files?: Maybe<Array<Maybe<File>>>
  Owner?: Maybe<User>
  OwnerId?: Maybe<Scalars['Int']>
  activationType?: Maybe<ContractHistoryActivationType>
  contractCodeName?: Maybe<Scalars['String']>
  createdAt?: Maybe<Scalars['String']>
  creation_date?: Maybe<Scalars['String']>
  end_date?: Maybe<Scalars['String']>
  enerp_id?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['ID']>
  signatureDate?: Maybe<Scalars['String']>
  start_date?: Maybe<Scalars['String']>
  updatedAt?: Maybe<Scalars['String']>
};

export type ContractHistoryFilesArgs = {
  id?: Maybe<Array<Maybe<Scalars['ID']>>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  sort?: Maybe<Array<Maybe<SortInput>>>
  where?: Maybe<Array<Maybe<WhereClause>>>
};

export enum ContractHistoryActivationType {
  Activation = 'activation',
  Renewal = 'renewal',
  Switch = 'switch',
  SwitchTransfer = 'switch_transfer',
  Takeover = 'takeover',
  Transfer = 'transfer'
}

export type ContractHistoryInput = {
  Contract?: Maybe<ContractInput>
  ContractId?: Maybe<Scalars['Int']>
  Files?: Maybe<Array<Maybe<FileInput>>>
  Owner?: Maybe<UserInput>
  activationType?: Maybe<ContractHistoryActivationType>
  contractCodeName?: Maybe<Scalars['String']>
  createdAt?: Maybe<Scalars['String']>
  creation_date?: Maybe<Scalars['String']>
  end_date?: Maybe<Scalars['String']>
  enerp_id?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['ID']>
  signatureDate?: Maybe<Scalars['String']>
  start_date?: Maybe<Scalars['String']>
  updatedAt?: Maybe<Scalars['String']>
};

export type ContractInput = {
  Attributes?: Maybe<Array<Maybe<AttributeInput>>>
  Balances?: Maybe<Array<Maybe<BalanceInput>>>
  BillingContact?: Maybe<ContactInput>
  BillingContactId?: Maybe<Scalars['Int']>
  ContractHistories?: Maybe<Array<Maybe<ContractHistoryInput>>>
  ContractTransferFrom?: Maybe<ContractTransferInput>
  ContractTransferTo?: Maybe<ContractTransferInput>
  Customer?: Maybe<CustomerInput>
  CustomerId?: Maybe<Scalars['Int']>
  Discounts?: Maybe<Array<Maybe<DiscountInput>>>
  Files?: Maybe<Array<Maybe<FileInput>>>
  Invoices?: Maybe<Array<Maybe<InvoiceInput>>>
  Items?: Maybe<Array<Maybe<ItemInput>>>
  Labels?: Maybe<Array<Maybe<LabelInput>>>
  OTPCodes?: Maybe<Array<Maybe<OtpCodeInput>>>
  Owner?: Maybe<UserInput>
  OwnerContact?: Maybe<ContactInput>
  OwnerContactId?: Maybe<Scalars['Int']>
  PaymentMethod?: Maybe<PaymentMethodInput>
  PaymentMethodId?: Maybe<Scalars['Int']>
  PromoCode?: Maybe<PromoCodeInput>
  PromoCodeId?: Maybe<Scalars['Int']>
  PromoCodeRelation?: Maybe<Array<Maybe<PromoCodeInput>>>
  SubStatuses?: Maybe<Array<Maybe<SubStatusInput>>>
  activationType?: Maybe<ContractActivationType>
  contractCodeName?: Maybe<Scalars['String']>
  createdAt?: Maybe<Scalars['String']>
  creation_date?: Maybe<Scalars['String']>
  end_date?: Maybe<Scalars['String']>
  enerpStatus?: Maybe<Scalars['String']>
  enerp_id?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['ID']>
  labels?: Maybe<Array<Maybe<Scalars['String']>>>
  name?: Maybe<Scalars['String']>
  payload?: Maybe<Scalars['JSON']>
  promoCodeProcessed?: Maybe<Scalars['Boolean']>
  signatureDate?: Maybe<Scalars['String']>
  source?: Maybe<ContractSource>
  start_date?: Maybe<Scalars['String']>
  status?: Maybe<ContractStatus>
  target?: Maybe<ContractTarget>
  type?: Maybe<ContractType>
  updatedAt?: Maybe<Scalars['String']>
};

export type ContractLabel = {
  __typename?: 'ContractLabel'
  contractId?: Maybe<Scalars['Int']>
  createdAt?: Maybe<Scalars['String']>
  labelId?: Maybe<Scalars['String']>
  updatedAt?: Maybe<Scalars['String']>
};

export type ContractLabelInput = {
  contractId?: Maybe<Scalars['Int']>
  createdAt?: Maybe<Scalars['String']>
  labelId?: Maybe<Scalars['String']>
  updatedAt?: Maybe<Scalars['String']>
};

export type ContractMeterReadingsNotificationSettings = {
  __typename?: 'ContractMeterReadingsNotificationSettings'
  active?: Maybe<Attribute>
  dayTime?: Maybe<Attribute>
  period?: Maybe<Attribute>
};

export type ContractPromoCode = {
  __typename?: 'ContractPromoCode'
  confirmedDiscounts?: Maybe<Array<Maybe<Discount>>>
  given?: Maybe<Scalars['Float']>
  nextActionDate?: Maybe<Scalars['String']>
  remaining?: Maybe<Scalars['Float']>
  remainingDiscounts?: Maybe<Array<Maybe<Discount>>>
  status?: Maybe<ContractAllocationStatus>
};

export enum ContractSource {
  Enerp = 'enerp',
  Native = 'native'
}

export enum ContractStatus {
  Active = 'active',
  Disabled = 'disabled',
  Drafted = 'drafted',
  Pending = 'pending',
  Transferred = 'transferred'
}

export enum ContractTarget {
  Business = 'business',
  Private = 'private'
}

export type ContractTransfer = {
  __typename?: 'ContractTransfer'
  Owner?: Maybe<User>
  OwnerId?: Maybe<Scalars['Int']>
  SourceContract?: Maybe<Contract>
  SourceContractId?: Maybe<Scalars['Int']>
  TargetContract?: Maybe<Contract>
  TargetContractId?: Maybe<Scalars['Int']>
  code?: Maybe<Scalars['String']>
  createdAt?: Maybe<Scalars['String']>
  external?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['ID']>
  requestDate?: Maybe<Scalars['String']>
  source_contract_id?: Maybe<Scalars['Int']>
  status?: Maybe<ContractTransferStatus>
  target_contract_id?: Maybe<Scalars['Int']>
  updatedAt?: Maybe<Scalars['String']>
};

export type ContractTransferCheck = {
  __typename?: 'ContractTransferCheck'
  code?: Maybe<Scalars['String']>
  link?: Maybe<Scalars['String']>
  status?: Maybe<ContractTransferCheckStatus>
};

export enum ContractTransferCheckStatus {
  Ok = 'ok',
  RejectedBusinessTarget = 'rejectedBusinessTarget',
  RejectedNotSupplied = 'rejectedNotSupplied',
  RejectedPendingInvoices = 'rejectedPendingInvoices',
  RejectedPendingTransfer = 'rejectedPendingTransfer'
}

export type ContractTransferInput = {
  Owner?: Maybe<UserInput>
  SourceContract?: Maybe<ContractInput>
  SourceContractId?: Maybe<Scalars['Int']>
  TargetContract?: Maybe<ContractInput>
  TargetContractId?: Maybe<Scalars['Int']>
  code?: Maybe<Scalars['String']>
  createdAt?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['ID']>
  requestDate?: Maybe<Scalars['String']>
  source_contract_id?: Maybe<Scalars['Int']>
  status?: Maybe<ContractTransferStatus>
  target_contract_id?: Maybe<Scalars['Int']>
  updatedAt?: Maybe<Scalars['String']>
};

export enum ContractTransferStatus {
  Completed = 'completed',
  Pending = 'pending'
}

export enum ContractType {
  Ee = 'ee',
  Gas = 'gas'
}

export enum ContractUserEnrichedStatus {
  Disabled = 'Disabled',
  PaymentMethodRequired = 'PaymentMethodRequired',
  PendingSupply = 'PendingSupply',
  SelfServiceReadingRequired = 'SelfServiceReadingRequired',
  SignatureRequired = 'SignatureRequired',
  Supplied = 'Supplied',
  TariffRenewalRequired = 'TariffRenewalRequired',
  Terminated = 'Terminated',
  TransferCompleted = 'TransferCompleted',
  TransferInProgress = 'TransferInProgress',
  WithCmorInvoices = 'WithCMORInvoices',
  WithExpiredInvoices = 'WithExpiredInvoices'
}

export type CountResult = {
  __typename?: 'CountResult'
  value?: Maybe<Scalars['Int']>
};

export type CreditDetails = {
  __typename?: 'CreditDetails'
  amount?: Maybe<Scalars['Float']>
  amountToPay?: Maybe<Scalars['Float']>
  balance?: Maybe<Scalars['Float']>
  totalCredit?: Maybe<Scalars['Float']>
  totalDebit?: Maybe<Scalars['Float']>
};

export type CtaConfiguration = {
  __typename?: 'CtaConfiguration'
  action?: Maybe<Scalars['String']>
  label?: Maybe<Scalars['String']>
};

export type Customer = {
  __typename?: 'Customer'
  Attributes?: Maybe<Array<Maybe<Attribute>>>
  BillingContact?: Maybe<Contact>
  BillingContactId?: Maybe<Scalars['Int']>
  Contracts?: Maybe<Array<Maybe<Contract>>>
  CustomerRiskHistories?: Maybe<Array<Maybe<CustomerRiskHistory>>>
  Events?: Maybe<Array<Maybe<Event>>>
  Files?: Maybe<Array<Maybe<File>>>
  Fines?: Maybe<Array<Maybe<Fine>>>
  Owner?: Maybe<User>
  OwnerContact?: Maybe<Contact>
  OwnerContactId?: Maybe<Scalars['Int']>
  OwnerId?: Maybe<Scalars['Int']>
  User?: Maybe<User>
  UserId?: Maybe<Scalars['Int']>
  createdAt?: Maybe<Scalars['String']>
  enerp_id?: Maybe<Scalars['Int']>
  enerp_ref?: Maybe<Scalars['String']>
  highRisk?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['ID']>
  isBusiness?: Maybe<Scalars['Boolean']>
  risk?: Maybe<CustomerRisk>
  status?: Maybe<CustomerStatus>
  type?: Maybe<CustomerType>
  updatedAt?: Maybe<Scalars['String']>
};

export type CustomerAttributesArgs = {
  id?: Maybe<Array<Maybe<Scalars['ID']>>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  sort?: Maybe<Array<Maybe<SortInput>>>
  where?: Maybe<Array<Maybe<WhereClause>>>
};

export type CustomerContractsArgs = {
  id?: Maybe<Array<Maybe<Scalars['ID']>>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  sort?: Maybe<Array<Maybe<SortInput>>>
  where?: Maybe<Array<Maybe<WhereClause>>>
};

export type CustomerCustomerRiskHistoriesArgs = {
  id?: Maybe<Array<Maybe<Scalars['ID']>>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  sort?: Maybe<Array<Maybe<SortInput>>>
  where?: Maybe<Array<Maybe<WhereClause>>>
};

export type CustomerEventsArgs = {
  id?: Maybe<Array<Maybe<Scalars['ID']>>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  sort?: Maybe<Array<Maybe<SortInput>>>
  where?: Maybe<Array<Maybe<WhereClause>>>
};

export type CustomerFilesArgs = {
  id?: Maybe<Array<Maybe<Scalars['ID']>>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  sort?: Maybe<Array<Maybe<SortInput>>>
  where?: Maybe<Array<Maybe<WhereClause>>>
};

export type CustomerFinesArgs = {
  id?: Maybe<Array<Maybe<Scalars['ID']>>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  sort?: Maybe<Array<Maybe<SortInput>>>
  where?: Maybe<Array<Maybe<WhereClause>>>
};

export type CustomerInput = {
  Attributes?: Maybe<Array<Maybe<AttributeInput>>>
  BillingContact?: Maybe<ContactInput>
  BillingContactId?: Maybe<Scalars['Int']>
  Contracts?: Maybe<Array<Maybe<ContractInput>>>
  CustomerRiskHistories?: Maybe<Array<Maybe<CustomerRiskHistoryInput>>>
  Events?: Maybe<Array<Maybe<EventInput>>>
  Files?: Maybe<Array<Maybe<FileInput>>>
  Fines?: Maybe<Array<Maybe<FineInput>>>
  Owner?: Maybe<UserInput>
  OwnerContact?: Maybe<ContactInput>
  OwnerContactId?: Maybe<Scalars['Int']>
  User?: Maybe<UserInput>
  UserId?: Maybe<Scalars['Int']>
  createdAt?: Maybe<Scalars['String']>
  enerp_id?: Maybe<Scalars['Int']>
  enerp_ref?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['ID']>
  risk?: Maybe<CustomerRisk>
  status?: Maybe<CustomerStatus>
  type?: Maybe<CustomerType>
  updatedAt?: Maybe<Scalars['String']>
};

export enum CustomerRisk {
  High = 'high',
  Low = 'low',
  Normal = 'normal'
}

export type CustomerRiskHistory = {
  __typename?: 'CustomerRiskHistory'
  Customer?: Maybe<Customer>
  CustomerId?: Maybe<Scalars['Int']>
  User?: Maybe<User>
  UserId?: Maybe<Scalars['Int']>
  createdAt?: Maybe<Scalars['String']>
  current?: Maybe<CustomerRiskHistoryCurrent>
  endDate?: Maybe<Scalars['String']>
  former?: Maybe<CustomerRiskHistoryFormer>
  id?: Maybe<Scalars['ID']>
  reason?: Maybe<Scalars['String']>
  source?: Maybe<CustomerRiskHistorySource>
  updatedAt?: Maybe<Scalars['String']>
};

export enum CustomerRiskHistoryCurrent {
  High = 'high',
  Low = 'low',
  Normal = 'normal'
}

export enum CustomerRiskHistoryFormer {
  High = 'high',
  Low = 'low',
  Normal = 'normal'
}

export type CustomerRiskHistoryInput = {
  Customer?: Maybe<CustomerInput>
  CustomerId?: Maybe<Scalars['Int']>
  User?: Maybe<UserInput>
  UserId?: Maybe<Scalars['Int']>
  createdAt?: Maybe<Scalars['String']>
  current?: Maybe<CustomerRiskHistoryCurrent>
  endDate?: Maybe<Scalars['String']>
  former?: Maybe<CustomerRiskHistoryFormer>
  id?: Maybe<Scalars['ID']>
  reason?: Maybe<Scalars['String']>
  source?: Maybe<CustomerRiskHistorySource>
  updatedAt?: Maybe<Scalars['String']>
};

export enum CustomerRiskHistorySource {
  Manual = 'manual',
  System = 'system'
}

export enum CustomerStatus {
  Active = 'active',
  Approved = 'approved',
  Pending = 'pending',
  Rejected = 'rejected'
}

export enum CustomerType {
  Company = 'company',
  Freelance = 'freelance',
  Private = 'private'
}

export type DailyData = {
  __typename?: 'DailyData'
  consumptionsStatus: ConsumptionsDataStatus
  dailyCoefficient: Scalars['Float']
  date: Scalars['String']
  hourlyData: Array<Maybe<HourlyData>>
  pricesStatus: PricesDataStatus
};

export type DailyDataResponse = {
  __typename?: 'DailyDataResponse'
  dailyData: Array<Maybe<DailyData>>
  lastUpdated?: Maybe<Scalars['String']>
  maxConsumption?: Maybe<Scalars['Float']>
  maxPrice: Scalars['Float']
  minConsumption?: Maybe<Scalars['Float']>
  minPrice: Scalars['Float']
};

export type DailyPrice = {
  __typename?: 'DailyPrice'
  coefficient?: Maybe<Scalars['Float']>
  createdAt?: Maybe<Scalars['String']>
  date?: Maybe<Scalars['String']>
  hourlyCoefficientCalculated?: Maybe<Scalars['Boolean']>
  hourlyPrices?: Maybe<Scalars['JSON']>
  id?: Maybe<Scalars['ID']>
  positiveDeltasSum?: Maybe<Scalars['Float']>
  rawCoefficient?: Maybe<Scalars['Float']>
  updatedAt?: Maybe<Scalars['String']>
};

export type DailyPriceInput = {
  coefficient?: Maybe<Scalars['Float']>
  createdAt?: Maybe<Scalars['String']>
  date?: Maybe<Scalars['String']>
  hourlyCoefficientCalculated?: Maybe<Scalars['Boolean']>
  hourlyPrices?: Maybe<Scalars['JSON']>
  id?: Maybe<Scalars['ID']>
  positiveDeltasSum?: Maybe<Scalars['Float']>
  rawCoefficient?: Maybe<Scalars['Float']>
  updatedAt?: Maybe<Scalars['String']>
};

export type DeviceStatus = {
  codePushLatestVersion?: Maybe<Scalars['String']>
  codePushVersion?: Maybe<Scalars['String']>
  nativeLatestVersion?: Maybe<Scalars['String']>
  nativeVersion?: Maybe<Scalars['String']>
  unreadMessages?: Maybe<Scalars['Boolean']>
};

export type Discount = {
  __typename?: 'Discount'
  Contract?: Maybe<Contract>
  ContractId?: Maybe<Scalars['Int']>
  Owner?: Maybe<User>
  OwnerId?: Maybe<Scalars['Int']>
  PromoCode?: Maybe<PromoCode>
  PromoCodeId?: Maybe<Scalars['Int']>
  amount?: Maybe<Scalars['Float']>
  createdAt?: Maybe<Scalars['String']>
  date?: Maybe<Scalars['String']>
  description?: Maybe<Scalars['String']>
  externalReference?: Maybe<Scalars['JSON']>
  id?: Maybe<Scalars['ID']>
  partnerId?: Maybe<Scalars['String']>
  source?: Maybe<DiscountSource>
  status?: Maybe<DiscountStatus>
  type?: Maybe<DiscountType>
  updatedAt?: Maybe<Scalars['String']>
};

export type DiscountInput = {
  Contract?: Maybe<ContractInput>
  ContractId?: Maybe<Scalars['Int']>
  Owner?: Maybe<UserInput>
  PromoCode?: Maybe<PromoCodeInput>
  PromoCodeId?: Maybe<Scalars['Int']>
  amount?: Maybe<Scalars['Float']>
  createdAt?: Maybe<Scalars['String']>
  date?: Maybe<Scalars['String']>
  description?: Maybe<Scalars['String']>
  externalReference?: Maybe<Scalars['JSON']>
  id?: Maybe<Scalars['ID']>
  partnerId?: Maybe<Scalars['String']>
  source?: Maybe<DiscountSource>
  status?: Maybe<DiscountStatus>
  type?: Maybe<DiscountType>
  updatedAt?: Maybe<Scalars['String']>
};

export enum DiscountSource {
  Manual = 'manual',
  System = 'system'
}

export enum DiscountStatus {
  Active = 'active',
  Blocked = 'blocked',
  Confirmed = 'confirmed',
  Pending = 'pending'
}

export enum DiscountType {
  Administrative = 'administrative',
  Discount = 'discount',
  Dispute = 'dispute'
}

export enum DiscoverTateWidgetVariant {
  ActiveContract = 'ActiveContract',
  DisabledContracts = 'DisabledContracts',
  NoContracts = 'NoContracts'
}

export type EmailInfo = {
  __typename?: 'EmailInfo'
  email?: Maybe<Scalars['String']>
  found?: Maybe<Scalars['Boolean']>
};

export type EnergyTariffExtras = {
  __typename?: 'EnergyTariffExtras'
  pcv?: Maybe<TariffElement>
};

export type EnerpJob = {
  __typename?: 'EnerpJob'
  createdAt?: Maybe<Scalars['String']>
  entity?: Maybe<EnerpJobEntity>
  entityId?: Maybe<Scalars['Int']>
  id?: Maybe<Scalars['ID']>
  name?: Maybe<Scalars['String']>
  payload?: Maybe<Scalars['JSON']>
  response?: Maybe<Scalars['JSON']>
  statusCode?: Maybe<Scalars['String']>
  updatedAt?: Maybe<Scalars['String']>
};

export enum EnerpJobEntity {
  Contract = 'contract',
  Customer = 'customer',
  Discount = 'discount',
  Invoice = 'invoice',
  SelfService = 'selfService'
}

export type EnerpJobInput = {
  createdAt?: Maybe<Scalars['String']>
  entity?: Maybe<EnerpJobEntity>
  entityId?: Maybe<Scalars['Int']>
  id?: Maybe<Scalars['ID']>
  name?: Maybe<Scalars['String']>
  payload?: Maybe<Scalars['JSON']>
  response?: Maybe<Scalars['JSON']>
  statusCode?: Maybe<Scalars['String']>
  updatedAt?: Maybe<Scalars['String']>
};

export type EnerpPaymentStatus = {
  __typename?: 'EnerpPaymentStatus'
  Entity?: Maybe<Invoice>
  EntityId?: Maybe<Scalars['String']>
  createdAt?: Maybe<Scalars['String']>
  entityId?: Maybe<Scalars['String']>
  entityType?: Maybe<EnerpPaymentStatusEntityType>
  id?: Maybe<Scalars['ID']>
  lastSyncPayload?: Maybe<Scalars['JSON']>
  status?: Maybe<EnerpPaymentStatusStatus>
  updatedAt?: Maybe<Scalars['String']>
};

export enum EnerpPaymentStatusEntityType {
  Balance = 'balance',
  Installment = 'installment',
  Invoice = 'invoice'
}

export type EnerpPaymentStatusInput = {
  Entity?: Maybe<InvoiceInput>
  EntityId?: Maybe<Scalars['String']>
  createdAt?: Maybe<Scalars['String']>
  entityId?: Maybe<Scalars['String']>
  entityType?: Maybe<EnerpPaymentStatusEntityType>
  id?: Maybe<Scalars['ID']>
  lastSyncPayload?: Maybe<Scalars['JSON']>
  status?: Maybe<EnerpPaymentStatusStatus>
  updatedAt?: Maybe<Scalars['String']>
};

export enum EnerpPaymentStatusStatus {
  Closed = 'closed',
  Deleted = 'deleted',
  Open = 'open'
}

export type Event = {
  __typename?: 'Event'
  AccountCustomer?: Maybe<Customer>
  AccountId?: Maybe<Scalars['String']>
  AccountUser?: Maybe<User>
  Category?: Maybe<Category>
  CategoryId?: Maybe<Scalars['String']>
  Owner?: Maybe<User>
  OwnerId?: Maybe<Scalars['Int']>
  SafeCategory?: Maybe<Category>
  Service?: Maybe<Service>
  ServiceId?: Maybe<Scalars['String']>
  StyleConfiguration?: Maybe<StyleConfiguration>
  StyleConfigurationId?: Maybe<Scalars['String']>
  Template?: Maybe<Template>
  TemplateId?: Maybe<Scalars['String']>
  accountType?: Maybe<EventAccountType>
  account_id?: Maybe<Scalars['Int']>
  amount?: Maybe<Scalars['Int']>
  createdAt?: Maybe<Scalars['String']>
  ctaConfiguration?: Maybe<Scalars['JSON']>
  currency?: Maybe<Scalars['String']>
  description?: Maybe<Scalars['String']>
  dirtyTemplate?: Maybe<Scalars['Boolean']>
  endDate?: Maybe<Scalars['String']>
  externalReference?: Maybe<Scalars['JSON']>
  id?: Maybe<Scalars['ID']>
  name?: Maybe<Scalars['String']>
  recurrencyModel?: Maybe<Scalars['JSON']>
  recurrent?: Maybe<Scalars['Boolean']>
  reference?: Maybe<EventReference>
  startDate?: Maybe<Scalars['String']>
  type?: Maybe<EventType>
  updatedAt?: Maybe<Scalars['String']>
};

export enum EventAccountType {
  Customer = 'customer',
  User = 'user'
}

export type EventInput = {
  AccountCustomer?: Maybe<CustomerInput>
  AccountId?: Maybe<Scalars['String']>
  AccountUser?: Maybe<UserInput>
  Category?: Maybe<CategoryInput>
  CategoryId?: Maybe<Scalars['String']>
  Owner?: Maybe<UserInput>
  Service?: Maybe<ServiceInput>
  ServiceId?: Maybe<Scalars['String']>
  StyleConfiguration?: Maybe<StyleConfigurationInput>
  StyleConfigurationId?: Maybe<Scalars['String']>
  Template?: Maybe<TemplateInput>
  TemplateId?: Maybe<Scalars['String']>
  accountType?: Maybe<EventAccountType>
  account_id?: Maybe<Scalars['Int']>
  amount?: Maybe<Scalars['Int']>
  createdAt?: Maybe<Scalars['String']>
  ctaConfiguration?: Maybe<Scalars['JSON']>
  currency?: Maybe<Scalars['String']>
  description?: Maybe<Scalars['String']>
  dirtyTemplate?: Maybe<Scalars['Boolean']>
  endDate?: Maybe<Scalars['String']>
  externalReference?: Maybe<Scalars['JSON']>
  id?: Maybe<Scalars['ID']>
  name?: Maybe<Scalars['String']>
  recurrencyModel?: Maybe<Scalars['JSON']>
  recurrent?: Maybe<Scalars['Boolean']>
  startDate?: Maybe<Scalars['String']>
  type?: Maybe<EventType>
  updatedAt?: Maybe<Scalars['String']>
};

export enum EventRecurrency {
  Month = 'month',
  Week = 'week',
  Year = 'year'
}

export type EventReference = Contract | Installment | Invoice;

export enum EventType {
  Financial = 'financial',
  Informative = 'informative'
}

export type ExternalReference = {
  __typename?: 'ExternalReference'
  id?: Maybe<Scalars['ID']>
  type?: Maybe<Scalars['String']>
};

export type File = {
  __typename?: 'File'
  Contract?: Maybe<Contract>
  ContractHistory?: Maybe<ContractHistory>
  ContractHistoryId?: Maybe<Scalars['Int']>
  ContractId?: Maybe<Scalars['Int']>
  Customer?: Maybe<Customer>
  CustomerId?: Maybe<Scalars['Int']>
  Invoice?: Maybe<Invoice>
  InvoiceId?: Maybe<Scalars['Int']>
  Owner?: Maybe<User>
  OwnerId?: Maybe<Scalars['Int']>
  SelfService?: Maybe<SelfService>
  SelfServiceId?: Maybe<Scalars['Int']>
  createdAt?: Maybe<Scalars['String']>
  getSignedObjectUrl?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['ID']>
  name?: Maybe<Scalars['String']>
  note?: Maybe<Scalars['String']>
  s3Location?: Maybe<Scalars['String']>
  s3_bucket?: Maybe<Scalars['String']>
  s3_etag?: Maybe<Scalars['String']>
  s3_key?: Maybe<Scalars['String']>
  s3_location?: Maybe<Scalars['String']>
  s3_type?: Maybe<Scalars['String']>
  signedPutObjectUrl?: Maybe<Scalars['String']>
  size?: Maybe<Scalars['Int']>
  status?: Maybe<FileStatus>
  type?: Maybe<FileType>
  updatedAt?: Maybe<Scalars['String']>
};

export type FileInput = {
  Contract?: Maybe<ContractInput>
  ContractHistory?: Maybe<ContractHistoryInput>
  ContractHistoryId?: Maybe<Scalars['Int']>
  ContractId?: Maybe<Scalars['Int']>
  Customer?: Maybe<CustomerInput>
  CustomerId?: Maybe<Scalars['Int']>
  Invoice?: Maybe<InvoiceInput>
  InvoiceId?: Maybe<Scalars['Int']>
  Owner?: Maybe<UserInput>
  SelfService?: Maybe<SelfServiceInput>
  SelfServiceId?: Maybe<Scalars['Int']>
  createdAt?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['ID']>
  name?: Maybe<Scalars['String']>
  note?: Maybe<Scalars['String']>
  s3_bucket?: Maybe<Scalars['String']>
  s3_etag?: Maybe<Scalars['String']>
  s3_key?: Maybe<Scalars['String']>
  s3_type?: Maybe<Scalars['String']>
  size?: Maybe<Scalars['Int']>
  status?: Maybe<FileStatus>
  type?: Maybe<FileType>
  updatedAt?: Maybe<Scalars['String']>
};

export enum FileStatus {
  Pending = 'pending',
  Uploaded = 'uploaded'
}

export enum FileType {
  Cmor = 'cmor',
  ContractDocumentation = 'contract_documentation',
  ContractRenewalDocumentation = 'contract_renewal_documentation',
  Documentation = 'documentation',
  IdentityCardBack = 'identity_card_back',
  IdentityCardFront = 'identity_card_front',
  Invoice = 'invoice',
  InvoiceDetail = 'invoice_detail',
  OfficeDocumentation = 'office_documentation',
  PassportBack = 'passport_back',
  PassportFront = 'passport_front',
  UnifiedInvoice = 'unified_invoice'
}

export type Fine = {
  __typename?: 'Fine'
  Customer?: Maybe<Customer>
  CustomerId?: Maybe<Scalars['Int']>
  Invoice?: Maybe<Invoice>
  InvoiceId?: Maybe<Scalars['Int']>
  amount?: Maybe<Scalars['Float']>
  createdAt?: Maybe<Scalars['String']>
  date?: Maybe<Scalars['String']>
  dueDate?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['ID']>
  status?: Maybe<FineStatus>
  updatedAt?: Maybe<Scalars['String']>
};

export type FineInput = {
  Customer?: Maybe<CustomerInput>
  CustomerId?: Maybe<Scalars['Int']>
  Invoice?: Maybe<InvoiceInput>
  InvoiceId?: Maybe<Scalars['Int']>
  amount?: Maybe<Scalars['Float']>
  createdAt?: Maybe<Scalars['String']>
  date?: Maybe<Scalars['String']>
  dueDate?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['ID']>
  status?: Maybe<FineStatus>
  updatedAt?: Maybe<Scalars['String']>
};

export enum FineStatus {
  Cancelled = 'cancelled',
  Confirmed = 'confirmed',
  Graced = 'graced',
  Pending = 'pending'
}

export type GasTariffExtras = {
  __typename?: 'GasTariffExtras'
  ccr?: Maybe<TariffElement>
  fixedQvd?: Maybe<TariffElement>
  variableQvd?: Maybe<TariffElement>
};

export type GeneralOperationResult = {
  __typename?: 'GeneralOperationResult'
  payload?: Maybe<Scalars['JSON']>
  status?: Maybe<Scalars['String']>
};

export type HomeController = {
  __typename?: 'HomeController'
  widgets?: Maybe<Array<Maybe<WidgetConfiguration>>>
};

export type HourlyConsumption = {
  __typename?: 'HourlyConsumption'
  Item?: Maybe<Item>
  ItemId?: Maybe<Scalars['Int']>
  Owner?: Maybe<User>
  OwnerId?: Maybe<Scalars['Int']>
  consumption?: Maybe<Scalars['Float']>
  createdAt?: Maybe<Scalars['String']>
  date?: Maybe<Scalars['String']>
  hour?: Maybe<Scalars['Int']>
  id?: Maybe<Scalars['ID']>
  owner_id?: Maybe<Scalars['Int']>
  updatedAt?: Maybe<Scalars['String']>
};

export type HourlyConsumptionInput = {
  Item?: Maybe<ItemInput>
  ItemId?: Maybe<Scalars['Int']>
  Owner?: Maybe<UserInput>
  consumption?: Maybe<Scalars['Float']>
  createdAt?: Maybe<Scalars['String']>
  date?: Maybe<Scalars['String']>
  hour?: Maybe<Scalars['Int']>
  id?: Maybe<Scalars['ID']>
  owner_id?: Maybe<Scalars['Int']>
  updatedAt?: Maybe<Scalars['String']>
};

export type HourlyData = {
  __typename?: 'HourlyData'
  coefficient: Scalars['Float']
  consumption?: Maybe<Scalars['Float']>
  hour: Scalars['Int']
  price: Scalars['Float']
};

export type HourlyPrice = {
  __typename?: 'HourlyPrice'
  coefficient?: Maybe<Scalars['Int']>
  hour: Scalars['Int']
  price: Scalars['Float']
};

export type Installment = {
  __typename?: 'Installment'
  InstallmentsPlan?: Maybe<InstallmentsPlan>
  InstallmentsPlanId?: Maybe<Scalars['String']>
  Owner?: Maybe<User>
  OwnerId?: Maybe<Scalars['Int']>
  PaymentAttempts?: Maybe<Array<Maybe<PaymentAttempt>>>
  amount?: Maybe<Scalars['Float']>
  createdAt?: Maybe<Scalars['String']>
  decimalAmount?: Maybe<Scalars['String']>
  dueDate?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['ID']>
  number?: Maybe<Scalars['Int']>
  paymentAmount?: Maybe<Scalars['Float']>
  paymentDate?: Maybe<Scalars['String']>
  paymentSource?: Maybe<InstallmentPaymentSource>
  status?: Maybe<InstallmentStatus>
  stripeId?: Maybe<Scalars['String']>
  updatedAt?: Maybe<Scalars['String']>
};

export type InstallmentPaymentAttemptsArgs = {
  id?: Maybe<Array<Maybe<Scalars['ID']>>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  sort?: Maybe<Array<Maybe<SortInput>>>
  where?: Maybe<Array<Maybe<WhereClause>>>
};

export type InstallmentConfiguration = {
  amount?: Maybe<Scalars['Float']>
  dueDate?: Maybe<Scalars['String']>
  paid?: Maybe<Scalars['Boolean']>
};

export type InstallmentInput = {
  InstallmentsPlan?: Maybe<InstallmentsPlanInput>
  InstallmentsPlanId?: Maybe<Scalars['String']>
  Owner?: Maybe<UserInput>
  PaymentAttempts?: Maybe<Array<Maybe<PaymentAttemptInput>>>
  amount?: Maybe<Scalars['Float']>
  createdAt?: Maybe<Scalars['String']>
  dueDate?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['ID']>
  number?: Maybe<Scalars['Int']>
  paymentAmount?: Maybe<Scalars['Float']>
  paymentDate?: Maybe<Scalars['String']>
  paymentSource?: Maybe<InstallmentPaymentSource>
  status?: Maybe<InstallmentStatus>
  stripeId?: Maybe<Scalars['String']>
  updatedAt?: Maybe<Scalars['String']>
};

export enum InstallmentPaymentSource {
  Manual = 'manual',
  System = 'system'
}

export enum InstallmentSimplifiedStatus {
  Error = 'Error',
  Open = 'Open',
  Paid = 'Paid',
  Pending = 'Pending'
}

export enum InstallmentStatus {
  Drafted = 'drafted',
  ManualBilling = 'manual_billing',
  NotPaid = 'not_paid',
  Open = 'open',
  Paid = 'paid',
  PendingDraft = 'pending_draft'
}

export type InstallmentsPlan = {
  __typename?: 'InstallmentsPlan'
  Installments?: Maybe<Array<Maybe<Installment>>>
  Invoice?: Maybe<Invoice>
  InvoiceId?: Maybe<Scalars['Int']>
  Owner?: Maybe<User>
  OwnerId?: Maybe<Scalars['Int']>
  acceptedAt?: Maybe<Scalars['String']>
  administrativeCost?: Maybe<Scalars['Float']>
  configuration?: Maybe<Scalars['JSON']>
  createdAt?: Maybe<Scalars['String']>
  date?: Maybe<Scalars['String']>
  expireAt?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['ID']>
  paidAmount?: Maybe<Scalars['Float']>
  previousInvoiceStatus?: Maybe<Scalars['String']>
  previouslyConfirmed?: Maybe<Scalars['Boolean']>
  remainingAmount?: Maybe<Scalars['Float']>
  status?: Maybe<InstallmentsPlanStatus>
  updatedAt?: Maybe<Scalars['String']>
};

export type InstallmentsPlanInstallmentsArgs = {
  id?: Maybe<Array<Maybe<Scalars['ID']>>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  sort?: Maybe<Array<Maybe<SortInput>>>
  where?: Maybe<Array<Maybe<WhereClause>>>
};

export type InstallmentsPlanInfo = {
  __typename?: 'InstallmentsPlanInfo'
  hasPlan: Scalars['Boolean']
  installments?: Maybe<Array<Maybe<InstallmentSimplifiedStatus>>>
  nextActionDate?: Maybe<Scalars['String']>
  paid?: Maybe<Scalars['Float']>
  remaining?: Maybe<Scalars['Float']>
};

export type InstallmentsPlanInput = {
  Installments?: Maybe<Array<Maybe<InstallmentInput>>>
  Invoice?: Maybe<InvoiceInput>
  InvoiceId?: Maybe<Scalars['Int']>
  Owner?: Maybe<UserInput>
  acceptedAt?: Maybe<Scalars['String']>
  administrativeCost?: Maybe<Scalars['Float']>
  configuration?: Maybe<Scalars['JSON']>
  createdAt?: Maybe<Scalars['String']>
  date?: Maybe<Scalars['String']>
  expireAt?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['ID']>
  previousInvoiceStatus?: Maybe<Scalars['String']>
  previouslyConfirmed?: Maybe<Scalars['Boolean']>
  status?: Maybe<InstallmentsPlanStatus>
  updatedAt?: Maybe<Scalars['String']>
};

export enum InstallmentsPlanStatus {
  Accepted = 'accepted',
  Completed = 'completed',
  Declined = 'declined',
  NotRespected = 'not_respected',
  Pending = 'pending'
}

export type Invoice = {
  __typename?: 'Invoice'
  Contract?: Maybe<Contract>
  ContractId?: Maybe<Scalars['Int']>
  CreatedBalances?: Maybe<Array<Maybe<Balance>>>
  EnerpPaymentStatus?: Maybe<EnerpPaymentStatus>
  Files?: Maybe<Array<Maybe<File>>>
  Fines?: Maybe<Array<Maybe<Fine>>>
  InstallmentsPlans?: Maybe<Array<Maybe<InstallmentsPlan>>>
  InvoiceEvents?: Maybe<Array<Maybe<InvoiceEvent>>>
  InvoiceRows?: Maybe<Array<Maybe<InvoiceRow>>>
  Owner?: Maybe<User>
  OwnerId?: Maybe<Scalars['Int']>
  PaymentAttempts?: Maybe<Array<Maybe<PaymentAttempt>>>
  UsedBalances?: Maybe<Array<Maybe<Balance>>>
  alreadyPaidAmount?: Maybe<Scalars['Float']>
  amount?: Maybe<Scalars['Float']>
  amountPaid?: Maybe<Scalars['Float']>
  balanceAmount?: Maybe<Scalars['Float']>
  balanceRecords?: Maybe<Array<Maybe<Balance>>>
  cmorDate?: Maybe<Scalars['String']>
  createdAt?: Maybe<Scalars['String']>
  creditDetails?: Maybe<CreditDetails>
  date?: Maybe<Scalars['String']>
  decimalAmount?: Maybe<Scalars['String']>
  dueDate?: Maybe<Scalars['String']>
  endDate?: Maybe<Scalars['String']>
  enerpPaymentStatus?: Maybe<EnerpPaymentStatusStatus>
  enerp_id?: Maybe<Scalars['String']>
  enerp_ref?: Maybe<Scalars['String']>
  getInstallmentsBalance?: Maybe<Scalars['Float']>
  health?: Maybe<Scalars['Boolean']>
  id?: Maybe<Scalars['ID']>
  invoiceBalanceAmount?: Maybe<Scalars['Float']>
  name?: Maybe<Scalars['String']>
  payNowEnabled?: Maybe<PayNowEnabled>
  paymentAmount?: Maybe<Scalars['Float']>
  paymentDate?: Maybe<Scalars['String']>
  readingSource?: Maybe<InvoiceReadingSource>
  startDate?: Maybe<Scalars['String']>
  status?: Maybe<InvoiceStatus>
  stripeId?: Maybe<Scalars['String']>
  tate_ratio?: Maybe<Scalars['String']>
  totalEnergyKWh?: Maybe<Scalars['String']>
  updatedAt?: Maybe<Scalars['String']>
  userActionRequired?: Maybe<Scalars['Boolean']>
  userEnrichedStatus?: Maybe<InvoiceUserEnrichedStatus>
};

export type InvoiceCreatedBalancesArgs = {
  id?: Maybe<Array<Maybe<Scalars['ID']>>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  sort?: Maybe<Array<Maybe<SortInput>>>
  where?: Maybe<Array<Maybe<WhereClause>>>
};

export type InvoiceFilesArgs = {
  id?: Maybe<Array<Maybe<Scalars['ID']>>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  sort?: Maybe<Array<Maybe<SortInput>>>
  where?: Maybe<Array<Maybe<WhereClause>>>
};

export type InvoiceFinesArgs = {
  id?: Maybe<Array<Maybe<Scalars['ID']>>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  sort?: Maybe<Array<Maybe<SortInput>>>
  where?: Maybe<Array<Maybe<WhereClause>>>
};

export type InvoiceInstallmentsPlansArgs = {
  id?: Maybe<Array<Maybe<Scalars['ID']>>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  sort?: Maybe<Array<Maybe<SortInput>>>
  where?: Maybe<Array<Maybe<WhereClause>>>
};

export type InvoiceInvoiceEventsArgs = {
  id?: Maybe<Array<Maybe<Scalars['ID']>>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  sort?: Maybe<Array<Maybe<SortInput>>>
  where?: Maybe<Array<Maybe<WhereClause>>>
};

export type InvoiceInvoiceRowsArgs = {
  id?: Maybe<Array<Maybe<Scalars['ID']>>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  sort?: Maybe<Array<Maybe<SortInput>>>
  where?: Maybe<Array<Maybe<WhereClause>>>
};

export type InvoicePaymentAttemptsArgs = {
  id?: Maybe<Array<Maybe<Scalars['ID']>>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  sort?: Maybe<Array<Maybe<SortInput>>>
  where?: Maybe<Array<Maybe<WhereClause>>>
};

export type InvoiceUsedBalancesArgs = {
  id?: Maybe<Array<Maybe<Scalars['ID']>>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  sort?: Maybe<Array<Maybe<SortInput>>>
  where?: Maybe<Array<Maybe<WhereClause>>>
};

export type InvoiceUserEnrichedStatusArgs = {
  version: UiVersion
};

export enum InvoiceElementType {
  Balance = 'balance',
  Installment = 'installment',
  Invoice = 'invoice'
}

/** Outputs definition */
export type InvoiceElements = {
  __typename?: 'InvoiceElements'
  amount?: Maybe<Scalars['Float']>
  type?: Maybe<InvoiceElementType>
};

export type InvoiceEvent = {
  __typename?: 'InvoiceEvent'
  Invoice?: Maybe<Invoice>
  InvoiceId?: Maybe<Scalars['Int']>
  createdAt?: Maybe<Scalars['String']>
  date?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['ID']>
  name?: Maybe<InvoiceEventName>
  payload?: Maybe<Scalars['JSON']>
  source?: Maybe<InvoiceEventSource>
  updatedAt?: Maybe<Scalars['String']>
};

export type InvoiceEventInput = {
  Invoice?: Maybe<InvoiceInput>
  InvoiceId?: Maybe<Scalars['Int']>
  createdAt?: Maybe<Scalars['String']>
  date?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['ID']>
  name?: Maybe<InvoiceEventName>
  payload?: Maybe<Scalars['JSON']>
  source?: Maybe<InvoiceEventSource>
  updatedAt?: Maybe<Scalars['String']>
};

export enum InvoiceEventName {
  Created = 'created',
  Dispute = 'dispute',
  InstallmentsPlanAccepted = 'installments_plan_accepted',
  InstallmentsPlanCompleted = 'installments_plan_completed',
  InstallmentsPlanCreated = 'installments_plan_created',
  InstallmentsPlanFailed = 'installments_plan_failed',
  InstallmentsPlanRejected = 'installments_plan_rejected',
  MovedToDrafted = 'moved_to_drafted',
  MovedToNotPaid = 'moved_to_not_paid',
  MovedToPaid = 'moved_to_paid',
  MovedToPendingDraft = 'moved_to_pending_draft',
  Opened = 'opened',
  PaymentFailed = 'payment_failed'
}

export enum InvoiceEventSource {
  Office = 'office',
  Stripe = 'stripe',
  System = 'system',
  User = 'user'
}

export type InvoiceInput = {
  Contract?: Maybe<ContractInput>
  ContractId?: Maybe<Scalars['Int']>
  CreatedBalances?: Maybe<Array<Maybe<BalanceInput>>>
  EnerpPaymentStatus?: Maybe<EnerpPaymentStatusInput>
  Files?: Maybe<Array<Maybe<FileInput>>>
  Fines?: Maybe<Array<Maybe<FineInput>>>
  InstallmentsPlans?: Maybe<Array<Maybe<InstallmentsPlanInput>>>
  InvoiceEvents?: Maybe<Array<Maybe<InvoiceEventInput>>>
  InvoiceRows?: Maybe<Array<Maybe<InvoiceRowInput>>>
  Owner?: Maybe<UserInput>
  PaymentAttempts?: Maybe<Array<Maybe<PaymentAttemptInput>>>
  UsedBalances?: Maybe<Array<Maybe<BalanceInput>>>
  amount?: Maybe<Scalars['Float']>
  cmorDate?: Maybe<Scalars['String']>
  createdAt?: Maybe<Scalars['String']>
  date?: Maybe<Scalars['String']>
  dueDate?: Maybe<Scalars['String']>
  endDate?: Maybe<Scalars['String']>
  enerp_id?: Maybe<Scalars['String']>
  enerp_ref?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['ID']>
  name?: Maybe<Scalars['String']>
  paymentAmount?: Maybe<Scalars['Float']>
  paymentDate?: Maybe<Scalars['String']>
  readingSource?: Maybe<InvoiceReadingSource>
  startDate?: Maybe<Scalars['String']>
  status?: Maybe<InvoiceStatus>
  stripeId?: Maybe<Scalars['String']>
  updatedAt?: Maybe<Scalars['String']>
  userActionRequired?: Maybe<Scalars['Boolean']>
};

export enum InvoiceReadingSource {
  ReadingSourceEstimated = 'reading_source_estimated',
  ReadingSourceReal = 'reading_source_real',
  ReadingSourceSelfService = 'reading_source_self_service'
}

export type InvoiceRow = {
  __typename?: 'InvoiceRow'
  Invoice?: Maybe<Invoice>
  InvoiceId?: Maybe<Scalars['Int']>
  Owner?: Maybe<User>
  OwnerId?: Maybe<Scalars['Int']>
  amount?: Maybe<Scalars['Float']>
  createdAt?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['ID']>
  key?: Maybe<Scalars['String']>
  quantity?: Maybe<Scalars['Float']>
  quantity_unit?: Maybe<Scalars['String']>
  tax?: Maybe<Scalars['Float']>
  total?: Maybe<Scalars['String']>
  updatedAt?: Maybe<Scalars['String']>
};

export type InvoiceRowInput = {
  Invoice?: Maybe<InvoiceInput>
  InvoiceId?: Maybe<Scalars['Int']>
  Owner?: Maybe<UserInput>
  amount?: Maybe<Scalars['Float']>
  createdAt?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['ID']>
  key?: Maybe<Scalars['String']>
  quantity?: Maybe<Scalars['Float']>
  quantity_unit?: Maybe<Scalars['String']>
  tax?: Maybe<Scalars['Float']>
  updatedAt?: Maybe<Scalars['String']>
};

export enum InvoiceStatus {
  AcceptedInstallment = 'accepted_installment',
  Drafted = 'drafted',
  NotPayed = 'not_payed',
  Open = 'open',
  Payed = 'payed',
  PaymentError = 'payment_error',
  PaymentPendingCreation = 'payment_pending_creation',
  PaymentPendingCreationError = 'payment_pending_creation_error',
  PaymentRequested = 'payment_requested',
  PaymentSuccess = 'payment_success',
  PendingDataFetch = 'pending_data_fetch',
  PendingDraft = 'pending_draft',
  PendingInstallment = 'pending_installment',
  Unpayed = 'unpayed'
}

export enum InvoiceUserEnrichedStatus {
  AcceptedInstallment = 'AcceptedInstallment',
  ExpiredInstallment = 'ExpiredInstallment',
  Issues = 'Issues',
  New = 'New',
  Open = 'Open',
  Paid = 'Paid'
}

export type Item = {
  __typename?: 'Item'
  Contact?: Maybe<Contact>
  ContactId?: Maybe<Scalars['Int']>
  Contract?: Maybe<Contract>
  ContractId?: Maybe<Scalars['Int']>
  HourlyConsumptions?: Maybe<Array<Maybe<HourlyConsumption>>>
  Owner?: Maybe<User>
  OwnerId?: Maybe<Scalars['Int']>
  SelfServices?: Maybe<Array<Maybe<SelfService>>>
  createdAt?: Maybe<Scalars['String']>
  enerp_id?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['ID']>
  meterReadingRequired?: Maybe<Scalars['Boolean']>
  meterReadingStatus?: Maybe<MeterReadingStatus>
  name?: Maybe<Scalars['String']>
  payload?: Maybe<Scalars['JSON']>
  type?: Maybe<ItemType>
  updatedAt?: Maybe<Scalars['String']>
};

export type ItemHourlyConsumptionsArgs = {
  id?: Maybe<Array<Maybe<Scalars['ID']>>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  sort?: Maybe<Array<Maybe<SortInput>>>
  where?: Maybe<Array<Maybe<WhereClause>>>
};

export type ItemSelfServicesArgs = {
  id?: Maybe<Array<Maybe<Scalars['ID']>>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  sort?: Maybe<Array<Maybe<SortInput>>>
  where?: Maybe<Array<Maybe<WhereClause>>>
};

export type ItemInput = {
  Contact?: Maybe<ContactInput>
  ContactId?: Maybe<Scalars['Int']>
  Contract?: Maybe<ContractInput>
  ContractId?: Maybe<Scalars['Int']>
  HourlyConsumptions?: Maybe<Array<Maybe<HourlyConsumptionInput>>>
  Owner?: Maybe<UserInput>
  SelfServices?: Maybe<Array<Maybe<SelfServiceInput>>>
  createdAt?: Maybe<Scalars['String']>
  enerp_id?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['ID']>
  name?: Maybe<Scalars['String']>
  payload?: Maybe<Scalars['JSON']>
  type?: Maybe<ItemType>
  updatedAt?: Maybe<Scalars['String']>
};

export enum ItemType {
  Pdr = 'pdr',
  Pod = 'pod'
}

export type Label = {
  __typename?: 'Label'
  Attributes?: Maybe<Array<Maybe<Attribute>>>
  Contact?: Maybe<Contact>
  ContactId?: Maybe<Scalars['Int']>
  Contracts?: Maybe<Array<Maybe<Contract>>>
  Owner?: Maybe<User>
  OwnerId?: Maybe<Scalars['Int']>
  User?: Maybe<User>
  UserId?: Maybe<Scalars['Int']>
  color?: Maybe<Scalars['String']>
  contractsCount?: Maybe<Scalars['Int']>
  createdAt?: Maybe<Scalars['String']>
  enabled?: Maybe<Scalars['Boolean']>
  id?: Maybe<Scalars['ID']>
  name?: Maybe<Scalars['String']>
  type?: Maybe<LabelType>
  updatedAt?: Maybe<Scalars['String']>
};

export type LabelAttributesArgs = {
  id?: Maybe<Array<Maybe<Scalars['ID']>>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  sort?: Maybe<Array<Maybe<SortInput>>>
  where?: Maybe<Array<Maybe<WhereClause>>>
};

export type LabelContractsArgs = {
  id?: Maybe<Array<Maybe<Scalars['ID']>>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  sort?: Maybe<Array<Maybe<SortInput>>>
  where?: Maybe<Array<Maybe<WhereClause>>>
};

export type LabelInput = {
  Attributes?: Maybe<Array<Maybe<AttributeInput>>>
  Contact?: Maybe<ContactInput>
  ContactId?: Maybe<Scalars['Int']>
  Contracts?: Maybe<Array<Maybe<ContractInput>>>
  Owner?: Maybe<UserInput>
  User?: Maybe<UserInput>
  UserId?: Maybe<Scalars['Int']>
  color?: Maybe<Scalars['String']>
  createdAt?: Maybe<Scalars['String']>
  enabled?: Maybe<Scalars['Boolean']>
  id?: Maybe<Scalars['ID']>
  name?: Maybe<Scalars['String']>
  type?: Maybe<LabelType>
  updatedAt?: Maybe<Scalars['String']>
};

export enum LabelType {
  Property = 'property',
  System = 'system',
  User = 'user'
}

export enum LinkedPartner {
  Hon = 'hon'
}

export type MaterializedEvent = {
  __typename?: 'MaterializedEvent'
  Category?: Maybe<Category>
  CategoryId?: Maybe<Scalars['ID']>
  CustomerId?: Maybe<Scalars['ID']>
  SafeCategory?: Maybe<Category>
  Service?: Maybe<Service>
  ServiceId?: Maybe<Scalars['ID']>
  StyleConfiguration?: Maybe<StyleConfiguration>
  StyleConfigurationId?: Maybe<Scalars['ID']>
  amount?: Maybe<Scalars['Int']>
  ctaConfiguration?: Maybe<CtaConfiguration>
  date?: Maybe<Scalars['String']>
  description?: Maybe<Scalars['String']>
  eventId?: Maybe<Scalars['ID']>
  externalReference?: Maybe<ExternalReference>
  id?: Maybe<Scalars['ID']>
  name?: Maybe<Scalars['String']>
  recurrency?: Maybe<Scalars['String']>
  recurrent?: Maybe<Scalars['Boolean']>
  reference?: Maybe<EventReference>
  type?: Maybe<Scalars['String']>
};

export type MeterReadingInfo = {
  __typename?: 'MeterReadingInfo'
  daysUntilNextReading?: Maybe<Scalars['Int']>
  meterReadingMonthDate?: Maybe<Scalars['String']>
  status?: Maybe<MeterReadingStatus>
};

export enum MeterReadingNotificationDayTime {
  Afternoon = 'afternoon',
  Evening = 'evening',
  Morning = 'morning'
}

export enum MeterReadingNotificationKey {
  MeterReadingDayTime = 'meter_reading_day_time',
  MeterReadingNotifications = 'meter_reading_notifications',
  MeterReadingPeriod = 'meter_reading_period'
}

export enum MeterReadingNotificationPeriod {
  AsSoonAsPossible = 'as_soon_as_possible',
  EndOfPeriod = 'end_of_period',
  Weekend = 'weekend'
}

export enum MeterReadingNotificationToggle {
  Off = 'off',
  On = 'on'
}

export enum MeterReadingStatus {
  Allowed = 'Allowed',
  Editable = 'Editable',
  NotAllowed = 'NotAllowed',
  NotNecessary = 'NotNecessary',
  OutOfPeriod = 'OutOfPeriod',
  Submitted = 'Submitted'
}

export type Mutation = {
  __typename?: 'Mutation'
  AcceptInstallmentsPlan_v1?: Maybe<InstallmentsPlan>
  AssignBenefit_v1?: Maybe<GeneralOperationResult>
  AssignCmor_v1?: Maybe<GeneralOperationResult>
  AssignPromoCode_v1?: Maybe<GeneralOperationResult>
  AttachLabelToContract_v1?: Maybe<Contract>
  CreateBalance_v1?: Maybe<Balance>
  CreateCategory_v1?: Maybe<Category>
  CreateConfiguration_v1?: Maybe<Configuration>
  CreateContractTransfer_v1?: Maybe<Contract>
  CreateCustomer_v1?: Maybe<Customer>
  CreateCustomer_v2?: Maybe<Customer>
  CreateDiscount_v1?: Maybe<Discount>
  CreateEnergyContract_v9?: Maybe<Contract>
  CreateEvent_v1?: Maybe<Event>
  CreateFile_v1?: Maybe<File>
  CreateFirebaseCustomToken_v1?: Maybe<Scalars['String']>
  CreateGasContract_v9?: Maybe<Contract>
  CreateInstallmentsPlan_v1?: Maybe<InstallmentsPlan>
  CreateLabel_v1?: Maybe<Label>
  CreateNote_v1?: Maybe<Note>
  CreateOrUpdateCadastralData_v1?: Maybe<Contract>
  CreateOrUpdatePrivacy_v1?: Maybe<Array<Maybe<Privacy>>>
  CreateOrUpdatePushToken_v1?: Maybe<PushToken>
  CreateOtp_v1?: Maybe<GeneralOperationResult>
  CreateOtp_v2?: Maybe<GeneralOperationResult>
  CreatePaymentIntent_v1?: Maybe<Scalars['String']>
  CreatePaymentMethod_v1?: Maybe<PaymentMethod>
  CreatePromoCode_v1?: Maybe<PromoCode>
  CreateSelfServiceMeterReading_v1?: Maybe<SelfService>
  CreateSelfServiceMeterReading_v2?: Maybe<SelfService>
  CreateSelfService_v1?: Maybe<SelfService>
  CreateTask_v1?: Maybe<Task>
  CreateTemplate_v1?: Maybe<Template>
  CreateUserFeedback_v1?: Maybe<UserFeedback>
  CreateUser_v1?: Maybe<User>
  DeclineInstallmentsPlan_v1?: Maybe<InstallmentsPlan>
  DeleteBalance_v1?: Maybe<Balance>
  DeleteCategory_v1?: Maybe<Category>
  DeleteContract_v2?: Maybe<Contract>
  DeleteCustomer_v1?: Maybe<Scalars['String']>
  DeleteEvent_v1?: Maybe<GeneralOperationResult>
  DeleteLabel_v1?: Maybe<Label>
  DeleteNote_v1?: Maybe<Note>
  DeletePaymentMethod_v1?: Maybe<Scalars['String']>
  DeletePaymentMethod_v2?: Maybe<Scalars['String']>
  DeleteSelfService_v1?: Maybe<Scalars['String']>
  DeleteTariffChange_v1?: Maybe<SelfService>
  DeleteTemplate_v1?: Maybe<Template>
  DeleteTransferCode_v1?: Maybe<ContractTransfer>
  DeleteUserContractSession_v1?: Maybe<UserContractSession>
  DetachLabelFromContract_v1?: Maybe<Label>
  DisableUser_v1?: Maybe<User>
  EndService_v1?: Maybe<GeneralOperationResult>
  FindOrCreateTransferCode_v1?: Maybe<Scalars['String']>
  ForceDeleteUser_v1?: Maybe<Scalars['String']>
  ForceOtp_v1?: Maybe<Contract>
  ImpersonateRequest_v1?: Maybe<Scalars['JSON']>
  LinkPartnerAccount_v1?: Maybe<GeneralOperationResult>
  PayEntityNow_v1?: Maybe<PayEntityNowResponse>
  PayEntityNow_v2?: Maybe<PayEntityNowResponse>
  PayInvoiceNow_v3?: Maybe<Scalars['String']>
  RegisterManualPaymentForInvoice_v1?: Maybe<Invoice>
  RemoveCustomerWhiteList_v1?: Maybe<Customer>
  RequestFileUpload_v1?: Maybe<GeneralOperationResult>
  ResetPhoneNumber_v1?: Maybe<User>
  RetryInvoicePayment?: Maybe<Invoice>
  RetryInvoicePayment_v1?: Maybe<GeneralOperationResult>
  SaveUserContractSession_v1?: Maybe<UserContractSession>
  Search_v1?: Maybe<GeneralOperationResult>
  StripeCreateSetupIntent_v1?: Maybe<Scalars['JSON']>
  TokenInvoicePayment?: Maybe<Invoice>
  UnlinkPartnerAccount_v1?: Maybe<GeneralOperationResult>
  UpdateCategory_v1?: Maybe<Category>
  UpdateConfiguration_v1?: Maybe<Configuration>
  UpdateContractAttachPromoCode_v1?: Maybe<Contract>
  UpdateContractName_v1?: Maybe<Contract>
  UpdateContractSubStatusStep1Ko_v1?: Maybe<Contract>
  UpdateContractTariff_v1?: Maybe<SelfService>
  UpdateCustomerRisk_v1?: Maybe<Customer>
  UpdateCustomer_v1?: Maybe<Customer>
  UpdateCustomer_v2?: Maybe<Customer>
  UpdateEvent_v1?: Maybe<GeneralOperationResult>
  UpdateInstallment_v1?: Maybe<Installment>
  UpdateInstallmentsPlanNotRespected_v1?: Maybe<InstallmentsPlan>
  UpdateInvoiceStatusNotPayed_v1?: Maybe<Invoice>
  UpdateInvoiceStatusOpen_v1?: Maybe<Invoice>
  UpdateInvoiceUserActionRequired_v1?: Maybe<Invoice>
  UpdateLabel_v1?: Maybe<Label>
  UpdateMeterReadingSettingsNotification_v1?: Maybe<ContractMeterReadingsNotificationSettings>
  UpdateNote_v1?: Maybe<Note>
  UpdatePaymentMethodContract_v1?: Maybe<Contract>
  UpdatePaymentMethodName_v1?: Maybe<PaymentMethod>
  UpdatePrice_v1?: Maybe<Price>
  UpdatePromoCodeToggleBan_v1?: Maybe<PromoCode>
  UpdatePromoCode_v1?: Maybe<PromoCode>
  UpdatePromoCode_v3?: Maybe<UpdatePromoCodeResponse>
  UpdatePunoPricesNotificationSettings_v1?: Maybe<Attribute>
  UpdateSubStatusStep3_v1?: Maybe<SubStatus>
  UpdateTemplate_v1?: Maybe<Template>
  UpdateUserChangeEmail_v1?: Maybe<User>
  UpdateUserInfo_v1?: Maybe<User>
  ValidateOtp_v1?: Maybe<Contract>
  ValidateOtp_v2?: Maybe<GeneralOperationResult>
};

export type MutationAcceptInstallmentsPlan_V1Args = {
  InstallmentsPlanId: Scalars['ID']
};

export type MutationAssignBenefit_V1Args = {
  ContractId: Scalars['ID']
  PromoCode: Scalars['String']
};

export type MutationAssignCmor_V1Args = {
  InvoiceId: Scalars['ID']
};

export type MutationAssignPromoCode_V1Args = {
  ContractId: Scalars['ID']
  promoCode: Scalars['String']
};

export type MutationAttachLabelToContract_V1Args = {
  contractId: Scalars['ID']
  labelId: Scalars['ID']
};

export type MutationCreateBalance_V1Args = {
  amount: Scalars['Float']
  description: Scalars['String']
  invoiceId: Scalars['ID']
};

export type MutationCreateCategory_V1Args = {
  generatedFrom: CategoryGeneratedFrom
  name: Scalars['String']
  styleConfiguration: StyleConfigurationInput
};

export type MutationCreateConfiguration_V1Args = {
  input: Scalars['JSON']
};

export type MutationCreateContractTransfer_V1Args = {
  Attributes: Array<Maybe<AttributeInput>>
  CustomerId: Scalars['ID']
  PaymentMethodId: Scalars['ID']
  contractCodeName: Scalars['String']
  contractRef: Scalars['String']
  contractType: Scalars['String']
  possessionOfProperty: Scalars['String']
  requestDate: Scalars['String']
};

export type MutationCreateCustomer_V1Args = {
  FileIds?: Maybe<Array<Maybe<Scalars['ID']>>>
  UserId?: Maybe<Scalars['ID']>
  billingContact: ContactInput
  customer?: Maybe<CustomerInput>
  ownerContact: ContactInput
};

export type MutationCreateCustomer_V2Args = {
  UserId?: Maybe<Scalars['ID']>
  billingContact: ContactInput
  customer: CustomerInput
  ownerContact: ContactInput
};

export type MutationCreateDiscount_V1Args = {
  amount: Scalars['Float']
  description?: Maybe<Scalars['String']>
  referenceId: Scalars['ID']
  referenceType: Scalars['String']
};

export type MutationCreateEnergyContract_V9Args = {
  Attributes: Array<Maybe<AttributeInput>>
  Contact?: Maybe<ContactInput>
  CustomerId: Scalars['ID']
  Item: ItemInput
  PaymentMethodId?: Maybe<Scalars['ID']>
  UserId?: Maybe<Scalars['ID']>
  activationType: Scalars['String']
  startDate?: Maybe<Scalars['String']>
  tariff: Scalars['String']
};

export type MutationCreateEvent_V1Args = {
  CategoryId?: Maybe<Scalars['ID']>
  CustomerId?: Maybe<Scalars['ID']>
  TemplateId?: Maybe<Scalars['ID']>
  amount: Scalars['Int']
  dirtyTemplate?: Maybe<Scalars['Boolean']>
  name: Scalars['String']
  recurrency?: Maybe<EventRecurrency>
  startDate: Scalars['String']
};

export type MutationCreateFile_V1Args = {
  UserId?: Maybe<Scalars['ID']>
  name: Scalars['String']
  s3_type: Scalars['String']
  type: Scalars['String']
};

export type MutationCreateGasContract_V9Args = {
  Attributes: Array<Maybe<AttributeInput>>
  Contact?: Maybe<ContactInput>
  CustomerId: Scalars['ID']
  Item: ItemInput
  PaymentMethodId?: Maybe<Scalars['ID']>
  UserId?: Maybe<Scalars['ID']>
  activationType: Scalars['String']
  startDate?: Maybe<Scalars['String']>
  tariff: Scalars['String']
};

export type MutationCreateInstallmentsPlan_V1Args = {
  Installments: Array<Maybe<InstallmentConfiguration>>
  InvoiceId: Scalars['ID']
  administrativeCost: Scalars['Float']
  previouslyConfirmed: Scalars['Boolean']
};

export type MutationCreateLabel_V1Args = {
  color: Scalars['String']
  name: Scalars['String']
};

export type MutationCreateNote_V1Args = {
  target: Scalars['String']
  targetId: Scalars['ID']
  text: Scalars['String']
};

export type MutationCreateOrUpdateCadastralData_V1Args = {
  ContractId: Scalars['ID']
  data: Scalars['JSON']
  possessionOfTheProperty: Scalars['String']
};

export type MutationCreateOrUpdatePrivacy_V1Args = {
  UserId?: Maybe<Scalars['ID']>
  privacies: Array<Maybe<PrivacyInput>>
};

export type MutationCreateOrUpdatePushToken_V1Args = {
  token: Scalars['String']
};

export type MutationCreateOtp_V1Args = {
  ContractId: Scalars['ID']
};

export type MutationCreateOtp_V2Args = {
  entityId: Scalars['ID']
  entityName: OtpSourceEntityName
};

export type MutationCreatePaymentIntent_V1Args = {
  invoiceId: Scalars['ID']
};

export type MutationCreatePaymentMethod_V1Args = {
  UserId?: Maybe<Scalars['ID']>
  paymentMethod: PaymentMethodInput
};

export type MutationCreatePromoCode_V1Args = {
  allowedContractType?: Maybe<Scalars['String']>
  amount: Scalars['Int']
  code: Scalars['String']
  description?: Maybe<Scalars['String']>
  expireDate?: Maybe<Scalars['String']>
  maxUses: Scalars['Int']
  maxUsesPerUser: Scalars['Int']
  partner: Scalars['String']
  purpose: Scalars['String']
  split: Scalars['Int']
};

export type MutationCreateSelfServiceMeterReading_V1Args = {
  ItemId: Scalars['ID']
  value: Scalars['Float']
};

export type MutationCreateSelfServiceMeterReading_V2Args = {
  ItemId: Scalars['ID']
  contractType: Scalars['String']
  energyValue?: Maybe<Scalars['JSON']>
  gasValue?: Maybe<Scalars['Float']>
};

export type MutationCreateSelfService_V1Args = {
  UserId?: Maybe<Scalars['ID']>
  file?: Maybe<FileInput>
  selfService: SelfServiceInput
};

export type MutationCreateTask_V1Args = {
  params: Scalars['JSON']
  routine: Scalars['String']
};

export type MutationCreateTemplate_V1Args = {
  CategoryId?: Maybe<Scalars['ID']>
  amount?: Maybe<Scalars['Int']>
  name: Scalars['String']
  recurrency?: Maybe<EventRecurrency>
  styleConfiguration: StyleConfigurationInput
};

export type MutationCreateUserFeedback_V1Args = {
  comment?: Maybe<Scalars['String']>
  stars: Scalars['Int']
  topics?: Maybe<Array<Maybe<Scalars['String']>>>
};

export type MutationCreateUser_V1Args = {
  email: Scalars['String']
};

export type MutationDeclineInstallmentsPlan_V1Args = {
  InstallmentsPlanId: Scalars['ID']
};

export type MutationDeleteBalance_V1Args = {
  balanceId: Scalars['ID']
};

export type MutationDeleteCategory_V1Args = {
  id: Scalars['ID']
};

export type MutationDeleteContract_V2Args = {
  id: Scalars['ID']
};

export type MutationDeleteCustomer_V1Args = {
  id: Scalars['ID']
};

export type MutationDeleteEvent_V1Args = {
  id: Scalars['ID']
  reconciliationStrategy: ReconciliationStrategy
};

export type MutationDeleteLabel_V1Args = {
  id: Scalars['ID']
};

export type MutationDeleteNote_V1Args = {
  id: Scalars['ID']
};

export type MutationDeletePaymentMethod_V1Args = {
  id: Scalars['ID']
};

export type MutationDeletePaymentMethod_V2Args = {
  id: Scalars['ID']
};

export type MutationDeleteSelfService_V1Args = {
  id: Scalars['ID']
};

export type MutationDeleteTariffChange_V1Args = {
  SelfServiceId: Scalars['ID']
};

export type MutationDeleteTemplate_V1Args = {
  id: Scalars['ID']
};

export type MutationDeleteTransferCode_V1Args = {
  ContractId: Scalars['ID']
};

export type MutationDetachLabelFromContract_V1Args = {
  contractId: Scalars['ID']
  labelId: Scalars['ID']
};

export type MutationDisableUser_V1Args = {
  id: Scalars['ID']
};

export type MutationEndService_V1Args = {
  id: Scalars['ID']
};

export type MutationFindOrCreateTransferCode_V1Args = {
  ContractId: Scalars['ID']
};

export type MutationForceDeleteUser_V1Args = {
  id?: Maybe<Scalars['ID']>
};

export type MutationForceOtp_V1Args = {
  ContractId: Scalars['ID']
};

export type MutationImpersonateRequest_V1Args = {
  id: Scalars['ID']
};

export type MutationLinkPartnerAccount_V1Args = {
  data: Scalars['JSON']
  partner: LinkedPartner
};

export type MutationPayEntityNow_V1Args = {
  entityRef: Scalars['ID']
  entityType: PayEntityNowEntityType
  intentType: PayEntityNowIntentType
  paymentMethodFormat: PaymentMethodFormat
  paymentMethodRef: Scalars['ID']
};

export type MutationPayEntityNow_V2Args = {
  invoiceId: Scalars['ID']
  paymentMethodFormat: PaymentMethodFormat
  paymentMethodRef: Scalars['ID']
};

export type MutationPayInvoiceNow_V3Args = {
  invoiceId: Scalars['ID']
  nativePaymentMethodId?: Maybe<Scalars['String']>
  paymentMethodId?: Maybe<Scalars['ID']>
};

export type MutationRegisterManualPaymentForInvoice_V1Args = {
  amount: Scalars['Float']
  createBalance: Scalars['Boolean']
  description?: Maybe<Scalars['String']>
  invoiceId: Scalars['ID']
  paymentDate: Scalars['String']
  shouldCloseInvoice: Scalars['Boolean']
};

export type MutationRemoveCustomerWhiteList_V1Args = {
  CustomerRiskHistoryId: Scalars['ID']
  reason: Scalars['String']
};

export type MutationRequestFileUpload_V1Args = {
  ext: Scalars['String']
};

export type MutationResetPhoneNumber_V1Args = {
  userId: Scalars['ID']
};

export type MutationRetryInvoicePaymentArgs = {
  invoiceId: Scalars['ID']
};

export type MutationRetryInvoicePayment_V1Args = {
  invoiceId: Scalars['ID']
  paymentMethodId?: Maybe<Scalars['ID']>
};

export type MutationSaveUserContractSession_V1Args = {
  contractType: ContractType
  payload: Scalars['JSON']
  tariffCodename: Scalars['String']
};

export type MutationSearch_V1Args = {
  extended?: Maybe<Scalars['Boolean']>
  query: Scalars['String']
};

export type MutationStripeCreateSetupIntent_V1Args = {
  paymentMethodId?: Maybe<Scalars['ID']>
};

export type MutationTokenInvoicePaymentArgs = {
  invoiceId: Scalars['ID']
  stripeToken: Scalars['String']
};

export type MutationUnlinkPartnerAccount_V1Args = {
  partner: LinkedPartner
};

export type MutationUpdateCategory_V1Args = {
  id: Scalars['ID']
  name?: Maybe<Scalars['String']>
  status?: Maybe<CategoryStatus>
  styleConfiguration?: Maybe<StyleConfigurationInput>
};

export type MutationUpdateConfiguration_V1Args = {
  id: Scalars['ID']
  input: Scalars['JSON']
};

export type MutationUpdateContractAttachPromoCode_V1Args = {
  code: Scalars['String']
  id: Scalars['ID']
};

export type MutationUpdateContractName_V1Args = {
  id: Scalars['ID']
  name: Scalars['String']
};

export type MutationUpdateContractSubStatusStep1Ko_V1Args = {
  ContractId: Scalars['ID']
  reason: Scalars['String']
};

export type MutationUpdateContractTariff_V1Args = {
  ContractId: Scalars['ID']
  earlyChange?: Maybe<Scalars['Boolean']>
  tariff: Scalars['String']
};

export type MutationUpdateCustomerRisk_V1Args = {
  CustomerId: Scalars['ID']
  endDate?: Maybe<Scalars['String']>
  reason?: Maybe<Scalars['String']>
  risk: Scalars['String']
};

export type MutationUpdateCustomer_V1Args = {
  UserId?: Maybe<Scalars['ID']>
  billingContact: ContactInput
  customer: CustomerInput
  ownerContact: ContactInput
};

export type MutationUpdateCustomer_V2Args = {
  billingContact?: Maybe<ContactInput>
  customer?: Maybe<CustomerInput>
  id: Scalars['ID']
  ownerContact?: Maybe<ContactInput>
};

export type MutationUpdateEvent_V1Args = {
  AccountId?: Maybe<Scalars['ID']>
  CategoryId?: Maybe<Scalars['ID']>
  TemplateId?: Maybe<Scalars['ID']>
  accountType?: Maybe<Scalars['String']>
  amount?: Maybe<Scalars['Int']>
  dirtyTemplate?: Maybe<Scalars['Boolean']>
  id: Scalars['ID']
  name?: Maybe<Scalars['String']>
  reconciliationStrategy: ReconciliationStrategy
};

export type MutationUpdateInstallment_V1Args = {
  InstallmentId: Scalars['ID']
  paymentAmount: Scalars['Float']
  paymentDate: Scalars['String']
};

export type MutationUpdateInstallmentsPlanNotRespected_V1Args = {
  InstallmentsPlanId: Scalars['ID']
};

export type MutationUpdateInvoiceStatusNotPayed_V1Args = {
  invoiceId: Scalars['ID']
};

export type MutationUpdateInvoiceStatusOpen_V1Args = {
  invoiceId: Scalars['ID']
};

export type MutationUpdateInvoiceUserActionRequired_V1Args = {
  invoiceId: Scalars['ID']
  userActionRequired: Scalars['Boolean']
};

export type MutationUpdateLabel_V1Args = {
  color: Scalars['String']
  enabled?: Maybe<Scalars['Boolean']>
  id: Scalars['ID']
  name: Scalars['String']
};

export type MutationUpdateMeterReadingSettingsNotification_V1Args = {
  ContractId: Scalars['ID']
  active: Scalars['Boolean']
  dayTime?: Maybe<MeterReadingNotificationDayTime>
  period?: Maybe<MeterReadingNotificationPeriod>
};

export type MutationUpdateNote_V1Args = {
  assigneeId?: Maybe<Scalars['ID']>
  done?: Maybe<Scalars['Boolean']>
  expireDate?: Maybe<Scalars['String']>
  noteId: Scalars['ID']
};

export type MutationUpdatePaymentMethodContract_V1Args = {
  ContractId: Scalars['ID']
  PaymentMethodId: Scalars['ID']
  UserId?: Maybe<Scalars['ID']>
};

export type MutationUpdatePaymentMethodName_V1Args = {
  id: Scalars['ID']
  name: Scalars['String']
};

export type MutationUpdatePrice_V1Args = {
  changeTariffEnabled: Scalars['String']
  changeTariffVisible: Scalars['String']
  description?: Maybe<Scalars['String']>
  id: Scalars['ID']
  name?: Maybe<Scalars['String']>
  newContractsEnabled: Scalars['String']
  newContractsVisible: Scalars['String']
  transferContractsEnabled: Scalars['String']
  transferContractsVisible: Scalars['String']
  unilateralDefaultTargetTariff?: Maybe<Scalars['String']>
  unilateralEmailInfo?: Maybe<Scalars['String']>
};

export type MutationUpdatePromoCodeToggleBan_V1Args = {
  id: Scalars['ID']
};

export type MutationUpdatePromoCode_V1Args = {
  description?: Maybe<Scalars['String']>
  expireDate?: Maybe<Scalars['String']>
  maxUses?: Maybe<Scalars['Int']>
  partner?: Maybe<Scalars['String']>
  promoCodeId: Scalars['ID']
};

export type MutationUpdatePromoCode_V3Args = {
  code?: Maybe<Scalars['String']>
  id: Scalars['ID']
  sections: Scalars['JSON']
  theme: Scalars['String']
};

export type MutationUpdatePunoPricesNotificationSettings_V1Args = {
  contractId: Scalars['ID']
  notificationActive: Scalars['Boolean']
};

export type MutationUpdateSubStatusStep3_V1Args = {
  contractId: Scalars['ID']
  startDate?: Maybe<Scalars['String']>
  subStatusName: Scalars['String']
};

export type MutationUpdateTemplate_V1Args = {
  CategoryId?: Maybe<Scalars['ID']>
  amount?: Maybe<Scalars['Int']>
  id: Scalars['ID']
  name?: Maybe<Scalars['String']>
  recurrency?: Maybe<EventRecurrency>
  status?: Maybe<TemplateStatus>
  styleConfiguration?: Maybe<StyleConfigurationInput>
};

export type MutationUpdateUserChangeEmail_V1Args = {
  email: Scalars['String']
  id?: Maybe<Scalars['ID']>
};

export type MutationUpdateUserInfo_V1Args = {
  UserId?: Maybe<Scalars['ID']>
  firstName?: Maybe<Scalars['String']>
  lastName?: Maybe<Scalars['String']>
  phone?: Maybe<Scalars['String']>
  tags?: Maybe<Array<Maybe<Scalars['String']>>>
};

export type MutationValidateOtp_V1Args = {
  ContractId: Scalars['ID']
  code: Scalars['String']
};

export type MutationValidateOtp_V2Args = {
  code: Scalars['String']
  entityId: Scalars['ID']
  entityName: OtpSourceEntityName
};

export type Namespace = {
  __typename?: 'Namespace'
  id?: Maybe<Scalars['ID']>
  name?: Maybe<Scalars['String']>
};

export type Note = {
  __typename?: 'Note'
  Assignee?: Maybe<User>
  AssigneeId?: Maybe<Scalars['Int']>
  User?: Maybe<User>
  UserId?: Maybe<Scalars['Int']>
  createdAt?: Maybe<Scalars['String']>
  date?: Maybe<Scalars['String']>
  done?: Maybe<Scalars['Boolean']>
  expireDate?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['ID']>
  target?: Maybe<NoteTarget>
  targetId?: Maybe<Scalars['Int']>
  targetName?: Maybe<Scalars['String']>
  text?: Maybe<Scalars['String']>
  updatedAt?: Maybe<Scalars['String']>
};

export type NoteInput = {
  Assignee?: Maybe<UserInput>
  AssigneeId?: Maybe<Scalars['Int']>
  User?: Maybe<UserInput>
  UserId?: Maybe<Scalars['Int']>
  createdAt?: Maybe<Scalars['String']>
  date?: Maybe<Scalars['String']>
  done?: Maybe<Scalars['Boolean']>
  expireDate?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['ID']>
  target?: Maybe<NoteTarget>
  targetId?: Maybe<Scalars['Int']>
  text?: Maybe<Scalars['String']>
  updatedAt?: Maybe<Scalars['String']>
};

export enum NoteTarget {
  Contract = 'contract',
  Invoice = 'invoice',
  User = 'user'
}

export enum NotesContractOwner {
  All = 'all',
  Mine = 'mine',
  NotMine = 'not_mine'
}

export type Notification = {
  __typename?: 'Notification'
  NotificationTrackings?: Maybe<Array<Maybe<NotificationTracking>>>
  Owner?: Maybe<User>
  OwnerId?: Maybe<Scalars['Int']>
  User?: Maybe<User>
  UserId?: Maybe<Scalars['Int']>
  clicksCount?: Maybe<Scalars['Int']>
  contentId?: Maybe<Scalars['String']>
  createdAt?: Maybe<Scalars['String']>
  date?: Maybe<Scalars['String']>
  externalRef?: Maybe<Scalars['String']>
  failureReason?: Maybe<Scalars['JSON']>
  id?: Maybe<Scalars['ID']>
  lastClick?: Maybe<Scalars['String']>
  lastOpen?: Maybe<Scalars['String']>
  message?: Maybe<Scalars['JSON']>
  meta?: Maybe<Scalars['JSON']>
  opensCount?: Maybe<Scalars['Int']>
  rawMessage?: Maybe<Scalars['String']>
  receiver?: Maybe<Scalars['String']>
  status?: Maybe<NotificationStatus>
  type?: Maybe<NotificationType>
  updatedAt?: Maybe<Scalars['String']>
};

export type NotificationNotificationTrackingsArgs = {
  id?: Maybe<Array<Maybe<Scalars['ID']>>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  sort?: Maybe<Array<Maybe<SortInput>>>
  where?: Maybe<Array<Maybe<WhereClause>>>
};

export type NotificationInput = {
  NotificationTrackings?: Maybe<Array<Maybe<NotificationTrackingInput>>>
  Owner?: Maybe<UserInput>
  User?: Maybe<UserInput>
  UserId?: Maybe<Scalars['Int']>
  clicksCount?: Maybe<Scalars['Int']>
  contentId?: Maybe<Scalars['String']>
  createdAt?: Maybe<Scalars['String']>
  date?: Maybe<Scalars['String']>
  externalRef?: Maybe<Scalars['String']>
  failureReason?: Maybe<Scalars['JSON']>
  id?: Maybe<Scalars['ID']>
  lastClick?: Maybe<Scalars['String']>
  lastOpen?: Maybe<Scalars['String']>
  meta?: Maybe<Scalars['JSON']>
  opensCount?: Maybe<Scalars['Int']>
  rawMessage?: Maybe<Scalars['String']>
  receiver?: Maybe<Scalars['String']>
  status?: Maybe<NotificationStatus>
  type?: Maybe<NotificationType>
  updatedAt?: Maybe<Scalars['String']>
};

export enum NotificationStatus {
  Delivered = 'delivered',
  DeliveryFailure = 'delivery_failure',
  Pending = 'pending'
}

export type NotificationTracking = {
  __typename?: 'NotificationTracking'
  Notification?: Maybe<Notification>
  NotificationId?: Maybe<Scalars['Int']>
  Owner?: Maybe<User>
  OwnerId?: Maybe<Scalars['Int']>
  createdAt?: Maybe<Scalars['String']>
  date?: Maybe<Scalars['String']>
  geo?: Maybe<Scalars['JSON']>
  id?: Maybe<Scalars['ID']>
  ip?: Maybe<Scalars['String']>
  trackingData?: Maybe<Scalars['JSON']>
  type?: Maybe<NotificationTrackingType>
  updatedAt?: Maybe<Scalars['String']>
  userAgent?: Maybe<Scalars['String']>
};

export type NotificationTrackingInput = {
  Notification?: Maybe<NotificationInput>
  NotificationId?: Maybe<Scalars['Int']>
  Owner?: Maybe<UserInput>
  createdAt?: Maybe<Scalars['String']>
  date?: Maybe<Scalars['String']>
  geo?: Maybe<Scalars['JSON']>
  id?: Maybe<Scalars['ID']>
  ip?: Maybe<Scalars['String']>
  trackingData?: Maybe<Scalars['JSON']>
  type?: Maybe<NotificationTrackingType>
  updatedAt?: Maybe<Scalars['String']>
  userAgent?: Maybe<Scalars['String']>
};

export enum NotificationTrackingType {
  Click = 'click',
  Open = 'open'
}

export enum NotificationType {
  Email = 'email',
  Push = 'push',
  RegisteredEmail = 'registered_email',
  Sms = 'sms'
}

export type OtpCode = {
  __typename?: 'OTPCode'
  Contract?: Maybe<Contract>
  ContractId?: Maybe<Scalars['Int']>
  Owner?: Maybe<User>
  OwnerId?: Maybe<Scalars['Int']>
  SelfService?: Maybe<SelfService>
  SelfServiceId?: Maybe<Scalars['Int']>
  User?: Maybe<User>
  UserId?: Maybe<Scalars['Int']>
  code?: Maybe<Scalars['String']>
  createdAt?: Maybe<Scalars['String']>
  expireAt?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['ID']>
  mediaIdentifier?: Maybe<Scalars['String']>
  type?: Maybe<OtpCodeType>
  updatedAt?: Maybe<Scalars['String']>
  verifiedAt?: Maybe<Scalars['String']>
};

export type OtpCodeInput = {
  Contract?: Maybe<ContractInput>
  ContractId?: Maybe<Scalars['Int']>
  Owner?: Maybe<UserInput>
  SelfService?: Maybe<SelfServiceInput>
  SelfServiceId?: Maybe<Scalars['Int']>
  User?: Maybe<UserInput>
  UserId?: Maybe<Scalars['Int']>
  code?: Maybe<Scalars['String']>
  createdAt?: Maybe<Scalars['String']>
  expireAt?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['ID']>
  mediaIdentifier?: Maybe<Scalars['String']>
  type?: Maybe<OtpCodeType>
  updatedAt?: Maybe<Scalars['String']>
  verifiedAt?: Maybe<Scalars['String']>
};

export enum OtpCodeType {
  Email = 'email',
  Sms = 'sms',
  System = 'system'
}

export enum OtpSourceEntityName {
  Contract = 'Contract',
  SelfService = 'SelfService'
}

export type Option = {
  __typename?: 'Option'
  ProductOptions?: Maybe<Array<Maybe<ProductOption>>>
  configuration?: Maybe<Scalars['JSON']>
  createdAt?: Maybe<Scalars['String']>
  description?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['ID']>
  mandatory?: Maybe<Scalars['Boolean']>
  name?: Maybe<Scalars['String']>
  namespace?: Maybe<Scalars['String']>
  type?: Maybe<OptionType>
  updatedAt?: Maybe<Scalars['String']>
};

export type OptionProductOptionsArgs = {
  id?: Maybe<Array<Maybe<Scalars['ID']>>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  sort?: Maybe<Array<Maybe<SortInput>>>
  where?: Maybe<Array<Maybe<WhereClause>>>
};

export type OptionInput = {
  ProductOptions?: Maybe<Array<Maybe<ProductOptionInput>>>
  configuration?: Maybe<Scalars['JSON']>
  createdAt?: Maybe<Scalars['String']>
  description?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['ID']>
  mandatory?: Maybe<Scalars['Boolean']>
  name?: Maybe<Scalars['String']>
  namespace?: Maybe<Scalars['String']>
  type?: Maybe<OptionType>
  updatedAt?: Maybe<Scalars['String']>
};

export enum OptionType {
  Boolean = 'boolean',
  Datetime = 'datetime',
  Enum = 'enum',
  Number = 'number',
  String = 'string'
}

export type OutstandingContracts = {
  __typename?: 'OutstandingContracts'
  contracts?: Maybe<Array<Maybe<Contract>>>
  totalPages?: Maybe<Scalars['Int']>
};

export type PageView = {
  __typename?: 'PageView'
  count?: Maybe<Scalars['Int']>
  createdAt?: Maybe<Scalars['String']>
  day?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['ID']>
  product?: Maybe<Scalars['String']>
  updatedAt?: Maybe<Scalars['String']>
};

export type PageViewInput = {
  count?: Maybe<Scalars['Int']>
  createdAt?: Maybe<Scalars['String']>
  day?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['ID']>
  product?: Maybe<Scalars['String']>
  updatedAt?: Maybe<Scalars['String']>
};

/** TODO:: Move to named mutation once done */
export enum PatternEnum {
  AsLateAsPossible = 'AsLateAsPossible',
  AsSoonAsPossible = 'AsSoonAsPossible',
  OnWeekend = 'OnWeekend'
}

/** Inputs */
export enum PayEntityNowEntityType {
  Installment = 'installment',
  Invoice = 'invoice'
}

export enum PayEntityNowIntentType {
  Pay = 'pay',
  Simulate = 'simulate'
}

/** Outputs */
export type PayEntityNowResponse = {
  __typename?: 'PayEntityNowResponse'
  amountToPay?: Maybe<Scalars['Float']>
  confirmationSecret?: Maybe<Scalars['String']>
  invoiceElements?: Maybe<Array<Maybe<InvoiceElements>>>
  status?: Maybe<PayEntityNowStatus>
};

export enum PayEntityNowStatus {
  Ready = 'ready',
  RequiresClientConfirmation = 'requires_client_confirmation',
  Success = 'success'
}

export enum PayNowEnabled {
  Allowed = 'Allowed',
  Disabled = 'Disabled',
  PreviouslyUnsuccessful = 'PreviouslyUnsuccessful'
}

export type PayNowInfoResponse = {
  __typename?: 'PayNowInfoResponse'
  amountToPay?: Maybe<Scalars['Float']>
  invoiceElements?: Maybe<Array<Maybe<InvoiceElements>>>
  status?: Maybe<PayEntityNowStatus>
};

export type PaymentAttempt = {
  __typename?: 'PaymentAttempt'
  Installment?: Maybe<Installment>
  InstallmentId?: Maybe<Scalars['String']>
  Invoice?: Maybe<Invoice>
  InvoiceId?: Maybe<Scalars['Int']>
  PaymentMethod?: Maybe<PaymentMethod>
  PaymentMethodId?: Maybe<Scalars['Int']>
  User?: Maybe<User>
  UserId?: Maybe<Scalars['Int']>
  createdAt?: Maybe<Scalars['String']>
  date?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['ID']>
  paymentIntentId?: Maybe<Scalars['String']>
  source?: Maybe<PaymentAttemptSource>
  updatedAt?: Maybe<Scalars['String']>
  wallet?: Maybe<PaymentAttemptWallet>
};

export type PaymentAttemptInput = {
  Installment?: Maybe<InstallmentInput>
  InstallmentId?: Maybe<Scalars['String']>
  Invoice?: Maybe<InvoiceInput>
  InvoiceId?: Maybe<Scalars['Int']>
  PaymentMethod?: Maybe<PaymentMethodInput>
  PaymentMethodId?: Maybe<Scalars['Int']>
  User?: Maybe<UserInput>
  UserId?: Maybe<Scalars['Int']>
  createdAt?: Maybe<Scalars['String']>
  date?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['ID']>
  paymentIntentId?: Maybe<Scalars['String']>
  source?: Maybe<PaymentAttemptSource>
  updatedAt?: Maybe<Scalars['String']>
  wallet?: Maybe<PaymentAttemptWallet>
};

export enum PaymentAttemptSource {
  System = 'system',
  User = 'user'
}

export enum PaymentAttemptWallet {
  ApplePay = 'apple_pay',
  GooglePay = 'google_pay'
}

export type PaymentMethod = {
  __typename?: 'PaymentMethod'
  Contracts?: Maybe<Array<Maybe<Contract>>>
  Owner?: Maybe<User>
  OwnerId?: Maybe<Scalars['Int']>
  PaymentAttempts?: Maybe<Array<Maybe<PaymentAttempt>>>
  User?: Maybe<User>
  UserId?: Maybe<Scalars['Int']>
  createdAt?: Maybe<Scalars['String']>
  declinedReason?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['ID']>
  name?: Maybe<Scalars['String']>
  primary?: Maybe<Scalars['String']>
  ref?: Maybe<Scalars['String']>
  retrieveStripeData?: Maybe<Scalars['JSON']>
  status?: Maybe<PaymentMethodStatus>
  stripeId?: Maybe<Scalars['String']>
  stripeType?: Maybe<Scalars['String']>
  stripe_source_id?: Maybe<Scalars['String']>
  target?: Maybe<PaymentMethodTarget>
  typeReadable?: Maybe<Scalars['String']>
  updatedAt?: Maybe<Scalars['String']>
  userActionRequired?: Maybe<Scalars['Boolean']>
};

export type PaymentMethodContractsArgs = {
  id?: Maybe<Array<Maybe<Scalars['ID']>>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  sort?: Maybe<Array<Maybe<SortInput>>>
  where?: Maybe<Array<Maybe<WhereClause>>>
};

export type PaymentMethodPaymentAttemptsArgs = {
  id?: Maybe<Array<Maybe<Scalars['ID']>>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  sort?: Maybe<Array<Maybe<SortInput>>>
  where?: Maybe<Array<Maybe<WhereClause>>>
};

export enum PaymentMethodFormat {
  Id = 'id',
  Token = 'token'
}

export type PaymentMethodInput = {
  Contracts?: Maybe<Array<Maybe<ContractInput>>>
  Owner?: Maybe<UserInput>
  PaymentAttempts?: Maybe<Array<Maybe<PaymentAttemptInput>>>
  User?: Maybe<UserInput>
  UserId?: Maybe<Scalars['Int']>
  country?: Maybe<Scalars['String']>
  createdAt?: Maybe<Scalars['String']>
  declinedReason?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['ID']>
  name?: Maybe<Scalars['String']>
  ownerName?: Maybe<Scalars['String']>
  ref?: Maybe<Scalars['String']>
  status?: Maybe<PaymentMethodStatus>
  stripeId?: Maybe<Scalars['String']>
  stripe_source_id?: Maybe<Scalars['String']>
  target?: Maybe<PaymentMethodTarget>
  updatedAt?: Maybe<Scalars['String']>
  userActionRequired?: Maybe<Scalars['Boolean']>
};

export enum PaymentMethodStatus {
  Disabled = 'disabled',
  Enabled = 'enabled'
}

export enum PaymentMethodTarget {
  CreditCard = 'credit_card',
  Sepa = 'sepa'
}

export type Payout = {
  __typename?: 'Payout'
  amount?: Maybe<Scalars['Float']>
  arrivaldate?: Maybe<Scalars['String']>
  createdAt?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['ID']>
  report?: Maybe<Scalars['String']>
  reportUrl?: Maybe<Scalars['String']>
  stripeId?: Maybe<Scalars['String']>
  updatedAt?: Maybe<Scalars['String']>
};

export type PayoutInput = {
  amount?: Maybe<Scalars['Float']>
  arrivaldate?: Maybe<Scalars['String']>
  createdAt?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['ID']>
  report?: Maybe<Scalars['String']>
  reportUrl?: Maybe<Scalars['String']>
  stripeId?: Maybe<Scalars['String']>
  updatedAt?: Maybe<Scalars['String']>
};

export type PhoneLead = {
  __typename?: 'PhoneLead'
  Attributes?: Maybe<Array<Maybe<Attribute>>>
  createdAt?: Maybe<Scalars['String']>
  date?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['ID']>
  ip?: Maybe<Scalars['String']>
  phone?: Maybe<Scalars['String']>
  ua?: Maybe<Scalars['String']>
  updatedAt?: Maybe<Scalars['String']>
};

export type PhoneLeadAttributesArgs = {
  id?: Maybe<Array<Maybe<Scalars['ID']>>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  sort?: Maybe<Array<Maybe<SortInput>>>
  where?: Maybe<Array<Maybe<WhereClause>>>
};

export type PhoneLeadInput = {
  Attributes?: Maybe<Array<Maybe<AttributeInput>>>
  createdAt?: Maybe<Scalars['String']>
  date?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['ID']>
  ip?: Maybe<Scalars['String']>
  phone?: Maybe<Scalars['String']>
  ua?: Maybe<Scalars['String']>
  updatedAt?: Maybe<Scalars['String']>
};

export type Post = {
  __typename?: 'Post'
  category?: Maybe<PostCategory>
  content?: Maybe<Scalars['String']>
  createdAt?: Maybe<Scalars['String']>
  guid?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['ID']>
  imageHeight?: Maybe<Scalars['Int']>
  imageUrl?: Maybe<Scalars['String']>
  imageWidth?: Maybe<Scalars['Int']>
  pinned?: Maybe<Scalars['Boolean']>
  publishDate?: Maybe<Scalars['String']>
  subtitle?: Maybe<Scalars['String']>
  title?: Maybe<Scalars['String']>
  updatedAt?: Maybe<Scalars['String']>
  url?: Maybe<Scalars['String']>
};

export enum PostCategory {
  Energy = 'energy',
  Innovation = 'innovation',
  Sustainability = 'sustainability',
  Tate = 'tate',
  Trending = 'trending'
}

export type PostInput = {
  category?: Maybe<PostCategory>
  content?: Maybe<Scalars['String']>
  createdAt?: Maybe<Scalars['String']>
  guid?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['ID']>
  imageHeight?: Maybe<Scalars['Int']>
  imageUrl?: Maybe<Scalars['String']>
  imageWidth?: Maybe<Scalars['Int']>
  pinned?: Maybe<Scalars['Boolean']>
  publishDate?: Maybe<Scalars['String']>
  subtitle?: Maybe<Scalars['String']>
  title?: Maybe<Scalars['String']>
  updatedAt?: Maybe<Scalars['String']>
  url?: Maybe<Scalars['String']>
};

export type Price = {
  __typename?: 'Price'
  PriceInfos?: Maybe<Array<Maybe<PriceInfo>>>
  charges?: Maybe<Scalars['JSON']>
  codename?: Maybe<Scalars['String']>
  createdAt?: Maybe<Scalars['String']>
  endDate?: Maybe<Scalars['String']>
  enerpRef?: Maybe<Scalars['String']>
  formatTariff?: Maybe<TariffInfo>
  getName?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['ID']>
  name?: Maybe<Scalars['String']>
  namespace?: Maybe<Scalars['String']>
  offerCode?: Maybe<Scalars['String']>
  priceBands?: Maybe<Scalars['JSON']>
  priceBandsType?: Maybe<PricePriceBandsType>
  pricingStrategy?: Maybe<PricePricingStrategy>
  startDate?: Maybe<Scalars['String']>
  type?: Maybe<PriceType>
  updatedAt?: Maybe<Scalars['String']>
};

export type PricePriceInfosArgs = {
  id?: Maybe<Array<Maybe<Scalars['ID']>>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  sort?: Maybe<Array<Maybe<SortInput>>>
  where?: Maybe<Array<Maybe<WhereClause>>>
};

export type PriceInfo = {
  __typename?: 'PriceInfo'
  Price?: Maybe<Price>
  PriceId?: Maybe<Scalars['String']>
  createdAt?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['ID']>
  key?: Maybe<PriceInfoKey>
  updatedAt?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['String']>
};

export type PriceInfoInput = {
  Price?: Maybe<PriceInput>
  PriceId?: Maybe<Scalars['String']>
  createdAt?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['ID']>
  key?: Maybe<PriceInfoKey>
  updatedAt?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['String']>
};

export enum PriceInfoKey {
  ChangeTariffEnabled = 'change_tariff_enabled',
  ChangeTariffVisible = 'change_tariff_visible',
  Description = 'description',
  Name = 'name',
  NewContractEnabled = 'new_contract_enabled',
  NewContractVisible = 'new_contract_visible',
  TransferContractEnabled = 'transfer_contract_enabled',
  TransferContractVisible = 'transfer_contract_visible',
  UnilateralDefaultTargetTariff = 'unilateral_default_target_tariff',
  UnilateralEmailInfo = 'unilateral_email_info'
}

export type PriceInput = {
  PriceInfos?: Maybe<Array<Maybe<PriceInfoInput>>>
  charges?: Maybe<Scalars['JSON']>
  codename?: Maybe<Scalars['String']>
  createdAt?: Maybe<Scalars['String']>
  endDate?: Maybe<Scalars['String']>
  enerpRef?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['ID']>
  name?: Maybe<Scalars['String']>
  namespace?: Maybe<Scalars['String']>
  offerCode?: Maybe<Scalars['String']>
  priceBands?: Maybe<Scalars['JSON']>
  priceBandsType?: Maybe<PricePriceBandsType>
  pricingStrategy?: Maybe<PricePricingStrategy>
  startDate?: Maybe<Scalars['String']>
  type?: Maybe<PriceType>
  updatedAt?: Maybe<Scalars['String']>
};

export enum PricePriceBandsType {
  Mono = 'mono',
  Multi = 'multi'
}

export enum PricePricingStrategy {
  Fixed = 'fixed',
  HourlyPun = 'hourly_pun',
  Variable = 'variable',
  VariableSpread = 'variable_spread'
}

export enum PriceType {
  Energy = 'energy',
  Gas = 'gas'
}

export enum PricesDataStatus {
  MissingData = 'missing_data',
  Ok = 'ok',
  PendingData = 'pending_data'
}

export type Privacy = {
  __typename?: 'Privacy'
  Owner?: Maybe<User>
  OwnerId?: Maybe<Scalars['Int']>
  User?: Maybe<User>
  UserId?: Maybe<Scalars['Int']>
  accepted?: Maybe<Scalars['Boolean']>
  acceptedDate?: Maybe<Scalars['String']>
  createdAt?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['ID']>
  policy?: Maybe<PrivacyPolicy>
  updatedAt?: Maybe<Scalars['String']>
};

export type PrivacyInput = {
  Owner?: Maybe<UserInput>
  User?: Maybe<UserInput>
  UserId?: Maybe<Scalars['Int']>
  accepted?: Maybe<Scalars['Boolean']>
  acceptedDate?: Maybe<Scalars['String']>
  createdAt?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['ID']>
  policy?: Maybe<PrivacyPolicy>
  updatedAt?: Maybe<Scalars['String']>
};

export enum PrivacyPolicy {
  DataProtection = 'data_protection',
  Marketing = 'marketing',
  MarketingMobility = 'marketing_mobility',
  Profiling = 'profiling',
  PushNotifications = 'push_notifications',
  PushNotificationsMobility = 'push_notifications_mobility'
}

export type Product = {
  __typename?: 'Product'
  ProductCategory?: Maybe<ProductCategory>
  ProductCategoryId?: Maybe<Scalars['String']>
  ProductOptions?: Maybe<Array<Maybe<ProductOption>>>
  ProductVariants?: Maybe<Array<Maybe<ProductVariant>>>
  ProductVersions?: Maybe<Array<Maybe<ProductVersion>>>
  User?: Maybe<User>
  UserId?: Maybe<Scalars['Int']>
  createdAt?: Maybe<Scalars['String']>
  decimalPrice?: Maybe<Scalars['String']>
  decimalTax?: Maybe<Scalars['String']>
  decimalTotal?: Maybe<Scalars['String']>
  description?: Maybe<Scalars['String']>
  enabled?: Maybe<Scalars['Boolean']>
  id?: Maybe<Scalars['ID']>
  name?: Maybe<Scalars['String']>
  price?: Maybe<Scalars['Int']>
  sku?: Maybe<Scalars['String']>
  tax?: Maybe<Scalars['Int']>
  total?: Maybe<Scalars['String']>
  updatedAt?: Maybe<Scalars['String']>
};

export type ProductProductOptionsArgs = {
  id?: Maybe<Array<Maybe<Scalars['ID']>>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  sort?: Maybe<Array<Maybe<SortInput>>>
  where?: Maybe<Array<Maybe<WhereClause>>>
};

export type ProductProductVariantsArgs = {
  id?: Maybe<Array<Maybe<Scalars['ID']>>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  sort?: Maybe<Array<Maybe<SortInput>>>
  where?: Maybe<Array<Maybe<WhereClause>>>
};

export type ProductProductVersionsArgs = {
  id?: Maybe<Array<Maybe<Scalars['ID']>>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  sort?: Maybe<Array<Maybe<SortInput>>>
  where?: Maybe<Array<Maybe<WhereClause>>>
};

export type ProductCategory = {
  __typename?: 'ProductCategory'
  ProductVersions?: Maybe<Array<Maybe<ProductVersion>>>
  Products?: Maybe<Array<Maybe<Product>>>
  createdAt?: Maybe<Scalars['String']>
  description?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['ID']>
  name?: Maybe<Scalars['String']>
  namespace?: Maybe<Scalars['String']>
  updatedAt?: Maybe<Scalars['String']>
};

export type ProductCategoryProductVersionsArgs = {
  id?: Maybe<Array<Maybe<Scalars['ID']>>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  sort?: Maybe<Array<Maybe<SortInput>>>
  where?: Maybe<Array<Maybe<WhereClause>>>
};

export type ProductCategoryProductsArgs = {
  id?: Maybe<Array<Maybe<Scalars['ID']>>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  sort?: Maybe<Array<Maybe<SortInput>>>
  where?: Maybe<Array<Maybe<WhereClause>>>
};

export type ProductCategoryInput = {
  ProductVersions?: Maybe<Array<Maybe<ProductVersionInput>>>
  Products?: Maybe<Array<Maybe<ProductInput>>>
  createdAt?: Maybe<Scalars['String']>
  description?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['ID']>
  name?: Maybe<Scalars['String']>
  namespace?: Maybe<Scalars['String']>
  updatedAt?: Maybe<Scalars['String']>
};

export type ProductInput = {
  ProductCategory?: Maybe<ProductCategoryInput>
  ProductCategoryId?: Maybe<Scalars['String']>
  ProductOptions?: Maybe<Array<Maybe<ProductOptionInput>>>
  ProductVariants?: Maybe<Array<Maybe<ProductVariantInput>>>
  ProductVersions?: Maybe<Array<Maybe<ProductVersionInput>>>
  User?: Maybe<UserInput>
  UserId?: Maybe<Scalars['Int']>
  createdAt?: Maybe<Scalars['String']>
  decimalPrice?: Maybe<Scalars['String']>
  decimalTax?: Maybe<Scalars['String']>
  decimalTotal?: Maybe<Scalars['String']>
  description?: Maybe<Scalars['String']>
  enabled?: Maybe<Scalars['Boolean']>
  id?: Maybe<Scalars['ID']>
  name?: Maybe<Scalars['String']>
  price?: Maybe<Scalars['Int']>
  sku?: Maybe<Scalars['String']>
  tax?: Maybe<Scalars['Int']>
  total?: Maybe<Scalars['String']>
  updatedAt?: Maybe<Scalars['String']>
};

export type ProductOption = {
  __typename?: 'ProductOption'
  Option?: Maybe<Option>
  OptionId?: Maybe<Scalars['String']>
  Product?: Maybe<Product>
  ProductId?: Maybe<Scalars['String']>
  ProductOptionId?: Maybe<Scalars['String']>
  createdAt?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['ID']>
  option_id?: Maybe<Scalars['String']>
  product_id?: Maybe<Scalars['String']>
  updatedAt?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['String']>
};

export type ProductOptionInput = {
  Option?: Maybe<OptionInput>
  OptionId?: Maybe<Scalars['String']>
  Product?: Maybe<ProductInput>
  ProductId?: Maybe<Scalars['String']>
  ProductOptionId?: Maybe<Scalars['String']>
  createdAt?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['ID']>
  option_id?: Maybe<Scalars['String']>
  product_id?: Maybe<Scalars['String']>
  updatedAt?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['String']>
};

export type ProductVariant = {
  __typename?: 'ProductVariant'
  Product?: Maybe<Product>
  ProductId?: Maybe<Scalars['String']>
  ProductVersion?: Maybe<ProductVersion>
  ProductVersionId?: Maybe<Scalars['String']>
  User?: Maybe<User>
  UserId?: Maybe<Scalars['Int']>
  createdAt?: Maybe<Scalars['String']>
  description?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['ID']>
  name?: Maybe<Scalars['String']>
  price?: Maybe<Scalars['Int']>
  tax?: Maybe<Scalars['Int']>
  updatedAt?: Maybe<Scalars['String']>
  validFrom?: Maybe<Scalars['String']>
  validTo?: Maybe<Scalars['String']>
};

export type ProductVariantInput = {
  Product?: Maybe<ProductInput>
  ProductId?: Maybe<Scalars['String']>
  ProductVersion?: Maybe<ProductVersionInput>
  ProductVersionId?: Maybe<Scalars['String']>
  User?: Maybe<UserInput>
  UserId?: Maybe<Scalars['Int']>
  createdAt?: Maybe<Scalars['String']>
  description?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['ID']>
  name?: Maybe<Scalars['String']>
  price?: Maybe<Scalars['Int']>
  tax?: Maybe<Scalars['Int']>
  updatedAt?: Maybe<Scalars['String']>
  validFrom?: Maybe<Scalars['String']>
  validTo?: Maybe<Scalars['String']>
};

export type ProductVersion = {
  __typename?: 'ProductVersion'
  Product?: Maybe<Product>
  ProductCategory?: Maybe<ProductCategory>
  ProductCategoryId?: Maybe<Scalars['String']>
  ProductId?: Maybe<Scalars['String']>
  ProductVariants?: Maybe<Array<Maybe<ProductVariant>>>
  User?: Maybe<User>
  UserId?: Maybe<Scalars['Int']>
  attributes?: Maybe<Scalars['JSON']>
  createdAt?: Maybe<Scalars['String']>
  date?: Maybe<Scalars['String']>
  externalRef?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['ID']>
  options?: Maybe<Scalars['JSON']>
  updatedAt?: Maybe<Scalars['String']>
  version?: Maybe<Scalars['Int']>
};

export type ProductVersionProductVariantsArgs = {
  id?: Maybe<Array<Maybe<Scalars['ID']>>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  sort?: Maybe<Array<Maybe<SortInput>>>
  where?: Maybe<Array<Maybe<WhereClause>>>
};

export type ProductVersionInput = {
  Product?: Maybe<ProductInput>
  ProductCategory?: Maybe<ProductCategoryInput>
  ProductCategoryId?: Maybe<Scalars['String']>
  ProductId?: Maybe<Scalars['String']>
  ProductVariants?: Maybe<Array<Maybe<ProductVariantInput>>>
  User?: Maybe<UserInput>
  UserId?: Maybe<Scalars['Int']>
  attributes?: Maybe<Scalars['JSON']>
  createdAt?: Maybe<Scalars['String']>
  date?: Maybe<Scalars['String']>
  externalRef?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['ID']>
  options?: Maybe<Scalars['JSON']>
  updatedAt?: Maybe<Scalars['String']>
  version?: Maybe<Scalars['Int']>
};

export type PromoCode = {
  __typename?: 'PromoCode'
  ContractRelation?: Maybe<Array<Maybe<Contract>>>
  Contracts?: Maybe<Array<Maybe<Contract>>>
  Discounts?: Maybe<Array<Maybe<Discount>>>
  Owner?: Maybe<User>
  OwnerId?: Maybe<Scalars['Int']>
  User?: Maybe<User>
  UserId?: Maybe<Scalars['Int']>
  activeContractsCount?: Maybe<Scalars['String']>
  allowedContractType?: Maybe<PromoCodeAllowedContractType>
  amount?: Maybe<Scalars['Float']>
  banned?: Maybe<Scalars['Boolean']>
  bannedReference?: Maybe<Scalars['JSON']>
  code?: Maybe<Scalars['String']>
  configuration?: Maybe<Scalars['JSON']>
  contractsCount?: Maybe<Scalars['String']>
  createdAt?: Maybe<Scalars['String']>
  dealtDiscountsByStatus?: Maybe<Scalars['Float']>
  description?: Maybe<Scalars['String']>
  expireDate?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['ID']>
  maxUses?: Maybe<Scalars['Int']>
  maxUsesPerUser?: Maybe<Scalars['Int']>
  partner?: Maybe<Scalars['String']>
  purpose?: Maybe<Scalars['String']>
  split?: Maybe<Scalars['Int']>
  styleConfiguration?: Maybe<Scalars['JSON']>
  type?: Maybe<PromoCodeType>
  updatedAt?: Maybe<Scalars['String']>
  validationStatus?: Maybe<Scalars['String']>
};

export type PromoCodeContractRelationArgs = {
  id?: Maybe<Array<Maybe<Scalars['ID']>>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  sort?: Maybe<Array<Maybe<SortInput>>>
  where?: Maybe<Array<Maybe<WhereClause>>>
};

export type PromoCodeContractsArgs = {
  id?: Maybe<Array<Maybe<Scalars['ID']>>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  sort?: Maybe<Array<Maybe<SortInput>>>
  where?: Maybe<Array<Maybe<WhereClause>>>
};

export type PromoCodeDiscountsArgs = {
  id?: Maybe<Array<Maybe<Scalars['ID']>>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  sort?: Maybe<Array<Maybe<SortInput>>>
  where?: Maybe<Array<Maybe<WhereClause>>>
};

export type PromoCodeDealtDiscountsByStatusArgs = {
  status: Scalars['String']
};

export enum PromoCodeAllowedContractType {
  Both = 'both',
  Dual = 'dual',
  Ee = 'ee',
  Gas = 'gas'
}

export type PromoCodeContract = {
  __typename?: 'PromoCodeContract'
  claimedAt?: Maybe<Scalars['String']>
  contractId?: Maybe<Scalars['Int']>
  createdAt?: Maybe<Scalars['String']>
  promoCodeId?: Maybe<Scalars['Int']>
  updatedAt?: Maybe<Scalars['String']>
};

export type PromoCodeContractInput = {
  claimedAt?: Maybe<Scalars['String']>
  contractId?: Maybe<Scalars['Int']>
  createdAt?: Maybe<Scalars['String']>
  promoCodeId?: Maybe<Scalars['Int']>
  updatedAt?: Maybe<Scalars['String']>
};

export type PromoCodeInput = {
  ContractRelation?: Maybe<Array<Maybe<ContractInput>>>
  Contracts?: Maybe<Array<Maybe<ContractInput>>>
  Discounts?: Maybe<Array<Maybe<DiscountInput>>>
  Owner?: Maybe<UserInput>
  User?: Maybe<UserInput>
  UserId?: Maybe<Scalars['Int']>
  allowedContractType?: Maybe<PromoCodeAllowedContractType>
  amount?: Maybe<Scalars['Float']>
  banned?: Maybe<Scalars['Boolean']>
  bannedReference?: Maybe<Scalars['JSON']>
  code?: Maybe<Scalars['String']>
  createdAt?: Maybe<Scalars['String']>
  description?: Maybe<Scalars['String']>
  expireDate?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['ID']>
  maxUses?: Maybe<Scalars['Int']>
  maxUsesPerUser?: Maybe<Scalars['Int']>
  partner?: Maybe<Scalars['String']>
  purpose?: Maybe<Scalars['String']>
  split?: Maybe<Scalars['Int']>
  styleConfiguration?: Maybe<Scalars['JSON']>
  type?: Maybe<PromoCodeType>
  updatedAt?: Maybe<Scalars['String']>
  validationStatus?: Maybe<Scalars['String']>
};

export enum PromoCodeType {
  Benefit = 'benefit',
  Coupon = 'coupon',
  Referral = 'referral'
}

export enum PunoPriceNotificationToggle {
  Off = 'off',
  On = 'on'
}

export type PushToken = {
  __typename?: 'PushToken'
  Owner?: Maybe<User>
  OwnerId?: Maybe<Scalars['Int']>
  User?: Maybe<User>
  UserId?: Maybe<Scalars['Int']>
  createdAt?: Maybe<Scalars['String']>
  disabled?: Maybe<Scalars['Boolean']>
  id?: Maybe<Scalars['ID']>
  token?: Maybe<Scalars['String']>
  updatedAt?: Maybe<Scalars['String']>
};

export type PushTokenInput = {
  Owner?: Maybe<UserInput>
  User?: Maybe<UserInput>
  UserId?: Maybe<Scalars['Int']>
  createdAt?: Maybe<Scalars['String']>
  disabled?: Maybe<Scalars['Boolean']>
  id?: Maybe<Scalars['ID']>
  token?: Maybe<Scalars['String']>
  updatedAt?: Maybe<Scalars['String']>
};

export type Query = {
  __typename?: 'Query'
  AirtableRecords?: Maybe<Array<Maybe<AirtableRecord>>>
  AppUsages?: Maybe<Array<Maybe<AppUsage>>>
  Articles?: Maybe<Array<Maybe<Article>>>
  Attributes?: Maybe<Array<Maybe<Attribute>>>
  Audits?: Maybe<Array<Maybe<Audit>>>
  AvailableTariffs_v1?: Maybe<AvailableTariffs>
  Balances?: Maybe<Array<Maybe<Balance>>>
  BannedItems?: Maybe<Array<Maybe<BannedItem>>>
  BusinessDataSum_v1?: Maybe<BusinessDataSum>
  BusinessDataTrends_v1?: Maybe<Array<Maybe<BusinessDataTrend>>>
  Categories?: Maybe<Array<Maybe<Category>>>
  CodeValidation?: Maybe<PromoCode>
  Collections?: Maybe<Array<Maybe<Collection>>>
  ConfigurationHistories?: Maybe<Array<Maybe<ConfigurationHistory>>>
  Configurations?: Maybe<Array<Maybe<Configuration>>>
  Contacts?: Maybe<Array<Maybe<Contact>>>
  ContractBalanceHistory_v1?: Maybe<BalanceHistory>
  ContractBenefitsInfo_v1?: Maybe<ContractBenefitsInfo>
  ContractDiscountAllocation_v1?: Maybe<ContractPromoCode>
  ContractGasConsumption_v1?: Maybe<Scalars['Int']>
  ContractHistories?: Maybe<Array<Maybe<ContractHistory>>>
  ContractInfoFromTransferCode?: Maybe<GeneralOperationResult>
  ContractLabels?: Maybe<Array<Maybe<ContractLabel>>>
  ContractTransferCheck_v1?: Maybe<ContractTransferCheck>
  ContractTransferCheck_v2?: Maybe<ContractTransferCheck>
  ContractTransfers?: Maybe<Array<Maybe<ContractTransfer>>>
  Contracts?: Maybe<Array<Maybe<Contract>>>
  CustomerRiskHistories?: Maybe<Array<Maybe<CustomerRiskHistory>>>
  Customers?: Maybe<Array<Maybe<Customer>>>
  DailyData_v1?: Maybe<DailyDataResponse>
  DailyPrices?: Maybe<Array<Maybe<DailyPrice>>>
  Discounts?: Maybe<Array<Maybe<Discount>>>
  EnergyConfiguration?: Maybe<Array<Maybe<Scalars['JSON']>>>
  EnerpJobs?: Maybe<Array<Maybe<EnerpJob>>>
  EnerpPaymentStatuses?: Maybe<Array<Maybe<EnerpPaymentStatus>>>
  Events?: Maybe<Array<Maybe<Event>>>
  Events_v1?: Maybe<Array<Maybe<MaterializedEvent>>>
  Files?: Maybe<Array<Maybe<File>>>
  Fines?: Maybe<Array<Maybe<Fine>>>
  GasConfiguration?: Maybe<Array<Maybe<Scalars['JSON']>>>
  HomePageWidgets_v1?: Maybe<HomeController>
  HomePageWidgets_v2?: Maybe<HomeController>
  HourlyConsumptions?: Maybe<Array<Maybe<HourlyConsumption>>>
  Installments?: Maybe<Array<Maybe<Installment>>>
  InstallmentsPlanInfo_v1?: Maybe<InstallmentsPlanInfo>
  InstallmentsPlans?: Maybe<Array<Maybe<InstallmentsPlan>>>
  InvoiceEvents?: Maybe<Array<Maybe<InvoiceEvent>>>
  InvoiceRows?: Maybe<Array<Maybe<InvoiceRow>>>
  Invoices?: Maybe<Array<Maybe<Invoice>>>
  Items?: Maybe<Array<Maybe<Item>>>
  Labels?: Maybe<Array<Maybe<Label>>>
  Me?: Maybe<User>
  MeterReadingInfo_v1?: Maybe<MeterReadingInfo>
  Namespaces?: Maybe<Array<Maybe<Namespace>>>
  Notes?: Maybe<Array<Maybe<Note>>>
  NotificationTrackings?: Maybe<Array<Maybe<NotificationTracking>>>
  Notifications?: Maybe<Array<Maybe<Notification>>>
  OTPCodes?: Maybe<Array<Maybe<OtpCode>>>
  Options?: Maybe<Array<Maybe<Option>>>
  OutstandingContracts_v1?: Maybe<OutstandingContracts>
  PageViews?: Maybe<Array<Maybe<PageView>>>
  PayNowInfo_v1?: Maybe<PayNowInfoResponse>
  PaymentAttempts?: Maybe<Array<Maybe<PaymentAttempt>>>
  PaymentMethods?: Maybe<Array<Maybe<PaymentMethod>>>
  Payouts?: Maybe<Array<Maybe<Payout>>>
  PhoneLeads?: Maybe<Array<Maybe<PhoneLead>>>
  Posts?: Maybe<Array<Maybe<Post>>>
  PriceInfos?: Maybe<Array<Maybe<PriceInfo>>>
  PriceTrends?: Maybe<Array<Maybe<Configuration>>>
  Prices?: Maybe<Array<Maybe<Price>>>
  Privacies?: Maybe<Array<Maybe<Privacy>>>
  ProductCategories?: Maybe<Array<Maybe<ProductCategory>>>
  ProductOptions?: Maybe<Array<Maybe<ProductOption>>>
  ProductVariants?: Maybe<Array<Maybe<ProductVariant>>>
  ProductVersions?: Maybe<Array<Maybe<ProductVersion>>>
  Products?: Maybe<Array<Maybe<Product>>>
  PromoCodeContracts?: Maybe<Array<Maybe<PromoCodeContract>>>
  PromoCodes?: Maybe<Array<Maybe<PromoCode>>>
  PushTokens?: Maybe<Array<Maybe<PushToken>>>
  RecoveredEmail_v1?: Maybe<EmailInfo>
  ReferralInformation_v2?: Maybe<ReferralInformation>
  Search_v2?: Maybe<SearchResponse>
  SelfServices?: Maybe<Array<Maybe<SelfService>>>
  Services?: Maybe<Array<Maybe<Service>>>
  Stories?: Maybe<Array<Maybe<Story>>>
  StyleConfigurations?: Maybe<Array<Maybe<StyleConfiguration>>>
  SubStatuses?: Maybe<Array<Maybe<SubStatus>>>
  SystemCategories_v1?: Maybe<Array<Maybe<Category>>>
  TariffData_v1?: Maybe<TariffInfo>
  TariffsStatusData_v1?: Maybe<TariffsStatusDataResponse>
  Tasks?: Maybe<Array<Maybe<Task>>>
  Templates?: Maybe<Array<Maybe<Template>>>
  UserContractSession_v1?: Maybe<UserContractSession>
  UserContractSessions?: Maybe<Array<Maybe<UserContractSession>>>
  UserFeedbacks?: Maybe<Array<Maybe<UserFeedback>>>
  UserIdentities?: Maybe<Array<Maybe<UserIdentity>>>
  Users?: Maybe<Array<Maybe<User>>>
  ValidItem?: Maybe<Scalars['Boolean']>
  ValidSocialSecurityNumberV2?: Maybe<Scalars['Boolean']>
  ValidSocialSecurityNumber_v1?: Maybe<Scalars['Boolean']>
  ValidSocialSecurityNumber_v2?: Maybe<ValidSocialSecurityNumberResponse>
  countAirtableRecords?: Maybe<CountResult>
  countAppUsages?: Maybe<CountResult>
  countArticles?: Maybe<CountResult>
  countAttributes?: Maybe<CountResult>
  countAudits?: Maybe<CountResult>
  countBalances?: Maybe<CountResult>
  countBannedItems?: Maybe<CountResult>
  countCategories?: Maybe<CountResult>
  countCollections?: Maybe<CountResult>
  countConfigurationHistories?: Maybe<CountResult>
  countConfigurations?: Maybe<CountResult>
  countContacts?: Maybe<CountResult>
  countContractHistories?: Maybe<CountResult>
  countContractLabels?: Maybe<CountResult>
  countContractTransfers?: Maybe<CountResult>
  countContracts?: Maybe<CountResult>
  countCustomerRiskHistories?: Maybe<CountResult>
  countCustomers?: Maybe<CountResult>
  countDailyPrices?: Maybe<CountResult>
  countDiscounts?: Maybe<CountResult>
  countEnerpJobs?: Maybe<CountResult>
  countEnerpPaymentStatuses?: Maybe<CountResult>
  countEvents?: Maybe<CountResult>
  countFiles?: Maybe<CountResult>
  countFines?: Maybe<CountResult>
  countHourlyConsumptions?: Maybe<CountResult>
  countInstallments?: Maybe<CountResult>
  countInstallmentsPlans?: Maybe<CountResult>
  countInvoiceEvents?: Maybe<CountResult>
  countInvoiceRows?: Maybe<CountResult>
  countInvoices?: Maybe<CountResult>
  countItems?: Maybe<CountResult>
  countLabels?: Maybe<CountResult>
  countNotes?: Maybe<CountResult>
  countNotificationTrackings?: Maybe<CountResult>
  countNotifications?: Maybe<CountResult>
  countOTPCodes?: Maybe<CountResult>
  countOptions?: Maybe<CountResult>
  countPageViews?: Maybe<CountResult>
  countPaymentAttempts?: Maybe<CountResult>
  countPaymentMethods?: Maybe<CountResult>
  countPayouts?: Maybe<CountResult>
  countPhoneLeads?: Maybe<CountResult>
  countPosts?: Maybe<CountResult>
  countPriceInfos?: Maybe<CountResult>
  countPrices?: Maybe<CountResult>
  countPrivacies?: Maybe<CountResult>
  countProductCategories?: Maybe<CountResult>
  countProductOptions?: Maybe<CountResult>
  countProductVariants?: Maybe<CountResult>
  countProductVersions?: Maybe<CountResult>
  countProducts?: Maybe<CountResult>
  countPromoCodeContracts?: Maybe<CountResult>
  countPromoCodes?: Maybe<CountResult>
  countPushTokens?: Maybe<CountResult>
  countSelfServices?: Maybe<CountResult>
  countServices?: Maybe<CountResult>
  countStories?: Maybe<CountResult>
  countStyleConfigurations?: Maybe<CountResult>
  countSubStatuses?: Maybe<CountResult>
  countTasks?: Maybe<CountResult>
  countTemplates?: Maybe<CountResult>
  countUserContractSessions?: Maybe<CountResult>
  countUserFeedbacks?: Maybe<CountResult>
  countUserIdentities?: Maybe<CountResult>
  countUsers?: Maybe<CountResult>
  searchArticles?: Maybe<Array<Maybe<Article>>>
};

export type QueryAirtableRecordsArgs = {
  id?: Maybe<Array<Maybe<Scalars['ID']>>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  scopes?: Maybe<Array<Maybe<ScopeInput>>>
  sort?: Maybe<Array<Maybe<SortInput>>>
  where?: Maybe<Array<Maybe<WhereClause>>>
};

export type QueryAppUsagesArgs = {
  id?: Maybe<Array<Maybe<Scalars['ID']>>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  scopes?: Maybe<Array<Maybe<ScopeInput>>>
  sort?: Maybe<Array<Maybe<SortInput>>>
  where?: Maybe<Array<Maybe<WhereClause>>>
};

export type QueryArticlesArgs = {
  id?: Maybe<Array<Maybe<Scalars['ID']>>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  scopes?: Maybe<Array<Maybe<ScopeInput>>>
  sort?: Maybe<Array<Maybe<SortInput>>>
  where?: Maybe<Array<Maybe<WhereClause>>>
};

export type QueryAttributesArgs = {
  id?: Maybe<Array<Maybe<Scalars['ID']>>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  scopes?: Maybe<Array<Maybe<ScopeInput>>>
  sort?: Maybe<Array<Maybe<SortInput>>>
  where?: Maybe<Array<Maybe<WhereClause>>>
};

export type QueryAuditsArgs = {
  id?: Maybe<Array<Maybe<Scalars['ID']>>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  scopes?: Maybe<Array<Maybe<ScopeInput>>>
  sort?: Maybe<Array<Maybe<SortInput>>>
  where?: Maybe<Array<Maybe<WhereClause>>>
};

export type QueryAvailableTariffs_V1Args = {
  contractType: Scalars['String']
};

export type QueryBalancesArgs = {
  id?: Maybe<Array<Maybe<Scalars['ID']>>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  scopes?: Maybe<Array<Maybe<ScopeInput>>>
  sort?: Maybe<Array<Maybe<SortInput>>>
  where?: Maybe<Array<Maybe<WhereClause>>>
};

export type QueryBannedItemsArgs = {
  id?: Maybe<Array<Maybe<Scalars['ID']>>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  scopes?: Maybe<Array<Maybe<ScopeInput>>>
  sort?: Maybe<Array<Maybe<SortInput>>>
  where?: Maybe<Array<Maybe<WhereClause>>>
};

export type QueryBusinessDataSum_V1Args = {
  end_date?: Maybe<Scalars['String']>
  labels: Array<Maybe<Scalars['String']>>
  start_date?: Maybe<Scalars['String']>
};

export type QueryBusinessDataTrends_V1Args = {
  end_date: Scalars['String']
  labels: Array<Maybe<Scalars['String']>>
  start_date: Scalars['String']
};

export type QueryCategoriesArgs = {
  id?: Maybe<Array<Maybe<Scalars['ID']>>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  scopes?: Maybe<Array<Maybe<ScopeInput>>>
  sort?: Maybe<Array<Maybe<SortInput>>>
  where?: Maybe<Array<Maybe<WhereClause>>>
};

export type QueryCodeValidationArgs = {
  code: Scalars['String']
};

export type QueryCollectionsArgs = {
  id?: Maybe<Array<Maybe<Scalars['ID']>>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  scopes?: Maybe<Array<Maybe<ScopeInput>>>
  sort?: Maybe<Array<Maybe<SortInput>>>
  where?: Maybe<Array<Maybe<WhereClause>>>
};

export type QueryConfigurationHistoriesArgs = {
  id?: Maybe<Array<Maybe<Scalars['ID']>>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  scopes?: Maybe<Array<Maybe<ScopeInput>>>
  sort?: Maybe<Array<Maybe<SortInput>>>
  where?: Maybe<Array<Maybe<WhereClause>>>
};

export type QueryConfigurationsArgs = {
  id?: Maybe<Array<Maybe<Scalars['ID']>>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  scopes?: Maybe<Array<Maybe<ScopeInput>>>
  sort?: Maybe<Array<Maybe<SortInput>>>
  where?: Maybe<Array<Maybe<WhereClause>>>
};

export type QueryContactsArgs = {
  id?: Maybe<Array<Maybe<Scalars['ID']>>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  scopes?: Maybe<Array<Maybe<ScopeInput>>>
  sort?: Maybe<Array<Maybe<SortInput>>>
  where?: Maybe<Array<Maybe<WhereClause>>>
};

export type QueryContractBalanceHistory_V1Args = {
  ContractId: Scalars['ID']
};

export type QueryContractBenefitsInfo_V1Args = {
  ContractId: Scalars['ID']
};

export type QueryContractDiscountAllocation_V1Args = {
  ContractId: Scalars['ID']
};

export type QueryContractGasConsumption_V1Args = {
  cap: Scalars['String']
  city: Scalars['String']
  cooking: Scalars['Boolean']
  heating: Scalars['Boolean']
  meters: QuoteMeters
  people: QuotePeople
};

export type QueryContractHistoriesArgs = {
  id?: Maybe<Array<Maybe<Scalars['ID']>>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  scopes?: Maybe<Array<Maybe<ScopeInput>>>
  sort?: Maybe<Array<Maybe<SortInput>>>
  where?: Maybe<Array<Maybe<WhereClause>>>
};

export type QueryContractInfoFromTransferCodeArgs = {
  code: Scalars['String']
};

export type QueryContractLabelsArgs = {
  id?: Maybe<Array<Maybe<Scalars['ID']>>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  scopes?: Maybe<Array<Maybe<ScopeInput>>>
  sort?: Maybe<Array<Maybe<SortInput>>>
  where?: Maybe<Array<Maybe<WhereClause>>>
};

export type QueryContractTransferCheck_V1Args = {
  contractId: Scalars['ID']
  retrieveCode?: Maybe<Scalars['Boolean']>
  retrieveLink?: Maybe<Scalars['Boolean']>
};

export type QueryContractTransferCheck_V2Args = {
  contractId: Scalars['ID']
  retrieveLink?: Maybe<Scalars['Boolean']>
};

export type QueryContractTransfersArgs = {
  id?: Maybe<Array<Maybe<Scalars['ID']>>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  scopes?: Maybe<Array<Maybe<ScopeInput>>>
  sort?: Maybe<Array<Maybe<SortInput>>>
  where?: Maybe<Array<Maybe<WhereClause>>>
};

export type QueryContractsArgs = {
  id?: Maybe<Array<Maybe<Scalars['ID']>>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  scopes?: Maybe<Array<Maybe<ScopeInput>>>
  sort?: Maybe<Array<Maybe<SortInput>>>
  where?: Maybe<Array<Maybe<WhereClause>>>
};

export type QueryCustomerRiskHistoriesArgs = {
  id?: Maybe<Array<Maybe<Scalars['ID']>>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  scopes?: Maybe<Array<Maybe<ScopeInput>>>
  sort?: Maybe<Array<Maybe<SortInput>>>
  where?: Maybe<Array<Maybe<WhereClause>>>
};

export type QueryCustomersArgs = {
  id?: Maybe<Array<Maybe<Scalars['ID']>>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  scopes?: Maybe<Array<Maybe<ScopeInput>>>
  sort?: Maybe<Array<Maybe<SortInput>>>
  where?: Maybe<Array<Maybe<WhereClause>>>
};

export type QueryDailyData_V1Args = {
  endDate: Scalars['String']
  itemId: Scalars['ID']
  startDate: Scalars['String']
};

export type QueryDailyPricesArgs = {
  id?: Maybe<Array<Maybe<Scalars['ID']>>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  scopes?: Maybe<Array<Maybe<ScopeInput>>>
  sort?: Maybe<Array<Maybe<SortInput>>>
  where?: Maybe<Array<Maybe<WhereClause>>>
};

export type QueryDiscountsArgs = {
  id?: Maybe<Array<Maybe<Scalars['ID']>>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  scopes?: Maybe<Array<Maybe<ScopeInput>>>
  sort?: Maybe<Array<Maybe<SortInput>>>
  where?: Maybe<Array<Maybe<WhereClause>>>
};

export type QueryEnerpJobsArgs = {
  id?: Maybe<Array<Maybe<Scalars['ID']>>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  scopes?: Maybe<Array<Maybe<ScopeInput>>>
  sort?: Maybe<Array<Maybe<SortInput>>>
  where?: Maybe<Array<Maybe<WhereClause>>>
};

export type QueryEnerpPaymentStatusesArgs = {
  id?: Maybe<Array<Maybe<Scalars['ID']>>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  scopes?: Maybe<Array<Maybe<ScopeInput>>>
  sort?: Maybe<Array<Maybe<SortInput>>>
  where?: Maybe<Array<Maybe<WhereClause>>>
};

export type QueryEventsArgs = {
  id?: Maybe<Array<Maybe<Scalars['ID']>>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  scopes?: Maybe<Array<Maybe<ScopeInput>>>
  sort?: Maybe<Array<Maybe<SortInput>>>
  where?: Maybe<Array<Maybe<WhereClause>>>
};

export type QueryEvents_V1Args = {
  CategoryId?: Maybe<Scalars['ID']>
  CustomerId?: Maybe<Scalars['ID']>
  ServiceId?: Maybe<Scalars['ID']>
  endDate?: Maybe<Scalars['String']>
  sortOrder?: Maybe<Scalars['String']>
  startDate: Scalars['String']
  textFilter?: Maybe<Scalars['String']>
};

export type QueryFilesArgs = {
  id?: Maybe<Array<Maybe<Scalars['ID']>>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  scopes?: Maybe<Array<Maybe<ScopeInput>>>
  sort?: Maybe<Array<Maybe<SortInput>>>
  where?: Maybe<Array<Maybe<WhereClause>>>
};

export type QueryFinesArgs = {
  id?: Maybe<Array<Maybe<Scalars['ID']>>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  scopes?: Maybe<Array<Maybe<ScopeInput>>>
  sort?: Maybe<Array<Maybe<SortInput>>>
  where?: Maybe<Array<Maybe<WhereClause>>>
};

export type QueryHomePageWidgets_V1Args = {
  unreadMessages?: Maybe<Scalars['Boolean']>
};

export type QueryHomePageWidgets_V2Args = {
  deviceStatus?: Maybe<DeviceStatus>
};

export type QueryHourlyConsumptionsArgs = {
  id?: Maybe<Array<Maybe<Scalars['ID']>>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  scopes?: Maybe<Array<Maybe<ScopeInput>>>
  sort?: Maybe<Array<Maybe<SortInput>>>
  where?: Maybe<Array<Maybe<WhereClause>>>
};

export type QueryInstallmentsArgs = {
  id?: Maybe<Array<Maybe<Scalars['ID']>>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  scopes?: Maybe<Array<Maybe<ScopeInput>>>
  sort?: Maybe<Array<Maybe<SortInput>>>
  where?: Maybe<Array<Maybe<WhereClause>>>
};

export type QueryInstallmentsPlanInfo_V1Args = {
  invoiceId: Scalars['ID']
};

export type QueryInstallmentsPlansArgs = {
  id?: Maybe<Array<Maybe<Scalars['ID']>>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  scopes?: Maybe<Array<Maybe<ScopeInput>>>
  sort?: Maybe<Array<Maybe<SortInput>>>
  where?: Maybe<Array<Maybe<WhereClause>>>
};

export type QueryInvoiceEventsArgs = {
  id?: Maybe<Array<Maybe<Scalars['ID']>>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  scopes?: Maybe<Array<Maybe<ScopeInput>>>
  sort?: Maybe<Array<Maybe<SortInput>>>
  where?: Maybe<Array<Maybe<WhereClause>>>
};

export type QueryInvoiceRowsArgs = {
  id?: Maybe<Array<Maybe<Scalars['ID']>>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  scopes?: Maybe<Array<Maybe<ScopeInput>>>
  sort?: Maybe<Array<Maybe<SortInput>>>
  where?: Maybe<Array<Maybe<WhereClause>>>
};

export type QueryInvoicesArgs = {
  id?: Maybe<Array<Maybe<Scalars['ID']>>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  scopes?: Maybe<Array<Maybe<ScopeInput>>>
  sort?: Maybe<Array<Maybe<SortInput>>>
  where?: Maybe<Array<Maybe<WhereClause>>>
};

export type QueryItemsArgs = {
  id?: Maybe<Array<Maybe<Scalars['ID']>>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  scopes?: Maybe<Array<Maybe<ScopeInput>>>
  sort?: Maybe<Array<Maybe<SortInput>>>
  where?: Maybe<Array<Maybe<WhereClause>>>
};

export type QueryLabelsArgs = {
  id?: Maybe<Array<Maybe<Scalars['ID']>>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  scopes?: Maybe<Array<Maybe<ScopeInput>>>
  sort?: Maybe<Array<Maybe<SortInput>>>
  where?: Maybe<Array<Maybe<WhereClause>>>
};

export type QueryMeterReadingInfo_V1Args = {
  ContractId: Scalars['ID']
};

export type QueryNotesArgs = {
  id?: Maybe<Array<Maybe<Scalars['ID']>>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  scopes?: Maybe<Array<Maybe<ScopeInput>>>
  sort?: Maybe<Array<Maybe<SortInput>>>
  where?: Maybe<Array<Maybe<WhereClause>>>
};

export type QueryNotificationTrackingsArgs = {
  id?: Maybe<Array<Maybe<Scalars['ID']>>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  scopes?: Maybe<Array<Maybe<ScopeInput>>>
  sort?: Maybe<Array<Maybe<SortInput>>>
  where?: Maybe<Array<Maybe<WhereClause>>>
};

export type QueryNotificationsArgs = {
  id?: Maybe<Array<Maybe<Scalars['ID']>>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  scopes?: Maybe<Array<Maybe<ScopeInput>>>
  sort?: Maybe<Array<Maybe<SortInput>>>
  where?: Maybe<Array<Maybe<WhereClause>>>
};

export type QueryOtpCodesArgs = {
  id?: Maybe<Array<Maybe<Scalars['ID']>>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  scopes?: Maybe<Array<Maybe<ScopeInput>>>
  sort?: Maybe<Array<Maybe<SortInput>>>
  where?: Maybe<Array<Maybe<WhereClause>>>
};

export type QueryOptionsArgs = {
  id?: Maybe<Array<Maybe<Scalars['ID']>>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  scopes?: Maybe<Array<Maybe<ScopeInput>>>
  sort?: Maybe<Array<Maybe<SortInput>>>
  where?: Maybe<Array<Maybe<WhereClause>>>
};

export type QueryOutstandingContracts_V1Args = {
  contractStatus: Scalars['String']
  hideLowRisk: Scalars['Boolean']
  notesContractOwner: NotesContractOwner
  page: Scalars['Int']
  sort: Scalars['String']
};

export type QueryPageViewsArgs = {
  id?: Maybe<Array<Maybe<Scalars['ID']>>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  scopes?: Maybe<Array<Maybe<ScopeInput>>>
  sort?: Maybe<Array<Maybe<SortInput>>>
  where?: Maybe<Array<Maybe<WhereClause>>>
};

export type QueryPayNowInfo_V1Args = {
  invoiceId: Scalars['ID']
};

export type QueryPaymentAttemptsArgs = {
  id?: Maybe<Array<Maybe<Scalars['ID']>>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  scopes?: Maybe<Array<Maybe<ScopeInput>>>
  sort?: Maybe<Array<Maybe<SortInput>>>
  where?: Maybe<Array<Maybe<WhereClause>>>
};

export type QueryPaymentMethodsArgs = {
  id?: Maybe<Array<Maybe<Scalars['ID']>>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  scopes?: Maybe<Array<Maybe<ScopeInput>>>
  sort?: Maybe<Array<Maybe<SortInput>>>
  where?: Maybe<Array<Maybe<WhereClause>>>
};

export type QueryPayoutsArgs = {
  id?: Maybe<Array<Maybe<Scalars['ID']>>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  scopes?: Maybe<Array<Maybe<ScopeInput>>>
  sort?: Maybe<Array<Maybe<SortInput>>>
  where?: Maybe<Array<Maybe<WhereClause>>>
};

export type QueryPhoneLeadsArgs = {
  id?: Maybe<Array<Maybe<Scalars['ID']>>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  scopes?: Maybe<Array<Maybe<ScopeInput>>>
  sort?: Maybe<Array<Maybe<SortInput>>>
  where?: Maybe<Array<Maybe<WhereClause>>>
};

export type QueryPostsArgs = {
  id?: Maybe<Array<Maybe<Scalars['ID']>>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  scopes?: Maybe<Array<Maybe<ScopeInput>>>
  sort?: Maybe<Array<Maybe<SortInput>>>
  where?: Maybe<Array<Maybe<WhereClause>>>
};

export type QueryPriceInfosArgs = {
  id?: Maybe<Array<Maybe<Scalars['ID']>>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  scopes?: Maybe<Array<Maybe<ScopeInput>>>
  sort?: Maybe<Array<Maybe<SortInput>>>
  where?: Maybe<Array<Maybe<WhereClause>>>
};

export type QueryPriceTrendsArgs = {
  limit?: Maybe<Scalars['Int']>
  namespace: Scalars['String']
};

export type QueryPricesArgs = {
  id?: Maybe<Array<Maybe<Scalars['ID']>>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  scopes?: Maybe<Array<Maybe<ScopeInput>>>
  sort?: Maybe<Array<Maybe<SortInput>>>
  where?: Maybe<Array<Maybe<WhereClause>>>
};

export type QueryPrivaciesArgs = {
  id?: Maybe<Array<Maybe<Scalars['ID']>>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  scopes?: Maybe<Array<Maybe<ScopeInput>>>
  sort?: Maybe<Array<Maybe<SortInput>>>
  where?: Maybe<Array<Maybe<WhereClause>>>
};

export type QueryProductCategoriesArgs = {
  id?: Maybe<Array<Maybe<Scalars['ID']>>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  scopes?: Maybe<Array<Maybe<ScopeInput>>>
  sort?: Maybe<Array<Maybe<SortInput>>>
  where?: Maybe<Array<Maybe<WhereClause>>>
};

export type QueryProductOptionsArgs = {
  id?: Maybe<Array<Maybe<Scalars['ID']>>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  scopes?: Maybe<Array<Maybe<ScopeInput>>>
  sort?: Maybe<Array<Maybe<SortInput>>>
  where?: Maybe<Array<Maybe<WhereClause>>>
};

export type QueryProductVariantsArgs = {
  id?: Maybe<Array<Maybe<Scalars['ID']>>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  scopes?: Maybe<Array<Maybe<ScopeInput>>>
  sort?: Maybe<Array<Maybe<SortInput>>>
  where?: Maybe<Array<Maybe<WhereClause>>>
};

export type QueryProductVersionsArgs = {
  id?: Maybe<Array<Maybe<Scalars['ID']>>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  scopes?: Maybe<Array<Maybe<ScopeInput>>>
  sort?: Maybe<Array<Maybe<SortInput>>>
  where?: Maybe<Array<Maybe<WhereClause>>>
};

export type QueryProductsArgs = {
  id?: Maybe<Array<Maybe<Scalars['ID']>>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  scopes?: Maybe<Array<Maybe<ScopeInput>>>
  sort?: Maybe<Array<Maybe<SortInput>>>
  where?: Maybe<Array<Maybe<WhereClause>>>
};

export type QueryPromoCodeContractsArgs = {
  id?: Maybe<Array<Maybe<Scalars['ID']>>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  scopes?: Maybe<Array<Maybe<ScopeInput>>>
  sort?: Maybe<Array<Maybe<SortInput>>>
  where?: Maybe<Array<Maybe<WhereClause>>>
};

export type QueryPromoCodesArgs = {
  id?: Maybe<Array<Maybe<Scalars['ID']>>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  scopes?: Maybe<Array<Maybe<ScopeInput>>>
  sort?: Maybe<Array<Maybe<SortInput>>>
  where?: Maybe<Array<Maybe<WhereClause>>>
};

export type QueryPushTokensArgs = {
  id?: Maybe<Array<Maybe<Scalars['ID']>>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  scopes?: Maybe<Array<Maybe<ScopeInput>>>
  sort?: Maybe<Array<Maybe<SortInput>>>
  where?: Maybe<Array<Maybe<WhereClause>>>
};

export type QueryRecoveredEmail_V1Args = {
  itemName: Scalars['String']
  socialSecurityNumber: Scalars['String']
};

export type QuerySearch_V2Args = {
  searchValue: Scalars['String']
};

export type QuerySelfServicesArgs = {
  id?: Maybe<Array<Maybe<Scalars['ID']>>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  scopes?: Maybe<Array<Maybe<ScopeInput>>>
  sort?: Maybe<Array<Maybe<SortInput>>>
  where?: Maybe<Array<Maybe<WhereClause>>>
};

export type QueryServicesArgs = {
  id?: Maybe<Array<Maybe<Scalars['ID']>>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  scopes?: Maybe<Array<Maybe<ScopeInput>>>
  sort?: Maybe<Array<Maybe<SortInput>>>
  where?: Maybe<Array<Maybe<WhereClause>>>
};

export type QueryStoriesArgs = {
  id?: Maybe<Array<Maybe<Scalars['ID']>>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  scopes?: Maybe<Array<Maybe<ScopeInput>>>
  sort?: Maybe<Array<Maybe<SortInput>>>
  where?: Maybe<Array<Maybe<WhereClause>>>
};

export type QueryStyleConfigurationsArgs = {
  id?: Maybe<Array<Maybe<Scalars['ID']>>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  scopes?: Maybe<Array<Maybe<ScopeInput>>>
  sort?: Maybe<Array<Maybe<SortInput>>>
  where?: Maybe<Array<Maybe<WhereClause>>>
};

export type QuerySubStatusesArgs = {
  id?: Maybe<Array<Maybe<Scalars['ID']>>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  scopes?: Maybe<Array<Maybe<ScopeInput>>>
  sort?: Maybe<Array<Maybe<SortInput>>>
  where?: Maybe<Array<Maybe<WhereClause>>>
};

export type QueryTariffData_V1Args = {
  transferCode: Scalars['String']
};

export type QueryTariffsStatusData_V1Args = {
  contractId: Scalars['ID']
  requestTariffComparison?: Maybe<Scalars['Boolean']>
};

export type QueryTasksArgs = {
  id?: Maybe<Array<Maybe<Scalars['ID']>>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  scopes?: Maybe<Array<Maybe<ScopeInput>>>
  sort?: Maybe<Array<Maybe<SortInput>>>
  where?: Maybe<Array<Maybe<WhereClause>>>
};

export type QueryTemplatesArgs = {
  id?: Maybe<Array<Maybe<Scalars['ID']>>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  scopes?: Maybe<Array<Maybe<ScopeInput>>>
  sort?: Maybe<Array<Maybe<SortInput>>>
  where?: Maybe<Array<Maybe<WhereClause>>>
};

export type QueryUserContractSession_V1Args = {
  contractType?: Maybe<ContractType>
  tariffCodename?: Maybe<Scalars['String']>
};

export type QueryUserContractSessionsArgs = {
  id?: Maybe<Array<Maybe<Scalars['ID']>>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  scopes?: Maybe<Array<Maybe<ScopeInput>>>
  sort?: Maybe<Array<Maybe<SortInput>>>
  where?: Maybe<Array<Maybe<WhereClause>>>
};

export type QueryUserFeedbacksArgs = {
  id?: Maybe<Array<Maybe<Scalars['ID']>>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  scopes?: Maybe<Array<Maybe<ScopeInput>>>
  sort?: Maybe<Array<Maybe<SortInput>>>
  where?: Maybe<Array<Maybe<WhereClause>>>
};

export type QueryUserIdentitiesArgs = {
  id?: Maybe<Array<Maybe<Scalars['ID']>>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  scopes?: Maybe<Array<Maybe<ScopeInput>>>
  sort?: Maybe<Array<Maybe<SortInput>>>
  where?: Maybe<Array<Maybe<WhereClause>>>
};

export type QueryUsersArgs = {
  id?: Maybe<Array<Maybe<Scalars['ID']>>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  scopes?: Maybe<Array<Maybe<ScopeInput>>>
  sort?: Maybe<Array<Maybe<SortInput>>>
  where?: Maybe<Array<Maybe<WhereClause>>>
};

export type QueryValidItemArgs = {
  itemName: Scalars['String']
};

export type QueryValidSocialSecurityNumberV2Args = {
  code: Scalars['String']
  contactType: Scalars['String']
};

export type QueryValidSocialSecurityNumber_V1Args = {
  code: Scalars['String']
  contactType: Scalars['String']
};

export type QueryValidSocialSecurityNumber_V2Args = {
  code: Scalars['String']
  contactType: Scalars['String']
};

export type QueryCountAirtableRecordsArgs = {
  scopes?: Maybe<Array<Maybe<ScopeInput>>>
  where?: Maybe<Array<Maybe<WhereClause>>>
};

export type QueryCountAppUsagesArgs = {
  scopes?: Maybe<Array<Maybe<ScopeInput>>>
  where?: Maybe<Array<Maybe<WhereClause>>>
};

export type QueryCountArticlesArgs = {
  scopes?: Maybe<Array<Maybe<ScopeInput>>>
  where?: Maybe<Array<Maybe<WhereClause>>>
};

export type QueryCountAttributesArgs = {
  scopes?: Maybe<Array<Maybe<ScopeInput>>>
  where?: Maybe<Array<Maybe<WhereClause>>>
};

export type QueryCountAuditsArgs = {
  scopes?: Maybe<Array<Maybe<ScopeInput>>>
  where?: Maybe<Array<Maybe<WhereClause>>>
};

export type QueryCountBalancesArgs = {
  scopes?: Maybe<Array<Maybe<ScopeInput>>>
  where?: Maybe<Array<Maybe<WhereClause>>>
};

export type QueryCountBannedItemsArgs = {
  scopes?: Maybe<Array<Maybe<ScopeInput>>>
  where?: Maybe<Array<Maybe<WhereClause>>>
};

export type QueryCountCategoriesArgs = {
  scopes?: Maybe<Array<Maybe<ScopeInput>>>
  where?: Maybe<Array<Maybe<WhereClause>>>
};

export type QueryCountCollectionsArgs = {
  scopes?: Maybe<Array<Maybe<ScopeInput>>>
  where?: Maybe<Array<Maybe<WhereClause>>>
};

export type QueryCountConfigurationHistoriesArgs = {
  scopes?: Maybe<Array<Maybe<ScopeInput>>>
  where?: Maybe<Array<Maybe<WhereClause>>>
};

export type QueryCountConfigurationsArgs = {
  scopes?: Maybe<Array<Maybe<ScopeInput>>>
  where?: Maybe<Array<Maybe<WhereClause>>>
};

export type QueryCountContactsArgs = {
  scopes?: Maybe<Array<Maybe<ScopeInput>>>
  where?: Maybe<Array<Maybe<WhereClause>>>
};

export type QueryCountContractHistoriesArgs = {
  scopes?: Maybe<Array<Maybe<ScopeInput>>>
  where?: Maybe<Array<Maybe<WhereClause>>>
};

export type QueryCountContractLabelsArgs = {
  scopes?: Maybe<Array<Maybe<ScopeInput>>>
  where?: Maybe<Array<Maybe<WhereClause>>>
};

export type QueryCountContractTransfersArgs = {
  scopes?: Maybe<Array<Maybe<ScopeInput>>>
  where?: Maybe<Array<Maybe<WhereClause>>>
};

export type QueryCountContractsArgs = {
  scopes?: Maybe<Array<Maybe<ScopeInput>>>
  where?: Maybe<Array<Maybe<WhereClause>>>
};

export type QueryCountCustomerRiskHistoriesArgs = {
  scopes?: Maybe<Array<Maybe<ScopeInput>>>
  where?: Maybe<Array<Maybe<WhereClause>>>
};

export type QueryCountCustomersArgs = {
  scopes?: Maybe<Array<Maybe<ScopeInput>>>
  where?: Maybe<Array<Maybe<WhereClause>>>
};

export type QueryCountDailyPricesArgs = {
  scopes?: Maybe<Array<Maybe<ScopeInput>>>
  where?: Maybe<Array<Maybe<WhereClause>>>
};

export type QueryCountDiscountsArgs = {
  scopes?: Maybe<Array<Maybe<ScopeInput>>>
  where?: Maybe<Array<Maybe<WhereClause>>>
};

export type QueryCountEnerpJobsArgs = {
  scopes?: Maybe<Array<Maybe<ScopeInput>>>
  where?: Maybe<Array<Maybe<WhereClause>>>
};

export type QueryCountEnerpPaymentStatusesArgs = {
  scopes?: Maybe<Array<Maybe<ScopeInput>>>
  where?: Maybe<Array<Maybe<WhereClause>>>
};

export type QueryCountEventsArgs = {
  scopes?: Maybe<Array<Maybe<ScopeInput>>>
  where?: Maybe<Array<Maybe<WhereClause>>>
};

export type QueryCountFilesArgs = {
  scopes?: Maybe<Array<Maybe<ScopeInput>>>
  where?: Maybe<Array<Maybe<WhereClause>>>
};

export type QueryCountFinesArgs = {
  scopes?: Maybe<Array<Maybe<ScopeInput>>>
  where?: Maybe<Array<Maybe<WhereClause>>>
};

export type QueryCountHourlyConsumptionsArgs = {
  scopes?: Maybe<Array<Maybe<ScopeInput>>>
  where?: Maybe<Array<Maybe<WhereClause>>>
};

export type QueryCountInstallmentsArgs = {
  scopes?: Maybe<Array<Maybe<ScopeInput>>>
  where?: Maybe<Array<Maybe<WhereClause>>>
};

export type QueryCountInstallmentsPlansArgs = {
  scopes?: Maybe<Array<Maybe<ScopeInput>>>
  where?: Maybe<Array<Maybe<WhereClause>>>
};

export type QueryCountInvoiceEventsArgs = {
  scopes?: Maybe<Array<Maybe<ScopeInput>>>
  where?: Maybe<Array<Maybe<WhereClause>>>
};

export type QueryCountInvoiceRowsArgs = {
  scopes?: Maybe<Array<Maybe<ScopeInput>>>
  where?: Maybe<Array<Maybe<WhereClause>>>
};

export type QueryCountInvoicesArgs = {
  scopes?: Maybe<Array<Maybe<ScopeInput>>>
  where?: Maybe<Array<Maybe<WhereClause>>>
};

export type QueryCountItemsArgs = {
  scopes?: Maybe<Array<Maybe<ScopeInput>>>
  where?: Maybe<Array<Maybe<WhereClause>>>
};

export type QueryCountLabelsArgs = {
  scopes?: Maybe<Array<Maybe<ScopeInput>>>
  where?: Maybe<Array<Maybe<WhereClause>>>
};

export type QueryCountNotesArgs = {
  scopes?: Maybe<Array<Maybe<ScopeInput>>>
  where?: Maybe<Array<Maybe<WhereClause>>>
};

export type QueryCountNotificationTrackingsArgs = {
  scopes?: Maybe<Array<Maybe<ScopeInput>>>
  where?: Maybe<Array<Maybe<WhereClause>>>
};

export type QueryCountNotificationsArgs = {
  scopes?: Maybe<Array<Maybe<ScopeInput>>>
  where?: Maybe<Array<Maybe<WhereClause>>>
};

export type QueryCountOtpCodesArgs = {
  scopes?: Maybe<Array<Maybe<ScopeInput>>>
  where?: Maybe<Array<Maybe<WhereClause>>>
};

export type QueryCountOptionsArgs = {
  scopes?: Maybe<Array<Maybe<ScopeInput>>>
  where?: Maybe<Array<Maybe<WhereClause>>>
};

export type QueryCountPageViewsArgs = {
  scopes?: Maybe<Array<Maybe<ScopeInput>>>
  where?: Maybe<Array<Maybe<WhereClause>>>
};

export type QueryCountPaymentAttemptsArgs = {
  scopes?: Maybe<Array<Maybe<ScopeInput>>>
  where?: Maybe<Array<Maybe<WhereClause>>>
};

export type QueryCountPaymentMethodsArgs = {
  scopes?: Maybe<Array<Maybe<ScopeInput>>>
  where?: Maybe<Array<Maybe<WhereClause>>>
};

export type QueryCountPayoutsArgs = {
  scopes?: Maybe<Array<Maybe<ScopeInput>>>
  where?: Maybe<Array<Maybe<WhereClause>>>
};

export type QueryCountPhoneLeadsArgs = {
  scopes?: Maybe<Array<Maybe<ScopeInput>>>
  where?: Maybe<Array<Maybe<WhereClause>>>
};

export type QueryCountPostsArgs = {
  scopes?: Maybe<Array<Maybe<ScopeInput>>>
  where?: Maybe<Array<Maybe<WhereClause>>>
};

export type QueryCountPriceInfosArgs = {
  scopes?: Maybe<Array<Maybe<ScopeInput>>>
  where?: Maybe<Array<Maybe<WhereClause>>>
};

export type QueryCountPricesArgs = {
  scopes?: Maybe<Array<Maybe<ScopeInput>>>
  where?: Maybe<Array<Maybe<WhereClause>>>
};

export type QueryCountPrivaciesArgs = {
  scopes?: Maybe<Array<Maybe<ScopeInput>>>
  where?: Maybe<Array<Maybe<WhereClause>>>
};

export type QueryCountProductCategoriesArgs = {
  scopes?: Maybe<Array<Maybe<ScopeInput>>>
  where?: Maybe<Array<Maybe<WhereClause>>>
};

export type QueryCountProductOptionsArgs = {
  scopes?: Maybe<Array<Maybe<ScopeInput>>>
  where?: Maybe<Array<Maybe<WhereClause>>>
};

export type QueryCountProductVariantsArgs = {
  scopes?: Maybe<Array<Maybe<ScopeInput>>>
  where?: Maybe<Array<Maybe<WhereClause>>>
};

export type QueryCountProductVersionsArgs = {
  scopes?: Maybe<Array<Maybe<ScopeInput>>>
  where?: Maybe<Array<Maybe<WhereClause>>>
};

export type QueryCountProductsArgs = {
  scopes?: Maybe<Array<Maybe<ScopeInput>>>
  where?: Maybe<Array<Maybe<WhereClause>>>
};

export type QueryCountPromoCodeContractsArgs = {
  scopes?: Maybe<Array<Maybe<ScopeInput>>>
  where?: Maybe<Array<Maybe<WhereClause>>>
};

export type QueryCountPromoCodesArgs = {
  scopes?: Maybe<Array<Maybe<ScopeInput>>>
  where?: Maybe<Array<Maybe<WhereClause>>>
};

export type QueryCountPushTokensArgs = {
  scopes?: Maybe<Array<Maybe<ScopeInput>>>
  where?: Maybe<Array<Maybe<WhereClause>>>
};

export type QueryCountSelfServicesArgs = {
  scopes?: Maybe<Array<Maybe<ScopeInput>>>
  where?: Maybe<Array<Maybe<WhereClause>>>
};

export type QueryCountServicesArgs = {
  scopes?: Maybe<Array<Maybe<ScopeInput>>>
  where?: Maybe<Array<Maybe<WhereClause>>>
};

export type QueryCountStoriesArgs = {
  scopes?: Maybe<Array<Maybe<ScopeInput>>>
  where?: Maybe<Array<Maybe<WhereClause>>>
};

export type QueryCountStyleConfigurationsArgs = {
  scopes?: Maybe<Array<Maybe<ScopeInput>>>
  where?: Maybe<Array<Maybe<WhereClause>>>
};

export type QueryCountSubStatusesArgs = {
  scopes?: Maybe<Array<Maybe<ScopeInput>>>
  where?: Maybe<Array<Maybe<WhereClause>>>
};

export type QueryCountTasksArgs = {
  scopes?: Maybe<Array<Maybe<ScopeInput>>>
  where?: Maybe<Array<Maybe<WhereClause>>>
};

export type QueryCountTemplatesArgs = {
  scopes?: Maybe<Array<Maybe<ScopeInput>>>
  where?: Maybe<Array<Maybe<WhereClause>>>
};

export type QueryCountUserContractSessionsArgs = {
  scopes?: Maybe<Array<Maybe<ScopeInput>>>
  where?: Maybe<Array<Maybe<WhereClause>>>
};

export type QueryCountUserFeedbacksArgs = {
  scopes?: Maybe<Array<Maybe<ScopeInput>>>
  where?: Maybe<Array<Maybe<WhereClause>>>
};

export type QueryCountUserIdentitiesArgs = {
  scopes?: Maybe<Array<Maybe<ScopeInput>>>
  where?: Maybe<Array<Maybe<WhereClause>>>
};

export type QueryCountUsersArgs = {
  scopes?: Maybe<Array<Maybe<ScopeInput>>>
  where?: Maybe<Array<Maybe<WhereClause>>>
};

export type QuerySearchArticlesArgs = {
  collectionId?: Maybe<Scalars['ID']>
  searchType: Scalars['String']
  text: Array<Maybe<Scalars['String']>>
};

export enum QuoteMeters {
  Value_40 = 'Value_40',
  Value_60 = 'Value_60',
  Value_80 = 'Value_80',
  Value_100 = 'Value_100',
  Value_120 = 'Value_120',
  ValueGreater = 'Value_Greater'
}

export enum QuotePeople {
  Value_1 = 'Value_1',
  Value_2 = 'Value_2',
  Value_3 = 'Value_3',
  Value_4 = 'Value_4',
  Value_5 = 'Value_5',
  ValueGreater = 'Value_Greater'
}

export enum ReconciliationStrategy {
  All = 'ALL',
  FromNow = 'FROM_NOW',
  Nexts = 'NEXTS',
  This = 'THIS'
}

export enum ReferenceUnit {
  Kw = 'kw',
  Kwh = 'kwh',
  Month = 'month',
  Smc = 'smc'
}

export enum ReferralAuthorizationStatus {
  Authorized = 'Authorized',
  Unauthorized = 'Unauthorized'
}

export type ReferralInformation = {
  __typename?: 'ReferralInformation'
  landingUrl?: Maybe<Scalars['String']>
  progresses?: Maybe<Array<Maybe<ReferralProgress>>>
  promoCode?: Maybe<PromoCode>
  stats?: Maybe<ReferralStats>
  status?: Maybe<ReferralAuthorizationStatus>
};

export type ReferralProgress = {
  __typename?: 'ReferralProgress'
  date?: Maybe<Scalars['String']>
  name?: Maybe<Scalars['String']>
  redeemedAmount?: Maybe<Scalars['Float']>
  status?: Maybe<Scalars['String']>
  totalAmount?: Maybe<Scalars['Float']>
};

export type ReferralStats = {
  __typename?: 'ReferralStats'
  acceptedFriends?: Maybe<Scalars['Int']>
  pendingBalance?: Maybe<Scalars['Float']>
  pendingFriends?: Maybe<Scalars['Int']>
  redeemedBalance?: Maybe<Scalars['Float']>
};

export enum RequestTariffChangeVariants {
  EndTariffForced = 'EndTariffForced',
  TariffExpiration = 'TariffExpiration'
}

export type SameCadastralContract = {
  __typename?: 'SameCadastralContract'
  cadastral?: Maybe<Scalars['JSON']>
  contracts?: Maybe<Array<Maybe<Contract>>>
};

export type ScopeInput = {
  args?: Maybe<Array<Maybe<Scalars['String']>>>
  name: Scalars['String']
};

export type SearchContractDocument = {
  __typename?: 'SearchContractDocument'
  UserId: Scalars['String']
  addressFull: Scalars['String']
  contractCodeName: Scalars['String']
  id: Scalars['ID']
  name: Scalars['String']
  ownerFullName: Scalars['String']
  startDate: Scalars['String']
};

export type SearchCustomerDocument = {
  __typename?: 'SearchCustomerDocument'
  addressFull: Scalars['String']
  firstName: Scalars['String']
  id: Scalars['ID']
  lastName: Scalars['String']
  socialSecurityNumber: Scalars['String']
  type: Scalars['String']
};

export type SearchDocument = SearchContractDocument | SearchCustomerDocument | SearchInvoiceDocument | SearchItemDocument | SearchUserDocument;

export enum SearchEntityType {
  Contract = 'Contract',
  Customer = 'Customer',
  Invoice = 'Invoice',
  Item = 'Item',
  User = 'User'
}

export type SearchInvoiceDocument = {
  __typename?: 'SearchInvoiceDocument'
  ContractId: Scalars['String']
  enerpRef: Scalars['String']
  id: Scalars['ID']
  name: Scalars['String']
  status: Scalars['String']
  stripeId: Scalars['String']
  userActionRequired: Scalars['Boolean']
};

export type SearchItemDocument = {
  __typename?: 'SearchItemDocument'
  ContractId: Scalars['String']
  enerpId: Scalars['String']
  id: Scalars['ID']
  name: Scalars['String']
  ownerFullName: Scalars['String']
  type: Scalars['String']
};

export type SearchResponse = {
  __typename?: 'SearchResponse'
  count: Scalars['Int']
  maxScore?: Maybe<Scalars['Float']>
  results: Array<SearchResult>
};

export type SearchResult = {
  __typename?: 'SearchResult'
  document: Scalars['JSON']
  entityId: Scalars['String']
  entityType: SearchEntityType
  score: Scalars['Float']
};

export type SearchUserDocument = {
  __typename?: 'SearchUserDocument'
  disabled: Scalars['Boolean']
  email: Scalars['String']
  firstName: Scalars['String']
  fullName: Scalars['String']
  id: Scalars['ID']
  lastName: Scalars['String']
  phone: Scalars['String']
  promoCode: Scalars['String']
  role: Scalars['String']
};

export type SelectNewTariffPayload = {
  __typename?: 'SelectNewTariffPayload'
  deadline?: Maybe<Scalars['String']>
  variant?: Maybe<SelectNewTariffVariant>
};

export enum SelectNewTariffVariant {
  Expiring = 'expiring',
  NonExpiring = 'non_expiring'
}

export type SelfService = {
  __typename?: 'SelfService'
  Files?: Maybe<Array<Maybe<File>>>
  Item?: Maybe<Item>
  ItemId?: Maybe<Scalars['Int']>
  OTPCodes?: Maybe<Array<Maybe<OtpCode>>>
  Owner?: Maybe<User>
  OwnerId?: Maybe<Scalars['Int']>
  createdAt?: Maybe<Scalars['String']>
  date?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['ID']>
  payload?: Maybe<Scalars['JSON']>
  status?: Maybe<SelfServiceStatus>
  type?: Maybe<SelfServiceType>
  updatedAt?: Maybe<Scalars['String']>
};

export type SelfServiceFilesArgs = {
  id?: Maybe<Array<Maybe<Scalars['ID']>>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  sort?: Maybe<Array<Maybe<SortInput>>>
  where?: Maybe<Array<Maybe<WhereClause>>>
};

export type SelfServiceOtpCodesArgs = {
  id?: Maybe<Array<Maybe<Scalars['ID']>>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  sort?: Maybe<Array<Maybe<SortInput>>>
  where?: Maybe<Array<Maybe<WhereClause>>>
};

export type SelfServiceInput = {
  Files?: Maybe<Array<Maybe<FileInput>>>
  Item?: Maybe<ItemInput>
  ItemId?: Maybe<Scalars['Int']>
  OTPCodes?: Maybe<Array<Maybe<OtpCodeInput>>>
  Owner?: Maybe<UserInput>
  createdAt?: Maybe<Scalars['String']>
  date?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['ID']>
  payload?: Maybe<Scalars['JSON']>
  status?: Maybe<SelfServiceStatus>
  type?: Maybe<SelfServiceType>
  updatedAt?: Maybe<Scalars['String']>
};

export enum SelfServiceStatus {
  Completed = 'completed',
  Drafted = 'drafted',
  Pending = 'pending'
}

export enum SelfServiceType {
  ContractSwitchTransfer = 'contract_switch_transfer',
  ContractTakeover = 'contract_takeover',
  MeterReading = 'meter_reading',
  OfficeDocumentation = 'office_documentation',
  TariffRenewal = 'tariff_renewal'
}

export type Service = {
  __typename?: 'Service'
  Events?: Maybe<Array<Maybe<Event>>>
  Owner?: Maybe<User>
  OwnerId?: Maybe<Scalars['Int']>
  StyleConfiguration?: Maybe<StyleConfiguration>
  StyleConfigurationId?: Maybe<Scalars['String']>
  User?: Maybe<User>
  UserId?: Maybe<Scalars['Int']>
  createdAt?: Maybe<Scalars['String']>
  currentEvent?: Maybe<MaterializedEvent>
  endDate?: Maybe<Scalars['String']>
  enrichedStatus?: Maybe<Scalars['String']>
  externalReference?: Maybe<Scalars['JSON']>
  id?: Maybe<Scalars['ID']>
  lastEvent?: Maybe<MaterializedEvent>
  name?: Maybe<Scalars['String']>
  reference?: Maybe<ServiceReference>
  serviceStats?: Maybe<ServiceStats>
  source?: Maybe<ServiceSource>
  startDate?: Maybe<Scalars['String']>
  status?: Maybe<ServiceStatus>
  updatedAt?: Maybe<Scalars['String']>
};

export type ServiceEventsArgs = {
  id?: Maybe<Array<Maybe<Scalars['ID']>>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  sort?: Maybe<Array<Maybe<SortInput>>>
  where?: Maybe<Array<Maybe<WhereClause>>>
};

export type ServiceInput = {
  Events?: Maybe<Array<Maybe<EventInput>>>
  Owner?: Maybe<UserInput>
  StyleConfiguration?: Maybe<StyleConfigurationInput>
  StyleConfigurationId?: Maybe<Scalars['String']>
  User?: Maybe<UserInput>
  UserId?: Maybe<Scalars['Int']>
  createdAt?: Maybe<Scalars['String']>
  externalReference?: Maybe<Scalars['JSON']>
  id?: Maybe<Scalars['ID']>
  name?: Maybe<Scalars['String']>
  source?: Maybe<ServiceSource>
  status?: Maybe<ServiceStatus>
  updatedAt?: Maybe<Scalars['String']>
};

export type ServiceReference = Contract;

export enum ServiceSource {
  Tate = 'tate',
  User = 'user'
}

export type ServiceStats = {
  __typename?: 'ServiceStats'
  amount?: Maybe<Scalars['Int']>
  count?: Maybe<Scalars['Int']>
};

export enum ServiceStatus {
  Disabled = 'disabled',
  Enabled = 'enabled'
}

export enum SocialSecurityNumberStatus {
  ExistOtherAccount = 'exist_other_account',
  ExistSameAccount = 'exist_same_account',
  Ok = 'ok'
}

export type SortInput = {
  field: Scalars['String']
  order?: Maybe<Scalars['String']>
};

export enum SortOrder {
  Asc = 'ASC',
  Desc = 'DESC'
}

export type Story = {
  __typename?: 'Story'
  category?: Maybe<StoryCategory>
  createdAt?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['ID']>
  intercomId?: Maybe<Scalars['String']>
  title?: Maybe<Scalars['String']>
  updatedAt?: Maybe<Scalars['String']>
};

export enum StoryCategory {
  EnergyGas = 'energyGas',
  Innovation = 'innovation',
  News = 'news',
  Sustainability = 'sustainability',
  Wiki = 'wiki'
}

export type StoryInput = {
  category?: Maybe<StoryCategory>
  createdAt?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['ID']>
  intercomId?: Maybe<Scalars['String']>
  title?: Maybe<Scalars['String']>
  updatedAt?: Maybe<Scalars['String']>
};

export type StyleConfiguration = {
  __typename?: 'StyleConfiguration'
  Category?: Maybe<Category>
  Events?: Maybe<Array<Maybe<Event>>>
  Services?: Maybe<Array<Maybe<Service>>>
  Template?: Maybe<Template>
  color?: Maybe<Scalars['String']>
  createdAt?: Maybe<Scalars['String']>
  icon?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['ID']>
  signedPutObjectUrl?: Maybe<Scalars['JSON']>
  updatedAt?: Maybe<Scalars['String']>
};

export type StyleConfigurationEventsArgs = {
  id?: Maybe<Array<Maybe<Scalars['ID']>>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  sort?: Maybe<Array<Maybe<SortInput>>>
  where?: Maybe<Array<Maybe<WhereClause>>>
};

export type StyleConfigurationServicesArgs = {
  id?: Maybe<Array<Maybe<Scalars['ID']>>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  sort?: Maybe<Array<Maybe<SortInput>>>
  where?: Maybe<Array<Maybe<WhereClause>>>
};

export type StyleConfigurationSignedPutObjectUrlArgs = {
  contentType: Scalars['String']
  extension: Scalars['String']
};

export type StyleConfigurationInput = {
  Category?: Maybe<CategoryInput>
  Events?: Maybe<Array<Maybe<EventInput>>>
  Services?: Maybe<Array<Maybe<ServiceInput>>>
  Template?: Maybe<TemplateInput>
  color?: Maybe<Scalars['String']>
  createdAt?: Maybe<Scalars['String']>
  icon?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['ID']>
  updatedAt?: Maybe<Scalars['String']>
};

export type SubStatus = {
  __typename?: 'SubStatus'
  Contract?: Maybe<Contract>
  ContractId?: Maybe<Scalars['Int']>
  Owner?: Maybe<User>
  OwnerId?: Maybe<Scalars['Int']>
  completed?: Maybe<Scalars['Boolean']>
  completedAt?: Maybe<Scalars['String']>
  createdAt?: Maybe<Scalars['String']>
  creationDate?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['ID']>
  name?: Maybe<Scalars['String']>
  note?: Maybe<Scalars['String']>
  updatedAt?: Maybe<Scalars['String']>
};

export type SubStatusInput = {
  Contract?: Maybe<ContractInput>
  ContractId?: Maybe<Scalars['Int']>
  Owner?: Maybe<UserInput>
  completed?: Maybe<Scalars['Boolean']>
  completedAt?: Maybe<Scalars['String']>
  createdAt?: Maybe<Scalars['String']>
  creationDate?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['ID']>
  name?: Maybe<Scalars['String']>
  note?: Maybe<Scalars['String']>
  updatedAt?: Maybe<Scalars['String']>
};

export type SuggestionData = {
  __typename?: 'SuggestionData'
  comparisonData?: Maybe<Array<Maybe<TariffComparisonData>>>
  suggestionType: TariffSuggestionType
  totalSavedAmount?: Maybe<Scalars['Float']>
};

export type TariffChangeInProgressPayload = {
  __typename?: 'TariffChangeInProgressPayload'
  name?: Maybe<Scalars['String']>
  selfServiceDeletionAllowed?: Maybe<Scalars['Boolean']>
  selfServiceId?: Maybe<Scalars['ID']>
  startDate?: Maybe<Scalars['String']>
  variant?: Maybe<TariffChangeInProgressVariant>
};

export enum TariffChangeInProgressVariant {
  Assigned = 'assigned',
  Selected = 'selected'
}

export enum TariffChangeStatusVariants {
  Completed = 'completed',
  Pending = 'pending',
  PendingForced = 'pendingForced'
}

export type TariffComparisonData = {
  __typename?: 'TariffComparisonData'
  comparedTariffDiscount?: Maybe<Scalars['Float']>
  comparedTariffPrice: Scalars['Int']
  currentTariffPrice: Scalars['Int']
  month: Scalars['String']
};

export type TariffData = {
  __typename?: 'TariffData'
  codeName: Scalars['String']
  fixedPrice?: Maybe<Scalars['Float']>
  name: Scalars['String']
  pricingStrategy: PricePricingStrategy
  spread?: Maybe<TariffElement>
  tateFee: TariffElement
};

export type TariffElement = {
  __typename?: 'TariffElement'
  referenceUnit?: Maybe<ReferenceUnit>
  value?: Maybe<Scalars['Float']>
};

export type TariffEvent = {
  __typename?: 'TariffEvent'
  renewalSourceType?: Maybe<Scalars['String']>
  signatureDate?: Maybe<Scalars['String']>
  startDate?: Maybe<Scalars['String']>
  tariffType?: Maybe<Scalars['String']>
  type?: Maybe<Scalars['String']>
};

export type TariffHistory = {
  __typename?: 'TariffHistory'
  endDate?: Maybe<Scalars['String']>
  signatureDate?: Maybe<Scalars['String']>
  startDate?: Maybe<Scalars['String']>
  tariff?: Maybe<Scalars['String']>
  type?: Maybe<TariffHistoryEventType>
};

export enum TariffHistoryEventType {
  Assigned = 'assigned',
  Extended = 'extended',
  First = 'first',
  Selected = 'selected'
}

export type TariffInfo = {
  __typename?: 'TariffInfo'
  codeName: Scalars['String']
  description?: Maybe<Scalars['String']>
  energyExtras?: Maybe<EnergyTariffExtras>
  fixedPrice?: Maybe<Scalars['Float']>
  gasExtras?: Maybe<GasTariffExtras>
  name: Scalars['String']
  pricingStrategy: PricePricingStrategy
  spread?: Maybe<TariffElement>
  tateFee?: Maybe<TariffElement>
};

export enum TariffStatus {
  Allowed = 'allowed',
  BlockedBusiness = 'blocked_business',
  BlockedDisabled = 'blocked_disabled',
  BlockedFixedPrice = 'blocked_fixed_price',
  BlockedNoInvoice = 'blocked_no_invoice',
  BlockedPaymentMethodProblems = 'blocked_payment_method_problems',
  BlockedPendingChange = 'blocked_pending_change',
  BlockedTooManyChanges = 'blocked_too_many_changes',
  BlockedTransferInProgress = 'blocked_transfer_in_progress',
  BlockedTransferred = 'blocked_transferred',
  BlockedUnpaidInvoices = 'blocked_unpaid_invoices'
}

export type TariffStatusPayload = {
  __typename?: 'TariffStatusPayload'
  blockedPayload?: Maybe<BlockedPayload>
  selectNewTariffPayload?: Maybe<SelectNewTariffPayload>
  tariffChangeInProgressPayload?: Maybe<TariffChangeInProgressPayload>
  type?: Maybe<TariffStatusPayloadType>
};

export enum TariffStatusPayloadType {
  Blocked = 'blocked',
  NoAvailableTariffs = 'no_available_tariffs',
  SelectNewTariff = 'select_new_tariff',
  TariffChangeInProgress = 'tariff_change_in_progress'
}

export enum TariffStatusPayloadVersion {
  V1 = 'v1',
  V2 = 'v2'
}

export enum TariffSuggestionType {
  Best = 'best',
  Normal = 'normal',
  NotSuggested = 'not_suggested',
  Special = 'special'
}

export enum TariffsStatusDataError {
  Blocked = 'Blocked',
  ContractNotFound = 'ContractNotFound',
  NoAvailableTariffs = 'NoAvailableTariffs'
}

export type TariffsStatusDataErrorPayload = {
  __typename?: 'TariffsStatusDataErrorPayload'
  limit?: Maybe<Scalars['Int']>
  months?: Maybe<Scalars['Int']>
  tariffStartDate?: Maybe<Scalars['String']>
};

export type TariffsStatusDataResponse = {
  __typename?: 'TariffsStatusDataResponse'
  availableTariffs?: Maybe<Array<Maybe<AvailableTariff>>>
  currentTariff?: Maybe<Scalars['String']>
  error?: Maybe<TariffsStatusDataError>
  errorPayload?: Maybe<TariffsStatusDataErrorPayload>
  isForcedTariffChange?: Maybe<Scalars['Boolean']>
  showComparison?: Maybe<Scalars['Boolean']>
  tariffStatus?: Maybe<TariffStatus>
};

export type Task = {
  __typename?: 'Task'
  User?: Maybe<User>
  UserId?: Maybe<Scalars['Int']>
  artifacts?: Maybe<Scalars['String']>
  artifactsUrl?: Maybe<Scalars['String']>
  createdAt?: Maybe<Scalars['String']>
  domain?: Maybe<TaskDomain>
  id?: Maybe<Scalars['ID']>
  params?: Maybe<Scalars['JSON']>
  results?: Maybe<Scalars['JSON']>
  routine?: Maybe<Scalars['String']>
  status?: Maybe<TaskStatus>
  updatedAt?: Maybe<Scalars['String']>
};

export enum TaskDomain {
  Energy = 'energy',
  Mobility = 'mobility'
}

export type TaskInput = {
  User?: Maybe<UserInput>
  UserId?: Maybe<Scalars['Int']>
  artifacts?: Maybe<Scalars['String']>
  artifactsUrl?: Maybe<Scalars['String']>
  createdAt?: Maybe<Scalars['String']>
  domain?: Maybe<TaskDomain>
  id?: Maybe<Scalars['ID']>
  params?: Maybe<Scalars['JSON']>
  results?: Maybe<Scalars['JSON']>
  routine?: Maybe<Scalars['String']>
  status?: Maybe<TaskStatus>
  updatedAt?: Maybe<Scalars['String']>
};

export enum TaskStatus {
  Completed = 'completed',
  Error = 'error',
  Pending = 'pending'
}

export type Template = {
  __typename?: 'Template'
  Category?: Maybe<Category>
  CategoryId?: Maybe<Scalars['String']>
  StyleConfiguration?: Maybe<StyleConfiguration>
  StyleConfigurationId?: Maybe<Scalars['String']>
  amount?: Maybe<Scalars['Int']>
  createdAt?: Maybe<Scalars['String']>
  currency?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['ID']>
  name?: Maybe<Scalars['String']>
  recurrencyModel?: Maybe<Scalars['JSON']>
  recurrent?: Maybe<Scalars['Boolean']>
  status?: Maybe<TemplateStatus>
  updatedAt?: Maybe<Scalars['String']>
};

export type TemplateInput = {
  Category?: Maybe<CategoryInput>
  CategoryId?: Maybe<Scalars['String']>
  StyleConfiguration?: Maybe<StyleConfigurationInput>
  StyleConfigurationId?: Maybe<Scalars['String']>
  amount?: Maybe<Scalars['Int']>
  createdAt?: Maybe<Scalars['String']>
  currency?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['ID']>
  name?: Maybe<Scalars['String']>
  recurrencyModel?: Maybe<Scalars['JSON']>
  recurrent?: Maybe<Scalars['Boolean']>
  status?: Maybe<TemplateStatus>
  updatedAt?: Maybe<Scalars['String']>
};

export enum TemplateStatus {
  Deprecated = 'deprecated',
  Drafted = 'drafted',
  Published = 'published'
}

export enum TimePeriodEnum {
  Afternoon = 'Afternoon',
  Evening = 'Evening',
  Morning = 'Morning'
}

export enum UiVersion {
  V2022_01_21 = 'v2022_01_21',
  V2022_03_22 = 'v2022_03_22'
}

export type User = {
  __typename?: 'User'
  AppUsages?: Maybe<Array<Maybe<AppUsage>>>
  Attributes?: Maybe<Array<Maybe<Attribute>>>
  Audits?: Maybe<Array<Maybe<Audit>>>
  Contacts?: Maybe<Array<Maybe<Contact>>>
  Customers?: Maybe<Array<Maybe<Customer>>>
  Events?: Maybe<Array<Maybe<Event>>>
  HourlyConsumptions?: Maybe<Array<Maybe<HourlyConsumption>>>
  Labels?: Maybe<Array<Maybe<Label>>>
  Notifications?: Maybe<Array<Maybe<Notification>>>
  PaymentMethods?: Maybe<Array<Maybe<PaymentMethod>>>
  Privacies?: Maybe<Array<Maybe<Privacy>>>
  ProductVariants?: Maybe<Array<Maybe<ProductVariant>>>
  ProductVersions?: Maybe<Array<Maybe<ProductVersion>>>
  Products?: Maybe<Array<Maybe<Product>>>
  PromoCode?: Maybe<PromoCode>
  PushTokens?: Maybe<Array<Maybe<PushToken>>>
  Tasks?: Maybe<Array<Maybe<Task>>>
  UserContractSession?: Maybe<UserContractSession>
  UserFeedbacks?: Maybe<Array<Maybe<UserFeedback>>>
  UserIdentities?: Maybe<Array<Maybe<UserIdentity>>>
  anonymous?: Maybe<Scalars['Boolean']>
  createdAt?: Maybe<Scalars['String']>
  demo?: Maybe<Scalars['Boolean']>
  disabled?: Maybe<Scalars['Boolean']>
  email?: Maybe<Scalars['String']>
  featureFlags?: Maybe<UserFeatureFlags>
  firebase_id?: Maybe<Scalars['String']>
  firstName?: Maybe<Scalars['String']>
  fullName?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['ID']>
  intercomHash?: Maybe<Scalars['String']>
  intercomId?: Maybe<Scalars['String']>
  intercomUserId?: Maybe<Scalars['String']>
  intercom_id?: Maybe<Scalars['String']>
  lastName?: Maybe<Scalars['String']>
  linkedPartners?: Maybe<Array<Maybe<LinkedPartner>>>
  phone?: Maybe<Scalars['String']>
  role?: Maybe<UserRole>
  shouldSeeUserFeedbackScreen?: Maybe<Scalars['Boolean']>
  stripe_id?: Maybe<Scalars['String']>
  updatedAt?: Maybe<Scalars['String']>
  username?: Maybe<Scalars['String']>
};

export type UserAppUsagesArgs = {
  id?: Maybe<Array<Maybe<Scalars['ID']>>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  sort?: Maybe<Array<Maybe<SortInput>>>
  where?: Maybe<Array<Maybe<WhereClause>>>
};

export type UserAttributesArgs = {
  id?: Maybe<Array<Maybe<Scalars['ID']>>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  sort?: Maybe<Array<Maybe<SortInput>>>
  where?: Maybe<Array<Maybe<WhereClause>>>
};

export type UserAuditsArgs = {
  id?: Maybe<Array<Maybe<Scalars['ID']>>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  sort?: Maybe<Array<Maybe<SortInput>>>
  where?: Maybe<Array<Maybe<WhereClause>>>
};

export type UserContactsArgs = {
  id?: Maybe<Array<Maybe<Scalars['ID']>>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  sort?: Maybe<Array<Maybe<SortInput>>>
  where?: Maybe<Array<Maybe<WhereClause>>>
};

export type UserCustomersArgs = {
  id?: Maybe<Array<Maybe<Scalars['ID']>>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  sort?: Maybe<Array<Maybe<SortInput>>>
  where?: Maybe<Array<Maybe<WhereClause>>>
};

export type UserEventsArgs = {
  id?: Maybe<Array<Maybe<Scalars['ID']>>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  sort?: Maybe<Array<Maybe<SortInput>>>
  where?: Maybe<Array<Maybe<WhereClause>>>
};

export type UserHourlyConsumptionsArgs = {
  id?: Maybe<Array<Maybe<Scalars['ID']>>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  sort?: Maybe<Array<Maybe<SortInput>>>
  where?: Maybe<Array<Maybe<WhereClause>>>
};

export type UserLabelsArgs = {
  id?: Maybe<Array<Maybe<Scalars['ID']>>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  sort?: Maybe<Array<Maybe<SortInput>>>
  where?: Maybe<Array<Maybe<WhereClause>>>
};

export type UserNotificationsArgs = {
  id?: Maybe<Array<Maybe<Scalars['ID']>>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  sort?: Maybe<Array<Maybe<SortInput>>>
  where?: Maybe<Array<Maybe<WhereClause>>>
};

export type UserPaymentMethodsArgs = {
  id?: Maybe<Array<Maybe<Scalars['ID']>>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  sort?: Maybe<Array<Maybe<SortInput>>>
  where?: Maybe<Array<Maybe<WhereClause>>>
};

export type UserPrivaciesArgs = {
  id?: Maybe<Array<Maybe<Scalars['ID']>>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  sort?: Maybe<Array<Maybe<SortInput>>>
  where?: Maybe<Array<Maybe<WhereClause>>>
};

export type UserProductVariantsArgs = {
  id?: Maybe<Array<Maybe<Scalars['ID']>>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  sort?: Maybe<Array<Maybe<SortInput>>>
  where?: Maybe<Array<Maybe<WhereClause>>>
};

export type UserProductVersionsArgs = {
  id?: Maybe<Array<Maybe<Scalars['ID']>>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  sort?: Maybe<Array<Maybe<SortInput>>>
  where?: Maybe<Array<Maybe<WhereClause>>>
};

export type UserProductsArgs = {
  id?: Maybe<Array<Maybe<Scalars['ID']>>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  sort?: Maybe<Array<Maybe<SortInput>>>
  where?: Maybe<Array<Maybe<WhereClause>>>
};

export type UserPushTokensArgs = {
  id?: Maybe<Array<Maybe<Scalars['ID']>>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  sort?: Maybe<Array<Maybe<SortInput>>>
  where?: Maybe<Array<Maybe<WhereClause>>>
};

export type UserTasksArgs = {
  id?: Maybe<Array<Maybe<Scalars['ID']>>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  sort?: Maybe<Array<Maybe<SortInput>>>
  where?: Maybe<Array<Maybe<WhereClause>>>
};

export type UserUserFeedbacksArgs = {
  id?: Maybe<Array<Maybe<Scalars['ID']>>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  sort?: Maybe<Array<Maybe<SortInput>>>
  where?: Maybe<Array<Maybe<WhereClause>>>
};

export type UserUserIdentitiesArgs = {
  id?: Maybe<Array<Maybe<Scalars['ID']>>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  sort?: Maybe<Array<Maybe<SortInput>>>
  where?: Maybe<Array<Maybe<WhereClause>>>
};

export type UserContractSession = {
  __typename?: 'UserContractSession'
  Owner?: Maybe<User>
  OwnerId?: Maybe<Scalars['Int']>
  contractType?: Maybe<UserContractSessionContractType>
  createdAt?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['ID']>
  owner_id?: Maybe<Scalars['Int']>
  payload?: Maybe<Scalars['JSON']>
  tariffCodename?: Maybe<Scalars['String']>
  updatedAt?: Maybe<Scalars['String']>
};

export enum UserContractSessionContractType {
  Ee = 'ee',
  Gas = 'gas'
}

export type UserContractSessionInput = {
  Owner?: Maybe<UserInput>
  contractType?: Maybe<UserContractSessionContractType>
  createdAt?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['ID']>
  owner_id?: Maybe<Scalars['Int']>
  payload?: Maybe<Scalars['JSON']>
  tariffCodename?: Maybe<Scalars['String']>
  updatedAt?: Maybe<Scalars['String']>
};

export type UserFeatureFlags = {
  __typename?: 'UserFeatureFlags'
  canAccessDeveloperFeatures?: Maybe<Scalars['Boolean']>
  hourlyPunEnabled?: Maybe<Scalars['Boolean']>
};

export type UserFeedback = {
  __typename?: 'UserFeedback'
  Owner?: Maybe<User>
  OwnerId?: Maybe<Scalars['Int']>
  User?: Maybe<User>
  UserId?: Maybe<Scalars['Int']>
  comment?: Maybe<Scalars['String']>
  createdAt?: Maybe<Scalars['String']>
  date?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['ID']>
  stars?: Maybe<Scalars['Int']>
  topics?: Maybe<Array<Maybe<Scalars['String']>>>
  updatedAt?: Maybe<Scalars['String']>
};

export type UserFeedbackInput = {
  Owner?: Maybe<UserInput>
  User?: Maybe<UserInput>
  UserId?: Maybe<Scalars['Int']>
  comment?: Maybe<Scalars['String']>
  createdAt?: Maybe<Scalars['String']>
  date?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['ID']>
  stars?: Maybe<Scalars['Int']>
  topics?: Maybe<Array<Maybe<Scalars['String']>>>
  updatedAt?: Maybe<Scalars['String']>
};

export type UserIdentity = {
  __typename?: 'UserIdentity'
  Owner?: Maybe<User>
  OwnerId?: Maybe<Scalars['Int']>
  User?: Maybe<User>
  UserId?: Maybe<Scalars['Int']>
  context?: Maybe<Scalars['JSON']>
  createdAt?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['ID']>
  owner_id?: Maybe<Scalars['Int']>
  reference?: Maybe<Scalars['String']>
  type?: Maybe<UserIdentityType>
  updatedAt?: Maybe<Scalars['String']>
};

export type UserIdentityInput = {
  Owner?: Maybe<UserInput>
  User?: Maybe<UserInput>
  UserId?: Maybe<Scalars['Int']>
  context?: Maybe<Scalars['JSON']>
  createdAt?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['ID']>
  owner_id?: Maybe<Scalars['Int']>
  reference?: Maybe<Scalars['String']>
  type?: Maybe<UserIdentityType>
  updatedAt?: Maybe<Scalars['String']>
};

export enum UserIdentityType {
  Firebase = 'firebase',
  Hon = 'hon',
  HubjectDesfireUid = 'hubject_desfire_uid',
  HubjectEvcoId = 'hubject_evco_id',
  HubjectMifareUid = 'hubject_mifare_uid',
  Intercom = 'intercom',
  Stripe = 'stripe'
}

export type UserInput = {
  AppUsages?: Maybe<Array<Maybe<AppUsageInput>>>
  Attributes?: Maybe<Array<Maybe<AttributeInput>>>
  Audits?: Maybe<Array<Maybe<AuditInput>>>
  Contacts?: Maybe<Array<Maybe<ContactInput>>>
  Customers?: Maybe<Array<Maybe<CustomerInput>>>
  Events?: Maybe<Array<Maybe<EventInput>>>
  HourlyConsumptions?: Maybe<Array<Maybe<HourlyConsumptionInput>>>
  Labels?: Maybe<Array<Maybe<LabelInput>>>
  Notifications?: Maybe<Array<Maybe<NotificationInput>>>
  PaymentMethods?: Maybe<Array<Maybe<PaymentMethodInput>>>
  Privacies?: Maybe<Array<Maybe<PrivacyInput>>>
  ProductVariants?: Maybe<Array<Maybe<ProductVariantInput>>>
  ProductVersions?: Maybe<Array<Maybe<ProductVersionInput>>>
  Products?: Maybe<Array<Maybe<ProductInput>>>
  PromoCode?: Maybe<PromoCodeInput>
  PushTokens?: Maybe<Array<Maybe<PushTokenInput>>>
  Tasks?: Maybe<Array<Maybe<TaskInput>>>
  UserContractSession?: Maybe<UserContractSessionInput>
  UserFeedbacks?: Maybe<Array<Maybe<UserFeedbackInput>>>
  UserIdentities?: Maybe<Array<Maybe<UserIdentityInput>>>
  anonymous?: Maybe<Scalars['Boolean']>
  createdAt?: Maybe<Scalars['String']>
  demo?: Maybe<Scalars['Boolean']>
  disabled?: Maybe<Scalars['Boolean']>
  email?: Maybe<Scalars['String']>
  firebase_id?: Maybe<Scalars['String']>
  firstName?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['ID']>
  intercom_id?: Maybe<Scalars['String']>
  lastName?: Maybe<Scalars['String']>
  phone?: Maybe<Scalars['String']>
  role?: Maybe<UserRole>
  stripe_id?: Maybe<Scalars['String']>
  updatedAt?: Maybe<Scalars['String']>
  username?: Maybe<Scalars['String']>
};

export enum UserRole {
  Admin = 'admin',
  User = 'user'
}

export type ValidSocialSecurityNumberResponse = {
  __typename?: 'ValidSocialSecurityNumberResponse'
  status: SocialSecurityNumberStatus
};

export type WhereClause = {
  field: Scalars['String']
  operator: WhereOperator
  value?: Maybe<Scalars['String']>
  values?: Maybe<Array<Maybe<Scalars['String']>>>
};

export enum WhereOperator {
  Equal = 'EQUAL',
  GreaterThan = 'GREATER_THAN',
  GreaterThanEqual = 'GREATER_THAN_EQUAL',
  Ilike = 'ILIKE',
  In = 'IN',
  IsNotNull = 'IS_NOT_NULL',
  IsNull = 'IS_NULL',
  LessThan = 'LESS_THAN',
  LessThanEqual = 'LESS_THAN_EQUAL',
  Like = 'LIKE',
  NotEqual = 'NOT_EQUAL',
  NotIn = 'NOT_IN'
}

export enum Widget {
  AcceptInstallmentsPlan = 'AcceptInstallmentsPlan',
  AddPaymentMethod = 'AddPaymentMethod',
  AuthenticateInvoiceCharge = 'AuthenticateInvoiceCharge',
  AuthenticatePaymentMethod = 'AuthenticatePaymentMethod',
  AvailableUpdate = 'AvailableUpdate',
  BannedReferral = 'BannedReferral',
  Co2Savings = 'CO2Savings',
  Canone = 'Canone',
  ContractActivationStatus = 'ContractActivationStatus',
  ContractDetached = 'ContractDetached',
  ContractProgression = 'ContractProgression',
  ContractRejected = 'ContractRejected',
  DisabledContract = 'DisabledContract',
  DiscoverTate = 'DiscoverTate',
  DraftedContract = 'DraftedContract',
  FormalNotice = 'FormalNotice',
  HonLinking = 'HonLinking',
  HourlyCoefficients = 'HourlyCoefficients',
  InstallmentsPlanProgression = 'InstallmentsPlanProgression',
  InvoicesNotPaid = 'InvoicesNotPaid',
  LastRecurrentServices = 'LastRecurrentServices',
  MarketingHourlyPunMarketingInfo = 'Marketing_HourlyPunMarketingInfo',
  MeterReading = 'MeterReading',
  MissingConsumptions = 'MissingConsumptions',
  NewInvoice = 'NewInvoice',
  OpenInvoices = 'OpenInvoices',
  ReferralInfo = 'ReferralInfo',
  ReferralNewContract = 'ReferralNewContract',
  RequestChangeTariff = 'RequestChangeTariff',
  Stories = 'Stories',
  TariffChangeStatus = 'TariffChangeStatus',
  TariffRenewal = 'TariffRenewal',
  TempRequestChangeTariff = 'TempRequestChangeTariff',
  TimeSlot = 'TimeSlot',
  UnreadMessages = 'UnreadMessages',
  UpdateCadastral = 'UpdateCadastral'
}

export enum WidgetBorderStyle {
  Contract = 'Contract',
  Danger = 'Danger',
  Warning = 'Warning'
}

export type WidgetConfiguration = {
  __typename?: 'WidgetConfiguration'
  addendum?: Maybe<Addendum>
  extra?: Maybe<Scalars['JSON']>
  payload?: Maybe<Scalars['JSON']>
  type?: Maybe<Widget>
  widgetStyle?: Maybe<WidgetStyle>
};

export enum WidgetPriorityType {
  Decreasing = 'decreasing',
  Fixed = 'fixed',
  Increasing = 'increasing'
}

export type WidgetStyle = {
  __typename?: 'WidgetStyle'
  borderStyle?: Maybe<WidgetBorderStyle>
  contractType?: Maybe<ContractType>
};

export type UpdatePromoCodeResponse = {
  __typename?: 'updatePromoCodeResponse'
  promoCode?: Maybe<PromoCode>
  status?: Maybe<UpdatePromoCodeStatus>
};

export enum UpdatePromoCodeStatus {
  AlreadyExists = 'AlreadyExists',
  ContainsProfanity = 'ContainsProfanity',
  Ok = 'Ok'
}
