import { ArrowLink } from '../arrowLink'
import { Card, Icon, InvoiceTable, Spacer } from '../../components'
import { Colors, darkMint, marigold, uaRed, violetBlue } from '../../style/colors'
import { Contract, ContractStatus, InvoiceStatus } from '../../lib/apollo/types'
import { ItemLayout } from '../../layouts/item'
import { Layout, Spacing } from '../../style/spacing'
import { Screens } from '../../main/types'
import { Sizes } from '../../style/sizes'
import { TextWithStyle } from '../../components/textWithStyle'
import { Timeline } from '../../components/timeline'
import { Typography } from '../../style/typography'
import { css } from '@emotion/react'
import { toCurrency, translate } from '../../lib/localization'
import React, { useCallback } from 'react'
import dayjs from '../../lib/dayJs'
import radioOff from '../../images/icons/radio-button-on.svg'

export const STATUS_COLOR_MAP = {
  [ContractStatus.Active]: darkMint[500],
  [ContractStatus.Disabled]: uaRed[500],
  [ContractStatus.Pending]: violetBlue[500]
}

export interface WidgetStateContractProps {
  contract: Contract
  /* Personalized CSS name for custom styling */
  className?: string
  /* true if the contract has been disabled before been supplied */
  neverSuppliedContract: boolean
}

export const WidgetStateContract: React.FC<WidgetStateContractProps> = ({
  className,
  contract,
  neverSuppliedContract
}) => {
  const invoices = contract?.Invoices ?? []
  const status = contract?.status
  const startDate = contract?.start_date
  const endDate = contract?.end_date
  const paymentMethod = contract?.PaymentMethod
  const unpayedInvoice = invoices.find(invoice => invoice.status === InvoiceStatus.NotPayed || (invoice.status !== InvoiceStatus.Payed && dayjs().isAfter(invoice.dueDate)))
  const draftedOrOpenInvoice = invoices.find(invoice => invoice.status === InvoiceStatus.Drafted || invoice.status === InvoiceStatus.Open)

  const getPaymentStatus = useCallback(() => {
    if (unpayedInvoice) {
      return <TextWithStyle css={[Typography.paragraph.p3_400, { color: uaRed['500'] }]} text={translate('invoices_screen__unpaid_bill__title', { invoiceId: unpayedInvoice.enerp_ref })} />
    }
    if (draftedOrOpenInvoice && draftedOrOpenInvoice.amount >= 5) {
      return <TextWithStyle css={[Typography.paragraph.p3_400, { color: marigold['500'] }]} text={translate('invoices_screen__drafted_bill__title', { amount: toCurrency(draftedOrOpenInvoice.amount), invoiceId: draftedOrOpenInvoice.enerp_ref })} />
    }
    return <TextWithStyle css={[Typography.paragraph.p3_400, css`strong:last-of-type{ color: ${darkMint['500']}}`]} text={translate('invoices_screen__paid_bill__title')} />
  }, [draftedOrOpenInvoice, unpayedInvoice])

  const isContractTransferred = status === ContractStatus.Active && dayjs(endDate).isBefore(dayjs.utc(), 'day')
  const contractStatus = isContractTransferred ? ContractStatus.Disabled : status
  return (
    <div css={[Layout.flex_1]} className={className}>
      <Card css={{ height: '100%' }}>
        <div
          css={[Typography.label.label4_600, Colors.Color.eerieBlack500, Typography.fonts.upperCase]}>{translate('state_contract_widget__title')}</div>
        <Spacer height={12}/>
        <ItemLayout
          leftElement={<Icon src={radioOff} size={Sizes.xsmall} color={STATUS_COLOR_MAP[contractStatus]}
            css={Spacing.Padding.right_6}/>}
          firstTextRow={<div
            css={[Typography.label.label4_600, Typography.fonts.upperCase]}>{translate(neverSuppliedContract ? 'state_contract_widget__state--never_supplied' : `state_contract_widget__state--${contractStatus}`)}</div>}
        />
        {status === ContractStatus.Active && !isContractTransferred &&
        <TextWithStyle text={translate('state_contract_widget__starting_date_info', { date: dayjs(startDate).format('DD/MM/YYYY') })} css={Typography.paragraph.p3_400}/>}
        {isContractTransferred &&
        <TextWithStyle text={translate('state_contract_widget__end_date_info', { date: dayjs(endDate).format('DD/MM/YYYY') })} css={Typography.paragraph.p3_400}/>}
        {status === ContractStatus.Pending &&
        <TextWithStyle text={translate('state_contract_widget__pending_info', { date: dayjs(startDate).format('DD/MM/YYYY') })} css={Typography.paragraph.p3_400}/>}
        {status === ContractStatus.Disabled && !neverSuppliedContract &&
        <TextWithStyle text={translate('state_contract_widget__end_date_info', { date: dayjs(endDate).format('DD/MM/YYYY') })} css={Typography.paragraph.p3_400}/>}
        {status === ContractStatus.Disabled && neverSuppliedContract &&
        <div css={Typography.paragraph.p3_400}>{translate('state_contract_widget__never_supplied_info')}</div>}
        <Spacer height={16}/>
        <div css={Layout.border}/>
        <Spacer height={16}/>
        {/* TODO: Manage Drafted contracts */}
        {status === ContractStatus.Pending && <Timeline contract={contract}/>}
        {!neverSuppliedContract && status !== ContractStatus.Pending && <>
          <div
            css={[Typography.label.label4_600, Colors.Color.eerieBlack500, Typography.fonts.upperCase]}>{translate('state_contract_widget__title_bills')}</div>
          <Spacer height={12}/>
          <div
            css={[Typography.label.label4_400, Colors.Color.eerieBlack600]}>{translate('state_contract_widget__state_payments')}</div>
          {getPaymentStatus()}
          <Spacer height={24}/>
          <InvoiceTable invoices={invoices} singleButton paymentMethod={paymentMethod}/>
          <Spacer height={24}/>
          <ArrowLink title={translate('state_contract_widget__go_to_bills')} navigationProps={{ params: { contractId: contract.id }, path: Screens.Consumi }}/>
        </>}
      </Card>
    </div>
  )
}
