
import { Colors } from '../../style/colors'
import { Typography } from '../../style/typography'
import { css } from '@emotion/react'

export const nameSurnameStyles = css`
  background-color: transparent;
  border-radius: 8px;
  transition: background-color .5s;
  ${Typography.Style.pointer};
  margin-right: 6px;
  margin-left: -6px;

  :hover{
    ${Colors.BackgroundColor.eerieBlack100};
    transition: background-color .5s;
  }

  .container {
      padding: 6px;
  }
`
