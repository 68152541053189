import { __DEV__, apiHostname, magicLinkPublicKey } from '../configuration/env'

export type MagicLinkOptions = {
  version: 'v2'
  useLocalLoginLink?: boolean
}
const DEFAULT_OPTIONS: MagicLinkOptions = __DEV__ ? { useLocalLoginLink: true, version: 'v2' } : { version: 'v2' }

export enum MagicLinkError {
  MissingKey = 'missing-key',
  MagicLinkRequestFailed = 'magic-link-request-failed'
}

export enum AuthenticationError {
  ExpiredToken = 'expired',
  Generic = 'generic',
  MissingKey = 'missing_key'
}

export const DEVICE_ID_KEY = 'device_id'

// RSA configuration constants
const PRIVATE_KEY = 'jwk_aes'
const IV_KEY = 'iv'

const FORMAT = 'jwk'
const ALGORITHM_CONFIG = {
  length: 256,
  name: 'AES-GCM'
}
const RSA_ALGORITHM_CONFIG = {
  hash: 'SHA-256',
  modulusLength: 4096,
  name: 'RSA-OAEP',
  publicExponent: new Uint8Array([1, 0, 1])
}

export type RequestMagicLinkParams = {
  email: string
  strategy?: 'signIn'
  target: 'dashboard'| 'business'
  redirectUrl?: string
}

export const requestMagicLink = async ({ email, strategy = 'signIn', target, redirectUrl }: RequestMagicLinkParams) => {
  // generate symmetric key
  const aesKey = await window.crypto.subtle.generateKey(
    ALGORITHM_CONFIG,
    true, // Se la chiave è esportabile
    ['encrypt', 'decrypt'] // Usi permessi
  )

  let deviceId = localStorage.getItem(DEVICE_ID_KEY)
  if (!deviceId) {
    deviceId = window.btoa(window.crypto.getRandomValues(new Uint8Array(4)).toString())
    localStorage.setItem(DEVICE_ID_KEY, deviceId)
  }

  // encrypt symmetric key with public key
  const publicKey = await window.crypto.subtle.importKey(FORMAT, magicLinkPublicKey, RSA_ALGORITHM_CONFIG, true, ['encrypt', 'wrapKey'])
  const aesEncrypted = await window.crypto.subtle.wrapKey(FORMAT, aesKey, publicKey, RSA_ALGORITHM_CONFIG)

  const response = await fetch(`${apiHostname}/send-magic-link`, {
    body: JSON.stringify({
      deviceId,
      email,
      encryptedKey: Array.from(new Uint8Array(aesEncrypted)),
      options: { ...DEFAULT_OPTIONS, redirectUrl, target },
      strategy
    }),
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    method: 'post'
  })

  if (!response.ok) {
    throw new Error(MagicLinkError.MagicLinkRequestFailed)
  }

  // save private key in local storage
  const { iv } = await response.json()
  localStorage.setItem(IV_KEY, JSON.stringify(iv))
  localStorage.setItem(PRIVATE_KEY, JSON.stringify(await window.crypto.subtle.exportKey(FORMAT, aesKey)))
}

const cleanLocalStorage = () => {
  localStorage.removeItem(PRIVATE_KEY)
  localStorage.removeItem(IV_KEY)
}

export const decryptToken = async (token: number[]) => {
  // import data from local storage
  const storedPrivateKey = localStorage.getItem(PRIVATE_KEY)
  if (!storedPrivateKey) {
    throw new Error(MagicLinkError.MissingKey)
  }
  const privateKey = await window.crypto.subtle.importKey(
    FORMAT,
    JSON.parse(storedPrivateKey),
    ALGORITHM_CONFIG,
    true,
    ['decrypt'])
  const iv = new Uint8Array(JSON.parse(localStorage.getItem(IV_KEY)))

  cleanLocalStorage()

  // try to decrypt token
  const tokenUint = new Uint8Array(token)
  const arrayBufferToken = await window.crypto.subtle.decrypt(
    { ...ALGORITHM_CONFIG, iv },
    privateKey,
    tokenUint
  )
  return new TextDecoder().decode(arrayBufferToken)
}
