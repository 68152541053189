import { Card } from '../../components'
import { Colors } from '../../style/colors'
import { Typography } from '../../style/typography'
import React from 'react'

export const Splash: React.FC = () => {
  return <div css={{ width: '452px' }}>
    <Card>
      <div css={[Typography.heading.h5_700, Colors.Color.eerieBlack700]}>
        Caricamento...
      </div>
    </Card>
  </div>
}
