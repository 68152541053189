import { badgeStyle } from './index.css'
import React from 'react'

export interface BadgeProps {
  className?: string
  title: string
  style: 'success' | 'pending' | 'failed'
  icon?: any
}

export const Badge: React.FC<BadgeProps> = ({
  title,
  className,
  style,
  icon
}) => {
  return (
    <div
      className={className}
      css={badgeStyle}
      data-style={style}
    >
      {icon}
      <div>{title}</div>
    </div>
  )
}
