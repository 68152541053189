import * as Sentry from '@sentry/react'
import { AppRegistry } from 'react-native'
import { Integration } from '@sentry/types'
import { Integrations } from '@sentry/tracing'
import { isStaging, isStagingOrProduction } from './configuration/env'
import RootComponent from './main'
import jsonPackage from '../package.json'

declare global {
  interface Window {
    dataLayer: any[]
    gtag: any
    Intercom: any
  }
}

if (isStagingOrProduction) {
  Sentry.init({
    debug: isStaging,
    dsn: 'https://55dfe0adb27c40d3999583027361fa82@o86188.ingest.sentry.io/5952017"',
    environment: process.env.REACT_APP_STAGE,
    integrations: [
      new Integrations.BrowserTracing() as unknown as Integration
    ],
    release: `${jsonPackage.name}-${jsonPackage.version}--${process.env.REACT_APP_GIT_REV_SHORT}`,
    tracesSampleRate: 1.0
  })
}

AppRegistry.registerComponent('App', () => RootComponent)

AppRegistry.runApplication('App', {
  rootTag: document.getElementById('root')
})
