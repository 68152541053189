import { separatorStyle } from './index.css'
import React from 'react'

export interface SeparatorProps {
  margin?: number
}

export const Separator: React.FC<SeparatorProps> = ({
  margin = 16
}) => {
  return (
    <div css={separatorStyle} style={{ marginBottom: `${margin}px`, marginTop: `${margin}px` }}/>
  )
}
