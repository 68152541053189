import { Typography } from '../../style/typography'
import { css } from '@emotion/react'
import { eerieBlack } from '../../style/colors'

export const headerListCommands = css`
  .headerListContainer{
    display: flex;
    justify-content: space-between;
  }

  .headerListBody{
    display: flex;
  }

  .headerListTitle{
    ${Typography.label.label4_600}
    margin-bottom: 12px;
  }

  .listElement{
    padding: 6px 12px;
    border-radius: 7px;

    &[data-active='true'] {
      ${Typography.label.label1_600}
      background: ${eerieBlack['100']};
      color: ${eerieBlack['800']};
      box-shadow: none;
    }
    &[data-active='false'] {
      ${Typography.label.label1_600}
      color: ${eerieBlack['500']};
      cursor: pointer;
      box-shadow: none;
    }
  }
`
