import { css } from '@emotion/react'

export const Typography = {
  Align: {
    center: css`text-align: center`,
    left: css`text-align: left`,
    right: css`text-align: right`
  },
  Callout: {
    medium: css`
      font-size: 16px;
      line-height: 24px;
      letter-spacing: 0.1px;
    `
  },
  Caption: {
    caption1_400: css`
      font-size: 12px;
      line-height: 18px;
      letter-spacing: 0.1px;
      font-weight: 400;
    `,
    caption1_regular: css`
      font-size: 12px;
      line-height: 18px;
      letter-spacing: 0.1px;
    `
  },
  Footnote: {
    regular: css`
      font-size: 14px;
      line-height: 20px;
      letter-spacing: 0.1px;
    `,
    semibold: css`
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: 0.1px;
    `
  },
  Style: {
    disabled: css`cursor: not-allowed`,
    ellipses: css`
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    `,
    not_allowed: css`cursor: not-allowed`,
    pointer: css`cursor: pointer`,
    underline: css`text-decoration: underline`
  },
  Subhead: {
    regular: css`
      font-size: 15px;
      line-height: 24px;
      letter-spacing: 0.1px;
    `
  },
  Title: {
    bold: css`
      font-size: 24px;
      line-height: 36px;
      font-weight: bold;
    `
  },
  fonts: {
    inter: css`
      font-family: Inter, sans-serif;
      font-style: normal;
    `,
    upperCase: css`
      text-transform: uppercase;
    `
  },
  heading: {
    h1_500: css`
      font-weight: 500;
      font-size: 56px;
      line-height: 120%;

      letter-spacing: -0.9px;
    `,
    h1_700: css`
      font-weight: 700;
      font-size: 56px;
      line-height: 120%;

      letter-spacing: -0.9px;
    `,
    h2_500: css`
      font-weight: 500;
      font-size: 48px;
      line-height: 120%;

      letter-spacing: -0.8px;
    `,
    h2_700: css`
      font-weight: 700;
      font-size: 48px;
      line-height: 120%;

      letter-spacing: -0.8px;
    `,
    h3_500: css`
      font-weight: 500;
      font-size: 40px;
      line-height: 120%;

      letter-spacing: -0.6px;
    `,
    h3_700: css`
      font-weight: 700;
      font-size: 40px;
      line-height: 120%;

      letter-spacing: -0.6px;
    `,
    h4_500: css`
      font-weight: 500;
      font-size: 32px;
      line-height: 130%;

      letter-spacing: -0.6px;
    `,
    h4_700: css`
      font-weight: 700;
      font-size: 32px;
      line-height: 130%;

      letter-spacing: -0.6px;
    `,
    h5_400: css`
      font-weight: 400;
      font-size: 24px;
      line-height: 140%;

      letter-spacing: -0.45px;
    `,
    h5_500: css`
      font-weight: 500;
      font-size: 24px;
      line-height: 140%;

      letter-spacing: -0.45px;
    `,
    h5_600: css`
      font-weight: 600;
      font-size: 24px;
      line-height: 140%;

      letter-spacing: -0.45px;
    `,
    h5_700: css`
      font-weight: 700;
      font-size: 24px;
      line-height: 140%;

      letter-spacing: -0.5px;
    `,
    h6_400: css`
      font-weight: 400;
      font-size: 20px;
      line-height: 150%;

      letter-spacing: -0.35px;
    `,
    h6_500: css`
      font-weight: 500;
      font-size: 20px;
      line-height: 150%;

      letter-spacing: -0.35px;
    `,
    h6_600: css`
      font-weight: 600;
      font-size: 20px;
      line-height: 150%;

      letter-spacing: -0.35px;
    `,
    h6_700: css`
      font-weight: 700;
      font-size: 20px;
      line-height: 150%;

      letter-spacing: -0.35px;
    `
  },
  label: {
    label1_400: css`
      font-weight: 400;
      font-size: 18px;
      line-height: 24px;

      letter-spacing: -0.26px;
    `,
    label1_500: css`
      font-weight: 500;
      font-size: 18px;
      line-height: 24px;

      letter-spacing: -0.26px;
    `,
    label1_600: css`
      font-weight: 600;
      font-size: 18px;
      line-height: 24px;

      letter-spacing: -0.26px;
    `,
    label1_700: css`
      font-weight: 600;
      font-size: 18px;
      line-height: 24px;

      letter-spacing: -0.26px;
    `,
    label2_400: css`
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;

      letter-spacing: -0.18px;
    `,
    label2_500: css`
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;

      letter-spacing: -0.18px;
    `,
    label2_600: css`
      font-weight: 600;
      font-size: 16px;
      line-height: 20px;
      letter-spacing: -0.18px;
    `,
    label2_700: css`
      font-weight: 700;
      font-size: 16px;
      line-height: 20px;
      letter-spacing: -0.18px;
    `,
    label3_400: css`
      font-weight: 400;
      font-size: 14px;
      line-height: 16px;

      letter-spacing: -0.09px;
    `,
    label3_500: css`
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;

      letter-spacing: -0.09px;
    `,
    label3_600: css`
      font-weight: 600;
      font-size: 14px;
      line-height: 16px;

      letter-spacing: -0.09px;
    `,
    label4_400: css`
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;

      letter-spacing: 0.01px;
    `,
    label4_500: css`
      font-weight: 500;
      font-size: 12px;
      line-height: 16px;

      letter-spacing: 0.01px;
    `,
    label4_600: css`
      font-weight: 600;
      font-size: 12px;
      line-height: 16px;

      letter-spacing: 0.01px;
    `,
    label4_700: css`
      font-weight: 700;
      font-size: 12px;
      line-height: 16px;
      letter-spacing: 0.01px;
    `,
    label5_400: css`
      font-weight: 400;
      font-size: 10px;
      line-height: 13px;
      letter-spacing: 0.01px;
    `
  },
  paragraph: {
    p1_400: css`
      font-weight: 400;
      font-size: 18px;
      line-height: 150%;

      letter-spacing: -0.25px;
    `,
    p1_500: css`
      font-weight: 500;
      font-size: 18px;
      line-height: 150%;

      letter-spacing: -0.25px;
    `,
    p1_600: css`
      font-weight: 600;
      font-size: 18px;
      line-height: 150%;

      letter-spacing: -0.25px;
    `,
    p2_400: css`
      font-weight: 400;
      font-size: 16px;
      line-height: 150%;

      letter-spacing: -0.18px;
    `,
    p2_500: css`
      font-weight: 500;
      font-size: 16px;
      line-height: 150%;

      letter-spacing: -0.18px;
    `,
    p2_600: css`
      font-weight: 600;
      font-size: 16px;
      line-height: 150%;

      letter-spacing: -0.18px;
    `,
    p3_400: css`
      font-weight: 400;
      font-size: 14px;
      line-height: 150%;

      letter-spacing: -0.1px;
    `,
    p3_500: css`
      font-weight: 500;
      font-size: 14px;
      line-height: 150%;

      letter-spacing: -0.1px;
    `,
    p3_600: css`
      font-weight: 500;
      font-size: 14px;
      line-height: 150%;

      letter-spacing: -0.1px;
    `,
    p4_400: css`
      font-weight: 400;
      font-size: 12px;
      line-height: 150%;

      letter-spacing: -0.1px;
    `,
    p4_500: css`
      font-weight: 400;
      font-size: 12px;
      line-height: 150%;

      letter-spacing: -0.1px;
    `,
    p4_600: css`
      font-weight: 500;
      font-size: 12px;
      line-height: 150%;

      letter-spacing: -0.1px;
    `
  }
}
