import { css } from '@emotion/react'
import { eerieBlack } from '../../style/colors'

export const style = css`   
   background-color: ${eerieBlack['0']};
   border-radius: 8px;
   position: absolute;
   box-shadow: 0px 1px 2px rgba(28, 28, 28, 0.12), 0px 2px 8px rgba(28, 28, 28, 0.08);
   z-index: 2;
   overflow: hidden;
`
