import { Accordion, Button, Card, Icon, Modal, Spacer } from '../../../components'
import { ArrowLink } from '../../../widgets/arrowLink'
import { Colors, contractAzul, eerieBlack, primaryColor, royalPurple } from '../../../style/colors'
import { ContractType } from '../../../lib/apollo/types'
import {
  GetPaymentMethodsDocument,
  useDeletePaymentMethodMutation,
  useGetPaymentMethodsQuery
} from '../../../lib/apollo/hooks'
import { ItemLayout } from '../../../layouts/item'
import { Layout, Spacing } from '../../../style/spacing'
import { MainScreenLayout } from '../../../layouts/mainScreen'
import { PageLayout } from '../../../layouts/page'
import { Placeholder } from '../../../components/placeholder'
import { Screens } from '../../../main/types'
import { Sizes } from '../../../style/sizes'
import { SnackbarTypes, snackbarDurationDefault, useSnackbar } from '../../../hooks/useSnackbar'
import { Typography } from '../../../style/typography'
import { WidgetReferral } from '../../../widgets/widgetReferral'
import { css } from '@emotion/react'
import { getPaymentMethodItemSubtitle, getPaymentMethodSvgIcon } from '../../../services/paymentMethod'
import { hoverClicker } from '../../../style/responsive'
import { isBusinessUsageTarget, isPrivateUsageTarget } from '../../../configuration/env'
import { logAndSendToErrorTracker } from '../../../services/errorTracker'
import { styleHoverDeleteIcon } from './index.css'
import { translate } from '../../../lib/localization'
import Delete from '../../../images/icons/trash.svg'
import Energy from '../../../images/icons/energy-icon.svg'
import Gas from '../../../images/icons/gas-icon.svg'
import React, { useCallback, useState } from 'react'
import cardIcon from '../../../images/icons/card.svg'
import cardOutline from '../../../images/icons/outline/card.svg'
import forwardIcon from '../../../images/icons/chevron-forward.svg'

const CONTRACT_TYPE_MAP = {
  [ContractType.Gas]: {
    backgroundColor: royalPurple['50'],
    color: royalPurple['500'],
    icon: Gas
  },
  [ContractType.Ee]: {
    backgroundColor: contractAzul['50'],
    color: contractAzul['500'],
    icon: Energy
  }
}

export const placeHolderPaymentMethods = <>
  <div css={[Layout.display_flex, css`padding: 32px 18px 32px 22px`]}>
    <div css={[Layout.flex_1, css`max-width: 48px`]}>
      <Placeholder width={'28px'} height={'28px'} radius={28}/>
    </div>
    <div css={[Layout.flex_1]}>
      <Placeholder width={'30%'} height={'20px'}/>
      <Spacer height={4}/>
      <Placeholder width={'30%'} height={'20px'}/>
    </div>
  </div>
  <div css={[Layout.display_flex, css`padding: 32px 18px 32px 22px`]}>
    <div css={[Layout.flex_1, css`max-width: 48px`]}>
      <Placeholder width={'28px'} height={'28px'} radius={28}/>
    </div>
    <div css={[Layout.flex_1]}>
      <Placeholder width={'30%'} height={'20px'}/>
      <Spacer height={4}/>
      <Placeholder width={'30%'} height={'20px'}/>
    </div>
  </div></>

export const PaymentMethodsScreen = ({ navigation }) => {
  const [showModal, setShowModal] = useState(false)
  const [deletingPaymentMethod, setDeletingPaymentMethod] = useState<string>()
  const [openSnackbar] = useSnackbar()
  const [openSnackbarError] = useSnackbar(SnackbarTypes.error)
  const { data, loading } = useGetPaymentMethodsQuery()
  const [deletePaymentMethodMutation, { loading: deletePaymentMethodLoading }] = useDeletePaymentMethodMutation({
    onCompleted: () => openSnackbar(translate('snackbar_message__removal__payment_method_success'), snackbarDurationDefault),
    onError: error => {
      openSnackbarError(translate('snackbar_message__removal__payment_method_error'), snackbarDurationDefault)
      logAndSendToErrorTracker(error)
    }
  })
  const paymentMethods = data?.PaymentMethods ?? []

  const deletePaymentMethod = useCallback(async (id: string) => {
    await deletePaymentMethodMutation({ refetchQueries: [GetPaymentMethodsDocument], variables: { id } })
    setDeletingPaymentMethod(undefined)
    setShowModal(false)
  }, [deletePaymentMethodMutation])

  const openDeletePaymentMethodModal = useCallback((id: string) => {
    setDeletingPaymentMethod(id)
    setShowModal(true)
  }, [])

  const PrivatePaymentMethodsList = paymentMethods?.map((paymentMethodItem) => (
    <div css={[Spacing.Padding.vertical_24, Layout.border_bottom, hoverClicker]} key={paymentMethodItem.id}>
      <Accordion
        css={[Spacing.Margin.vertical_24, Layout.border_bottom]}
        value={false}
        title={<div css={[Typography.label.label2_500]}>{getPaymentMethodItemSubtitle(paymentMethodItem)}</div>}
        leftElement={<Icon src={getPaymentMethodSvgIcon(paymentMethodItem)} css={[Spacing.Padding.left_4, Spacing.Padding.right_16]} color={primaryColor} size={Sizes.large}/>}
        secondTextRow={<div css={[Typography.Caption.caption1_400, Colors.Color.eerieBlack600]}>{paymentMethodItem?.name}</div>}
        rightElement={ !paymentMethodItem.Contracts.length ? <div css={Layout.display_flex}><div onClick={() => openDeletePaymentMethodModal(paymentMethodItem.id)}><Icon src={Delete} css={[styleHoverDeleteIcon, Typography.Style.pointer]} color={eerieBlack['400']}/></div><div css={Spacing.Margin.horizontal_12}/></div> : undefined}
      >
        <div css={Spacing.Margin.top_16}>
          <div css={[Typography.label.label4_600, Colors.Color.eerieBlack500, Typography.fonts.upperCase]}>{translate('section_title__associated_contracts')}</div>
          {!paymentMethodItem.Contracts.length && <div css={Typography.label.label2_500}>{translate('payment_method_no_contracts')}</div>}
          {paymentMethodItem.Contracts.map((contract) => <ItemLayout
            key={contract.id}
            css={Spacing.Margin.top_12}
            leftElement={<Icon src={CONTRACT_TYPE_MAP[contract.type].icon} css={[Spacing.Padding.left_4, Spacing.Margin.right_12]} color={CONTRACT_TYPE_MAP[contract.type].color} size={Sizes.xsmall} type={'circle'} backgroundColor={CONTRACT_TYPE_MAP[contract.type].backgroundColor}/>}
            firstTextRow={<div css={[Typography.label.label2_500]}>{contract.name}</div>}
            secondTextRow={<div css={[Typography.Caption.caption1_400, Colors.Color.eerieBlack600]}>{contract.OwnerContact?.fullname}</div>}
          />)}
        </div>
      </Accordion>
    </div>
  ))

  const BusinessPaymentMethodsList = paymentMethods?.map((paymentMethodItem) => (
    <>
      <div key={paymentMethodItem.id} css={[Typography.Style.pointer, Spacing.Padding.vertical_8, hoverClicker, Spacing.Margin.top_16, Spacing.Margin.bottom_16]} onClick={() => navigation.navigate(Screens.BusinessPaymentMethod, {
        paymentMethodId: paymentMethodItem.id
      }
      )}>
        <ItemLayout
          firstTextRow={<div css={[Typography.label.label2_500]}>{getPaymentMethodItemSubtitle(paymentMethodItem)}</div>}
          leftElement={<Icon src={getPaymentMethodSvgIcon(paymentMethodItem)} css={[Spacing.Padding.left_4, Spacing.Padding.right_16]} color={primaryColor} size={Sizes.large}/>}
          secondTextRow={<div css={[Typography.Caption.caption1_400, Colors.Color.eerieBlack600]}>{paymentMethodItem?.name}</div>}
          rightElement={
            <div css={Layout.display_flex}>
              <Icon src={forwardIcon} css={[Typography.Style.pointer]} color={eerieBlack['400']}/>
            </div>
          }
        />
      </div>
      <div css={Layout.border_bottom} />
    </>
  ))

  return (
    <PageLayout>
      <MainScreenLayout showToolbox={false} sectionHeaderProps={{
        icon: isBusinessUsageTarget ? cardOutline : cardIcon,
        path: translate('screens_title__account_settings') + ' ' + '/',
        title: translate('screens_title__payment_methods')
      }}>
        <div css={[Layout.display_flex, Layout.ColumnGap.sm]}>
          <Card css={Layout.flex_2}>
            {
              isPrivateUsageTarget && <div css={[Typography.paragraph.p3_400, Colors.Color.eerieBlack600]}>{translate('screens_subtitle__payment_methods')}</div>
            }
            <Spacer height={16} />
            {
              loading && placeHolderPaymentMethods
            }
            { isBusinessUsageTarget ? BusinessPaymentMethodsList : PrivatePaymentMethodsList }
            <Spacer height={24}/>
            <ArrowLink title={translate('link_to__add_payment_method__screen')} navigationProps={{ path: isBusinessUsageTarget ? Screens.BusinessAddPaymentMethod : Screens.AggiungiPagamenti }}/>
          </Card>
          {
            !isBusinessUsageTarget && <WidgetReferral/>
          }
        </div>
        { showModal &&
          <Modal
            title={<div css={Typography.heading.h5_700}>{translate('payment_method_removal_modal__title')}</div>}
            hideModal={() => setShowModal(false)}
            hideBackground={true}
          >
            <div>{translate('payment_method_removal_modal__subtitle')}</div>
            <div css={[Layout.display_flex, Layout.row_reverse, Spacing.Margin.top_16]}>
              <Button
                title={translate('button_delete')}
                style={'alert'}
                onClick={() => deletePaymentMethod(deletingPaymentMethod)}
                size={Sizes.medium}
                loading={deletePaymentMethodLoading}
              />
              <div css={Spacing.Padding.horizontal_8}/>
              <Button
                title={translate('button_cancel')}
                style={'border-only'} onClick={() => setShowModal(false)}
                size={Sizes.medium}
              />
            </div>
          </Modal>
        }
      </MainScreenLayout>
    </PageLayout>
  )
}
