import { Typography } from '../../style/typography'
import { css } from '@emotion/react'
import { darkMint, marigold, uaRed } from '../../style/colors'
import { transitionMedium } from '../constants'

export const badgeStyle = css`
  padding: 5px 12px 5px 5px;
  border-radius: 32px;
  ${Typography.label.label4_500}

  text-align: center;
  display: inline-flex;
  align-items: center;
  transition: background ${transitionMedium} ease-in-out, color ${transitionMedium} ease-in-out;

  div {
    width: 100%;
    white-space: nowrap;
  }

  &[data-style='success'] {
    background: ${darkMint['50']};
    color: ${darkMint['500']};
  }

  &[data-style='failed'] {
    background: ${uaRed['50']};
    color: ${uaRed['500']};
  }

  &[data-style='pending'] {
    background: ${marigold['50']};
    color: ${marigold['500']};
  }
`
