import { IntegrationCategoryMappings, createWrapper, resolveWhen } from '@segment/analytics-consent-tools'
import type { Categories } from '@segment/analytics-consent-tools/src/types/wrapper'

/**
 * Default iubenda purposes
 */
const defaultPurposes = {
  1: true,
  2: false,
  4: false,
  5: false
}

/**
 * Normalize iubenda purposes to segment categories
 * @param purposes
 */
function normalizeCategories (purposes = defaultPurposes): Categories {
  return {
    Advertising: purposes[5],
    Analytics: purposes[4],
    Functional: purposes[2],
    Necessary: purposes[1],
    'Tag Managers': purposes[4]
  }
}

let previousPurpose: typeof defaultPurposes | undefined

/**
 * Check if the consent has changed
 * If previousPurpose is undefined, it means that the user has not changed a preference
 * is the first time he selects the preferences and we don't have to reload the page and send the event to segment
 * It already does it by default
 * @param purposes
 */
function consentChanged (purposes: typeof defaultPurposes) {
  const changed = previousPurpose !== undefined && Object.entries(purposes).some(([key, value]) => value !== previousPurpose?.[key as unknown as keyof typeof defaultPurposes])
  previousPurpose = { ...purposes }
  return changed
}

export const ConsentManagerWrapper = (integrationCategoryMappings: IntegrationCategoryMappings) => createWrapper({
  // We need to translate iubenda purposes to segment categories
  getCategories: () => normalizeCategories(window._iub.cs.consent.purposes),
  integrationCategoryMappings,
  registerOnConsentChanged: (setCategories) => {
    window._iub.cs.options.callback.onPreferenceExpressed = (event: any) => {
      if (!event.purposes[4]) {
        // deleting clarity cookies if analytics is not accepted
        const clarityCookies = document.cookie.split('; ').filter((row) => row.startsWith('_clck') || row.startsWith('_clsk'))
        if (clarityCookies.length > 0) {
          clarityCookies.forEach((cookie) => {
            document.cookie = `${cookie.split('=')[0]}=; expires=Thu, 01 Jan 1970 00:00:00 GMT`
          })
        }
      }
      const changed = consentChanged(event.purposes)
      if (changed) {
        setCategories(normalizeCategories(event.purposes))
        window.location.reload()
      }
    }
  },
  shouldDisableSegment: () => false,

  shouldLoadSegment: async (ctx) => {
    // We wait for the first time the user selects a preferences
    await window._iub.cs.firstActivationCompletedPromise
    if (window._iub.cs.isConsentRejected()) {
      ctx.abort({ loadSegmentNormally: false })
    }
  },

  shouldLoadWrapper: async () => {
    await resolveWhen(
      () => {
        return window._iub && !!window._iub.cs
      },
      500
    )
    previousPurpose = window._iub.cs.consent?.purposes ? { ...window._iub.cs.consent?.purposes } : undefined
  }
})
