import { Button, Card, GuestFooter, Spacer } from '../index'
import { Colors } from '../../style/colors'
import { Layout, Spacing } from '../../style/spacing'
import { Typography } from '../../style/typography'
import { auth } from '../../configuration/firebase'
import { logAndSendToErrorTracker } from '../../services/errorTracker'
import { translate } from '../../lib/localization'
import React from 'react'
import contractsIcon from '../../images/login/icons.svg'
import image from '../../images/error/error@3x.png'
import logoIcon from '../../images/login/logo.svg'

type ErrorBoundaryProps = {
  children: React.ReactNode
};

export class RootErrorBoundary extends React.Component<ErrorBoundaryProps> {
  state = {
    errorId: null,
    hasError: false
  }

  static getDerivedStateFromError () {
    return { hasError: true }
  }

  componentDidCatch (error) {
    const errorId = logAndSendToErrorTracker(error)
    this.setState(errorId)
  }

  render () {
    if (this.state.hasError) {
      return (
        <div css={[Layout.display_flex, Layout.flex_column, Colors.BackgroundColor.violetBlue500, {
          alignItems: 'center',
          height: '100%'
        }]}>
          <div>
            <header css={[Layout.display_flex, {
              justifyContent: 'space-between',
              marginBottom: 40,
              maxWidth: '452px'
            }, Spacing.Padding.top_40]}>
              <img alt={'Logo Tate'} src={logoIcon}/>
              <img alt={'Luce e Gas'} src={contractsIcon}/>
            </header>

            <div css={{ width: '452px' }}>
              <Card>
                <div css={[Typography.heading.h5_700, Colors.Color.eerieBlack700, Layout.flex_column, Layout.vertical_center]}>
                  <Spacer height={48}/>
                  <img src={image} width={300} alt={'Error'}/>
                  <Spacer height={24}/>
                  <div css={[Typography.heading.h5_700, Colors.Color.eerieBlack800]}>{translate('error_boundary__title--fatal')}</div>
                  <Spacer height={8}/>
                  <div css={[Typography.paragraph.p2_400, Colors.Color.eerieBlack600, Typography.Align.center, Layout.width_max_540]}>{translate('error_boundary__subtitle--fatal', { code: this.state.errorId })}</div>
                  <Spacer height={8}/>
                  <div css={[Typography.paragraph.p2_400, Colors.Color.eerieBlack600, Typography.Align.center, Layout.width_max_540]}>{translate('error_boundary__subtitle-retry--fatal')}</div>
                  <Spacer height={24}/>
                  <Button title={translate('error_boundary__button--fatal')} style={'primary'} onClick={() => this.restart()}/>
                  <Spacer height={48}/>
                </div>
              </Card>
            </div>

            <GuestFooter/>
          </div>
        </div>
      )
    }

    return this.props.children
  }

  restart = () => {
    auth.signOut()
    window.location.href = `https://${window.location.hostname}`
  }
}
