import { BarDatum, ResponsiveBar } from '@nivo/bar'
import { BusinessDataTrend, ContractType } from '../../lib/apollo/types'
import { Tooltip } from '../businessTooltipChart'
import { contractAzul, royalPurple } from '../../style/colors'
import { formatter } from '../../style/utils'
import { translate } from '../../lib/localization'
import React from 'react'
import dayjs from 'dayjs'

interface ConsumptionChartProps {
  data?: Array<ConsumptionChartDatum>
  type?: ContractType
}

interface ConsumptionChartDatum extends BarDatum {
  change: number
  date: string
  longDate: string
  value: number
  valueColor: string
}

const keys = ['value']
const commonProps = {
  height: 300,
  indexBy: 'date',
  keys,
  labelSkipHeight: 16,
  labelSkipWidth: 16,
  labelTextColor: 'inherit:darker(1.4)',
  padding: 0.2
}

const typeColor = {
  ee: contractAzul['500'],
  gas: royalPurple['500']
}

export const graphqlDataToSeriesConsumption = (data: BusinessDataTrend[], type: ContractType): ConsumptionChartDatum[] => data.map(
  ({ date, energy_consumption: eeConsumption, gas_consumption: gasConsumption }) => {
    const [ee = 0, eeDelta = 0] = eeConsumption ?? []
    const [gas = 0, gasDelta = 0] = gasConsumption ?? []

    if (type === ContractType.Ee) {
      return ({ change: eeDelta / 100, date: formatter(date), longDate: dayjs(date).format('MMMM YY'), value: ee, valueColor: typeColor[type] })
    }
    return ({ change: gasDelta / 100, date: formatter(date), longDate: dayjs(date).format('MMMM YY'), value: gas, valueColor: typeColor[type] })
  })

export const ConsumptionChart: React.FC<ConsumptionChartProps> = ({ type, data }) => {
  const marginLeft = 25 + (Math.max.apply(Math, data.map(element => element.value)).toFixed(0).toString().length - 2) * 10
  return (
    <ResponsiveBar
      {...commonProps}
      borderRadius={4}
      data={data}
      margin={{ bottom: 40, left: marginLeft, right: 0, top: 15 }}
      enableLabel={false}
      colors={({ id, data }) => String(data[`${id}Color`])}
      axisBottom={{
        tickPadding: 5,
        tickRotation: -40
      }}
      tooltip={(bar: any) => {
        let translateX = 0
        if (bar.index === 0 && bar.x > 10) translateX = 10 + (bar.width / 2)
        if (bar.index === 0 && bar.x > 80) translateX = 10 + bar.width
        if (data.length - 1 === bar.index && bar.x > 250) translateX = bar.width - 100
        return (
          <Tooltip date={bar.data.longDate} value={`${bar.data.value} ${translate(`consumption_unit--${type}`)}`} change={bar.data.change ?? 0} translateX={translateX}/>
        )
      }
      }
    />
  )
}
