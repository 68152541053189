import { envLocal, envProduction, envStaging } from '../constants'
import { get } from 'lodash'
export const __DEV__ = !process.env.REACT_APP_STAGE

export const apiHostname = get(
  {
    [envLocal]: 'http://localhost:3010',
    [envProduction]: 'https://api.tate.cloud',
    [envStaging]: 'https://test-api.tate.cloud'
  },
  process.env.REACT_APP_STAGE,
  // Per spinnare in locale, commenta https://test-api.tate.cloud e usa http://localhost:3010 con la rispettiva porta su cui è aperto
  // 'http://localhost:3010'
  'https://test-api.tate.cloud'
)

export const webOrderUrl = get(
  {
    [envLocal]: 'http://localhost:3000',
    [envProduction]: 'https://scopri.tate.it',
    [envStaging]: 'https://beta-scopri.tate.it'
  },
  process.env.REACT_APP_STAGE,
  'https://beta-scopri.tate.it'
)

export const intercomAppId = get(
  {
    [envLocal]: 'e3fkgtlv',
    [envProduction]: 'e3fkgtlv',
    [envStaging]: 'e3fkgtlv'
  },
  process.env.REACT_APP_STAGE,
  'e3fkgtlv'
)

export const segmentKey = get(
  {
    [envLocal]: 'TcgJcaGFqFVoczKjzOZ68h4mgojlmDH2',
    [envProduction]: 'nna4i79OgE6cCktAY22jCZBhKUR81Uz4',
    [envStaging]: 'TcgJcaGFqFVoczKjzOZ68h4mgojlmDH2'
  },
  process.env.REACT_APP_STAGE,
  'TcgJcaGFqFVoczKjzOZ68h4mgojlmDH2'
)

export const segmentCDN = 'https://atr-cdn.tate.it'

export const branchApiKey = get(
  {
    [envLocal]: 'key_test_ma2Mk9pE65fSUXmpJM9pJlaitEkYM42X',
    [envProduction]: 'key_live_bp7Pl1jwZ6eOUZclOVKkSefbDEm0OZZO',
    [envStaging]: 'key_test_ma2Mk9pE65fSUXmpJM9pJlaitEkYM42X'
  },
  process.env.REACT_APP_STAGE,
  'key_test_ma2Mk9pE65fSUXmpJM9pJlaitEkYM42X'
)

export const magicLinkPublicKey = get(
  {
    [envLocal]: { alg: 'RSA-OAEP-256', e: 'AQAB', ext: true, key_ops: ['encrypt', 'wrapKey'], kty: 'RSA', n: 'tN7_DEjppADdf_0H0Wv3dAxvIotCrIiMfW_emla2P5vDAloKLgL2RDJVq4KVwgxEGYIl7YcsKrSfl7Xb3q-jXdudTzsaBXdLA7Ai6XT3Biclb4inDa2S4I6tVJaZgcvM55cWYb3j8UkvQpnWE__8hF-2JXExqFyYo8O5FDhrJ5mbY60G6Q2lcRjcR1fpXOXTZkDzhXcah0uvSeca6GDKb0slvNv6RR1UiCmNJMFKQaiBdkOSi-dwQ59uiuCvW-2FZyg-czgIH6mEvWgsD7onJyxTfb7oGFda8aAOxITccOP4AyynoVJBlOM-lCnE1lX-KHqSRBVr3gAs5qTnxDFxMCR848mMwIxjdtOmOzY7G-4NeOpJfm7i2zjdrztiUrqKJUJg4N0wKI9zrKmAGcbOdb1R4N9roF1JI8cBdMiTPJ7WXYXwqltabyC0u3lo20zNqmSDFW-1suFJpD1sGGFl8z7jbTKVn9TkmhtiSi6M9WYWyy450yV7XkIFylc8bLq9Moj9VlzUGbB4eJRZsZg3NvCkDNV7S76KAIbE3pPJop-76a2nh9auk9knHb7BP60PeruuUwuMG4NzaeuW3JsA_cnvTRTm9tbdzU_pga7B0P-6MPJAVAombpg62oXMlOrIWAGeSQsNYWYp6kEPM14aRASNhjjzJA17hvLLiHURnok' },
    [envProduction]: { alg: 'RSA-OAEP-256', e: 'AQAB', ext: true, key_ops: ['encrypt', 'wrapKey'], kty: 'RSA', n: 'saCan1Kc7pGGGei7gQ01i2FXeX7szQVVayH31qWYnC51LTCH_yM2GXHPnJfnTNMjcB7KNRKOrbWiG_yWTFZy3M3CQITLILkZS2RZYNFdALr-HGHMXpiqGfOMEM4BjKxAcgr65zZwYklI2TrifiizOnpg6slzH2T_U38dlDp6wQb0O76FJ4HlmnnHlIvVJgYZKA3K6B34_mibtf9-Ic3g8prJrxlfavyEsdSvmpz83Zz-cHBSUiKRFummMbR84gVDCCJLr3HpE6LTe2JKw6z453u6EbJl2EaYryWKM6h5TUFg-bT_waovNMjVFAkx9cdNwxTUgEJzcD6MnzWtULgm2BOmKw7akbfRve-0H4F-W2bggBjIcVq6c5gyJHMknmvJR77QF7Ff8AXQOvYSY4wlCenNvAtWnJ4ibrPp_sODHfJp3r2nIwzWuoje5BEGMnU_LO4w-ys7VqnPTiXFcGwi3kh8boQvcEaEgEkp_VZU2IYvoeXPsu1czXhA5OcIt3NEQlby1zKXSr-a3Ntj1Es4uGXKPhg2Mb85XCdbOXCBtZAgrBN2CIb457JZpE_zHGfcGIOuQfNgzvrunhXAYYd5pEYxukWguoyf3waVDhVbLqgYD8A5vlUEMM5jAXsqenYYiDspIX31Yw9Nk0qBC-Wa5aEpStphyWJuEAjYX7TeAV8' },
    [envStaging]: { alg: 'RSA-OAEP-256', e: 'AQAB', ext: true, key_ops: ['encrypt', 'wrapKey'], kty: 'RSA', n: 'tN7_DEjppADdf_0H0Wv3dAxvIotCrIiMfW_emla2P5vDAloKLgL2RDJVq4KVwgxEGYIl7YcsKrSfl7Xb3q-jXdudTzsaBXdLA7Ai6XT3Biclb4inDa2S4I6tVJaZgcvM55cWYb3j8UkvQpnWE__8hF-2JXExqFyYo8O5FDhrJ5mbY60G6Q2lcRjcR1fpXOXTZkDzhXcah0uvSeca6GDKb0slvNv6RR1UiCmNJMFKQaiBdkOSi-dwQ59uiuCvW-2FZyg-czgIH6mEvWgsD7onJyxTfb7oGFda8aAOxITccOP4AyynoVJBlOM-lCnE1lX-KHqSRBVr3gAs5qTnxDFxMCR848mMwIxjdtOmOzY7G-4NeOpJfm7i2zjdrztiUrqKJUJg4N0wKI9zrKmAGcbOdb1R4N9roF1JI8cBdMiTPJ7WXYXwqltabyC0u3lo20zNqmSDFW-1suFJpD1sGGFl8z7jbTKVn9TkmhtiSi6M9WYWyy450yV7XkIFylc8bLq9Moj9VlzUGbB4eJRZsZg3NvCkDNV7S76KAIbE3pPJop-76a2nh9auk9knHb7BP60PeruuUwuMG4NzaeuW3JsA_cnvTRTm9tbdzU_pga7B0P-6MPJAVAombpg62oXMlOrIWAGeSQsNYWYp6kEPM14aRASNhjjzJA17hvLLiHURnok' }
  },
  process.env.REACT_APP_STAGE,
  { alg: 'RSA-OAEP-256', e: 'AQAB', ext: true, key_ops: ['encrypt', 'wrapKey'], kty: 'RSA', n: 'tN7_DEjppADdf_0H0Wv3dAxvIotCrIiMfW_emla2P5vDAloKLgL2RDJVq4KVwgxEGYIl7YcsKrSfl7Xb3q-jXdudTzsaBXdLA7Ai6XT3Biclb4inDa2S4I6tVJaZgcvM55cWYb3j8UkvQpnWE__8hF-2JXExqFyYo8O5FDhrJ5mbY60G6Q2lcRjcR1fpXOXTZkDzhXcah0uvSeca6GDKb0slvNv6RR1UiCmNJMFKQaiBdkOSi-dwQ59uiuCvW-2FZyg-czgIH6mEvWgsD7onJyxTfb7oGFda8aAOxITccOP4AyynoVJBlOM-lCnE1lX-KHqSRBVr3gAs5qTnxDFxMCR848mMwIxjdtOmOzY7G-4NeOpJfm7i2zjdrztiUrqKJUJg4N0wKI9zrKmAGcbOdb1R4N9roF1JI8cBdMiTPJ7WXYXwqltabyC0u3lo20zNqmSDFW-1suFJpD1sGGFl8z7jbTKVn9TkmhtiSi6M9WYWyy450yV7XkIFylc8bLq9Moj9VlzUGbB4eJRZsZg3NvCkDNV7S76KAIbE3pPJop-76a2nh9auk9knHb7BP60PeruuUwuMG4NzaeuW3JsA_cnvTRTm9tbdzU_pga7B0P-6MPJAVAombpg62oXMlOrIWAGeSQsNYWYp6kEPM14aRASNhjjzJA17hvLLiHURnok' }
)

export const eMask = get(
  {
    [envLocal]: '074837dfc8f4e97e99d9921ed8b7d6b859665c16baa30b0134c21d3f328a780f',
    [envProduction]: '789883e2b7874d0313a3f407f525ff22f5869028a10b7ec57b197ce76eb04bc2',
    [envStaging]: '074837dfc8f4e97e99d9921ed8b7d6b859665c16baa30b0134c21d3f328a780f'
  },
  process.env.REACT_APP_STAGE,
  '074837dfc8f4e97e99d9921ed8b7d6b859665c16baa30b0134c21d3f328a780f'
)
export const eMaskIv = get(
  {
    [envLocal]: 'a43b48aad63a9e78c7201bf666a38e9a',
    [envProduction]: '28b9dd3793cd9c09ecc077414d827b2a',
    [envStaging]: 'a43b48aad63a9e78c7201bf666a38e9a'
  },
  process.env.REACT_APP_STAGE,
  'a43b48aad63a9e78c7201bf666a38e9a'
)

export const stripeKey = get(
  {
    [envLocal]: 'pk_test_lx4PBXaNAZqCianhp16Jayb0',
    [envProduction]: 'pk_live_URxMccMBzfCRgA1LwebJhMVc',
    [envStaging]: 'pk_test_lx4PBXaNAZqCianhp16Jayb0'
  },
  process.env.REACT_APP_STAGE,
  'pk_test_lx4PBXaNAZqCianhp16Jayb0'
)

// Selectors
export const isProduction = process.env.REACT_APP_STAGE === envProduction
export const isNotProduction = process.env.REACT_APP_STAGE !== envProduction
export const isStaging = process.env.REACT_APP_STAGE === envStaging
export const isStagingOrProduction = [envStaging, envProduction].includes(process.env.REACT_APP_STAGE)

export const isPrivateUsageTarget = (process.env.REACT_APP_USAGE_TARGET ? process.env.REACT_APP_USAGE_TARGET === 'private' : true)
export const isBusinessUsageTarget = (!isPrivateUsageTarget && process.env.REACT_APP_USAGE_TARGET === 'business')
