import { css } from '@emotion/react'
import React from 'react'

const reg = /\[b](.*?)\[\/b]/g

interface TextWithStyleProps {
  text: string
  fontWeight?: number
  className?: string
}

export const TextWithStyle:React.FC<TextWithStyleProps> = ({ className = '', fontWeight = 600, text }) => (
  <span
    className={className}
    css={css`strong {font-weight: ${fontWeight}`}
    dangerouslySetInnerHTML={{ __html: text.replace(reg, (_, text) => `<strong>${text}</strong>`) }}
  />
)
