import { Layout } from '../../style/spacing'
import { css } from '@emotion/react'
import { eerieBlack, violetBlue } from '../../style/colors'

export const inputToggleStyle = css`
  display: flex;
  flex-wrap: wrap;
  align-items: center;

  .content {
    flex: 10;
    cursor: pointer;
  }

  .checkbox {
    flex: 2;
  }

  .checkbox > .value {
    width: 22px;
    height: 22px;
    cursor: pointer;
    border-radius: 16px;
    transition: padding 160ms,background-color 160ms;
    ${Layout.content_box}

    &[data-active='false'] {
      padding: 2px 24px 2px 2px;
      background-color: ${eerieBlack['500']};
    }

    &[data-active='true'] {
      padding: 2px 2px 2px 24px;
      background-color: ${violetBlue['500']};
    }

    .toggle-selector {
      width: 22px;
      height: 22px;
      border-radius: 11px;
      background-color: ${eerieBlack['0']};
      box-shadow: 0 2.42308px 6.46154px rgba(0, 0, 0, 0.15), 0 2.42308px 0.807692px rgba(0, 0, 0, 0.06);
    }
  }
`
