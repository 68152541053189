import { Typography } from '../../style/typography'
import { css } from '@emotion/react'

export const mediumStyle = css`
    border-radius: 4px;
    padding: 2px 8px 2px 8px;
    ${Typography.label.label4_400}
  }
`
export const smallStyle = css`
    border-radius: 6px;
    padding: 2px 6px 2px 6px;
    ${Typography.label.label5_400}
  }
`
