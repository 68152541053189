import React from 'react'

import { typographyErrorStyle } from './index.css'

export enum TypographyErrorStyle {
  Error = 'error',
  Warning = 'warning',
  Info = 'info'
}

export interface TypographyErrorProps {
  style?: TypographyErrorStyle
  error?: any
}

export const TypographyError: React.FC<TypographyErrorProps> = ({
  error,
  style = TypographyErrorStyle.Error
}) => (
  <div css={typographyErrorStyle} data-style={style}>
    {error}
  </div>
)
