import { Card, Spacer } from '../../../components'
import { Colors } from '../../../style/colors'
import { GapSizes, MainScreenLayout } from '../../../layouts/mainScreen'
import { ItemLayout } from '../../../layouts/item'
import { Layout, Spacing } from '../../../style/spacing'
import { PageLayout } from '../../../layouts/page'
import { PaymentMethodTarget } from '../../../lib/apollo/types'
import { Placeholder } from '../../../components/placeholder'
import { Typography } from '../../../style/typography'
import { logAndSendToErrorTracker } from '../../../services/errorTracker'
import { translate } from '../../../lib/localization'
import { useGetPaymentMethodQuery } from '../../../lib/apollo/hooks'
import BusinessContractTable from '../../../components/businessContractTable'
import CardIcon from '../../../images/icons/outline/card.svg'
import React from 'react'

const PlaceholderTable = <Placeholder width={'100%'} height={'241px'}
  css={[Layout.display_flex, Layout.ColumnGap.md, Layout.flex_column]} padding={24}
  animated={false}>
  <Placeholder width={'100%'} height={'40px'}/>
  <Spacer height={4}/>
  <Placeholder width={'100%'} height={'40px'}/>
</Placeholder>

const PlaceholderCard = <Placeholder width={'100%'} height={'220px'} css={[Layout.display_flex, Layout.ColumnGap.md]}
  padding={24} animated={false}>
  <div css={[Layout.flex_1]}>
    <Placeholder width={'80%'} height={'10px'}/>
    <Spacer height={4}/>
    <Placeholder width={'100%'} height={'20px'}/>
    <Spacer height={12}/>
    <Placeholder width={'80%'} height={'10px'}/>
    <Spacer height={4}/>
    <Placeholder width={'100%'} height={'20px'}/>
    <Spacer height={12}/>
    <Placeholder width={'80%'} height={'10px'}/>
    <Spacer height={4}/>
    <Placeholder width={'100%'} height={'20px'}/>
    <Spacer height={12}/>
  </div>
  <div css={[Layout.flex_1]}>
    <Placeholder width={'80%'} height={'10px'}/>
    <Spacer height={4}/>
    <Placeholder width={'100%'} height={'20px'}/>
    <Spacer height={12}/>
    <Placeholder width={'80%'} height={'10px'}/>
    <Spacer height={4}/>
    <Placeholder width={'100%'} height={'20px'}/>
    <Spacer height={12}/>
  </div>
</Placeholder>

export const BusinessPaymentMethodScreen = ({ route }) => {
  const paymentMethodId = route?.params.paymentMethodId
  const { data, loading } = useGetPaymentMethodQuery({
    fetchPolicy: 'cache-and-network',
    onError: error => {
      logAndSendToErrorTracker(error)
    },
    variables: {
      id: paymentMethodId
    }
  })
  const paymentMethod = data?.PaymentMethods[0]
  const contracts = paymentMethod?.Contracts

  const targetPaymentMethod = {
    [PaymentMethodTarget.CreditCard]: translate('payment_method--credit-card'),
    [PaymentMethodTarget.Sepa]: translate('payment_method--sepa')
  }[paymentMethod?.target]

  console.log(paymentMethod)
  const stripePaymentInfo = paymentMethod?.stripeData[paymentMethod?.stripeData?.type]

  return (
    <PageLayout>
      {
        loading ? <>
          <Spacer height={48}/>
          <ItemLayout
            firstTextRow={<Placeholder width={'60%'} height={'10px'} css={Spacing.Margin.bottom_8}/>}
            secondTextRow={<Placeholder width={'40%'} height={'15px'}/>}
            leftElement={<Placeholder height={'52px'} width={'52px'} radius={26} css={Spacing.Margin.right_8}/>}
          />
          <Spacer height={12}/>
          {PlaceholderCard}
          <Spacer height={12}/>
          {PlaceholderTable}
        </>
          : <MainScreenLayout showToolbox={false} gapSize={GapSizes.lg} sectionHeaderProps={{
            icon: CardIcon,
            path: `${translate('gestione-utenza__path')} / ${translate('sidenav_menu__title_payment_methods')}`,
            title: paymentMethod.name
          }}>
            <Card css={[Layout.display_flex, Layout.flex_column]}>
              <div
                css={[Typography.label.label4_700, Colors.Color.eerieBlack500]}>{translate('business_invoice_table__header__detail').toUpperCase()}</div>
              <Spacer height={12}/>
              <div css={[Layout.display_flex, Layout.flex_row]}>
                <div css={[Layout.display_flex, Layout.flex_column, Layout.flex_1]}>
                  <ItemLayout
                    firstTextRow={<div
                      css={[Typography.label.label4_400, Colors.Color.eerieBlack600]}>{translate('business_payment_method__name')}</div>}
                    secondTextRow={<div css={Typography.paragraph.p3_500}>{paymentMethod?.name}</div>}
                  />
                  <Spacer height={12}/>
                  {
                    (stripePaymentInfo?.brand) && <>
                      <ItemLayout
                        firstTextRow={<div
                          css={[Typography.label.label4_400, Colors.Color.eerieBlack600]}>{translate('business_payment_method__brand')}</div>}
                        secondTextRow={<div css={Typography.paragraph.p3_500}>{stripePaymentInfo?.brand}</div>}
                      />
                      <Spacer height={12}/>
                    </>
                  }
                  {
                    (paymentMethod?.stripeData?.card?.exp_month && paymentMethod?.stripeData?.card?.exp_year) && <>
                      <ItemLayout
                        firstTextRow={<div
                          css={[Typography.label.label4_400, Colors.Color.eerieBlack600]}>{translate('business_payment_method__expiration_date')}</div>}
                        secondTextRow={<div
                          css={Typography.paragraph.p3_500}>{`${(paymentMethod?.stripeData?.card?.exp_month)}/${paymentMethod?.stripeData?.card?.exp_year}`}</div>}
                      />
                      <Spacer height={12}/>
                    </>
                  }
                </div>
                <div css={[Layout.display_flex, Layout.flex_column, Layout.flex_1]}>
                  <ItemLayout
                    firstTextRow={<div
                      css={[Typography.label.label4_400, Colors.Color.eerieBlack600]}>{translate('business_payment_method__type')}</div>}
                    secondTextRow={<div css={Typography.paragraph.p3_500}>{targetPaymentMethod}</div>}
                  />
                  <Spacer height={12}/>
                  <ItemLayout
                    firstTextRow={<div
                      css={[Typography.label.label4_400, Colors.Color.eerieBlack600]}>{translate('business_payment_method__last_4_digits')}</div>}
                    secondTextRow={<div css={Typography.paragraph.p3_500}>{`•••• ${stripePaymentInfo?.last4}`}</div>}
                  />
                  <Spacer height={12}/>
                </div>
              </div>
            </Card>
            <BusinessContractTable
              contracts={contracts} onSelectionChanges={undefined}/>
          </MainScreenLayout>
      }
    </PageLayout>
  )
}
