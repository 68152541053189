import { Icon } from '../icon'
import { ItemLayout } from '../../layouts/item'
import { accordionStyle } from './index.css'
import React, { ReactNode, useMemo, useState } from 'react'

import { Layout } from '../../style/spacing'
import { Typography } from '../../style/typography'
import { eerieBlack } from '../../style/colors'
import closedIcon from '../../images/icons/chevron-forward.svg'
import openIcon from '../../images/icons/chevron-down.svg'

export interface AccordionProps {
  title: any
  secondTextRow?: any
  css?: any
  children: ReactNode
  onToggle?: Function
  defaultValue?: boolean
  value?: boolean
  leftElement?: any
  rightElement?: any
  hideClose?: boolean
  withBorder?: boolean
}

export const Accordion: React.FC<AccordionProps> = ({
  title,
  secondTextRow,
  css,
  children,
  defaultValue = false,
  value,
  onToggle,
  leftElement,
  rightElement,
  hideClose = false,
  withBorder = false
}) => {
  let [open, setOpen] = useState<boolean>(defaultValue)
  if (onToggle) {
    open = value
  }
  const onClick = useMemo(() => onToggle ?? setOpen, [onToggle])

  if (!open) {
    return <div css={[accordionStyle, Typography.Style.pointer]} data-border={withBorder} data-open={open}>
      <ItemLayout
        css={css}
        leftElement={<div onClick={() => onClick(!open)}>{leftElement}</div>}
        firstTextRow={<div onClick={() => onClick(!open)}>{title}</div>}
        secondTextRow={<div onClick={() => onClick(!open)}>{secondTextRow}</div>}
        rightElement={!hideClose && <div css={Layout.display_flex}>{rightElement}<div onClick={() => onClick(!open)}><Icon src={closedIcon} color={eerieBlack['400']} css={Typography.Style.pointer}/></div></div>}
      />
    </div>
  }
  if (open) {
    return (
      <div css={[accordionStyle, Typography.Style.pointer]} data-open={open} onClick={() => onClick(!open)}>
        <ItemLayout
          css={css}
          leftElement={<div onClick={() => onClick(!open)}>{leftElement}</div>}
          firstTextRow={<div onClick={() => onClick(!open)}>{title}</div>}
          secondTextRow={<div onClick={() => onClick(!open)}>{secondTextRow}</div>}
          rightElement={!hideClose && <div css={Layout.display_flex}>{rightElement}<div onClick={() => onClick(!open)}><Icon src={openIcon} color={eerieBlack['400']} css={Typography.Style.pointer}/></div></div>}
        />
        {children}
      </div>
    )
  }
}

export default Accordion
