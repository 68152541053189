import { Colors } from '../../style/colors'
import { Layout, Spacing } from '../../style/spacing'
import { Typography } from '../../style/typography'
import { translate } from '../../lib/localization'
import React from 'react'

interface ToolTipCharProps {
    date?: string
    ee?: number
    gas?: number
    translateX?: number
}

export const TooltipContracts: React.FC<ToolTipCharProps> = ({ date, ee, gas, translateX }) => {
  let total = ee + gas
  if (!total) {
    if (!gas) {
      total = ee
    }
    if (!ee) {
      total = gas
    }
  }
  return <div css={[Colors.DropShadow.white100, Spacing.Padding.all_8, Layout.rounded_8, Colors.BackgroundColor.eerieBlack0, { minWidth: 160, transform: `translate(${translateX}px, 0px)` }]}>
    <div css={[Typography.label.label3_500, Spacing.Margin.bottom_8]}>{date}</div>
    <div css={[Layout.display_flex, Layout.vertical_center, Typography.label.label4_400]}>
      <div css={[Colors.BackgroundColor.contractAzul500, Spacing.Margin.right_6, { borderRadius: 2, height: 10, width: 10 }]}/>
      <div css={[Colors.Color.eerieBlack600, Spacing.Margin.right_4]}>{translate('business__dashboard__ee_contracts_type')}</div>
      <div css={[Colors.Color.contractAzul500, Typography.label.label4_700]}>{`• ${ee}`}</div>
    </div>
    <div css={[Layout.display_flex, Layout.vertical_center, Typography.label.label4_400]}>
      <div css={[Colors.BackgroundColor.royalPurple500, Spacing.Margin.right_6, { borderRadius: 2, height: 10, width: 10 }]}/>
      <div css={[Colors.Color.eerieBlack600, Spacing.Margin.right_4]}>{translate('business__dashboard__gas_contracts_type')}</div>
      <div css={[Colors.Color.royalPurple500, Typography.label.label4_700]}>{`• ${gas ?? 0}`}</div>
    </div>
    <div css={[Layout.display_flex, Layout.vertical_center, Typography.label.label4_400]}>
      <div css={[Colors.BackgroundColor.eerieBlack500, Spacing.Margin.right_6, { borderRadius: 2, height: 10, width: 10 }]}/>
      <div css={[Colors.Color.eerieBlack600, Spacing.Margin.right_4]}>{translate('business__dashboard__all_contracts_type')}</div>
      <div css={[Colors.Color.eerieBlack500, Typography.label.label4_700]}>{`• ${(total)}`}</div>
    </div>

  </div>
}
