import { css } from '@emotion/react'

export const numberCounterStyle = css`
  .subtitle_box {
    width: 10px;
    height: 10px;
    border-radius: 2px;
    display: inline-block;
  }

  .cta {
    width: 32px
  }
`
