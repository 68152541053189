
import { Colors, darkMint } from '../../../../style/colors'
import { css } from '@emotion/react'

export const referralWidgetStyles = css`
    .widget-referral{
        transition: background-color .5s;
    }

  .widget-referral:hover{
    background-color: ${darkMint['600']};
    ${Colors.BorderColor.none}
    transition: background-color .5s;

    .widget-referral_text {
        ${Colors.Color.eerieBlack0}
    }

    .widget-referral_icon {
        .icon {
            ${Colors.BackgroundColor.eerieBlack0}
        }
    }
  }

`
