import { Icon } from '..'
import { Layout } from '../../style/spacing'
import { Sizes } from '../../style/sizes'
import { Typography } from '../../style/typography'
import { getStyleFromColor } from '../../style/utils'
import { mediumStyle, smallStyle } from './index.css'
import React from 'react'
import closeIcon from '../../images/icons/close.svg'

export interface TagProps {
  title: string
  color: string
  onClick?: Function
  type?: 'small' | 'medium'
}

export const Tag: React.FC<TagProps> = ({ title, color, onClick, type = 'medium' }) => {
  const colors = getStyleFromColor(color)

  return (
    <div css={[Layout.vertical_center, type === 'medium' ? mediumStyle : smallStyle, { whiteSpace: 'nowrap' }]} style={{
      backgroundColor: colors['50'],
      border: `1px solid ${colors['200']}`,
      color: colors['500']
    }}>
      <div>{title}</div>
      {onClick &&
      <div onClick={() => onClick()} css={{ marginLeft: 5 }}>
        <Icon src={closeIcon} size={Sizes.xxxsmall} color={colors['500']} css={Typography.Style.pointer}/>
      </div>
      }
    </div>
  )
}
