import { Button, Spacer } from '../../../components'
import { Colors } from '../../../style/colors'
import { SharedEvents } from '@tate-it/tracking'
import { Typography } from '../../../style/typography'
import { isBusinessUsageTarget } from '../../../configuration/env'
import { logAndSendToErrorTracker } from '../../../services/errorTracker'
import { requestMagicLink } from '../../../services/magicLink'
import { translate } from '../../../lib/localization'
import { useAppState } from '../../../store'
import { useTracking } from '../../../tracking/useTracking'
import React, { useCallback, useState } from 'react'

export const ResendMagicLink: React.FC<{email: string}> = ({ email }) => {
  const state = useAppState()
  const { track } = useTracking()
  const [loading, setLoading] = useState(false)
  const [sentLink, setSentLink] = useState(false)

  const sendMagicLink = useCallback(async () => {
    setLoading(true)
    try {
      let target: 'dashboard' | 'business' = 'dashboard'
      if (isBusinessUsageTarget) target = 'business'
      await requestMagicLink({ email, redirectUrl: state.redirectUrl, strategy: 'signIn', target })
      track({ name: SharedEvents.User.AttemptedLogin })
      setSentLink(true)
    } catch (error) {
      logAndSendToErrorTracker(error)
    } finally {
      setLoading(false)
    }
  }, [email, state.redirectUrl, track])

  return (
    <>
      <Button
        onClick={sendMagicLink}
        style="primary"
        title={translate('button_resend_magic_link')}
        loading={loading}
        disabled={sentLink}
      />
      {sentLink && <>
        <Spacer height={16}/>
        <div css={[Typography.label.label3_500, Colors.Color.eerieBlack600, { textAlign: 'center' }]}>
          {translate('login_widget__magic_link_sent')}
        </div>
      </>}
    </>
  )
}
