import { useCallback, useEffect, useRef, useState } from 'react'

const useCountdown = () => {
  const timeoutRef = useRef<number>(null)
  const [seconds, setCountdown] = useState<number>(0)

  useEffect(() => {
    const decrement = () => setCountdown(s => s - 1)
    if (seconds > 0) timeoutRef.current = setTimeout(decrement, 1000)
  }, [timeoutRef, seconds])

  useEffect(() => {
    return () => clearTimeout(timeoutRef.current)
  }, [timeoutRef])

  const startCountdown: (from: number) => void = useCallback(
    (from: number) => {
      clearTimeout(timeoutRef.current)
      setCountdown(from)
    },
    [timeoutRef]
  )
  const hook: [number, (number)=> void] = [seconds, startCountdown]
  return hook
}

export { useCountdown }
