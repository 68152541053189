import { css } from '@emotion/react'

export const layoutStyle = css`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;

  .mainContainer {
    flex: 1 1 auto;
    display: flex;
    justify-content: space-around;
    overflow-y: hidden;
  }

  main {
    // display: flex;
    flex: 1 1 auto;
    overflow-y: auto;
    -ms-overflow-style: none;
    scrollbar-width: 0;
  }

  main::-webkit-scrollbar {
    display: none;
  }

  footer {
    flex: 0 0 40px;
  }`
