import { PaymentMethod } from '../interfaces'
import { StripeCardBrand, StripeCardFundingType, StripeCardType } from '../enums'
import { translate } from '../lib/localization'
import creditCardIcon from '../images/icons/credit-card.svg'
import mastercardIcon from '../images/icons/mastercard.svg'
import sepaIcon from '../images/icons/sepa.svg'
import visaIcon from '../images/icons/visa.svg'

export const getTextFromPaymentMethodCard = paymentMethod => {
  if (paymentMethod.name) return paymentMethod.name

  switch (paymentMethod.stripeData?.card?.funding) {
    case StripeCardFundingType.credit:
      return translate('payment_method--credit-card')
    case StripeCardFundingType.debit:
      return translate('payment_method--debit-card')
    case StripeCardFundingType.prepaid:
      return translate('payment_method--prepaid-card')
    default:
      return translate('payment_method--card')
  }
}

export const getPaymentMethodItemSubtitle = (paymentMethod: PaymentMethod) => {
  switch (paymentMethod.stripeData?.type) {
    case StripeCardType.card:
      return `•••• ${paymentMethod?.stripeData?.card?.last4}`
    case StripeCardType.sepaDebit:
      return `${paymentMethod.stripeData.sepa_debit.country} •••• ${paymentMethod.stripeData.sepa_debit.last4}`
    default:
      return ''
  }
}

export const getPaymentMethodItemTitle = (paymentMethod: PaymentMethod) => {
  switch (paymentMethod.stripeData?.type) {
    case StripeCardType.card:
      return getTextFromPaymentMethodCard(paymentMethod)
    case StripeCardType.sepaDebit:
      return translate('payment_method--sepa')
    default:
      return ''
  }
}

export const getPaymentMethodSvgIcon = (paymentMethod: PaymentMethod) => {
  if (paymentMethod.stripeData?.type === StripeCardType.sepaDebit) return sepaIcon

  switch (paymentMethod.stripeData?.card?.brand) {
    case StripeCardBrand.Mastercard:
      return mastercardIcon
    case StripeCardBrand.Visa:
      return visaIcon
    default:
      return creditCardIcon
  }
}
