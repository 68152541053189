import { Layout, Spacing } from '../../style/spacing'
import { style } from './index.css'
import React, { useEffect, useRef } from 'react'

export interface PickerContainerProps{
  onToggle?: Function
  level?: number
}

export const PickerContainer: React.FC<PickerContainerProps> = ({ children, onToggle }) => {
  const ref = useRef(null)
  useEffect(() => {
    function handleClickOutside (event) {
      if (ref.current && !ref.current.contains(event.target)) {
        onToggle && onToggle()
      }
    }
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [onToggle])

  return (
    <div css={[Layout.border, Spacing.Padding.horizontal_16, Spacing.Padding.vertical_16, Layout.display_flex, Layout.flex_column, style]} style={{ right: 0, top: '100%', zIndex: 4 }} ref={ref}>
      {children}
    </div>
  )
}
