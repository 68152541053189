import { TypographyError } from '../index'
import { inputTextStyle } from './index.css'
import React, { useRef } from 'react'

export enum InputTextStatus {
  None = 'none',
  Valid = 'valid',
  Invalid = 'invalid',
  Disabled = 'disabled'
}

export interface InputTextProps {
  /**
   * Placheholder
   */
  placeholder?: string

  /**
   * primary eerieBlack100
   * secondary eerieBlack0
   */
  background?: 'primary' | 'secondary'

  /**
   * Label
   */
  label: string

  /**
   * inputMode is an enumerated attribute that hints at the type of data that might be entered by the user while editing the element or its contents
   */
  inputMode?: 'none' | 'text' | 'tel' | 'url' | 'email' | 'numeric' | 'decimal' | 'search'

  /**
   * the value
   */
  value?: any

  /**
   * status
   */
  status?: InputTextStatus

  /**
   * error msg
   */
  error?: string

  errorDescriptions?: any
  errorType?: string

  onBlur?: Function

  /**
   * Method onValue
   */
  onValue?: Function

  /**
   * Copy Text Input
   */
  copy?: boolean

  /**
   * Make the input read-only (disable the delete icon and auto blur onfocus)
   */
  readOnly?: boolean
}

export const InputText: React.FC<InputTextProps> = ({
  inputMode = 'text',
  label,
  onValue,
  placeholder,
  value,
  status = 'none',
  error,
  errorDescriptions = {},
  errorType,
  background = 'primary',
  copy = false,
  onBlur = () => { },
  readOnly = false
}) => {
  const inputEl = useRef<HTMLInputElement>(null)

  const onClick = () => {
    if (status === 'invalid') {
      onValue?.('')
      onBlur()
    }
    if (copy) {
      navigator.clipboard.writeText(value)
    }
    if (value) {
      onValue?.('')
    }
  }

  const onInputClick = () => {
    if (status !== 'disabled') {
      // eslint-disable-next-line no-unused-expressions
      inputEl?.current?.focus()
    }
  }
  return (
    <div className={'inputText'}>
      <div
        onClick={onInputClick}
        css={inputTextStyle}
        data-background={background}
        data-has-value={!!value}
        data-status={status}
        data-has-label={!!(label || error)}
        className={'input inputText'}
        data-copy={copy}
      >
        <input
          ref={inputEl}
          value={value}
          onChange={evt => onValue?.(evt.target.value)}
          type="text"
          inputMode={inputMode}
          disabled={status === InputTextStatus.Disabled || copy}
          placeholder={placeholder}
          onBlur={() => onBlur()}
          onFocus={(event) => readOnly ? event.target.blur() : () => {
          }}
        />
        <label>{error || label}</label>
        {!readOnly && <div onClick={onClick} className={'status-icon'}/>}
      </div>
      {errorDescriptions[errorType] && <TypographyError error={errorDescriptions[errorType]} />}
    </div>
  )
}
