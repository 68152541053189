import { AnalyticsBrowser } from '@segment/analytics-next'
import {
  ExecutionEnvironmentDomain,
  ExecutionEnvironmentProduct,
  ExecutionEnvironmentProtocol,
  getBranchFingerprintPlugin,
  getExecutionEnvironmentPlugin,
  getPageViewPayloadPlugin
} from '@tate-it/tracking'

import { branchInit } from '../services/branch'

import { ConsentManagerWrapper } from './consent'
import { IntegrationCategoryMappings } from '@segment/analytics-consent-tools'
import { logAndSendToErrorTracker } from '../services/errorTracker'
import { segmentCDN, segmentKey } from '../configuration/env'

export const segmentAnalytics = new AnalyticsBrowser()

export const initTracking = async () => {
  const branch = await branchInit()
  const branchFingerprintPlugin = getBranchFingerprintPlugin(branch)
  const executionEnvironmentPlugin = getExecutionEnvironmentPlugin({
    domain: ExecutionEnvironmentDomain.Energy,
    product: ExecutionEnvironmentProduct.Clienti,
    protocol: ExecutionEnvironmentProtocol.Web
  })
  const pageViewPayloadPlugin = getPageViewPayloadPlugin()

  /**
   * Fetch integrations from segment to create the integrationCategoryMappings
   * that will be used by the consent wrapper to enable/disable integrations
   * when a users change his preferences we need to reload the page to enable/disable integrations
   **/
  const segmentResponse = await fetch(`${segmentCDN}/v1/projects/${segmentKey}/integrations`)

  const destinations = await segmentResponse.json()
  const integrationCategoryMappings: IntegrationCategoryMappings = destinations.reduce((acc: IntegrationCategoryMappings, {
    category,
    creationName
  }: any) => (
    {
      ...acc,
      [creationName]: [category]
    }
  ), {})
  // change the key to tate web staging or production
  ConsentManagerWrapper(integrationCategoryMappings)(segmentAnalytics)

  segmentAnalytics.register(branchFingerprintPlugin, executionEnvironmentPlugin, pageViewPayloadPlugin)
  segmentAnalytics.load({
    cdnURL: segmentCDN,
    writeKey: segmentKey
  }).catch(logAndSendToErrorTracker)
}
