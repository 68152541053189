import { Badge } from '../badge'
import { Contract, ContractStatus } from '../../lib/apollo/types'
import { Icon } from '../icon'
import { Sizes } from '../../style/sizes'
import { Spacing } from '../../style/spacing'
import { darkMint, marigold, uaRed } from '../../style/colors'
import { translate } from '../../lib/localization'
import React from 'react'
import alarmIcon from '../../images/icons/alarm.svg'
import alertCircle from '../../images/icons/alert-circle.svg'
import checkmark from '../../images/icons/checkmark-circle.svg'
import dayjs from '../../lib/dayJs'

interface ContractStatusLabelProps {
  contract: Contract
}

export const ContractStatusLabel: React.FC<ContractStatusLabelProps> = ({ contract }): any => {
  // If the contract is active and the startDate is in the past
  if (contract.status === ContractStatus.Active && dayjs(contract.start_date).isBefore(dayjs(), 'day')) {
    return <Badge
      icon={<Icon src={checkmark} size={Sizes.xsmall} color={darkMint['500']} css={Spacing.Padding.right_4}/>}
      style={'success'} title={translate('business_contract_table__badge_status--supplied')}
    />
  }

  const invoiceStatusMap = {
    [ContractStatus.Drafted]: {
      icon: <Icon src={alarmIcon} size={Sizes.xsmall} color={marigold['500']} css={Spacing.Padding.right_4}/>,
      style: 'pending',
      title: translate('business_contract_table__badge_status--drafted')
    },
    [ContractStatus.Pending]: {
      icon: <Icon src={alarmIcon} size={Sizes.xsmall} color={marigold['500']} css={Spacing.Padding.right_4}/>,
      style: 'pending',
      title: translate('business_contract_table__badge_status--pending')
    },
    [ContractStatus.Active]: {
      icon: <Icon src={checkmark} size={Sizes.xsmall} color={darkMint['500']} css={Spacing.Padding.right_4}/>,
      style: 'success',
      title: translate('business_contract_table__badge_status--active')
    },
    [ContractStatus.Disabled]: {
      icon: <Icon src={alertCircle} size={Sizes.xsmall} color={uaRed['500']} css={Spacing.Padding.right_4}/>,
      style: 'failed',
      title: translate('business_contract_table__badge_status--disabled')
    }
  }[contract.status]

  return <Badge {...invoiceStatusMap} />
}
