import { Icon } from '../icon'
import React from 'react'

import { cardItemStyle, cardSelectStyle, externalCardStyle } from './index.css'

import { Layout, Spacing } from '../../style/spacing'
import { Sizes } from '../../style/sizes'
import { Typography } from '../../style/typography'
import { eerieBlack, primaryColor } from '../../style/colors'
import checkmark from '../../images/icons/checkmark-circle.svg'
import radioOff from '../../images/icons/radio-button-off.svg'

export interface CardSelectProps {
  /**
   * The cards splitted by row
   */
  cards: any

  /**
   * Method onSelect
   */
  onSelect?: Function

  /**
   * Cards array
   */
  values: Array<any>
}

export interface CardItemProps {
  active?: boolean
  label: any
  onSelect: any
  style?: any
}

const CardItem: React.FC<CardItemProps> = ({
  active = false,
  label,
  onSelect,
  style
}) => {
  return (
    <div css={externalCardStyle} data-active={active}>
      <div data-active={active} onClick={onSelect} css={[cardItemStyle, style]}>
        <Icon src={active ? checkmark : radioOff} color={active ? primaryColor : eerieBlack['400']} size={Sizes.small}/>
        <div css={[Spacing.Padding.left_12, Typography.label.label3_600]}>{label}</div>
      </div>
    </div>
  )
}

export const CardSelect: React.FC<CardSelectProps> = ({
  cards,
  onSelect = () => { },
  values = []
}) => {
  return (
    <div css={cardSelectStyle}>
      {
        cards.map((row = [], rowIndex) => (
          <div key={rowIndex}>
            {
              row.map((cardProps, index) => (
                <CardItem active={values.includes(cardProps.value)} key={index} onSelect={() => onSelect(cardProps.value)} style={[Layout.display_flex, Layout.border, Spacing.Padding.horizontal_12, Spacing.Padding.vertical_12, { alignItems: 'center', borderRadius: '10px', borderWidth: '2px' }]} label={cardProps.label}/>
              ))
            }
          </div>
        ))}
    </div>
  )
}
