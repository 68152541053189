import { Icon } from '../icon'
import { Layout, Spacing } from '../../style/spacing'
import { Sizes } from '../../style/sizes'
import { Typography } from '../../style/typography'
import { eerieBlack } from '../../style/colors'
import { style } from './index.css'
import React from 'react'
import close from '../../images/icons/close.svg'

interface ModalProps {
  title: React.ReactNode
  hideModal?: React.MouseEventHandler<HTMLDivElement>
  hideBackground?: boolean
  styleCustom?: any
}
export const Modal: React.FC<ModalProps> = ({
  title,
  children,
  hideModal,
  hideBackground = false,
  styleCustom
}) => (
  <div css={[style, styleCustom]}>
    <div
      className={'modal'}
      data-background={hideBackground}
      onClick={hideModal}
    />
    <section className="modal-main">
      <div css={[Layout.display_flex, { justifyContent: 'space-between' }, Spacing.Margin.bottom_8, style]}>
        {title}
        <div
          onClick={hideModal}
          css={Typography.Style.pointer}>
          <Icon src={close} size={Sizes.xsmall} color={eerieBlack['200']} />
        </div>
      </div>
      {children}
    </section>
  </div>
)
