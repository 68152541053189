import { Colors } from '../style/colors'
import { isBusinessUsageTarget, isPrivateUsageTarget } from './env'

export const Theme = {
  Shared: {
    backgroundColor: null
  }
}

if (isPrivateUsageTarget) Theme.Shared.backgroundColor = Colors.BackgroundColor.violetBlue500
if (isBusinessUsageTarget) Theme.Shared.backgroundColor = Colors.BackgroundColor.eerieBlack800
