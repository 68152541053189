import { Colors, darkMint, eerieBlack } from '../../style/colors'
import { Icon } from '../icon'
import { Label } from '../../lib/apollo/types'
import { Layout, Spacing } from '../../style/spacing'
import { PickerContainer } from '../pickerContainer'
import { Sizes } from '../../style/sizes'
import { Spacer } from '../index'
import { Tag, TagProps as TagElementProps } from '../tag'
import { Typography } from '../../style/typography'
import { style } from './index.css'
import { translate } from '../../lib/localization'
import { useActions, useAppState } from '../../store/index'
import React, { useMemo, useState } from 'react'
import checkBox from '../../images/icons/checkbox.svg'
import halfCheckbox from '../../images/icons/half-checkbox.svg'
import searchIcon from '../../images/icons/search.svg'
// import settingsOutline from '../../images/icons/settings-outline.svg'

export interface TagProps extends TagElementProps {
  value: boolean
}

export interface TagFilterProps{
  onToggle?: Function
  onPick?: Function
  data: Array<Label>
}

const ICON_MAPS = { all: checkBox, half: halfCheckbox }

const body = (data, labelsIconMap, onSetArrayCheckBoxes) => (
  data.map((tag, index) => (
    <div key={index} css={[Layout.flex_row, Layout.vertical_center]}>
      <div onClick={() => onSetArrayCheckBoxes({ select: labelsIconMap[tag.name] !== 'all', tag })} css={[Spacing.Margin.right_8, Typography.Style.pointer]}>
        {
          labelsIconMap[tag.name]
            ? <Icon src={ICON_MAPS[labelsIconMap[tag.name]]} color={eerieBlack['800']} size={Sizes.small}/>
            : <div css={[Layout.border, { border: `1px solid ${eerieBlack['500']}`, borderRadius: 2, height: 15, marginLeft: 3, width: 15 }]}/>
        }
      </div>
      <Tag title={tag.name} color={tag.color}/>
    </div>
  ))
)

export const TagFilter: React.FC<TagFilterProps> = ({ data, onPick, onToggle }) => {
  const { selectedContracts } = useAppState()
  const { setSelectedContracts } = useActions()
  const updateLabels = ({ select, tag }) => {
    if (select) {
      return setSelectedContracts(selectedContracts.map(contract => ({
        ...contract,
        Labels: contract.Labels.filter(({ name }) => name !== tag.name).concat(tag),
        // @ts-ignore
        originalLabels: contract.originalLabels || [...contract.Labels]
      })
      ))
    }
    setSelectedContracts(selectedContracts.map(contract => ({
      ...contract,
      Labels: contract.Labels.filter(({ name }) => name !== tag.name),
      // @ts-ignore
      originalLabels: contract.originalLabels || [...contract.Labels]
    })
    ))
  }
  const labelsIconMap = useMemo(() => {
    const map = selectedContracts.reduce(
      (map, { Labels = [] }) => {
        Labels.forEach(({ name }) => (map[name] = (map[name] ?? 0) + 1))
        return map
      }, {}
    )
    Object.keys(map).forEach(key => (map[key] = map[key] === selectedContracts.length ? 'all' : 'half'))
    return map
  }
  , [selectedContracts])
  const [search, setSearch] = useState('')
  const filteredData = () => data.filter(tag => tag.name.toLowerCase().indexOf(search.toLowerCase()) !== -1)
  return (
    <PickerContainer onToggle={onToggle}>
      <div css={[Layout.display_flex, Layout.flex_column, style]}>
        <div css={[Colors.BackgroundColor.eerieBlack100, Layout.display_flex, Spacing.Padding.horizontal_16, Spacing.Padding.vertical_8, Layout.rounded_8]}>
          <div css={Typography.Style.pointer}><Icon src={searchIcon} color={eerieBlack['600']} size={Sizes.small} css={Spacing.Margin.right_12} onClick={() => onPick()}/></div>
          <input type='text' placeholder={translate('business__tag_picker__placeholder')} value={search} onChange={evt => setSearch(evt.target.value)} className={'input-text'}/>
        </div>
        <Spacer height={16}/>
        <div css={[Typography.label.label4_500, Colors.Color.eerieBlack500]}>{translate('business_tag_filter__subtitle')}</div>
        <Spacer height={16}/>
        <div css={[Layout.display_flex, Layout.flex_column, Layout.RowGap.md]}>
          {body(filteredData(), labelsIconMap, updateLabels)}
        </div>
        {/* TODO: Navigazione gestione etichette */}
        { (data.length === 0 && search) &&
          <>
            <div css={[Spacing.Padding.vertical_8, Spacing.Padding.horizontal_8, Typography.Style.pointer, Layout.vertical_center, Layout.flex_row, Layout.rounded_8]} className={'navigator'}>
              <div css={[Typography.label.label4_500, Spacing.Margin.right_8, Colors.Color.eerieBlack800]}>
                {translate('business__tag_filter__create_text')}
              </div>
              <Tag title={search} color={darkMint[500]}/>
            </div>
            <Spacer height={16}/>
          </>}
        {/* <div css={Layout.border_bottom}/>
        <Spacer height={12}/>
         TODO: Navigazione gestione etichette
        <div css={[Spacing.Padding.horizontal_8, Spacing.Padding.vertical_8, Typography.Style.pointer, Layout.vertical_center, Layout.flex_row, Layout.rounded_8]} className={'navigator'}>
          <Icon src={settingsOutline} css={Spacing.Margin.right_8} size={Sizes.xsmall} color={eerieBlack['700']}/>
          <div css={[Typography.label.label3_500, Colors.Color.eerieBlack700]}>{translate('business_tag_filter__tag_handling')}</div>
        </div> */}
      </div>
    </PickerContainer>
  )
}
