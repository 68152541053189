import { Colors, eerieBlack } from '../../style/colors'
import { Icon } from '../icon'
import { Label } from '../../lib/apollo/types'
import { Layout, Spacing } from '../../style/spacing'
import { PickerContainer } from '../pickerContainer'
import { Sizes } from '../../style/sizes'
import { Spacer } from '../index'
import { Tag, TagProps as TagElementProps } from '../tag'
import { Typography } from '../../style/typography'
import { style } from './index.css'
import { translate } from '../../lib/localization'
import React, { useState } from 'react'
import checkBox from '../../images/icons/checkmark-circle.svg'
import closeIcon from '../../images/icons/close.svg'
import openIcon from '../../images/icons/chevron-down.svg'
import searchIcon from '../../images/icons/search.svg'

export interface TagListProps extends TagElementProps {
  id?: number
}

export interface TagPickerProps {
  onToggle?: Function
  onPick?: Function
  data: Array<Label>
  updateFilter?: Function
  tagsFilter: { andFilter: Array<Label>; orFilter: Array<Label> }
  showOr? :boolean
}

const addTagToList = (setSelector: Function, listPicked: Array<Label>, tag: Label) => {
  if (!listPicked.find(element => element.id === tag.id)) {
    const updatedArray = [...listPicked, tag]
    setSelector(updatedArray)
  }
}

const removeTagElement = (setSelector: Function, tagsArray: Array<Label>, id: string) => {
  const filtered = tagsArray.filter(tag => tag.id !== id)
  setSelector(filtered)
}

const body = (data, setSelector, listPicked: Array<Label>) => {
  return (data.map((tag) => {
    const isActive = listPicked.findIndex(({ id }) => id === tag.id) !== -1
    return (
      <div key={tag.id}
        css={[Layout.flex_justify_spaceBetween, Layout.display_flex, Layout.flex_1, Layout.vertical_center, { height: 32 }]}>
        <div onClick={() => {
          addTagToList(setSelector, listPicked, tag)
        }} css={Typography.Style.pointer}>
          <Tag title={tag.name} color={tag.color}
            onClick={isActive ? () => removeTagElement(setSelector, listPicked, tag.id) : undefined}/>
        </div>
        {
          isActive &&
            <div>
              <Icon size={Sizes.xsmall} src={checkBox} color={eerieBlack['800']}/>
            </div>
        }
      </div>
    )
  }
  ))
}

export const TagPicker: React.FC<TagPickerProps> = ({
  data,
  onPick,
  onToggle,
  updateFilter,
  tagsFilter = {},
  showOr = false
}) => {
  const [showPicker, setShowPicker] = useState(false)
  const [search, setSearch] = useState('')
  const { andFilter = [], orFilter = [] } = tagsFilter
  const [whichSelector, setWhichSelector] = useState<number>()

  const showPickerFunction = (selector) => {
    setShowPicker(!showPicker)
    setWhichSelector(selector)
  }

  const clearPickers = () => {
    updateFilter({})
  }
  const setOrFilter = (orLabels: Array<Label>) => {
    updateFilter({ andFilter, orFilter: orLabels })
  }
  const setAndFilter = (andLabels: Array<Label>) => {
    updateFilter({ andFilter: andLabels, orFilter })
  }
  const selectorPicked = {
    1: setAndFilter,
    2: setOrFilter
  }[whichSelector]

  const listPicked = {
    1: andFilter,
    2: orFilter
  }[whichSelector]

  const filteredData = () => data.filter(tag => tag.name.toLowerCase().indexOf(search.toLowerCase()) !== -1)

  const ListTags = (setSelector: Function, selector: Array<Label>) => {
    return (
      <div css={[Layout.display_flex, Layout.flex_row, Layout.ColumnGap.sm]}>
        {
          selector.map((tag, index) => {
            return <Tag title={tag.name} color={tag.color}
              onClick={() => removeTagElement(setSelector, selector, tag.id)} key={index}/>
          })
        }
      </div>
    )
  }

  return (
    <>
      <PickerContainer css={{ minWidth: 447 }} onToggle={!showPicker && onToggle}>
        <div css={[Typography.label.label4_500, Layout.vertical_center]}>
          <div
            css={[Colors.Color.eerieBlack500, Spacing.Margin.right_8, { minWidth: 80 }]}>{translate('business__tag_picker__owner_filter')}</div>
          <div
            css={[Layout.flex_1, Layout.flex_justify_spaceBetween, Colors.BackgroundColor.eerieBlack100, Spacing.Padding.horizontal_12, Spacing.Padding.vertical_8, Layout.rounded_8, !andFilter && Typography.label.label3_500, Colors.Color.eerieBlack600, Layout.vertical_center, {
              height: 37,
              width: 273
            }, Layout.scrollYEnabled, Layout.hide_scrollbar]}>
            {andFilter?.length > 0 ? ListTags(setAndFilter, andFilter) : translate('business__tag_picker__select_filters')}
            <div onClick={() => showPickerFunction(1)}>
              <Icon src={openIcon} size={Sizes.xxsmall} color={eerieBlack[600]} css={Typography.Style.pointer}/>
            </div>
          </div>
        </div>

        {
          showOr && <>
            <Spacer height={16}/>
            <div css={[Layout.flex_row, Layout.display_flex, Typography.label.label4_500, Layout.vertical_center]}>
              <div
                css={[Colors.Color.eerieBlack500, Spacing.Margin.right_8, { minWidth: 80 }]}>{translate('business__tag_picker__contract_filter')}</div>
              <div
                css={[Layout.flex_1, Layout.flex_justify_spaceBetween, Colors.BackgroundColor.eerieBlack100, Spacing.Padding.horizontal_12, Spacing.Padding.vertical_8, Layout.rounded_8, !orFilter && Typography.label.label3_500, Colors.Color.eerieBlack600, Layout.vertical_center, {
                  height: 37,
                  width: 273
                }]}>
                {orFilter?.length > 0 ? ListTags(setOrFilter, orFilter) : translate('business__tag_picker__select_filters')}
                <div onClick={() => showPickerFunction(2)}>
                  <Icon src={openIcon} size={Sizes.xxsmall} color={eerieBlack[600]} css={Typography.Style.pointer}/>
                </div>
              </div>
            </div>
          </>
        }

        {
          (andFilter.length > 0 || orFilter.length > 0) &&
          <>
            <Spacer height={16}/>
            <div
              css={[Layout.flex_justify_end, Typography.label.label3_500, Layout.vertical_center, Colors.Color.eerieBlack500]}>
              <div onClick={() => clearPickers()}
                css={[Layout.flex_justify_end, Layout.vertical_center, Typography.Style.pointer]}>
                <div css={[Spacing.Padding.right_6]}>{translate('business__tag_picker__remove_filters')}</div>
                <div>
                  <Icon src={closeIcon} size={Sizes.xxxsmall} color={eerieBlack['500']}/>
                </div>
              </div>
            </div>
          </>
        }
      </PickerContainer>
      {
        showPicker && <div style={ { transform: 'translateY(100px)' }}>
          <PickerContainer onToggle={() => setShowPicker(false)} level={whichSelector === 1 ? 1 : 2}>
            <div css={[Layout.display_flex, Layout.flex_column, { alignItems: 'flex-start' }, style]}>
              <div
                css={[Colors.BackgroundColor.eerieBlack100, Layout.display_flex, Spacing.Padding.horizontal_16, Spacing.Padding.vertical_8, Layout.rounded_8]}>
                <div css={Typography.Style.pointer}>
                  <Icon src={searchIcon} color={eerieBlack['600']} size={Sizes.small} css={Spacing.Margin.right_12}
                    onClick={() => onPick()} className='input-icon'/>
                </div>
                <input type='text' placeholder={translate('business__tag_picker__placeholder')} value={search}
                  onChange={evt => setSearch(evt.target.value)} className={'input-text'}/>
              </div>
              <Spacer height={16}/>
              <div
                css={[Typography.label.label4_500, Colors.Color.eerieBlack500]}>{translate('business__tag_picker__subtitle')}</div>
              <Spacer height={16}/>

              <div css={[Layout.hide_scrollbar, { alignSelf: 'stretch', maxHeight: 160, overflowY: 'scroll' }]}>
                {body(filteredData(), selectorPicked, listPicked)}
              </div>
            </div>
          </PickerContainer>
        </div>
      }
    </>
  )
}
