/* eslint-disable react/jsx-key */
import { Colors, eerieBlack } from '../../style/colors'
import { Icon } from '../icon'
import { Layout, Spacing } from '../../style/spacing'
import { Placeholder } from '../placeholder/index'
import { Spacer } from '../spacer/index'
import { Typography } from '../../style/typography'
import { style } from './index.css'
import { usePagination, useRowSelect, useTable } from 'react-table'
import React, { useEffect } from 'react'
import chevronBack from '../../images/icons/chevron-back.svg'
import chevronForward from '../../images/icons/chevron-forward.svg'

export interface BusinessTableProps {
  columns: any
  data: any
  onSelectionChanges?: Function
  onPaginationChange?: (pageIndex: number, pageSize: number) => void
  loading: boolean
  pageCount: number
  offset?: number
  limit?: number
}

const TableContainer = ({ children }) => {
  return <div css={[Colors.BackgroundColor.eerieBlack0, Layout.rounded_10, Colors.DropShadow.white100]}>
    {children}
  </div>
}

const PlaceholderTable = () => (
  <Placeholder width={'100%'} height={'241px'}
    css={[Layout.display_flex, Layout.ColumnGap.md, Layout.flex_column]} padding={24}
    animated={false}>
    <Placeholder width={'100%'} height={'40px'}/>
    <Spacer height={4}/>
    <Placeholder width={'100%'} height={'40px'}/>
  </Placeholder>
)

const IndeterminateCheckbox:React.FC<any> = React.forwardRef(
  ({ indeterminate, ...rest }, ref) => {
    const defaultRef = React.useRef()
    const resolvedRef = ref || defaultRef

    React.useEffect(() => {
      // @ts-ignore
      resolvedRef.current.indeterminate = indeterminate
    }, [resolvedRef, indeterminate])

    return <><input type="checkbox" ref={resolvedRef} {...rest} /></>
  }
)
export const PAGE_SIZE = 20
export const BusinessTable: React.FC<BusinessTableProps> = ({
  columns,
  data,
  onSelectionChanges,
  onPaginationChange,
  loading = false,
  pageCount = 1,
  offset = 0,
  limit = PAGE_SIZE
}) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    previousPage,
    canPreviousPage,
    nextPage,
    canNextPage,
    pageOptions,
    setPageSize,
    selectedFlatRows,
    state: { pageIndex, pageSize, selectedRowIds }
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: Math.ceil(offset / limit), pageSize: limit },
      manualPagination: true,
      pageCount,
      stateReducer: (newState, { type }) => {
        /** we need to have pagination controlled from state */
        if (type === 'gotoPage') return newState
        if (type === 'setPageSize') {
          newState.pageIndex = 0
          return newState
        }
        newState.pageIndex = Math.ceil(offset / limit)
        return newState
      }
    },
    usePagination,
    useRowSelect,
    hooks => {
      hooks.visibleColumns.push(columns => [
        {
          Cell: ({ row }) => (
            <div>
              <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
            </div>
          ),
          Header: ({ getToggleAllPageRowsSelectedProps }) => (
            <div>
              <IndeterminateCheckbox {...getToggleAllPageRowsSelectedProps()} />
            </div>
          ),
          id: 'selection'
        },
        ...columns
      ])
    }
  )
  useEffect(() => {
    onSelectionChanges && onSelectionChanges(selectedFlatRows.map(d => d.original))
  }, [onSelectionChanges, selectedFlatRows, selectedRowIds])
  useEffect(() => {
    onPaginationChange && onPaginationChange(pageIndex, pageSize)
  }, [onPaginationChange, pageIndex, pageSize])
  if (loading) return <PlaceholderTable/>
  return (
    <TableContainer>
      <div css={style}>
        <table {...getTableProps()}>
          <thead>
            {// Loop over the header rows
              headerGroups.map(headerGroup => (
              // Apply the header row props
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {// Loop over the headers in each row
                    headerGroup.headers.map(column => (
                      <th {...column.getHeaderProps()}>
                        {// Render the header
                          column.render('Header')}
                      </th>
                    ))}
                </tr>
              ))}
          </thead>
          {/* Apply the table body props */}
          <tbody {...getTableBodyProps()}>
            {// Loop over the table rows
              rows.map(row => {
              // Prepare the row for display
                prepareRow(row)
                return (
                // Apply the row props
                  <tr {...row.getRowProps()} >
                    {// Loop over the rows cells
                      row.cells.map(cell => {
                      // Apply the cell props
                        return (
                          <td {...cell.getCellProps()}>
                            {// Render the cell contents
                              cell.render('Cell')}
                          </td>
                        )
                      })}
                  </tr>
                )
              })}
          </tbody>
        </table>

        <div className="pagination" css={[Layout.display_flex, Layout.flex_justify_spaceBetween]}>
          <div css={[Layout.flex_grow_1]}>
            <span css={[Typography.label.label3_500, Colors.Color.eerieBlack500]}>
          Pagina{' '}
              <strong
                css={[Spacing.Padding.all_8, Colors.BackgroundColor.eerieBlack100, Layout.rounded_5, Colors.Color.eerieBlack600]}>{pageIndex + 1}</strong>{' '}
              di {pageOptions.length}
            </span>

            <select
              css={[Spacing.Margin.left_16, Spacing.Padding.all_8, Colors.BackgroundColor.eerieBlack100, Layout.rounded_5, Colors.Color.eerieBlack600, Colors.BorderColor.none, Typography.label.label3_500]}
              onChange={e => {
                setPageSize(Number(e.target.value))
              }}
            >
              {[20, 50, 100].map(size => (
                <option selected={pageSize === size} key={size} value={size}>
                  {size} per pagina
                </option>
              ))}
            </select>
          </div>

          <div>
            <button onClick={() => previousPage()} disabled={!canPreviousPage}
              css={[
                Colors.BackgroundColor.none,
                Colors.BorderColor.none,
                canPreviousPage ? Typography.Style.pointer : Typography.Style.not_allowed
              ]}>
              <Icon src={chevronBack} color={canPreviousPage ? eerieBlack['600'] : eerieBlack['200']}/>
            </button>
            <button onClick={() => nextPage()} disabled={!canNextPage}
              css={[
                Colors.BackgroundColor.none,
                Colors.BorderColor.none,
                canNextPage ? Typography.Style.pointer : Typography.Style.not_allowed
              ]}>
              <Icon src={chevronForward} color={canNextPage ? eerieBlack['600'] : eerieBlack['200']}/>
            </button>
          </div>
        </div>
      </div>
    </TableContainer>
  )
}
