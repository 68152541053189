/* eslint-disable react/jsx-key */
import { BusinessTable, BusinessTableProps } from '../businessTable'
import { Colors, eerieBlack } from '../../style/colors'
import { ContractIcon } from '../businessContractTable/contractIcon'
import { FileType, Invoice } from '../../lib/apollo/types'
import { Icon } from '../icon'
import { InvoiceStatusLabel } from './invoiceStatusLabel'
import { Layout } from '../../style/spacing'
import { SelectItem } from '../businessContractTable/index'
import { Sizes } from '../../style/sizes'
import { Typography } from '../../style/typography'
import { TypographyTitle } from '../typographyTitle'
import { formatDate } from '../../lib/localization/formatDate'
import { styleHoverIcon } from '../businessContractTable/index.css'
import { toCurrency, translate } from '../../lib/localization'
import React from 'react'
import dayjs from '../../lib/dayJs'
import downloadIcon from '../../images/icons/download.svg'

type BusinessInvoiceTableProps = Omit<BusinessTableProps, 'columns' | 'data'> & { invoices: Array<Invoice> }

interface CellProps {
  value: any
  row: {
    original: Invoice
    toggleRowSelected: (boolean) => void
    isSelected: boolean
  }
}
const columns = [
  {
    Cell: ({ value, row: { original: invoice, ...rest } }: CellProps) => {
      return <SelectItem {...rest}>
        <ContractIcon type={invoice?.Contract?.type}/>
        <span css={[Typography.label.label3_500, Colors.Color.eerieBlack800]}>Bolletta {value}</span>
      </SelectItem>
    },
    Header: translate('business_invoice_table__header__detail'),
    accessor: 'enerp_ref'
  },
  {
    Cell: ({ row: { original: invoice, ...rest } }: CellProps) => <SelectItem {...rest}>
      <InvoiceStatusLabel invoice={invoice}/>
    </SelectItem>,
    Header: translate('business_invoice_table__header__status'),
    accessor: 'status'
  },
  {
    Cell: ({ value, row }: CellProps) => (
      <SelectItem {...row}>
        <TypographyTitle text={toCurrency(value)}/>
      </SelectItem>
    ),
    Header: translate('business_invoice_table__header__amount'),
    accessor: 'amount'
  },
  {
    Cell: ({ row: { original: invoice, ...rest } }: CellProps) => {
      const sameMonth = dayjs(invoice.startDate).isSame(invoice.endDate, 'month')

      if (sameMonth) {
        return <SelectItem {...rest}><div
          css={[Typography.label.label4_400, Colors.Color.eerieBlack800]}>{formatDate(invoice.startDate)}</div></SelectItem>
      }
      return <SelectItem {...rest}>
        <div
          css={[Typography.label.label4_400, Colors.Color.eerieBlack800]}>{formatDate(invoice.startDate)} - {formatDate(invoice.endDate)}</div>
      </SelectItem>
    },
    Header: translate('business_invoice_table__header__startEndDate'),
    accessor: 'periodo'
  },
  {
    Cell: ({ value, row }: CellProps) => (
      <SelectItem {...row}>
        <div css={[Typography.label.label4_400, Colors.Color.eerieBlack800]}>{formatDate(value)}</div>
      </SelectItem>),
    Header: translate('business_invoice_table__header__date'),
    accessor: 'date'
  },
  {
    Cell: ({ row: { original: invoice } }: CellProps) => {
      if (invoice?.Files?.length === 0) return null
      const downloadInvoice = invoice.Files.find(file => file.type === FileType.UnifiedInvoice)?.getSignedObjectUrl || invoice.Files[0].getSignedObjectUrl
      return <div css={[Layout.flex_1, Layout.horizontal_center, styleHoverIcon]}>
        <a href={downloadInvoice} target={'_blank'} rel="noreferrer">
          <Icon src={downloadIcon}
            size={Sizes.xsmall}
            color={eerieBlack['500']}/>
        </a>
      </div>
    },
    Header: translate('contracts-table__header--download'),
    accessor: 'action',
    width: '24px'
  }
]
const BusinessInvoiceTable: React.FC<BusinessInvoiceTableProps> = ({ invoices, ...props }) => (
  <BusinessTable columns={columns} data={invoices} {...props}/>
)

export default BusinessInvoiceTable
