import { Icon } from '../components'
import { Layout, Spacing } from '../style/spacing'
import { Typography } from '../style/typography'
import { darkMint, eerieBlack, uaRed } from '../style/colors'
import { useSnackbar as useSnackbaRNr } from 'react-simple-snackbar'
import ErrorIcon from '../images/icons/alert-circle.svg'
import React, { useCallback } from 'react'
import SuccessIcon from '../images/icons/checkmark-circle.svg'

export enum SnackbarTypes {
  success = 'success',
  error = 'error'
}

export const snackbarDurationDefault = 5000

const IconMap = {
  [SnackbarTypes.success]: { color: darkMint['500'], src: SuccessIcon },
  [SnackbarTypes.error]: { color: uaRed['500'], src: ErrorIcon }
}
/**
 * Configurazione di default per
 * snackbar
 * type success warning
 */

const baseStyle = {
  closeStyle: {
    color: eerieBlack['700'],
    fontSize: '12px'
  },
  style: {
    backgroundColor: eerieBlack['800'],
    borderRadius: 10
  }
}
const options = {
  [SnackbarTypes.success]: { ...baseStyle },
  [SnackbarTypes.error]: { ...baseStyle }
}

const Content = ({ children, iconConfig }) => (<div css={[Layout.display_flex, Layout.vertical_center]}>
  <Icon css={[Spacing.Margin.right_12]} {...iconConfig}/>
  <div css={[Typography.label.label2_500]}>{children}</div>
</div>)

export const useSnackbar: (type?: SnackbarTypes) => [Function, Function] = (type = SnackbarTypes.success) => {
  const [open, close] = useSnackbaRNr(options[type])
  const openSnackbar = useCallback((node, duration) => {
    open(<Content iconConfig={IconMap[type]}>{node}</Content>, duration)
  }, [open, type])
  return [openSnackbar, close]
}
