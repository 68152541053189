import { contractAzul, royalPurple } from '../style/colors'
import EnergyIcon from '../images/icons/energy-icon.svg'
import GasIcon from '../images/icons/gas-icon.svg'

export const Attributes = {
  contractEarlyExecution: 'contractEarlyExecution',
  cooking: 'contract_gas_type_cooking',
  heating: 'contract_gas_type_heating',
  resident: 'resident'
}

export enum InvoiceStatus {
    payed = 'payed',
    open = 'open',
    notPayed = 'not_payed',
    pending = 'pending'
}

export enum StripeCardType {
    card = 'card',
    sepaDebit = 'sepa_debit',
    stamp = 'stamp'
}
export enum PaymentMethodTargetTypes {
    creditCard = 'credit_card',
    sepa = 'sepa'
}

export enum StripeCardFundingType {
    credit = 'credit',
    debit = 'debit',
    prepaid = 'prepaid'
}

export enum StripeCardBrand {
    amex,
    discover,
    jcb,
    unionpay,
    Visa = 'Visa',
    Mastercard = 'Mastercard',
    cartesBancaires = 'cartes_bancaires',
    dinersClub = 'diners_club'
}

export enum ActivationTypes {
    subentro = 'takeover',
    switch = 'switch',
    voltura = 'transfer'
}

export enum ContractTypes {
    energy = 'energy',
    gas = 'gas'
}

export enum CustomerTypes {
    company = 'company',
    freelance = 'freelance',
    individual = 'individual',
    private = 'private'
}

export enum ContactTypes {
    private = 'private',
    business = 'business'
}

export enum CustomerStatuses {
    active = 'active',
    disabled = 'disabled',
    rejected = 'rejected'
}

export enum CustomerSelectionType {
    owner = 'owner',
    private = 'private',
    business = 'business'
}

export enum Flow {
    quote = 'quote',
    contract = 'contract'
}

export enum TargetTypes {
    business = 'business',
    private = 'private' // in web-order we have private as domestic value checks backend
}

export enum PropertyUse {
    tenant = 'tenant',
    owner = 'owner',
    anotherCapacity = 'anotherCapacity'
}

export enum AddressTypes {
    customer = 'customer_address',
    supply = 'supply_address'
}

export enum GasTypesOfUse {
    heating = 'heating',
    cooking = 'cooking'
}

export enum EnergyDeviceTypes {
    AirConditioning = 'air-conditioning',
    Stove = 'stove',
    Refrigerator = 'refrigerator',
    Dishwasher = 'dishwasher',
    WashingMachine = 'washing-machine',
    InductionHob = 'induction-hob'
}

export enum TariffType {
    Variable = 'variable',
    Fixed = 'fixed'
}

export enum QuoteAccuracyTypes {
    accurate = 'accurate',
    unaccurate = 'unaccurate'
}

export const ContractIconMap = {
  [ContractTypes.energy]: EnergyIcon,
  [ContractTypes.gas]: GasIcon
}

export const ContractColorMap = {
  [ContractTypes.energy]: contractAzul['500'],
  [ContractTypes.gas]: royalPurple['500']
}

export const ContractTypeMap = {
  ee: ContractTypes.energy,
  gas: ContractTypes.gas
}

export enum ContractLegacyType {
  ee= 'ee',
  gas= 'gas'
}

export enum MagicLinkTarget {
  contract = 'contract'
}

export const SubStatuses = {
  SUBSTATUS_EE_STEP_1: 'ee_step_1',
  SUBSTATUS_EE_STEP_1_CREDIT_KO: 'ee_step_1_credit_ko',
  SUBSTATUS_EE_STEP_1_KO: 'ee_step_1_ko',
  SUBSTATUS_EE_STEP_2: 'ee_step_2',
  SUBSTATUS_EE_STEP_3_KO: 'ee_step_3_ko',
  SUBSTATUS_EE_STEP_3_OK: 'ee_step_3_ok',
  SUBSTATUS_EE_STEP_4: 'ee_step_4',
  SUBSTATUS_GAS_STEP_1: 'gas_step_1',
  SUBSTATUS_GAS_STEP_1_CREDIT_KO: 'gas_step_1_credit_ko',
  SUBSTATUS_GAS_STEP_1_KO: 'gas_step_1_ko',
  SUBSTATUS_GAS_STEP_2: 'gas_step_2',
  SUBSTATUS_GAS_STEP_3_KO: 'gas_step_3_ko',
  SUBSTATUS_GAS_STEP_3_OK: 'gas_step_3_ok',
  SUBSTATUS_GAS_STEP_4: 'gas_step_4'
}
