import { Layout } from '../../style/spacing'
import React, { ReactNode } from 'react'

interface BaseItemLayout {
  leftElement?: ReactNode
  rightElement?: ReactNode
  firstTextRow?: ReactNode
  secondTextRow?: ReactNode
  className?: string
  verticalAlign?: boolean
}

interface FirstTextRow extends BaseItemLayout {
  firstTextRow: ReactNode
}

interface SecondTextRow extends BaseItemLayout {
  secondTextRow: ReactNode
}

type ItemLayoutType = FirstTextRow | SecondTextRow;

/**
 * Generic Item Layout, It just manage the positioning of the Item elements in a row filling all horizontal available space
 * The Elements are vertically aligned and the central block expand itself to horizontally fill.
 * The highest elements gives the height
 */

export const ItemLayout: React.FC<ItemLayoutType> = ({
  className,
  leftElement,
  rightElement,
  firstTextRow,
  secondTextRow,
  verticalAlign = true
}) => (
  <div className={className}
    css={[Layout.display_flex, Layout.flex_0, verticalAlign && Layout.vertical_center]}>
    <div css={[Layout.flex_0]}>{leftElement}</div>
    <div css={[Layout.flex_1, { overflow: 'hidden' }]}>
      <div>{firstTextRow}</div>
      <div>{secondTextRow}</div>
    </div>
    <div css={[Layout.flex_0]}>{rightElement}</div>
  </div>

)
