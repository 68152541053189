import { Card, Icon, Spacer } from '../../../components'
import { Colors, eerieBlack } from '../../../style/colors'
import { ItemLayout } from '../../../layouts/item'
import { Layout, Spacing } from '../../../style/spacing'
import { MainScreenLayout } from '../../../layouts/mainScreen'
import { PageLayout } from '../../../layouts/page'
import { Placeholder } from '../../../components/placeholder'
import { Screens } from '../../../main/types'
import { Typography } from '../../../style/typography'
import { css } from '@emotion/react'
import { hoverClicker } from '../../../style/responsive'
import { iconStyle } from './index.css'
import { logAndSendToErrorTracker } from '../../../services/errorTracker'
import { translate } from '../../../lib/localization'
import { useGetFilteredCustomersQuery } from '../../../lib/apollo/hooks'
import React from 'react'
import customersIcon from '../../../images/icons/outline/id-card.svg'
import forwardIcon from '../../../images/icons/chevron-forward.svg'

export const PlaceHolderCustomersList = <>
  <div css={[Layout.display_flex, css`padding: 32px 18px 32px 22px`]}>
    <div css={[Layout.flex_1, css`max-width: 48px`]}>
      <Placeholder width={'28px'} height={'28px'} radius={28}/>
    </div>
    <div css={[Layout.flex_1]}>
      <Placeholder width={'30%'} height={'20px'}/>
      <Spacer height={4}/>
      <Placeholder width={'30%'} height={'20px'}/>
    </div>
  </div>
  <div css={[Layout.display_flex, css`padding: 32px 18px 32px 22px`]}>
    <div css={[Layout.flex_1, css`max-width: 48px`]}>
      <Placeholder width={'28px'} height={'28px'} radius={28}/>
    </div>
    <div css={[Layout.flex_1]}>
      <Placeholder width={'30%'} height={'20px'}/>
      <Spacer height={4}/>
      <Placeholder width={'30%'} height={'20px'}/>
    </div>
  </div></>

export const BusinessCustomersScreen = ({ navigation }) => {
  const { data, loading } = useGetFilteredCustomersQuery({
    fetchPolicy: 'cache-and-network',
    onError: error => {
      logAndSendToErrorTracker(error)
    }
  })
  const customers = data?.Customers ?? []
  return (
    <PageLayout>
      <MainScreenLayout sectionHeaderProps={{
        icon: customersIcon,
        path: `${translate('screens_title__account_settings')} /`,
        title: translate('sidenav_menu__title_customers')
      }}
      showToolbox={false}>
        <div css={[Layout.display_flex, Layout.ColumnGap.sm]}>
          <Card css={Layout.flex_1}>
            {
              loading && PlaceHolderCustomersList
            }
            { customers?.map((customer, index) => {
              const icon = customer.BillingContact?.firstName || customer.BillingContact?.lastName ? `${customer.BillingContact?.firstName?.charAt(0) ?? ''}${customer.BillingContact?.lastName?.charAt(0) ?? ''}` : customer.BillingContact?.fullname?.charAt(0)
              const text = customer.BillingContact?.fullname
              return (
                <div key={customer.id}>
                  <div css={[hoverClicker, Typography.Style.pointer, Spacing.Padding.vertical_8, Spacing.Margin.top_16, Spacing.Margin.bottom_16]} onClick={() => navigation.navigate(Screens.BusinessCustomer, {
                    customerId: customer.id
                  }
                  )}>
                    <ItemLayout
                      key={customer.BillingContact.id}
                      firstTextRow={<div css={[Typography.paragraph.p3_500, Spacing.Margin.left_12]}>{text}</div>}
                      leftElement={<div css={iconStyle}>{icon}</div>}
                      secondTextRow={<div css={[Typography.label.label4_400, Colors.Color.eerieBlack600, Spacing.Margin.left_12]}>{customer.BillingContact?.socialSecurityNumber}</div>}
                      rightElement={<div css={Layout.display_flex}><Icon src={forwardIcon} css={[Typography.Style.pointer]} color={eerieBlack['400']}/><div css={Spacing.Margin.horizontal_12}/></div>}
                    />
                  </div>
                  {
                    (index !== customers.length - 1) && <div css={[Layout.border_bottom]} />
                  }
                </div>
              )
            }
            )
            }
          </Card>
        </div>
      </MainScreenLayout>
    </PageLayout>
  )
}
