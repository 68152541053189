import { typographyTitleStyle } from './index.css'
import React from 'react'

export interface TypographyTitleProps {
  /**
   * Content to display
   */
  text: string
}

export const TypographyTitle: React.FC<TypographyTitleProps> = ({
  text
}) => {
  return (
    <h1 css={typographyTitleStyle}>{text}</h1>
  )
}
