import { branchApiKey } from '../configuration/env'
import { logAndSendToErrorTracker } from './errorTracker'
import { overmindInstance } from '../store'
import branch, { BranchError } from 'branch-sdk'

export const branchInit = async () => {
  const searchParams = new URLSearchParams(window.location.search)
  if (!searchParams.get('_branch_match_id')) {
    branch.init(branchApiKey, {}, (error: BranchError) => {
      if (error) logAndSendToErrorTracker(new Error(error))
    })
    return Promise.resolve(branch)
  }

  return new Promise<typeof branch>((resolve, reject) => {
    branch.init(branchApiKey, {}, (error: BranchError, data) => {
      if (error) {
        logAndSendToErrorTracker(new Error(error))
        reject(error)
      } else {
        // We store branch data in the store to be used later only if we clicked on a branch link
        if (data.data_parsed && data.data_parsed['+clicked_branch_link']) {
          overmindInstance.actions.setBranchData({ value: data.data_parsed })
          resolve(branch)
        }
      }
    })
  })
}
