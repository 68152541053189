import { Button, Card, HookFormInputText, Spacer, TextSeparator } from '../../components'
import { Colors, eerieBlack } from '../../style/colors'
import { SharedEvents } from '@tate-it/tracking'
import { Typography } from '../../style/typography'
import { auth } from '../../configuration/firebase'
import { fetchSignInMethodsForEmail } from 'firebase/auth'
import { isBusinessUsageTarget, isPrivateUsageTarget } from '../../configuration/env'
import { logAndSendToErrorTracker } from '../../services/errorTracker'
import { requestMagicLink } from '../../services/magicLink'
import { translate } from '../../lib/localization'
import { useAppState } from '../../store'
import { useCountdown } from '../../hooks/countdown'
import { useForm } from 'react-hook-form'
import { useTracking } from '../../tracking/useTracking'
import React, { useCallback, useState } from 'react'

enum message {
  none,
  error = 1,
  success = 2,
  emailNotFound = 3
}

const REGEX_EMAIL = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

export const Login: React.FC = () => {
  const state = useAppState()
  const { track } = useTracking()
  const [loading, setLoading] = useState(false)
  const { control, errors, handleSubmit, formState } = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onBlur'
  })
  const [count, startCountdown] = useCountdown()
  // 0 false, 1 error message, 2 success message
  const [stateMessage, setMessage] = useState<message>(0)

  const sendMagicLink = useCallback(async (data) => {
    setLoading(true)
    try {
      let target: 'dashboard' | 'business' = 'dashboard'
      if (isBusinessUsageTarget) target = 'business'
      await requestMagicLink({ email: data.email, redirectUrl: state.redirectUrl, strategy: 'signIn', target })
      track({ name: SharedEvents.User.AttemptedLogin })
      setMessage(message.success)
      startCountdown(60)
    } catch (error) {
      logAndSendToErrorTracker(error)
      setMessage(message.error)
    } finally {
      setLoading(false)
    }
  }, [startCountdown, state.redirectUrl, track])

  return (
    <div css={{ width: '452px' }}>
      <Card>
        <div css={Typography.Title.bold}>
          {translate('login_widget__title')}
        </div>
        <div css={[Typography.Callout.medium, { color: eerieBlack['600'] }]}>
          {translate('login_widget__subtitle')}
        </div>
        <Spacer height={24}/>
        <HookFormInputText
          name="email"
          control={control}
          rules={{
            pattern: {
              message: translate('forms__text-input__email__label--invalid_email'),
              value: REGEX_EMAIL
            },
            required: translate('forms__text-input__email__label--required'),
            validate: {
              asyncValidate: async (value) => {
                const authMethods = await fetchSignInMethodsForEmail(auth, value)
                if (!authMethods.length) {
                  setMessage(message.emailNotFound)
                  return false
                }
                setMessage(message.none)
                return true
              }
            }
          }}
          error={errors.email}
          inputProps={{
            inputMode: 'email',
            label: translate('input_text_label__email')
          }}
        />
        {
          stateMessage === message.error &&
          <>
            <Spacer height={8}/>
            <div css={[Typography.Caption.caption1_400, Colors.Color.uaRed500, Typography.Align.center]}>
              {translate('login_widget_magic_link_error')}
            </div>
          </>
        }
        {
          stateMessage === message.success &&
          <>
            <Spacer height={8}/>
            <div css={[Typography.Caption.caption1_400, Colors.Color.eerieBlack600, Typography.Align.center]}>
              {translate('login_widget__magic_link_sent')}
            </div>
          </>
        }
        {
          stateMessage === message.emailNotFound &&
          <>
            <Spacer height={8}/>
            <div css={[Typography.Caption.caption1_400, Colors.Color.eerieBlack600, Typography.Align.center]}>
              {translate('login_widget_magic_link__email_not_found')}
            </div>
          </>
        }
        {
          stateMessage !== message.emailNotFound &&
          <>
            <Spacer height={16}/>
            <Button
              disabled={count > 0}
              loading={loading || formState.isSubmitting}
              onClick={handleSubmit(sendMagicLink)}
              style="secondary"
              title={translate('button_login__login_with_email')}
            />
          </>
        }
        {
          isPrivateUsageTarget &&
          <>
            <Spacer height={16}/>
            <TextSeparator text={translate('login_widget__no_account')}/>
            <Spacer height={16}/>
            <Button
              onClick={() => {
                window.location.href = 'https://tate.it'
              }}
              style="primary"
              title={translate('button_login__discover_tate')}
            />
          </>
        }
        <Spacer height={32}/>
      </Card>
    </div>
  )
}
