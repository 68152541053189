import '../lib/gestureHandler'
import { ApolloProvider } from '@apollo/client'
import { Configuration } from '../contexts/configuration'
import { DefaultTheme, NavigationContainer, Theme } from '@react-navigation/native'
import { FirebaseProvider } from '../contexts/firebase'
import { GlobalStyle } from '../style/core.css'
import { Provider } from 'overmind-react'
import { RootErrorBoundary } from '../components/rootErrorBoundary'
import { RootNavigator } from './rootNavigator'
import { SharedEvents } from '@tate-it/tracking'
import { SplashScreen } from '../screens/shared/Splash'
import { auth, firebaseApp } from '../configuration/firebase'
import { createApolloClient } from '../configuration/apollo'
import { eerieBlack } from '../style/colors'
import { initTracking, segmentAnalytics } from '../tracking/init'
import { isBusinessUsageTarget, isPrivateUsageTarget } from '../configuration/env'
import { isMobileOnly } from 'react-device-detect'
import { onAuthStateChanged } from 'firebase/auth'
import { onLogin, onLogout } from './authHandlers'
import { overmindInstance } from '../store'
import React, { useEffect, useState } from 'react'
import SnackbarProvider from 'react-simple-snackbar'

const client = createApolloClient()

export const config: any = {
  screens: {
    Guest: {
      path: '/login',
      screens: {
        Login: '/',
        MagicLink: '/magic-link'
      }
    },
    NotFound: '/*'
  }
}
if (isPrivateUsageTarget) {
  config.screens.Private = {
    path: '/',
    screens: {
      AggiungiPagamenti: '/metodi-pagamento/aggiungi',
      Autolettura: '/contratto/:contractId/autolettura',
      Consumi: '/contratto/:contractId/bollette',
      GestisciMetodiPagamento: '/contratto/:contractId/metodi-pagamento',
      Home: '/',
      Intestatario: '/contratto/:contractId/voltura',
      PagaOra: 'contratto/:contractId/paga-ora/:invoiceId',
      Pagamenti: '/metodi-pagamento',
      PrivacyPolicy: '/privacy-policy',
      Profilo: '/profilo',
      Referral: '/risparmia',
      Utenza: '/contratto/:contractId'
    }
  }
}
if (isBusinessUsageTarget) {
  config.screens.Business = {
    path: '/',
    screens: {
      BusinessAddPaymentMethod: '/metodi-pagamento/aggiungi',
      BusinessContract: '/utenza/:id',
      BusinessContracts: '/utenze/:tag?',
      BusinessCustomer: '/anagrafica/:customerId',
      BusinessCustomers: '/anagrafiche',
      BusinessHome: '/',
      BusinessInvoices: '/fatture/:tag?',
      BusinessLabel: '/etichette',
      BusinessPaymentMethod: '/metodo-pagamento/:paymentMethodId',
      BusinessPaymentMethods: '/metodi-pagamento',
      BusinessProfile: '/profilo'
    }
  }
}

const customTheme: Theme = {
  ...DefaultTheme,
  colors: {
    ...DefaultTheme.colors,
    background: eerieBlack['50']
  }
}

const initializeApp = async () => {
  auth.useDeviceLanguage()
  await initTracking()
}

export const useInitializeApp = () => {
  const [isInitialized, setIsInitialized] = useState<boolean>(false)

  const onAuthStateChangedHandler = async user => {
    // Initialize app on first stateChange
    if (!isInitialized) {
      await initializeApp()
    }

    // React to Firebase user
    if (user) {
      await onLogin()
    } else {
      await onLogout()
    }

    // Toggle initialized
    if (!isInitialized) {
      setIsInitialized(true)
    }
  }

  useEffect((): any => {
    return onAuthStateChanged(auth, onAuthStateChangedHandler)
  }, [isInitialized]); // eslint-disable-line

  return [isInitialized]
}

const App = () => {
  useEffect(() => {
    if (isMobileOnly) {
      window.location.href = 'https://tate.it/deep-link/'
    }
  }, [])

  return (
    <Provider value={overmindInstance}>
      <Configuration>
        <FirebaseProvider value={firebaseApp}>
          <ApolloProvider client={client}>
            <SnackbarProvider>
              <NavigationContainer
                theme={customTheme}
                linking={{
                  config,
                  enabled: true,
                  prefixes: ['http://localhost:3000'] // TODO Deep links
                }}
                onReady={() => {
                  // Track initial page view
                  segmentAnalytics.page(SharedEvents.Page.Viewed)
                }}
                onStateChange={() => {
                  segmentAnalytics.page(SharedEvents.Page.Viewed)
                }}
              >
                <RootNavigator/>
              </NavigationContainer>
            </SnackbarProvider>
          </ApolloProvider>
        </FirebaseProvider>
      </Configuration>
    </Provider>
  )
}

export const RootComponent: React.FC = () => {
  const [isInitialized] = useInitializeApp()

  return (
    <RootErrorBoundary>
      <GlobalStyle/>
      {!isInitialized && <SplashScreen/>}
      {isInitialized && <App/>}
    </RootErrorBoundary>
  )
}

export default RootComponent
