import { Spacing } from '../../style/spacing'
import { Typography } from '../../style/typography'
import { cardStyle } from './index.css'
import React from 'react'

export const CardTitle: React.FC = ({ children }) => (
  <div css={[Typography.heading.h6_700]}>{children}</div>
)

export interface CardProps {
  children: any
  className?: any
  onClick?: Function
}

export const Card: React.FC<CardProps> = ({
  children,
  className,
  onClick
}) => {
  return (
    <div className={className} css={[cardStyle, Spacing.Padding.all_24]} onClick={() => onClick?.()}>
      {children}
    </div>
  )
}
