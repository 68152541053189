import { Typography } from '../../style/typography'
import { css } from '@emotion/react'

export const style = css`
  table {
    width: 100%;
    border-spacing: 0;
    border: 0;

    tr {
      :last-child {

        td {
          border-bottom: 0;
        }
      }
    }

    th {
      margin: 0;
      padding-bottom: 16px;
      border-bottom: 0;
      border-right: 0;
      text-align: left;
      ${Typography.fonts.upperCase}
      ${Typography.label.label4_600}
      color: #D2D2D2;
      :first-child {
        padding-left:12px;
      }
      :last-child {
        border-right: 0;
      }
    }

    td {
      margin: 0;
      padding-bottom: 24px;
      border-bottom: 0;
      border-right: 0;

      :last-child {
        border-right: 0;
      }
    }
  }
`
