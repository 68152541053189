import { Card, Spacer } from '../../../components'
import { Colors } from '../../../style/colors'
import { ContactType, WhereOperator } from '../../../lib/apollo/types'
import { GapSizes, MainScreenLayout } from '../../../layouts/mainScreen'
import { ItemLayout } from '../../../layouts/item'
import { Layout, Spacing } from '../../../style/spacing'
import { PageLayout } from '../../../layouts/page'
import { Placeholder } from '../../../components/placeholder'
import { Typography } from '../../../style/typography'
import { logAndSendToErrorTracker } from '../../../services/errorTracker'
import { translate } from '../../../lib/localization'
import { useGetFilteredCustomersQuery } from '../../../lib/apollo/hooks'
import BusinessContractTable from '../../../components/businessContractTable'
import React from 'react'
import customersIcon from '../../../images/icons/outline/id-card.svg'
import dayjs from 'dayjs'

const PlaceholderTable = <Placeholder width={'100%'} height={'241px'} css={[Layout.display_flex, Layout.ColumnGap.md, Layout.flex_column]} padding={24} animated={false}>
  <Placeholder width={'100%'} height={'40px'}/>
  <Spacer height={4}/>
  <Placeholder width={'100%'} height={'40px'}/>
</Placeholder>

const PlaceholderCard = <Placeholder width={'100%'} height={'220px'} css={[Layout.display_flex, Layout.ColumnGap.md]} padding={24} animated={false}>
  <div css={[Layout.flex_1]}>
    <Placeholder width={'80%'} height={'10px'}/>
    <Spacer height={4}/>
    <Placeholder width={'100%'} height={'20px'}/>
    <Spacer height={12}/>
    <Placeholder width={'80%'} height={'10px'}/>
    <Spacer height={4}/>
    <Placeholder width={'100%'} height={'20px'}/>
    <Spacer height={12}/>
    <Placeholder width={'80%'} height={'10px'}/>
    <Spacer height={4}/>
    <Placeholder width={'100%'} height={'20px'}/>
    <Spacer height={12}/>
  </div>
  <div css={[Layout.flex_1]}>
    <Placeholder width={'80%'} height={'10px'}/>
    <Spacer height={4}/>
    <Placeholder width={'100%'} height={'20px'}/>
    <Spacer height={12}/>
    <Placeholder width={'80%'} height={'10px'}/>
    <Spacer height={4}/>
    <Placeholder width={'100%'} height={'20px'}/>
    <Spacer height={12}/>
  </div>
</Placeholder>

export const BusinessCustomerScreen = ({ route }) => {
  const customerId = route?.params.customerId
  const { data, loading } = useGetFilteredCustomersQuery({
    fetchPolicy: 'cache-and-network',
    onError: error => {
      logAndSendToErrorTracker(error)
    },
    variables: {
      where: [
        {
          field: 'id',
          operator: WhereOperator.Equal,
          value: customerId
        }
      ]
    }
  })

  const contracts = data?.Customers[0].Contracts
  const contactInfo = data?.Customers[0].BillingContact

  const typeCustomer = {
    business: 'Business',
    private: 'Privato'
  }[contactInfo?.type]

  return (
    <PageLayout>
      {
        loading ? <>
          <Spacer height={48} />
          <ItemLayout
            firstTextRow={<Placeholder width={'60%'} height={'10px'} css={Spacing.Margin.bottom_8}/>}
            secondTextRow={<Placeholder width={'40%'} height={'15px'}/>}
            leftElement={<Placeholder height={'52px'} width={'52px'} radius={26} css={Spacing.Margin.right_8}/>}
          />
          <Spacer height={12} />
          {PlaceholderCard}
          <Spacer height={12} />
          {PlaceholderTable}
        </>
          : <MainScreenLayout gapSize={GapSizes.lg} sectionHeaderProps={{
            icon: customersIcon,
            path: `${translate('gestione-utenza__path')} / ${translate('sidenav_menu__title_customers')}`,
            title: `${contactInfo?.fullname}`
          }}
          showToolbox={false}>
            <Card css={[Layout.display_flex, Layout.flex_column]}>
              <div css={[Typography.label.label4_700, Colors.Color.eerieBlack500]}>{translate('business_invoice_table__header__detail').toUpperCase()}</div>
              <Spacer height={12} />
              <div css={[Layout.display_flex, Layout.flex_row]}>
                <div css={[Layout.display_flex, Layout.flex_column, Layout.flex_1]}>
                  {
                    contactInfo?.type === ContactType.Private && <ItemLayout
                      firstTextRow={<div css={[Typography.label.label4_400, Colors.Color.eerieBlack600]}>{translate('business_customer_fist_name')}</div>}
                      secondTextRow={<div css={Typography.paragraph.p3_500}>{contactInfo.firstName || 'N.D'}</div>}
                    />
                  }
                  {
                    contactInfo?.type === ContactType.Business && <ItemLayout
                      firstTextRow={<div css={[Typography.label.label4_400, Colors.Color.eerieBlack600]}>{translate('business_customer_fist_name')}</div>}
                      secondTextRow={<div css={Typography.paragraph.p3_500}>{contactInfo.fullname || 'N.D'}</div>}
                    />
                  }
                  <Spacer height={12} />
                  <ItemLayout
                    firstTextRow={<div css={[Typography.label.label4_400, Colors.Color.eerieBlack600]}>{translate('business_customer_category')}</div>}
                    secondTextRow={<div css={Typography.paragraph.p3_500}>{typeCustomer}</div>}
                  />
                  <Spacer height={12} />
                  {
                    contactInfo?.birthPlace && <ItemLayout
                      firstTextRow={<div css={[Typography.label.label4_400, Colors.Color.eerieBlack600]}>{translate('business_customer_birth_place')}</div>}
                      secondTextRow={<div css={Typography.paragraph.p3_500}>{contactInfo.birthPlace}</div>}
                    />
                  }
                  <Spacer height={12} />
                  <ItemLayout
                    firstTextRow={<div css={[Typography.label.label4_400, Colors.Color.eerieBlack600]}>{translate('business_customer_address')}</div>}
                    secondTextRow={<div css={Typography.paragraph.p3_500}>{contactInfo.addressFull}</div>}
                  />
                  <Spacer height={12} />
                </div>
                <div css={[Layout.display_flex, Layout.flex_column, Layout.flex_1]}>
                  {
                    contactInfo?.type === ContactType.Private && <ItemLayout
                      firstTextRow={<div css={[Typography.label.label4_400, Colors.Color.eerieBlack600]}>{translate('business_customer_fist_name')}</div>}
                      secondTextRow={<div css={Typography.paragraph.p3_500}>{contactInfo.lastName || 'N.D'}</div>}
                    />
                  }
                  <Spacer height={12} />
                  <ItemLayout
                    firstTextRow={<div css={[Typography.label.label4_400, Colors.Color.eerieBlack600]}>{translate('business_customer_social_security_number')}</div>}
                    secondTextRow={<div css={Typography.paragraph.p3_500}>{contactInfo.socialSecurityNumber}</div>}
                  />
                  <Spacer height={12} />
                  {
                    contactInfo?.birthDate && <ItemLayout
                      firstTextRow={<div css={[Typography.label.label4_400, Colors.Color.eerieBlack600]}>{translate('business_customer_birth_day')}</div>}
                      secondTextRow={<div css={Typography.paragraph.p3_500}>{dayjs(contactInfo.birthDate).format('LL')}</div>}
                    />
                  }
                  <Spacer height={12} />
                </div>
              </div>
            </Card>
            <BusinessContractTable
              contracts={contracts}
              onSelectionChanges={undefined}
            />
          </MainScreenLayout>
      }
    </PageLayout>
  )
}
