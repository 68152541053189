import { Card, Spacer } from '../../../components'
import { CardTitle } from '../../../components/card'
import { Colors, contractAzul, eerieBlack, royalPurple } from '../../../style/colors'
import { ConsumptionChart, graphqlDataToSeriesConsumption } from '../../../components/businessConsumptionChart'
import { ContractType } from '../../../lib/apollo/types'
import { ContractsChart, graphqlDataToSeriesContracts } from '../../../components/businessContractsChart'
import { CostsChart, getCostsChartType, graphqlDataToSeries } from '../../../components/businessCostsChart'
import { Layout, Spacing } from '../../../style/spacing'
import { MainScreenLayout } from '../../../layouts/mainScreen'
import { NumberCounter } from '../../../components/numberCounter'
import { PageLayout } from '../../../layouts/page'
import { Placeholder } from '../../../components/placeholder'
import { Screens } from '../../../main/types'
import { Typography } from '../../../style/typography'
import { logAndSendToErrorTracker } from '../../../services/errorTracker'
import { translate } from '../../../lib/localization'
import { useBusinessFilters } from '../../../hooks/useBusinessFilters'
import {
  useGetBusinessDashboardHomeSummaryQuery,
  useGetBusinessDashboardHomeTrendsQuery
} from '../../../lib/apollo/hooks'
import React, { useMemo } from 'react'
import SpeedometerOutlineIcon from '../../../images/icons/outline/speedometer.svg'
import useElementSize from '../../../hooks/useElementSize'

const PlaceholderHomepage: React.FC = () => (
  <div>
    <div css={[Layout.display_flex, Layout.flex_justify_spaceBetween, Layout.ColumnGap.md]}>
      <Placeholder width={'30%'} height={'150px'}/>
      <Placeholder width={'30%'} height={'150px'}/>
      <Placeholder width={'30%'} height={'150px'}/>
    </div>
    <Spacer height={24}/>
    <Placeholder width={'100%'} height={'375px'}/>
    <Spacer height={24}/>
    <div css={[Layout.display_flex, Layout.flex_justify_spaceBetween, Layout.ColumnGap.md]}>
      <Placeholder width={'50%'} height={'160px'}/>
      <Placeholder width={'50%'} height={'160px'}/>
    </div>
    <Spacer height={24}/>
    <div css={[Layout.display_flex, Layout.flex_justify_spaceBetween, Layout.ColumnGap.md]}>
      <Placeholder width={'50%'} height={'350px'}/>
      <Placeholder width={'50%'} height={'350px'}/>
    </div>
    <Spacer height={24}/>
    <Placeholder width={'100%'} height={'350px'}/>
  </div>
)

export const BusinessHomeScreen = ({ navigation, route }) => {
  const { endDate: endMonth, startDate: startMonth, rawLabels } = useBusinessFilters(route.name)
  const { data: dataDashboardSummary, loading: loadingSummary } = useGetBusinessDashboardHomeSummaryQuery({
    fetchPolicy: 'cache-and-network',
    onError: error => {
      logAndSendToErrorTracker(error)
    },
    variables: {
      end_date: endMonth,
      labels: rawLabels,
      start_date: startMonth
    }
  })

  const { data: dataDashboardTrends, loading: loadingTrends } = useGetBusinessDashboardHomeTrendsQuery({
    errorPolicy: 'all',
    fetchPolicy: 'cache-and-network',
    onError: error => {
      logAndSendToErrorTracker(error)
    },
    variables: {
      end_date: endMonth,
      labels: rawLabels,
      start_date: startMonth
    }
  })
  const {
    energy_consumption: eeConsumption,
    gas_consumption: gasConsumption
  } = dataDashboardSummary?.BusinessDataSum_v1 ?? {
    contracts: 0,
    energy_consumption: 0,
    energy_contracts: 0,
    gas_consumption: 0,
    gas_contracts: 0
  }
  const [costsChartContainerRef, { width: costsChartWidth }] = useElementSize()
  const costsChartData = useMemo(() => {
    return graphqlDataToSeries(dataDashboardTrends?.BusinessDataTrends_v1?.slice() ?? [])
  },
  [dataDashboardTrends?.BusinessDataTrends_v1])

  const consumptionChartData = useMemo(() => {
    return {
      dataEe: graphqlDataToSeriesConsumption(dataDashboardTrends?.BusinessDataTrends_v1 ?? [], ContractType.Ee),
      dataGas: graphqlDataToSeriesConsumption(dataDashboardTrends?.BusinessDataTrends_v1 ?? [], ContractType.Gas)
    }
  }, [dataDashboardTrends?.BusinessDataTrends_v1])

  const contractsChartData = useMemo(() => {
    return graphqlDataToSeriesContracts(dataDashboardTrends?.BusinessDataTrends_v1 ?? [])
  }, [dataDashboardTrends?.BusinessDataTrends_v1])

  return (
    <PageLayout>
      <MainScreenLayout sectionHeaderProps={{
        icon: SpeedometerOutlineIcon,
        path: `${translate('business__dashboard_header_message')}`,
        title: translate('sidenav_menu__title_dashboard')
      }}>
        {
          (loadingSummary || loadingTrends) && <PlaceholderHomepage/>
        }
        {!(loadingSummary || loadingTrends) &&
        <>
          <div css={[Layout.display_flex, Layout.flex_justify_spaceBetween, Layout.ColumnGap.md]}>
            <Card css={[Layout.flex_1]}>
              <CardTitle>{translate('business__dashboard__all_costs_type')}</CardTitle>
              <Spacer height={24}/>
              <NumberCounter
                value={dataDashboardSummary?.BusinessDataSum_v1.invoices}
                color={eerieBlack['500']}
                subtitle={translate('business__dashboard__all_contracts_type')}
                cta={() => {
                  navigation.navigate(Screens.BusinessContracts)
                }}/>
            </Card>
            <Card css={[Layout.flex_1]}>
              <CardTitle>{translate('business__dashboard__ee_costs_type')}</CardTitle>
              <Spacer height={24}/>
              <NumberCounter
                value={dataDashboardSummary?.BusinessDataSum_v1.energy_invoices}
                color={contractAzul['500']}
                subtitle={translate('business__dashboard__ee_contracts_type')}
                cta={() => {
                  navigation.navigate(Screens.BusinessContracts, { tag: 'Luce' })
                }}/>
            </Card>
            <Card css={[Layout.flex_1]}>
              <CardTitle>{translate('business__dashboard__gas_costs_type')}</CardTitle>
              <Spacer height={24}/>
              <NumberCounter
                value={dataDashboardSummary?.BusinessDataSum_v1.gas_invoices}
                color={royalPurple['500']}
                subtitle={translate('business__dashboard__gas_contracts_type')}
                cta={() => {
                  navigation.navigate(Screens.BusinessContracts, { tag: 'Gas' })
                }}/>
            </Card>
          </div>
          {
            costsChartData.find(element => element.data.find(deepData => deepData.x && deepData.y)) &&
            <Card>
              <CardTitle>{translate('business__dashboard__costs-chart__title')}</CardTitle>
              <Spacer height={24}/>
              <div css={{ height: 250 }} ref={costsChartContainerRef}>
                <CostsChart width={costsChartWidth} type={getCostsChartType(dataDashboardSummary?.BusinessDataSum_v1)}
                  data={costsChartData}/>
              </div>
            </Card>
          }

          <div css={[Layout.display_flex, Layout.flex_justify_spaceBetween, Layout.ColumnGap.md]}>
            <Card css={[Layout.flex_1]}>
              <CardTitle>{translate('business__dashboard__consumption--ee')}</CardTitle>
              <Spacer height={24}/>
              <NumberCounter
                type={ContractType.Ee}
                noFormat
                value={eeConsumption}
                color={contractAzul['500']}
                subtitle={translate('business__dashboard__ee_contracts_type')}
                cta={() => {
                  navigation.navigate(Screens.BusinessInvoices, { tag: 'Luce' })
                }}/>
            </Card>
            <Card css={[Layout.flex_1]}>
              <CardTitle>{translate('business__dashboard__consumption--gas')}</CardTitle>
              <Spacer height={24}/>
              <NumberCounter
                type={ContractType.Gas}
                noFormat
                value={gasConsumption}
                color={royalPurple['500']}
                subtitle={translate('business__dashboard__gas_contracts_type')}
                cta={() => {
                  navigation.navigate(Screens.BusinessInvoices, { tag: 'Gas' })
                }}/>
            </Card>
          </div>
          <div css={[Layout.display_flex, Layout.flex_justify_spaceBetween, Layout.ColumnGap.md]}>
            {
              consumptionChartData.dataEe.find(element => element.value !== 0) &&
              <Card css={[Layout.flex_1]}>
                <CardTitle>{translate('business__dashboard__consumption--ee')}</CardTitle>
                <Spacer height={24}/>
                <ConsumptionChart type={ContractType.Ee} data={consumptionChartData.dataEe}/>
                <Spacer height={10}/>
              </Card>
            }
            {
              consumptionChartData.dataGas.find(element => element.value !== 0) &&
              <Card css={[Layout.flex_1]}>
                <CardTitle>{translate('business__dashboard__consumption--gas')}</CardTitle>
                <Spacer height={24}/>
                <ConsumptionChart type={ContractType.Gas} data={consumptionChartData.dataGas}/>
                <Spacer height={10}/>
              </Card>
            }
          </div>
          {
            (contractsChartData.find(element => element.gas) || contractsChartData.find(element => element.ee)) &&
            <Card css={[Layout.flex_1]}>
              <CardTitle>{translate('business__dashboard__contracts')}</CardTitle>
              <Spacer height={24}/>
              <div css={Layout.display_flex}>
                <div css={[Layout.display_flex, Layout.horizontal_center, Layout.vertical_center]}>
                  <div css={[Colors.BackgroundColor.contractAzul500, Spacing.Margin.right_6, {
                    borderRadius: 2,
                    height: 10,
                    width: 10
                  }]}/>
                  <div
                    css={[Typography.label.label4_400, Colors.Color.eerieBlack600]}>{translate('business__dashboard__ee_contracts_type')}</div>
                </div>
                <div css={[Layout.display_flex, Layout.horizontal_center, Layout.vertical_center]}>
                  <div css={[Colors.BackgroundColor.royalPurple500, Spacing.Margin.right_6, {
                    borderRadius: 2,
                    height: 10,
                    width: 10
                  }]}/>
                  <div
                    css={[Typography.label.label4_400, Colors.Color.eerieBlack600]}>{translate('business__dashboard__gas_contracts_type')}</div>
                </div>
                <div css={[Layout.display_flex, Layout.horizontal_center, Layout.vertical_center]}>
                  <div css={[Colors.BackgroundColor.eerieBlack500, Spacing.Margin.right_6, {
                    borderRadius: 2,
                    height: 10,
                    width: 10
                  }]}/>
                  <div
                    css={[Typography.label.label4_400, Colors.Color.eerieBlack600]}>{translate('business__dashboard__all_contracts_type')}</div>
                </div>
              </div>
              <Spacer height={12}/>
              <div css={{ height: 300 }} ref={costsChartContainerRef}>
                <ContractsChart data={contractsChartData}/>
              </div>
            </Card>
          }
        </>
        }
      </MainScreenLayout>
    </PageLayout>
  )
}
