import { EnergyEventsPayload, SharedEventsPayload } from '@tate-it/tracking'

export enum ClientiEvents {
  TransferInvite_Created = 'transferInvite_created',
  TransferInvite_Shared = 'transferInvite_shared',
  TransferInviteCreation_Failed = 'TransferInviteCreation_failed'
}

export type ClientiEventsPayload = {
  [ClientiEvents.TransferInvite_Created]: undefined
  [ClientiEvents.TransferInvite_Shared]: undefined
  [ClientiEvents.TransferInviteCreation_Failed]: undefined
}

export type Event<Screens> = keyof SharedEventsPayload<Screens> | keyof ClientiEventsPayload | keyof EnergyEventsPayload

export type Payload<T, Screen> = T extends keyof SharedEventsPayload<Screen> ? SharedEventsPayload<Screen>[T] : T extends keyof ClientiEventsPayload ? ClientiEventsPayload[T] : never
