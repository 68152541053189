import { useAppState } from '../store'
import dayjs from '../lib/dayJs'

export const useBusinessFilters = (name: string) => {
  const { toolBox } = useAppState()
  const { monthFilter } = toolBox[name] ?? {}
  const { startMonth, endMonth } = monthFilter ?? {}

  const startDate = dayjs(startMonth).utc(true).startOf('day').toISOString()
  const endDate = dayjs(endMonth).utc(true).endOf('day').toISOString()

  const labelFilters = {
    rawLabels: [],
    scopeLabels: undefined
  }
  const toolboxFilters = toolBox[name]?.tagsFilter
  if (toolboxFilters?.andFilter?.length) {
    labelFilters.rawLabels = toolboxFilters?.andFilter?.map(filter => filter.name)
    labelFilters.scopeLabels = {
      args: JSON.stringify(labelFilters.rawLabels),
      name: 'withLabels'
    }
  }

  return {
    endDate,
    ...labelFilters,
    startDate
  }
}
