import { Button, Card, Icon, Modal, Spacer } from '../../../components'
import { ClientiEvents } from '../../../tracking/types'
import { Colors, eerieBlack } from '../../../style/colors'
import { ContractTransferCheckStatus, ContractType } from '../../../lib/apollo/types'
import { ItemLayout } from '../../../layouts/item'
import { Layout, Spacing } from '../../../style/spacing'
import { MainScreenLayout } from '../../../layouts/mainScreen'
import { PageLayout } from '../../../layouts/page'
import { Placeholder } from '../../../components/placeholder'
import { Screens } from '../../../main/types'
import { Sizes } from '../../../style/sizes'
import { SnackbarTypes, snackbarDurationDefault, useSnackbar } from '../../../hooks/useSnackbar'
import { Typography } from '../../../style/typography'
import { logAndSendToErrorTracker } from '../../../services/errorTracker'
import { toCurrency, translate } from '../../../lib/localization'
import { useDeleteTransferCodeMutation, useGetContractQuery, useGetContractTransferCheckLazyQuery, useGetTransferCostsQuery } from '../../../lib/apollo/hooks'
import { useTracking } from '../../../tracking/useTracking'
import React, { useEffect, useState } from 'react'
import error from '../../../images/dashboard/voltura/error@3x.png'
import groupIcon from '../../../images/icons/outline/group.svg'
import invite from '../../../images/dashboard/voltura/invite@3x.png'
import link from '../../../images/dashboard/voltura/link@3x.png'
import pending from '../../../images/dashboard/voltura/pending@3x.png'
import pricetagIcon from '../../../images/icons/outline/pricetag.svg'

const KEY_ADMIN_COST = 'transfer_admin_cost'
const KEY_SUPPLIER_COST = 'transfer_supplier_cost'

const transferCostKey = (type, additionalCosts) => {
  if (type === ContractType.Ee) {
    if (additionalCosts) return 'transfer_screen__item_layout_2_subtitle--ee--no-supplier-costs'
    return 'transfer_screen__item_layout_2_subtitle--ee'
  }

  return 'transfer_screen__item_layout_2_subtitle--gas'
}

const InvitePlaceholder = () => {
  return <Placeholder width={'100%'} height={'200px'} css={[Layout.flex_2]} padding={24} animated={false}>
    <div css={[Layout.display_flex]}>
      <div css={[Layout.flex_2]}>
        <Placeholder width={'90%'} height={'16px'}/>
        <Spacer height={16}/>
        <Placeholder width={'90%'} height={'16px'}/>
        <Spacer height={16}/>
        <Placeholder width={'50%'} height={'16px'}/>
        <Spacer height={16}/>
        <Placeholder width={'90%'} height={'40px'}/>
      </div>
      <div css={[Layout.flex_1, Layout.vertical_center, Layout.horizontal_center]}>
        <Placeholder width={'104px'} height={'104px'} radius={104}/>
      </div>
    </div>
  </Placeholder>
}

export const VolturaScreen = ({ route, navigation }) => {
  const { data: configurationsData } = useGetTransferCostsQuery()
  const [showModal, setShowModal] = useState(false)
  const [trackEvents, setTrackEvents] = useState(false)
  const { track } = useTracking()

  const transferAdminCost = configurationsData?.Configurations.find(
    c => c.key === KEY_ADMIN_COST
  )?.value
  const transferSupplierCost = configurationsData?.Configurations.find(
    c => c.key === KEY_SUPPLIER_COST
  )?.value

  const { data, loading } = useGetContractQuery({
    fetchPolicy: 'network-only',
    onError: logAndSendToErrorTracker,
    variables: { id: route.params?.contractId }
  })
  const [contractCheckQuery, { data: contractTransferCheckData, loading: contractTransferCheckLoading, error: queryError, called: transferCheckCalled }] = useGetContractTransferCheckLazyQuery({
    fetchPolicy: 'network-only',
    onCompleted: () => {
      if (trackEvents) track({ name: ClientiEvents.TransferInvite_Created })
      setTrackEvents(false)
    },
    onError: (error) => {
      logAndSendToErrorTracker(error)
      if (trackEvents) track({ name: ClientiEvents.TransferInviteCreation_Failed })
      setTrackEvents(false)
    }
  })

  const [openSnackbarError] = useSnackbar(SnackbarTypes.error)

  const [deleteTransferError, { loading: deleteTransferCodeLoading }] = useDeleteTransferCodeMutation({
    awaitRefetchQueries: true,
    onCompleted: () => {
      contractCheckQuery({
        variables: { contractId: route.params?.contractId, retrieveLink: false }
      })
    },
    onError: (error) => {
      logAndSendToErrorTracker(error)
      openSnackbarError(translate('snackbar_message__delete__transfer_code_error'), snackbarDurationDefault)
    },
    variables: { contractId: route.params?.contractId }
  })

  useEffect(() => {
    if (!transferCheckCalled && data?.Contracts[0]) {
      contractCheckQuery({
        variables: {
          contractId: route.params?.contractId,
          retrieveLink: data?.Contracts[0]?.hasTransferCode
        }
      })
    }
  }, [contractCheckQuery, data?.Contracts, route.params?.contractId, transferCheckCalled])

  const [openSnackbarSuccess] = useSnackbar()
  const copyToClipboard = (code: string) => {
    track({ name: ClientiEvents.TransferInvite_Shared })
    openSnackbarSuccess(translate('snackbar_message__copy__transfer_code_success'), snackbarDurationDefault)
    navigator.clipboard.writeText(code)
  }

  const cardConfiguration = () => {
    if (queryError) {
      // general error return
      return {
        CTAFunction: () => window.Intercom('show'),
        CTALabel: translate('button_help'),
        image: error,
        subtitle: translate('transfer_screen__invite_section--error__subtitle'),
        title: translate('transfer_screen__invite_section--error__title')
      }
    }

    if (contractTransferCheckLoading || !contractTransferCheckData) return {}

    if ([ContractTransferCheckStatus.RejectedBusinessTarget, ContractTransferCheckStatus.RejectedNotSupplied].includes(contractTransferCheckData.ContractTransferCheck_v2.status)) {
      // general error return
      return {
        CTAFunction: () => window.Intercom('show'),
        CTALabel: translate('button_help'),
        image: error,
        subtitle: translate('transfer_screen__invite_section--error__subtitle'),
        title: translate('transfer_screen__invite_section--error__title')
      }
    }
    if (contractTransferCheckData.ContractTransferCheck_v2.status === ContractTransferCheckStatus.RejectedPendingInvoices) {
      return {
        CTAFunction: () => navigation.navigate(Screens.Consumi, { contractId: route.params?.contractId }),
        CTALabel: translate('transfer_screen__invite_section--unpaid_invoices__CTA'),
        image: error,
        subtitle: translate('transfer_screen__invite_section--unpaid_invoices__subtitle'),
        title: translate('transfer_screen__invite_section--unpaid_invoices__title')
      }
    }

    // transfer exists and is pending
    if (contractTransferCheckData.ContractTransferCheck_v2.status === ContractTransferCheckStatus.RejectedPendingTransfer) {
      return {
        CTAFunction: () => window.Intercom('show'),
        CTALabel: translate('button_help'),
        image: pending,
        subtitle: translate('transfer_screen__invite_section--transfer_pending__subtitle'),
        title: translate('transfer_screen__invite_section--transfer_pending__title')
      }
    }

    // No transfer code present
    if (!contractTransferCheckData.ContractTransferCheck_v2.link) {
      return {
        CTAFunction: async () => {
          setTrackEvents(true)
          contractCheckQuery({
            variables: {
              contractId: route.params?.contractId,
              retrieveLink: true
            }
          })
        },
        CTALabel: translate('transfer_screen__invite_section--create_invite__CTA'),
        image: invite,
        subtitle: translate('transfer_screen__invite_section--create_invite__subtitle'),
        title: translate('transfer_screen__invite_section--create_invite__title')
      }
    }

    return {
      CTA2Function: () => setShowModal(true),
      CTA2Label: translate('button_destroy_code'),
      CTAFunction: () => copyToClipboard(contractTransferCheckData.ContractTransferCheck_v2.link),
      CTALabel: translate('button_share_code'),
      image: link,
      subtitle: translate('transfer_screen__invite_section--share_invite__subtitle'),
      title: translate('transfer_screen__invite_section--share_invite__title')
    }
  }

  const cardConfig = cardConfiguration()

  if (!data || loading) {
    return (
      <PageLayout>
        <div>
          <Spacer height={48}/>
          <Placeholder width={'52px'} height={'52px'} radius={52}/>
          <Spacer height={22}/>
          <div css={[Layout.display_flex]}>
            <Placeholder width={'100%'} height={'160px'} css={[Layout.flex_2]} padding={24} animated={false}>
              <div css={Layout.display_flex}>
                <ItemLayout
                  css={[{ flex: 1, width: '300px' }]}
                  firstTextRow={<div><Placeholder width={'30%'} height={'16px'}/><Placeholder width={'100%'} height={'16px'} css={[Spacing.Margin.top_8, Spacing.Margin.bottom_6]}/><Placeholder width={'100%'} height={'16px'} css={[Spacing.Margin.top_8, Spacing.Margin.bottom_6]}/></div>}
                  leftElement={<Placeholder width={'52px'} height={'52px'} radius={52} css={[Spacing.Margin.right_12, Spacing.Margin.bottom_48]}/>}
                  secondTextRow={<Placeholder width={'30%'} height={'16px'}/>}
                />
                <ItemLayout
                  css={[Layout.border_left, Spacing.Margin.left_16, { flex: 1, width: '300px' }]}
                  firstTextRow={<div><Placeholder width={'30%'} height={'16px'}/><Placeholder width={'100%'} height={'16px'} css={[Spacing.Margin.top_8, Spacing.Margin.bottom_6]}/><Placeholder width={'100%'} height={'16px'} css={[Spacing.Margin.top_8, Spacing.Margin.bottom_6]}/></div>}
                  leftElement={<Placeholder width={'52px'} height={'52px'} radius={52} css={[Spacing.Margin.left_16, Spacing.Margin.right_12, Spacing.Margin.bottom_56]}/>}
                  secondTextRow={<Placeholder width={'30%'} height={'16px'}/>}
                />
              </div>
            </Placeholder>
          </div>
          <Spacer height={32} />
          <InvitePlaceholder />
        </div>
      </PageLayout>
    )
  }

  const { title, subtitle, CTALabel, CTAFunction, CTA2Label, CTA2Function, image } = cardConfig

  return (
    <PageLayout>
      <MainScreenLayout sectionHeaderProps={{
        icon: groupIcon,
        path: `${data?.Contracts[0]?.name} /`,
        title: translate('transfer_screen_title')
      }} >
        <Card>
          <div css={[Typography.label.label4_600, Colors.Color.eerieBlack500, Typography.fonts.upperCase]}>{translate('informations_label')}</div>
          <Spacer height={16} />
          <div css={Layout.display_flex}>
            <ItemLayout
              css={[{ flex: 1 }]}
              verticalAlign={false}
              firstTextRow={<div css={[Typography.label.label2_500]}>{translate('transfer_screen__item_layout_1_title')}</div>}
              leftElement={<Icon src={groupIcon} css={[Spacing.Margin.right_12, Spacing.Margin.bottom_48]} color={eerieBlack['800']} type={'icon'} size={Sizes.medium} />}
              secondTextRow={<div css={[Typography.Caption.caption1_400, Colors.Color.eerieBlack600]}>{translate('transfer_screen__item_layout_1_subtitle')}</div>}
            />
            <ItemLayout
              css={[Layout.border_left, Spacing.Margin.left_16, { flex: 1 }]}
              verticalAlign={false}
              firstTextRow={<div css={[Typography.label.label2_500]}>{translate('transfer_screen__item_layout_2_title')}</div>}
              leftElement={<Icon src={pricetagIcon} css={[Spacing.Margin.left_16, Spacing.Margin.right_12, Spacing.Margin.bottom_48]} color={eerieBlack['800']} type={'icon'} size={Sizes.medium} />}
              secondTextRow={<div css={[Typography.Caption.caption1_400, Colors.Color.eerieBlack600]}>{translate(transferCostKey(data?.Contracts[0]?.type, parseInt(transferSupplierCost) === 0), { transferAdminCost: toCurrency(transferAdminCost), transferSupplierCost: toCurrency(transferSupplierCost) })}</div>}
            />
          </div>
        </Card>
        { (contractTransferCheckLoading || !cardConfig || deleteTransferCodeLoading) ? <InvitePlaceholder /> : <Card>
          <div css={[Typography.label.label4_600, Colors.Color.eerieBlack500, Typography.fonts.upperCase]}>{translate('transfer_code_label')}</div>
          <Spacer height={12} />
          <div css={Layout.display_flex}>
            <div css={[Layout.flex_1, Layout.flex_column, Layout.display_flex, Layout.flex_justify_spaceBetween]}>
              <div>
                <div css={[Typography.label.label1_600, Colors.Color.eerieBlack800]}>{title}</div>
                <Spacer height={8} />
                <div css={[Typography.paragraph.p3_400, Colors.Color.eerieBlack600]}>{subtitle}</div>
                <Spacer height={16} />
              </div>
              <div css={[Layout.display_flex]}>
                <div css={[Spacing.Margin.right_12, Layout.vertical_center]}>
                  <Button
                    title={CTALabel}
                    onClick={CTAFunction}
                    style={'primary'}
                    block={false}
                    size={Sizes.medium}
                  />
                </div>
                {
                  CTA2Label && <div css={[Spacing.Margin.right_12, Layout.vertical_center]}>
                    <Button
                      title={CTA2Label}
                      onClick={CTA2Function}
                      style={'border-only'}
                      block={false}
                      size={Sizes.medium}
                      css={[Spacing.Margin.left_12]}
                    />
                  </div>
                }
              </div>
            </div>
            <div css={[Layout.flex_1, Layout.horizontal_center, Spacing.Padding.left_48]}>
              <img alt="" src={image} css={{ height: 160, width: 160 }} />
            </div>
          </div>
        </Card>
        }
        { showModal &&
          <Modal
            title={<div css={Typography.heading.h5_700}>{translate('transfer_screen__invite_section--modal__title')}</div>}
            hideModal={() => setShowModal(false)}
            hideBackground={true}
          >
            <div>{translate('transfer_screen__invite_section--modal__subtitle')}</div>
            <div css={[Layout.display_flex, Layout.row_reverse, Spacing.Margin.top_32]}>
              <Button
                title={translate('button_confirm')}
                style={'primary'}
                onClick={() => {
                  setShowModal(false)
                  deleteTransferError()
                }}
                size={Sizes.medium}
                loading={false}
              />
              <div css={Spacing.Padding.horizontal_8}/>
              <Button
                title={translate('button_cancel')}
                style={'border-only'} onClick={() => setShowModal(false)}
                size={Sizes.medium}
              />
            </div>
          </Modal>
        }
      </MainScreenLayout>
    </PageLayout>
  )
}
