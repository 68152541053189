import { Colors, darkMint, uaRed } from '../../style/colors'
import { ContractType } from '../../lib/apollo/types'
import { Layout, Spacing } from '../../style/spacing'
import { Typography } from '../../style/typography'
import { toPercentage, translate } from '../../lib/localization'
import React from 'react'

interface ToolTipCharProps {
    data?: any
    contractType?: ContractType
    date?: string
    value?: string
    change: string | number
    translateX?: number
}

export const Tooltip: React.FC<ToolTipCharProps> = ({ data, date, value, change, translateX }) => {
  const deltaStyle = change <= 0 ? { backgroundColor: darkMint[50], color: darkMint[500] } : { backgroundColor: uaRed[50], color: uaRed[500] }
  return <div css={[Colors.DropShadow.white100, Spacing.Padding.all_8, Layout.rounded_8, Colors.BackgroundColor.eerieBlack0, { minWidth: 160, transform: `translate(${translateX}px, 0px)` }]}>
    <div css={[Typography.label.label3_500, Spacing.Margin.bottom_8]}>{date}</div>
    {
      data?.serieId && <div css={[Layout.display_flex, Layout.vertical_center]}>
        <div css={[Layout.rounded_3, { backgroundColor: data.serieColor, height: 10, width: 10 }]}/>
        <div css={[Typography.label.label4_500, Spacing.Padding.left_6, Colors.Color.eerieBlack600]}>{data.serieId}</div>
      </div>
    }
    <div css={[Typography.label.label4_700, Colors.Color.eerieBlack800]}>{value}</div>
    <div css={[Layout.display_flex, Layout.vertical_center, Spacing.Margin.top_2]}>
      <div css={[Typography.label.label4_400, Layout.rounded_3, Spacing.Padding.all_2, Spacing.Margin.right_4]} style={deltaStyle}>{toPercentage(change)}</div>
      <div css={[Typography.label.label4_500, Colors.Color.eerieBlack500]}>{translate('business__dashboard__costs-chart__tooltip--delta')}</div>
    </div>
  </div>
}
