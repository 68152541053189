import { Card, Spacer } from '../../components'
import { Colors } from '../../style/colors'
import { ContractTypes } from '../../enums'
import { Layout } from '../../style/spacing'
import { Typography } from '../../style/typography'
import { translate } from '../../lib/localization'
import React from 'react'

export interface WidgetTariffProps {
  /* Type of tariff */
  tariff: string
  /* Classname for custom styling */
  className?: string
  /* Type of contract */
  contractType: ContractTypes
  /* POD/PDR Code */
  code: string
  /* Address of the contract */
  address: string
  /* Estimated yearly consumption of the resource */
  yearlyConsumption: string
  /* Available meter power resource */
  availableMeterPower?: string
}

const CONSUMPTION_UNIT_MAP = {
  [ContractTypes.gas]: 'Smc',
  [ContractTypes.energy]: 'kWh'
}

export const WidgetTariff: React.FC<WidgetTariffProps> = ({ tariff, className, code, address, yearlyConsumption, availableMeterPower, contractType }) => (
  <div css={[Layout.flex_1]} className={className}>
    <Card css={{ height: '100%' }}>
      <div css={[Typography.label.label4_600, Colors.Color.eerieBlack500, Typography.fonts.upperCase]}>{translate('tariff_widget__your_tariff')}</div>
      <Spacer height={12} />
      <div css={[Typography.label.label4_400, Colors.Color.eerieBlack600]}>{translate('tariff_widget__type')}</div>
      <div css={Typography.paragraph.p3_400}>{tariff}</div>
      <Spacer height={8}/>
      <div css={Layout.border_bottom} />
      <Spacer height={16} />
      <div css={[Typography.label.label4_600, Colors.Color.eerieBlack500, Typography.fonts.upperCase]}>{translate('tariff_widget__your_info')}</div>
      <Spacer height={12} />
      <div css={[Typography.label.label4_400, Colors.Color.eerieBlack600]}>{translate(`tariff_widget__code--${contractType}`)}</div>
      <div css={Typography.paragraph.p3_400}>{code}</div>
      <Spacer height={12} />
      <div css={[Typography.label.label4_400, Colors.Color.eerieBlack600]}>{translate('tariff_widget__address')}</div>
      <div css={Typography.paragraph.p3_400}>{address}</div>
      {!!yearlyConsumption && <><Spacer height={12} />
        <div css={[Typography.label.label4_400, Colors.Color.eerieBlack600]}>{translate('tariff_widget__yearly_estimated_consumption')}</div>
        <div css={Typography.paragraph.p3_400}>{yearlyConsumption} {CONSUMPTION_UNIT_MAP[contractType]}</div>
      </>}
      {!!availableMeterPower && <><Spacer height={12} />
        <div css={[Typography.label.label4_400, Colors.Color.eerieBlack600]}>{translate('tariff_widget__available_meter_power')}</div>
        <div css={Typography.paragraph.p3_400}>{availableMeterPower} kW</div>
      </>}
      <Spacer height={56} />
    </Card>
  </div>
)
