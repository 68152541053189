import 'dayjs/locale/it'
import I18n from 'i18n-js'
import it from '../../locales/dev/translation.json'

I18n.defaultLocale = 'it'
I18n.fallbacks = true
I18n.translations = { it }
I18n.missingTranslation = function (missingKey) {
  const error = new Error(`Missing translation ${missingKey}`)
  console.error(error)
  return undefined
}

const DEFAULT_OPTIONS = {
  delimiter: '',
  precision: 2,
  separator: ','
}

const fixNumberValue = value => {
  if (typeof value === 'number') return value
  if (typeof value === 'string') return value.split(',', 2).join('.')
  return 0
}

const toCurrency = (value, options = {}) => {
  const fixedValue = fixNumberValue(value)

  return I18n.toCurrency(fixedValue, {
    ...DEFAULT_OPTIONS,
    format: '%n %u',
    ...options,
    strip_insignificant_zeros: !fixedValue,
    unit: '€'
  })
}
const DefaultPercentage = new Intl.NumberFormat('it', {
  maximumFractionDigits: 2,
  minimumFractionDigits: 2,
  signDisplay: 'exceptZero',
  style: 'percent'
})
const toPercentage = (value) => {
  return DefaultPercentage.format(value)
}

const translate = (...args) => I18n.t(...args)

export {
  translate,
  toCurrency,
  toPercentage
}
