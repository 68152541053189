import { Colors, eerieBlack } from '../../style/colors'
import { Typography } from '../../style/typography'
import { css } from '@emotion/react'

export const style = css`   

    .background {
        left:0;
        top: 0;
        width: 100%;
        position: fixed;
        height: 100%;
        z-index: 1;
    }
    
   .options-container{
      background-color: ${eerieBlack['0']};
      border-radius: 16px;
      position: absolute;
      transform: translate(5%, 40%);
      z-index: 2;
      overflow: hidden;
   }

    .choices {
        display: inline-block;
        ${Typography.Style.pointer}
        ${Typography.label.label4_600}
        color: ${eerieBlack['800']};
        padding: 10px 13px;
        transition: background .5s;

        :hover {
            ${Colors.BackgroundColor.eerieBlack100}
            transition: background .5s;
        }
    }
  }
`
