import { ActionPopUp } from '../actionPopup'
import { ActionsTypes } from '../../layouts/mainScreen'
import { Colors, eerieBlack, violetBlue } from '../../style/colors'
import { DatePicker } from '../datePicker'
import { Icon } from '../index'
import { Layout, Spacing } from '../../style/spacing'
import { Sizes } from '../../style/sizes'
import { TagFilter } from '../tagFilter'
import { TagPicker } from '../tagPicker'
import { Typography } from '../../style/typography'
import { style } from './index.css'
import { translate } from '../../lib/localization'
import { useActions, useAppState } from '../../store'
import {
  useAttachLabelToContractMutation,
  useDetachLabelToContractMutation,
  useGetLabelsQuery
} from '../../lib/apollo/hooks'
import { useRoute } from '@react-navigation/native'
import React, { useState } from 'react'
import calendarIcon from '../../images/icons/calendar.svg'
import closeIcon from '../../images/icons/close.svg'
import createIcon from '../../images/icons/createOutline.svg'
import dayjs from '../../lib/dayJs'
import differenceBy from 'lodash/differenceBy'
import optionsIcon from '../../images/icons/options.svg'
import pricetagIcon from '../../images/icons/pricetag.svg'

export interface ToolBoxProps {
  // Visualizzazione Icona
  calendar?: boolean
  actions?: boolean
  contractTagManager?: boolean
  tagPicker?: boolean
  isDoubleDatePicker?: boolean
  onMutation?: Function
  actionsTypes?: ActionsTypes
}

export const ToolBox: React.FC<ToolBoxProps> = ({
  calendar = false,
  actions = false,
  contractTagManager = false,
  tagPicker = false,
  isDoubleDatePicker,
  onMutation,
  actionsTypes = ActionsTypes.contract
}) => {
  const { data, loading } = useGetLabelsQuery()
  const { selectedContracts, selectedInvoices } = useAppState()
  // spread per evitare problemi con Overmind (Crasha dato che gli verrebbe passato un'oggetto GraphQL)
  const labels = data?.Labels.map(tag => ({ ...tag }))
  const { name: screenName } = useRoute<any>()
  const { toolBox } = useAppState()
  const { updatePageFilter } = useActions()

  const { monthFilter, tagsFilter } = toolBox[screenName] ?? {}

  const numberTags = (tagsFilter?.andFilter?.length ?? 0) + (tagsFilter?.orFilter?.length ?? 0)

  const [tagPickerState, setTagPickerState] = useState(false)
  const [tagFilterState, setTagFilterState] = useState(false)
  const [datePickerState, setDatePickerState] = useState(false)
  const [actionsState, setActionsState] = useState(false)
  const [attachLabelMutation] = useAttachLabelToContractMutation()
  const [detachLabelMutation] = useDetachLabelToContractMutation()
  /****
   * When the contract tag manager close we have to commit the changes to the selected contracts and refetch the queries
   */
  const onContractTagsManagerClose = async () => {
    // @ts-ignore
    const updated = await Promise.all(selectedContracts?.map(({ id: contractId, Labels, originalLabels }) => {
      if (!originalLabels) return
      /**
         * Calcola le differenze nelle etichette e se necessario le aggiorna
         * */
      const labelsToBeAdded = differenceBy(Labels, originalLabels, 'name').map(({ id }) => id)
      const labelsToBeRemoved = differenceBy(originalLabels, Labels, 'name').map(({ id }) => id)
      return Promise.all(
        labelsToBeAdded.map(labelId => (attachLabelMutation({ variables: { contractId, labelId } })))
          .concat(
            labelsToBeRemoved.map(labelId => (detachLabelMutation({ variables: { contractId, labelId } })))
          )
      )
    }
    ))
    if (onMutation && updated.length) onMutation()
    setTagFilterState(false)
  }

  const updateTagsFilter = (tagsFilter) => {
    updatePageFilter({
      filter: {
        monthFilter: monthFilter,
        tagsFilter: tagsFilter
      },
      page: screenName
    })
  }

  const updateDateFilter = (monthFilter) => {
    updatePageFilter({
      filter: {
        monthFilter: monthFilter,
        tagsFilter
      },
      page: screenName
    })
  }

  const dateShown = () => {
    if (monthFilter?.endMonth) return `${dayjs(monthFilter.startMonth).format('MMMM YYYY')} - ${dayjs(monthFilter.endMonth).format('MMMM YYYY')}`
    if (monthFilter?.startMonth) return dayjs(monthFilter.startMonth).format('MMMM YYYY')
    return dayjs().format('MMMM YYYY')
  }

  return (
    <div css={[Layout.vertical_center, Layout.flex_row, Layout.flex_1, style, Layout.flex_justify_end]}>
      <div css={[(calendar || tagPicker) && [Layout.border_right, Spacing.Padding.right_8], Layout.display_flex]}
        style={{ transform: 'translate(0, 0)', zIndex: 1 }}>
        {
          actions && <div css={Typography.Style.pointer} className={'icon-background'}
            onClick={() => (selectedContracts?.length || selectedInvoices?.length) && setActionsState(true)}>
            <Icon src={createIcon} color={eerieBlack['700']} size={Sizes.medium}/>
            {
              actionsState && <ActionPopUp actionsTypes={actionsTypes} onToggle={() => setActionsState(false)}/>
            }
          </div>
        }
        {
          contractTagManager &&
          <div css={Typography.Style.pointer} className={'icon-background'}
            onClick={() => selectedContracts?.length && setTagFilterState(true)}>
            <Icon src={pricetagIcon} color={eerieBlack['700']} size={Sizes.medium}/>
            {
              tagFilterState &&
              <TagFilter onToggle={() => onContractTagsManagerClose()}
                data={labels}/>
            }
          </div>
        }
      </div>
      {
        calendar && <div
          css={[Layout.display_flex, Layout.flex_row, Layout.vertical_center, tagPicker && Layout.border_right, Spacing.Padding.horizontal_8]}
          style={{ transform: 'translate(0,0)', zIndex: 1 }}>
          <div css={[Typography.label.label3_500, Colors.Color.eerieBlack700, { whiteSpace: 'nowrap' }]}>{dateShown()}</div>
          <div css={[Typography.Style.pointer, Spacing.Margin.left_8]} className={'icon-background'}
            onClick={() => setDatePickerState(true)}>
            <Icon src={calendarIcon} color={eerieBlack['700']} size={Sizes.medium}/>
          </div>
          {datePickerState && <DatePicker onToggle={() => setDatePickerState(false)} monthFilter={monthFilter}
            updateFilter={updateDateFilter} isDoubleDatePicker={isDoubleDatePicker}/>}
        </div>
      }
      {
        tagPicker && <div css={[Layout.display_flex, Layout.flex_row, Layout.vertical_center, Spacing.Padding.left_8]}
          style={{ transform: 'translate(0,0)', zIndex: 1 }}>
          {
            numberTags !== 0 && <div
              css={[Colors.BackgroundColor.violetBlue50, Spacing.Padding.horizontal_12, Layout.flex_row, Layout.display_flex, Layout.vertical_center, {
                borderRadius: 32,
                paddingBottom: 5,
                paddingTop: 5
              }]}>
              <div
                css={[Typography.label.label4_500, Colors.Color.violetBlue500, { whiteSpace: 'nowrap' }]}>{translate('business__filter_active_text', { number: numberTags })}</div>
              <div css={Typography.Style.pointer} onClick={() => {
                updatePageFilter({
                  filter: {
                    monthFilter,
                    tagsFilter: {
                      andFilter: [],
                      orFilter: []
                    }
                  },
                  page: screenName
                })
              }}>
                <Icon src={closeIcon} color={violetBlue['200']} size={Sizes.xxsmall} css={Spacing.Margin.left_12}/>
              </div>

            </div>
          }
          <div css={[Typography.Style.pointer, numberTags && Spacing.Margin.left_16]} className={'icon-background'}
            onClick={() => !loading && setTagPickerState(true)}>
            <Icon src={optionsIcon} color={eerieBlack['700']} size={Sizes.medium}/>
          </div>
          {tagPickerState && <TagPicker onToggle={() => setTagPickerState(false)} data={labels} tagsFilter={tagsFilter}
            updateFilter={updateTagsFilter}/>}
        </div>
      }

    </div>
  )
}
