/* eslint-disable camelcase */
import { Event, Payload } from './types'
import { Screens } from '../main/types'
import { segmentAnalytics } from './init'
import { useCallback } from 'react'

export const SESSION_START_KEY__LAST_INTERACTION = 'contract-session__last_interaction'

interface BaseTrackingParameters<T> {
  name: T
}

type TrackingParameters<T> = (Payload<T, Screens> extends undefined ? {
  payload?: Payload<T, Screens>
} : {
  payload: Payload<T, Screens>
}) & BaseTrackingParameters<T>

export const useTracking = () => {
  const track = useCallback(<T extends Event<Screens>>({ name, payload }: TrackingParameters<T>) => {
    segmentAnalytics.track(name as string, payload, { timestamp: new Date().toISOString() })
  }, [])
  return { track }
}
