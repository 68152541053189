/* eslint-disable sort-keys-fix/sort-keys-fix */
import { Button, Card, Icon, Spacer } from '../../../components'
import { Colors, contractAzul, darkMint, marigold, royalPurple, uaRed } from '../../../style/colors'
import { ContractStatus, ContractType, Invoice, InvoiceStatus } from '../../../lib/apollo/types'
import { DownloadCsvButton } from '../../../components/downloadCsvButton'
import { GapSizes, MainScreenLayout } from '../../../layouts/mainScreen'
import { ItemLayout } from '../../../layouts/item'
import { Layout, Spacing } from '../../../style/spacing'
import { PageLayout } from '../../../layouts/page'
import { Placeholder } from '../../../components/placeholder'
import { STATUS_COLOR_MAP } from '../../../widgets/widgetStateContract'
import { Screens } from '../../../main/types'
import { Sizes } from '../../../style/sizes'
import { SubStatuses } from '../../../enums'
import { TextWithStyle } from '../../../components/textWithStyle'
import { Typography } from '../../../style/typography'
import { css } from '@emotion/react'
import { formatDate } from '../../../lib/localization/formatDate'
import { logAndSendToErrorTracker } from '../../../services/errorTracker'
import { toCurrency, translate } from '../../../lib/localization'
import { useActions, useAppState } from '../../../store'
import { useGetContractQuery } from '../../../lib/apollo/hooks'
import { usePagination } from '../../../hooks/usePagination'
import BusinessInvoiceTable from '../../../components/businessInvoiceTable'
import EnergyIcon from '../../../images/icons/energy-icon.svg'
import GasIcon from '../../../images/icons/gas-icon.svg'
import React, { useCallback } from 'react'
import dayjs from '../../../lib/dayJs'
import radioOff from '../../../images/icons/radio-button-on.svg'

const INVOICES_CSV_HEADERS = [
  { label: 'IDENTIFICATIVO BOLLETTA', key: 'enerp_ref' },
  { label: 'STATO', key: 'status' },
  { label: 'IMPORTO', key: 'amount' },
  { label: 'PERIODO DI RIFERIMENTO', key: 'startDate' },
  { label: 'EMESSA A', key: 'date' }
]

const INVOICE_STATUS_MAP = {
  [InvoiceStatus.Open]: translate('invoices_screen__badge_status--pending'),
  [InvoiceStatus.Payed]: translate('invoices_screen__badge_status--success'),
  [InvoiceStatus.NotPayed]: translate('invoices_screen__badge_status--not-payed'),
  [InvoiceStatus.Drafted]: translate('invoices_screen__badge_status--drafted')
}

const PlaceholderTable = <Placeholder width={'100%'} height={'241px'}
  css={[Layout.display_flex, Layout.ColumnGap.md, Layout.flex_column]} padding={24}
  animated={false}>
  <Placeholder width={'100%'} height={'40px'}/>
  <Spacer height={4}/>
  <Placeholder width={'100%'} height={'40px'}/>
</Placeholder>

const PlaceholderCard = <Placeholder width={'100%'} height={'220px'} css={[Layout.display_flex, Layout.ColumnGap.md]}
  padding={24} animated={false}>
  <div css={[Layout.flex_1]}>
    <Placeholder width={'80%'} height={'10px'}/>
    <Spacer height={4}/>
    <Placeholder width={'100%'} height={'20px'}/>
    <Spacer height={12}/>
    <Placeholder width={'80%'} height={'10px'}/>
    <Spacer height={4}/>
    <Placeholder width={'100%'} height={'20px'}/>
    <Spacer height={12}/>
    <Placeholder width={'80%'} height={'10px'}/>
    <Spacer height={4}/>
    <Placeholder width={'100%'} height={'20px'}/>
    <Spacer height={12}/>
  </div>
  <div css={[Layout.flex_1]}>
    <Placeholder width={'80%'} height={'10px'}/>
    <Spacer height={4}/>
    <Placeholder width={'100%'} height={'20px'}/>
    <Spacer height={12}/>
    <Placeholder width={'80%'} height={'10px'}/>
    <Spacer height={4}/>
    <Placeholder width={'100%'} height={'20px'}/>
    <Spacer height={12}/>
  </div>
</Placeholder>

export const BusinessContractScreen = ({ navigation, route }) => {
  const contractId = route?.params.id
  const [offset, limit, onPaginationChange] = usePagination()
  const { setSelectedInvoices } = useActions()
  const { selectedInvoices } = useAppState()
  const { data, loading } = useGetContractQuery({
    fetchPolicy: 'cache-and-network',
    onError: error => {
      logAndSendToErrorTracker(error)
    },
    variables: {
      id: contractId,
      limit,
      offset
    }
  })
  const contract = data?.Contracts[0]

  const invoices = contract?.Invoices ?? []
  const resident = contract?.Attributes.find(attribute => attribute.key === 'resident')?.value === 'true'
  const contractEarlyExecution = contract?.Attributes.find(attribute => attribute.key === 'contractEarlyExecution')
  const status = contract?.status
  const isContractTransferred = status === ContractStatus.Active && dayjs(contract?.end_date).isBefore(dayjs.utc(), 'day')
  const neverSuppliedContract = status === ContractStatus.Disabled && contract?.SubStatuses.some(subStatus =>
    [SubStatuses.SUBSTATUS_EE_STEP_1_CREDIT_KO,
      SubStatuses.SUBSTATUS_EE_STEP_1_KO,
      SubStatuses.SUBSTATUS_EE_STEP_3_KO,
      SubStatuses.SUBSTATUS_GAS_STEP_1_CREDIT_KO,
      SubStatuses.SUBSTATUS_GAS_STEP_1_KO,
      SubStatuses.SUBSTATUS_GAS_STEP_3_KO].includes(subStatus.name))
  const unpayedInvoice = invoices.find(invoice => invoice.status === InvoiceStatus.NotPayed || (invoice.status !== InvoiceStatus.Payed && dayjs().isAfter(invoice.dueDate)))
  const draftedOrOpenInvoice = invoices.find(invoice => invoice.status === InvoiceStatus.Drafted || invoice.status === InvoiceStatus.Open)

  const getPaymentStatus = useCallback(() => {
    if (unpayedInvoice) {
      return <TextWithStyle css={[Typography.paragraph.p3_500, { color: uaRed['500'] }]}
        text={translate('invoices_screen__unpaid_bill__title', { invoiceId: unpayedInvoice.enerp_ref })}/>
    }
    if (draftedOrOpenInvoice && draftedOrOpenInvoice.amount >= 5) {
      return <TextWithStyle css={[Typography.paragraph.p3_500, { color: marigold['500'] }]}
        text={translate('invoices_screen__drafted_bill__title', {
          amount: toCurrency(draftedOrOpenInvoice.amount),
          invoiceId: draftedOrOpenInvoice.enerp_ref
        })}/>
    }
    return <TextWithStyle css={[Typography.paragraph.p3_500, css`strong:last-of-type {
      color: ${darkMint['500']}
    }`]} text={translate('invoices_screen__paid_bill__title')}/>
  }, [draftedOrOpenInvoice, unpayedInvoice])

  const trueAndFalseToString = (element) => translate(`widget__text_${element ? 'yes' : 'no'}`)

  const onSelectionChanges = useCallback((selectedInvoices: Invoice[]) => {
    setSelectedInvoices(selectedInvoices.map(invoice => {
      return {
        enerp_ref: invoice.enerp_ref,
        status: INVOICE_STATUS_MAP[invoice.status],
        amount: invoice.amount,
        startDate: formatDate(invoice.startDate),
        date: formatDate(invoice.date)
      }
    }))
  }, [setSelectedInvoices])

  return (
    <PageLayout>
      {
        loading && <>
          <Spacer height={48}/>
          <ItemLayout
            firstTextRow={<Placeholder width={'60%'} height={'10px'} css={Spacing.Margin.bottom_8}/>}
            secondTextRow={<Placeholder width={'40%'} height={'15px'}/>}
            leftElement={<Placeholder height={'52px'} width={'52px'} radius={26} css={Spacing.Margin.right_8}/>}
          />
          <Spacer height={12}/>
          {PlaceholderCard}
          <Spacer height={12}/>
          {PlaceholderTable}
        </>
      }
      {
        !loading &&
        <MainScreenLayout gapSize={GapSizes.lg} sectionHeaderProps={{
          icon: contract?.type === ContractType.Ee ? EnergyIcon : GasIcon,
          iconColor: contract?.type === ContractType.Ee ? contractAzul['500'] : royalPurple['500'],
          path: `${translate('gestione-utenza__path')} / ${translate('sidenav_menu__title_business_contracts')}`,
          tags: contract?.Labels,
          title: contract?.name
        }}
        toolBoxConfiguration={{
          calendar: false,
          tagPicker: false
        }}>
          <Card css={[Layout.display_flex, Layout.flex_column, Layout.flex_1]}>
            <div
              css={[Typography.label.label4_700, Colors.Color.eerieBlack500]}>{translate('tariff_widget__your_tariff').toUpperCase()}</div>
            <Spacer height={16}/>
            <ItemLayout
              firstTextRow={<div
                css={[Typography.label.label4_400, Colors.Color.eerieBlack600]}>{translate('business_contract_page__card_header_type')}</div>}
              secondTextRow={<div
                css={Typography.paragraph.p3_500}>{translate(`tariff_widget__${contract?.contractCodeName.split('_')[0].toLowerCase()}`) || contract?.contractCodeName}</div>}
            />
            <Spacer height={24}/>
            <div css={Layout.border_bottom}/>
            <Spacer height={24}/>
            <div
              css={[Typography.label.label4_700, Colors.Color.eerieBlack500]}>{translate('tariff_widget__your_info').toUpperCase()}</div>
            <Spacer height={16}/>
            <div css={[Layout.display_flex, Layout.flex_row]}>
              <div css={[Layout.display_flex, Layout.flex_column, Layout.flex_1]}>
                <ItemLayout
                  firstTextRow={<div
                    css={[Typography.label.label4_400, Colors.Color.eerieBlack600]}>{translate(`business_contract_page__card_header__number--${contract?.type}`)}</div>}
                  secondTextRow={<div
                    css={Typography.paragraph.p3_500}>{contract?.Items[0].payload.podNumber ?? contract?.Items[0].payload.pdrNumber}</div>}
                />
                <Spacer height={12}/>
                <ItemLayout
                  firstTextRow={<div
                    css={[Typography.label.label4_400, Colors.Color.eerieBlack600]}>{translate('business_contract_page__card_header_yearly_usage_estimate')}</div>}
                  secondTextRow={<div
                    css={Typography.paragraph.p3_500}>{contract?.Items[0].payload.yearlyEstimatedConsumption}</div>}
                />
                <Spacer height={12}/>
                <ItemLayout
                  firstTextRow={<div
                    css={[Typography.label.label4_400, Colors.Color.eerieBlack600]}>{translate('business_contract_page__card_header_sign_date')}</div>}
                  secondTextRow={<div
                    css={Typography.paragraph.p3_500}>{dayjs(contract?.signatureDate).format('LL')}</div>}
                />
                <Spacer height={12}/>
                <ItemLayout
                  firstTextRow={<div
                    css={[Typography.label.label4_400, Colors.Color.eerieBlack600]}>{translate('business_contract_page__card_header_anticipated_start')}</div>}
                  secondTextRow={<div
                    css={Typography.paragraph.p3_500}>{trueAndFalseToString(contractEarlyExecution?.value)}</div>}
                />
                <Spacer height={12}/>
                <ItemLayout
                  firstTextRow={<div
                    css={[Typography.label.label4_400, Colors.Color.eerieBlack600]}>{translate('business_contract_page__card_header_resident')}</div>}
                  secondTextRow={<div css={Typography.paragraph.p3_500}>{trueAndFalseToString(resident)}</div>}
                />
                <Spacer height={12}/>
              </div>
              <div css={[Layout.display_flex, Layout.flex_column, Layout.flex_1]}>
                <ItemLayout
                  firstTextRow={<div
                    css={[Typography.label.label4_400, Colors.Color.eerieBlack600]}>{translate('business_contract_page__card_header_address')}</div>}
                  secondTextRow={<div
                    css={Typography.paragraph.p3_500}>{contract?.Items[0]?.Contact?.addressFull}</div>}
                />
                <Spacer height={12}/>
                {
                  contract?.Items[0].payload?.availableMeterPower && <>
                    <ItemLayout
                      firstTextRow={<div
                        css={[Typography.label.label4_400, Colors.Color.eerieBlack600]}>{translate('business_contract_page__card_meter_power_avaiable')}</div>}
                      secondTextRow={<div
                        css={Typography.paragraph.p3_500}>{contract?.Items[0].payload?.availableMeterPower} kW</div>}
                    />
                    <Spacer height={12}/>
                  </>
                }
                <ItemLayout
                  firstTextRow={<div
                    css={[Typography.label.label4_400, Colors.Color.eerieBlack600]}>{translate('business_contract_page__card_header_start_date')}</div>}
                  secondTextRow={<div
                    css={Typography.paragraph.p3_500}>{dayjs(contract?.start_date).format('LL')}</div>}
                />
                <Spacer height={12}/>
                <ItemLayout
                  firstTextRow={<div
                    css={[Typography.label.label4_400, Colors.Color.eerieBlack600]}>{translate('business_contract_page__card_header_ownership')}</div>}
                  secondTextRow={<div
                    css={Typography.paragraph.p3_500}>{translate(`business_contract_page__card_text_ownership--${contract?.Items[0].payload.possessionOfTheProperty}`)}</div>}
                />
                <Spacer height={12}/>
              </div>
            </div>
          </Card>
          <Card css={[Layout.display_flex, Layout.flex_column, Layout.flex_1]}>
            <div css={Layout.display_flex}>
              <div css={Layout.flex_1}>
                <div
                  css={[Typography.label.label4_600, Colors.Color.eerieBlack500, Typography.fonts.upperCase]}>{translate('state_contract_widget__title')}</div>
                <Spacer height={16}/>
                <ItemLayout
                  leftElement={<Icon src={radioOff} size={Sizes.xxsmall} color={STATUS_COLOR_MAP[status]}
                    css={[Spacing.Margin.right_6, Spacing.Margin.bottom_6]}/>}
                  firstTextRow={<div
                    css={[Typography.label.label4_600, Typography.fonts.upperCase]}>{translate(neverSuppliedContract ? 'state_contract_widget__state--never_supplied' : `state_contract_widget__state--${status}`)}</div>}
                />
                {status === ContractStatus.Active && !isContractTransferred &&
                  <TextWithStyle
                    text={translate('state_contract_widget__starting_date_info', { date: dayjs(contract?.start_date).format('DD/MM/YYYY') })}
                    css={Typography.paragraph.p3_500}/>}
                {isContractTransferred &&
                  <TextWithStyle
                    text={translate('state_contract_widget__end_date_info', { date: dayjs(contract?.end_date).format('DD/MM/YYYY') })}
                    css={Typography.paragraph.p3_500}/>}
                {status === ContractStatus.Pending &&
                  <TextWithStyle
                    text={translate('state_contract_widget__pending_info', { date: dayjs(contract?.start_date).format('DD/MM/YYYY') })}
                    css={Typography.paragraph.p3_500}/>}
                {status === ContractStatus.Disabled && !neverSuppliedContract &&
                  <TextWithStyle
                    text={translate('state_contract_widget__end_date_info', { date: dayjs(contract?.end_date).format('DD/MM/YYYY') })}
                    css={Typography.paragraph.p3_500}/>}
                {status === ContractStatus.Disabled && neverSuppliedContract &&
                  <div
                    css={Typography.paragraph.p3_500}>{translate('state_contract_widget__never_supplied_info')}</div>}
                <Spacer height={16}/>
              </div>
              <div css={[Spacing.Margin.horizontal_12, Layout.border_left]}/>
              <div css={Layout.flex_1}>
                <div
                  css={[Typography.label.label4_600, Colors.Color.eerieBlack500, Typography.fonts.upperCase]}>{translate('state_contract_widget__state_payments')}</div>
                <Spacer height={12}/>
                {unpayedInvoice &&
                  <ItemLayout firstTextRow={getPaymentStatus()}
                    rightElement={<Button
                      size={Sizes.small}
                      title={translate('pay_now_screen__button_text')}
                      style={'primary'}
                      onClick={() => navigation.navigate(Screens.PagaOra, {
                        contractId: contract?.id,
                        invoiceId: unpayedInvoice.id
                      })}
                    />}
                  />
                }
                {!unpayedInvoice && getPaymentStatus()}
                <Spacer height={24}/>
              </div>
            </div>
          </Card>
          <div>
            <div css={[Layout.display_flex, Layout.flex_justify_end]}>
              <DownloadCsvButton
                fileName={`${contract?.name}.csv`}
                headers={INVOICES_CSV_HEADERS}
                rows={loading ? [] : selectedInvoices}
                disabled={selectedInvoices.length === 0}
              />
            </div>
            <BusinessInvoiceTable
              loading={loading}
              onPaginationChange={onPaginationChange}
              onSelectionChanges={onSelectionChanges}
              invoices={invoices ?? []}
              pageCount={Math.ceil((invoices?.length ?? 0) / limit)}
              offset={offset}
              limit={limit}
            />
          </div>
        </MainScreenLayout>
      }

    </PageLayout>
  )
}
