import { css } from '@emotion/react'
import { transitionFast } from '../constants'

/**
 * Circle Icon
 * */
export const iconStyle = css`
  box-sizing: border-box;
  &[data-icon-type='circle'] {
    border-radius: 31px;

    &[data-size='xsmall'] {
      width: 38px;
      height: 38px;
      padding: 7px;
      border-radius: 19px;
    }

  &[data-size='small'] {
      width: 42px;
      height: 42px;
      border-radius: 22px;
      padding: 8.1px;
  }

    &[data-size='medium'] {
      width: 52px;
      height: 52px;
      padding: 9px;
      border-radius: 26px;
    }

    &[data-size='large'] {
      width: 58px;
      height: 58px;
      padding: 14px;
      border-radius: 30px;
    }

    &[data-size='xlarge'] {
      width: 89px;
      height: 89px;
      padding: 22px;
      border-radius: 45px;
    }

  }
  &[data-raised="true"] {
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.08), 0px 8px 16px rgba(0, 0, 0, 0.08);
  }

  &[data-icon-type='icon']  {
    [data-flat-size='xxxsmall'] {
      width: 9px;
      height: 9px;
    }
    [data-flat-size='xxsmall'] {
      width: 12px;
      height: 12px;
    }

    [data-flat-size='xsmall'] {
      width: 16px;
      height: 16px;
    }
    [data-flat-size='small'] {
      width: 20px;
      height: 20px;
    }

    [data-flat-size='medium'] {
      width: 24px;
      height: 24px;
    }

    [data-flat-size='large'] {
      width: 28px;
      height: 28px;
    }
    [data-flat-size='xlarge'] {
      width: 32px;
      height: 32px;
    }

  }

  div {
    transition: background-color ${transitionFast} ease-in-out;
    mask-size: contain;
    width: 100%;
    height: 100%;
  }
`
