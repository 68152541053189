import { css } from '@emotion/react'

export const style = css`
  .input-text {
      border: 0px;
      background: transparent;
  }

  .input-text:focus {
    outline-width: 0;
}
`
