import { AddPaymentMethodScreen } from '../screens/shared/AddPaymentMethods'
import { BusinessContractScreen } from '../screens/business/Contract'
import { BusinessContractsScreen } from '../screens/business/Contracts'
import { BusinessCustomerScreen } from '../screens/business/Customer'
import { BusinessCustomersScreen } from '../screens/business/Customers'
import { BusinessHomeScreen } from '../screens/business/Home'
import { BusinessInvoicesScreen } from '../screens/business/Invoices'
import { BusinessLabelsScreen } from '../screens/business/Labels'
import { BusinessNavigatorParams, Screens } from './types'
import { BusinessPaymentMethodScreen } from '../screens/business/PaymentMethod'
import { PaymentMethodsScreen } from '../screens/shared/PaymentMethods'
import { PrivateErrorBoundary } from '../components/privateErrorBoundary'
import { UserScreen } from '../screens/shared/User'
import { createStackNavigator } from '@react-navigation/stack'
import React from 'react'

const PrivateStack = createStackNavigator<BusinessNavigatorParams>()

export const BusinessNavigator = () => {
  return (
    <PrivateErrorBoundary>
      <PrivateStack.Navigator
        screenOptions={{
          headerShown: false
        }}
        initialRouteName={Screens.BusinessHome}
      >
        <PrivateStack.Screen name={Screens.BusinessHome} component={BusinessHomeScreen}/>
        <PrivateStack.Screen name={Screens.BusinessContract} component={BusinessContractScreen} />
        <PrivateStack.Screen name={Screens.BusinessPaymentMethod} component={BusinessPaymentMethodScreen}/>
        <PrivateStack.Screen name={Screens.BusinessLabel} component={BusinessLabelsScreen}/>
        <PrivateStack.Screen name={Screens.BusinessCustomer} component={BusinessCustomerScreen} />
        <PrivateStack.Screen name={Screens.BusinessCustomers} component={BusinessCustomersScreen}/>
        <PrivateStack.Screen name={Screens.BusinessContracts} component={BusinessContractsScreen} />
        <PrivateStack.Screen name={Screens.BusinessInvoices} component={BusinessInvoicesScreen}/>
        <PrivateStack.Screen name={Screens.BusinessPaymentMethods} component={PaymentMethodsScreen}/>
        <PrivateStack.Screen name={Screens.BusinessAddPaymentMethod} component={AddPaymentMethodScreen}/>
        <PrivateStack.Screen name={Screens.BusinessProfile} component={UserScreen}/>
      </PrivateStack.Navigator>
    </PrivateErrorBoundary>
  )
}
