import * as Types from './operations'

import * as Apollo from '@apollo/client'
import { gql } from '@apollo/client'
const defaultOptions = {}
export const LabelFragmentFragmentDoc = gql`
    fragment LabelFragment on Label {
  id
  name
  color
}
    `
export const ContractSummaryCustomerItemFragmentFragmentDoc = gql`
    fragment ContractSummaryCustomerItemFragment on Contact {
  vatCode: vat_code
  businessName: business_name
  fullname
  socialSecurityNumber: social_security_number
  vatCode: vat_code
}
    `
export const ContractInvoicesFragmentDoc = gql`
    fragment ContractInvoices on Invoice {
  id
  amount
  cmorDate
  date
  dueDate
  endDate
  enerp_ref
  startDate
  status
  userActionRequired
  userEnrichedStatus(version: v2022_03_22)
  Files {
    id
    name
    getSignedObjectUrl
    s3Type: s3_type
    type
  }
}
    `
export const GetBusinessDashboardHomeSummaryDocument = gql`
    query getBusinessDashboardHomeSummary($start_date: String, $end_date: String, $labels: [String]!) {
  BusinessDataSum_v1(
    start_date: $start_date
    end_date: $end_date
    labels: $labels
  ) {
    invoices
    energy_invoices
    gas_invoices
    gas_contracts
    energy_contracts
    contracts
    energy_consumption
    gas_consumption
  }
}
    `

/**
 * __useGetBusinessDashboardHomeSummaryQuery__
 *
 * To run a query within a React component, call `useGetBusinessDashboardHomeSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBusinessDashboardHomeSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBusinessDashboardHomeSummaryQuery({
 *   variables: {
 *      start_date: // value for 'start_date'
 *      end_date: // value for 'end_date'
 *      labels: // value for 'labels'
 *   },
 * });
 */
export function useGetBusinessDashboardHomeSummaryQuery (baseOptions: Apollo.QueryHookOptions<Types.GetBusinessDashboardHomeSummaryQuery, Types.GetBusinessDashboardHomeSummaryQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<Types.GetBusinessDashboardHomeSummaryQuery, Types.GetBusinessDashboardHomeSummaryQueryVariables>(GetBusinessDashboardHomeSummaryDocument, options)
}
export function useGetBusinessDashboardHomeSummaryLazyQuery (baseOptions?: Apollo.LazyQueryHookOptions<Types.GetBusinessDashboardHomeSummaryQuery, Types.GetBusinessDashboardHomeSummaryQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<Types.GetBusinessDashboardHomeSummaryQuery, Types.GetBusinessDashboardHomeSummaryQueryVariables>(GetBusinessDashboardHomeSummaryDocument, options)
}
export type GetBusinessDashboardHomeSummaryQueryHookResult = ReturnType<typeof useGetBusinessDashboardHomeSummaryQuery>;
export type GetBusinessDashboardHomeSummaryLazyQueryHookResult = ReturnType<typeof useGetBusinessDashboardHomeSummaryLazyQuery>;
export type GetBusinessDashboardHomeSummaryQueryResult = Apollo.QueryResult<Types.GetBusinessDashboardHomeSummaryQuery, Types.GetBusinessDashboardHomeSummaryQueryVariables>;
export const GetBusinessDashboardHomeTrendsDocument = gql`
    query getBusinessDashboardHomeTrends($start_date: String!, $end_date: String!, $labels: [String]!) {
  BusinessDataTrends_v1(
    start_date: $start_date
    end_date: $end_date
    labels: $labels
  ) {
    invoices
    energy_invoices
    gas_invoices
    gas_contracts
    energy_contracts
    contracts
    energy_consumption
    gas_consumption
    date
  }
}
    `

/**
 * __useGetBusinessDashboardHomeTrendsQuery__
 *
 * To run a query within a React component, call `useGetBusinessDashboardHomeTrendsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBusinessDashboardHomeTrendsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBusinessDashboardHomeTrendsQuery({
 *   variables: {
 *      start_date: // value for 'start_date'
 *      end_date: // value for 'end_date'
 *      labels: // value for 'labels'
 *   },
 * });
 */
export function useGetBusinessDashboardHomeTrendsQuery (baseOptions: Apollo.QueryHookOptions<Types.GetBusinessDashboardHomeTrendsQuery, Types.GetBusinessDashboardHomeTrendsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<Types.GetBusinessDashboardHomeTrendsQuery, Types.GetBusinessDashboardHomeTrendsQueryVariables>(GetBusinessDashboardHomeTrendsDocument, options)
}
export function useGetBusinessDashboardHomeTrendsLazyQuery (baseOptions?: Apollo.LazyQueryHookOptions<Types.GetBusinessDashboardHomeTrendsQuery, Types.GetBusinessDashboardHomeTrendsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<Types.GetBusinessDashboardHomeTrendsQuery, Types.GetBusinessDashboardHomeTrendsQueryVariables>(GetBusinessDashboardHomeTrendsDocument, options)
}
export type GetBusinessDashboardHomeTrendsQueryHookResult = ReturnType<typeof useGetBusinessDashboardHomeTrendsQuery>;
export type GetBusinessDashboardHomeTrendsLazyQueryHookResult = ReturnType<typeof useGetBusinessDashboardHomeTrendsLazyQuery>;
export type GetBusinessDashboardHomeTrendsQueryResult = Apollo.QueryResult<Types.GetBusinessDashboardHomeTrendsQuery, Types.GetBusinessDashboardHomeTrendsQueryVariables>;
export const GetTransferCostsDocument = gql`
    query getTransferCosts {
  Configurations(
    where: {field: "key", operator: IN, values: ["transfer_admin_cost", "transfer_supplier_cost"]}
  ) {
    key
    value
  }
}
    `

/**
 * __useGetTransferCostsQuery__
 *
 * To run a query within a React component, call `useGetTransferCostsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTransferCostsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTransferCostsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetTransferCostsQuery (baseOptions?: Apollo.QueryHookOptions<Types.GetTransferCostsQuery, Types.GetTransferCostsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<Types.GetTransferCostsQuery, Types.GetTransferCostsQueryVariables>(GetTransferCostsDocument, options)
}
export function useGetTransferCostsLazyQuery (baseOptions?: Apollo.LazyQueryHookOptions<Types.GetTransferCostsQuery, Types.GetTransferCostsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<Types.GetTransferCostsQuery, Types.GetTransferCostsQueryVariables>(GetTransferCostsDocument, options)
}
export type GetTransferCostsQueryHookResult = ReturnType<typeof useGetTransferCostsQuery>;
export type GetTransferCostsLazyQueryHookResult = ReturnType<typeof useGetTransferCostsLazyQuery>;
export type GetTransferCostsQueryResult = Apollo.QueryResult<Types.GetTransferCostsQuery, Types.GetTransferCostsQueryVariables>;
export const GetCheckoutDataDocument = gql`
    query getCheckoutData($customerId: ID!, $paymentMethodId: ID!) {
  Customers(id: [$customerId]) {
    id
    BillingContact {
      ...ContractSummaryCustomerItemFragment
    }
  }
  PaymentMethods(id: [$paymentMethodId]) {
    id
    name
    stripeData: retrieveStripeData
  }
  Me {
    firstName
  }
}
    ${ContractSummaryCustomerItemFragmentFragmentDoc}`

/**
 * __useGetCheckoutDataQuery__
 *
 * To run a query within a React component, call `useGetCheckoutDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCheckoutDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCheckoutDataQuery({
 *   variables: {
 *      customerId: // value for 'customerId'
 *      paymentMethodId: // value for 'paymentMethodId'
 *   },
 * });
 */
export function useGetCheckoutDataQuery (baseOptions: Apollo.QueryHookOptions<Types.GetCheckoutDataQuery, Types.GetCheckoutDataQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<Types.GetCheckoutDataQuery, Types.GetCheckoutDataQueryVariables>(GetCheckoutDataDocument, options)
}
export function useGetCheckoutDataLazyQuery (baseOptions?: Apollo.LazyQueryHookOptions<Types.GetCheckoutDataQuery, Types.GetCheckoutDataQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<Types.GetCheckoutDataQuery, Types.GetCheckoutDataQueryVariables>(GetCheckoutDataDocument, options)
}
export type GetCheckoutDataQueryHookResult = ReturnType<typeof useGetCheckoutDataQuery>;
export type GetCheckoutDataLazyQueryHookResult = ReturnType<typeof useGetCheckoutDataLazyQuery>;
export type GetCheckoutDataQueryResult = Apollo.QueryResult<Types.GetCheckoutDataQuery, Types.GetCheckoutDataQueryVariables>;
export const GetContractDocument = gql`
    query getContract($id: [ID]!, $limit: Int, $offset: Int) {
  Contracts(id: $id) {
    id
    activationType
    contractCodeName
    end_date
    healthStatus
    name
    renewalStatus
    start_date
    signatureDate
    transferStartDate
    status
    type
    target
    hasTransferCode
    Attributes {
      id
      key
      value
    }
    Invoices(
      limit: $limit
      offset: $offset
      sort: {field: "endDate", order: "desc"}
      where: [{field: "status", values: ["open", "drafted", "payed", "not_payed"], operator: IN}]
    ) {
      ...ContractInvoices
    }
    Items {
      id
      name
      payload
      Contact {
        id
        addressFull: address_full
      }
    }
    PaymentMethod {
      id
      name
      target
      stripeData: retrieveStripeData
      userActionRequired
    }
    SubStatuses {
      id
      name
      completed
      completedAt
    }
    Labels(sort: {field: "name", order: "asc"}) {
      id
      name
      color
    }
  }
}
    ${ContractInvoicesFragmentDoc}`

/**
 * __useGetContractQuery__
 *
 * To run a query within a React component, call `useGetContractQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContractQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContractQuery({
 *   variables: {
 *      id: // value for 'id'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useGetContractQuery (baseOptions: Apollo.QueryHookOptions<Types.GetContractQuery, Types.GetContractQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<Types.GetContractQuery, Types.GetContractQueryVariables>(GetContractDocument, options)
}
export function useGetContractLazyQuery (baseOptions?: Apollo.LazyQueryHookOptions<Types.GetContractQuery, Types.GetContractQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<Types.GetContractQuery, Types.GetContractQueryVariables>(GetContractDocument, options)
}
export type GetContractQueryHookResult = ReturnType<typeof useGetContractQuery>;
export type GetContractLazyQueryHookResult = ReturnType<typeof useGetContractLazyQuery>;
export type GetContractQueryResult = Apollo.QueryResult<Types.GetContractQuery, Types.GetContractQueryVariables>;
export const GetContractTransferCheckDocument = gql`
    query getContractTransferCheck($contractId: ID!, $retrieveLink: Boolean) {
  ContractTransferCheck_v2(contractId: $contractId, retrieveLink: $retrieveLink) {
    status
    link
  }
}
    `

/**
 * __useGetContractTransferCheckQuery__
 *
 * To run a query within a React component, call `useGetContractTransferCheckQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContractTransferCheckQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContractTransferCheckQuery({
 *   variables: {
 *      contractId: // value for 'contractId'
 *      retrieveLink: // value for 'retrieveLink'
 *   },
 * });
 */
export function useGetContractTransferCheckQuery (baseOptions: Apollo.QueryHookOptions<Types.GetContractTransferCheckQuery, Types.GetContractTransferCheckQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<Types.GetContractTransferCheckQuery, Types.GetContractTransferCheckQueryVariables>(GetContractTransferCheckDocument, options)
}
export function useGetContractTransferCheckLazyQuery (baseOptions?: Apollo.LazyQueryHookOptions<Types.GetContractTransferCheckQuery, Types.GetContractTransferCheckQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<Types.GetContractTransferCheckQuery, Types.GetContractTransferCheckQueryVariables>(GetContractTransferCheckDocument, options)
}
export type GetContractTransferCheckQueryHookResult = ReturnType<typeof useGetContractTransferCheckQuery>;
export type GetContractTransferCheckLazyQueryHookResult = ReturnType<typeof useGetContractTransferCheckLazyQuery>;
export type GetContractTransferCheckQueryResult = Apollo.QueryResult<Types.GetContractTransferCheckQuery, Types.GetContractTransferCheckQueryVariables>;
export const DeleteTransferCodeDocument = gql`
    mutation deleteTransferCode($contractId: ID!) {
  DeleteTransferCode_v1(ContractId: $contractId) {
    id
  }
}
    `
export type DeleteTransferCodeMutationFn = Apollo.MutationFunction<Types.DeleteTransferCodeMutation, Types.DeleteTransferCodeMutationVariables>;

/**
 * __useDeleteTransferCodeMutation__
 *
 * To run a mutation, you first call `useDeleteTransferCodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTransferCodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTransferCodeMutation, { data, loading, error }] = useDeleteTransferCodeMutation({
 *   variables: {
 *      contractId: // value for 'contractId'
 *   },
 * });
 */
export function useDeleteTransferCodeMutation (baseOptions?: Apollo.MutationHookOptions<Types.DeleteTransferCodeMutation, Types.DeleteTransferCodeMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<Types.DeleteTransferCodeMutation, Types.DeleteTransferCodeMutationVariables>(DeleteTransferCodeDocument, options)
}
export type DeleteTransferCodeMutationHookResult = ReturnType<typeof useDeleteTransferCodeMutation>;
export type DeleteTransferCodeMutationResult = Apollo.MutationResult<Types.DeleteTransferCodeMutation>;
export type DeleteTransferCodeMutationOptions = Apollo.BaseMutationOptions<Types.DeleteTransferCodeMutation, Types.DeleteTransferCodeMutationVariables>;
export const GetContractsDocument = gql`
    query getContracts($scope: [ScopeInput], $limit: Int, $offset: Int) {
  count: countContracts(
    where: [{field: "status", value: "drafted", operator: NOT_EQUAL}]
    scopes: $scope
  ) {
    value
  }
  Contracts(
    sort: {field: "start_date", order: "DESC"}
    where: [{field: "status", value: "drafted", operator: NOT_EQUAL}]
    scopes: $scope
    limit: $limit
    offset: $offset
  ) {
    id
    name
    type
    status
    OwnerContact {
      address_short
    }
    Items {
      Contact {
        address_short
      }
    }
    start_date
    Labels(sort: {field: "name", order: "asc"}) {
      ...LabelFragment
    }
  }
}
    ${LabelFragmentFragmentDoc}`

/**
 * __useGetContractsQuery__
 *
 * To run a query within a React component, call `useGetContractsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContractsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContractsQuery({
 *   variables: {
 *      scope: // value for 'scope'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useGetContractsQuery (baseOptions?: Apollo.QueryHookOptions<Types.GetContractsQuery, Types.GetContractsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<Types.GetContractsQuery, Types.GetContractsQueryVariables>(GetContractsDocument, options)
}
export function useGetContractsLazyQuery (baseOptions?: Apollo.LazyQueryHookOptions<Types.GetContractsQuery, Types.GetContractsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<Types.GetContractsQuery, Types.GetContractsQueryVariables>(GetContractsDocument, options)
}
export type GetContractsQueryHookResult = ReturnType<typeof useGetContractsQuery>;
export type GetContractsLazyQueryHookResult = ReturnType<typeof useGetContractsLazyQuery>;
export type GetContractsQueryResult = Apollo.QueryResult<Types.GetContractsQuery, Types.GetContractsQueryVariables>;
export const GetAllContractsDocument = gql`
    query getAllContracts {
  Contracts {
    id
    name
    type
    status
    OwnerContact {
      address_short
    }
    start_date
  }
}
    `

/**
 * __useGetAllContractsQuery__
 *
 * To run a query within a React component, call `useGetAllContractsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllContractsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllContractsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllContractsQuery (baseOptions?: Apollo.QueryHookOptions<Types.GetAllContractsQuery, Types.GetAllContractsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<Types.GetAllContractsQuery, Types.GetAllContractsQueryVariables>(GetAllContractsDocument, options)
}
export function useGetAllContractsLazyQuery (baseOptions?: Apollo.LazyQueryHookOptions<Types.GetAllContractsQuery, Types.GetAllContractsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<Types.GetAllContractsQuery, Types.GetAllContractsQueryVariables>(GetAllContractsDocument, options)
}
export type GetAllContractsQueryHookResult = ReturnType<typeof useGetAllContractsQuery>;
export type GetAllContractsLazyQueryHookResult = ReturnType<typeof useGetAllContractsLazyQuery>;
export type GetAllContractsQueryResult = Apollo.QueryResult<Types.GetAllContractsQuery, Types.GetAllContractsQueryVariables>;
export const GetEmailSignInDataDocument = gql`
    query getEmailSignInData($id: [ID]!) {
  Contracts(id: $id) {
    contractCodeName
    Attributes {
      key
      value
    }
    Items {
      name
      Contact {
        address1: address_1
        addressNumber: address_number
        city
      }
    }
    contractPromocode
    Customer {
      id
      BillingContact {
        ...ContractSummaryCustomerItemFragment
      }
    }
    PaymentMethod {
      id
      name
      stripeData: retrieveStripeData
    }
  }
}
    ${ContractSummaryCustomerItemFragmentFragmentDoc}`

/**
 * __useGetEmailSignInDataQuery__
 *
 * To run a query within a React component, call `useGetEmailSignInDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEmailSignInDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEmailSignInDataQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetEmailSignInDataQuery (baseOptions: Apollo.QueryHookOptions<Types.GetEmailSignInDataQuery, Types.GetEmailSignInDataQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<Types.GetEmailSignInDataQuery, Types.GetEmailSignInDataQueryVariables>(GetEmailSignInDataDocument, options)
}
export function useGetEmailSignInDataLazyQuery (baseOptions?: Apollo.LazyQueryHookOptions<Types.GetEmailSignInDataQuery, Types.GetEmailSignInDataQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<Types.GetEmailSignInDataQuery, Types.GetEmailSignInDataQueryVariables>(GetEmailSignInDataDocument, options)
}
export type GetEmailSignInDataQueryHookResult = ReturnType<typeof useGetEmailSignInDataQuery>;
export type GetEmailSignInDataLazyQueryHookResult = ReturnType<typeof useGetEmailSignInDataLazyQuery>;
export type GetEmailSignInDataQueryResult = Apollo.QueryResult<Types.GetEmailSignInDataQuery, Types.GetEmailSignInDataQueryVariables>;
export const UpdateContractPaymentMethodDocument = gql`
    mutation updateContractPaymentMethod($contractId: ID!, $paymentMethodId: ID!) {
  UpdatePaymentMethodContract_v1(
    ContractId: $contractId
    PaymentMethodId: $paymentMethodId
  ) {
    id
  }
}
    `
export type UpdateContractPaymentMethodMutationFn = Apollo.MutationFunction<Types.UpdateContractPaymentMethodMutation, Types.UpdateContractPaymentMethodMutationVariables>;

/**
 * __useUpdateContractPaymentMethodMutation__
 *
 * To run a mutation, you first call `useUpdateContractPaymentMethodMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateContractPaymentMethodMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateContractPaymentMethodMutation, { data, loading, error }] = useUpdateContractPaymentMethodMutation({
 *   variables: {
 *      contractId: // value for 'contractId'
 *      paymentMethodId: // value for 'paymentMethodId'
 *   },
 * });
 */
export function useUpdateContractPaymentMethodMutation (baseOptions?: Apollo.MutationHookOptions<Types.UpdateContractPaymentMethodMutation, Types.UpdateContractPaymentMethodMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<Types.UpdateContractPaymentMethodMutation, Types.UpdateContractPaymentMethodMutationVariables>(UpdateContractPaymentMethodDocument, options)
}
export type UpdateContractPaymentMethodMutationHookResult = ReturnType<typeof useUpdateContractPaymentMethodMutation>;
export type UpdateContractPaymentMethodMutationResult = Apollo.MutationResult<Types.UpdateContractPaymentMethodMutation>;
export type UpdateContractPaymentMethodMutationOptions = Apollo.BaseMutationOptions<Types.UpdateContractPaymentMethodMutation, Types.UpdateContractPaymentMethodMutationVariables>;
export const FindOrCreateTransferCodeDocument = gql`
    mutation findOrCreateTransferCode($contractId: ID!) {
  FindOrCreateTransferCode_v1(ContractId: $contractId)
}
    `
export type FindOrCreateTransferCodeMutationFn = Apollo.MutationFunction<Types.FindOrCreateTransferCodeMutation, Types.FindOrCreateTransferCodeMutationVariables>;

/**
 * __useFindOrCreateTransferCodeMutation__
 *
 * To run a mutation, you first call `useFindOrCreateTransferCodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFindOrCreateTransferCodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [findOrCreateTransferCodeMutation, { data, loading, error }] = useFindOrCreateTransferCodeMutation({
 *   variables: {
 *      contractId: // value for 'contractId'
 *   },
 * });
 */
export function useFindOrCreateTransferCodeMutation (baseOptions?: Apollo.MutationHookOptions<Types.FindOrCreateTransferCodeMutation, Types.FindOrCreateTransferCodeMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<Types.FindOrCreateTransferCodeMutation, Types.FindOrCreateTransferCodeMutationVariables>(FindOrCreateTransferCodeDocument, options)
}
export type FindOrCreateTransferCodeMutationHookResult = ReturnType<typeof useFindOrCreateTransferCodeMutation>;
export type FindOrCreateTransferCodeMutationResult = Apollo.MutationResult<Types.FindOrCreateTransferCodeMutation>;
export type FindOrCreateTransferCodeMutationOptions = Apollo.BaseMutationOptions<Types.FindOrCreateTransferCodeMutation, Types.FindOrCreateTransferCodeMutationVariables>;
export const GetSelfServicesByContractIdDocument = gql`
    query getSelfServicesByContractId($id: [ID]) {
  Contracts(id: $id) {
    id
    type
    contractCodeName
    Items {
      id
      meterReadingRequired
      Contact {
        address_short
      }
      SelfServices(
        where: [{field: "type", operator: EQUAL, value: "meter_reading"}]
        sort: [{field: "date", order: "DESC"}]
      ) {
        id
        date
        payload
      }
    }
  }
}
    `

/**
 * __useGetSelfServicesByContractIdQuery__
 *
 * To run a query within a React component, call `useGetSelfServicesByContractIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSelfServicesByContractIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSelfServicesByContractIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetSelfServicesByContractIdQuery (baseOptions?: Apollo.QueryHookOptions<Types.GetSelfServicesByContractIdQuery, Types.GetSelfServicesByContractIdQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<Types.GetSelfServicesByContractIdQuery, Types.GetSelfServicesByContractIdQueryVariables>(GetSelfServicesByContractIdDocument, options)
}
export function useGetSelfServicesByContractIdLazyQuery (baseOptions?: Apollo.LazyQueryHookOptions<Types.GetSelfServicesByContractIdQuery, Types.GetSelfServicesByContractIdQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<Types.GetSelfServicesByContractIdQuery, Types.GetSelfServicesByContractIdQueryVariables>(GetSelfServicesByContractIdDocument, options)
}
export type GetSelfServicesByContractIdQueryHookResult = ReturnType<typeof useGetSelfServicesByContractIdQuery>;
export type GetSelfServicesByContractIdLazyQueryHookResult = ReturnType<typeof useGetSelfServicesByContractIdLazyQuery>;
export type GetSelfServicesByContractIdQueryResult = Apollo.QueryResult<Types.GetSelfServicesByContractIdQuery, Types.GetSelfServicesByContractIdQueryVariables>;
export const CreateMeterReadingDocument = gql`
    mutation createMeterReading($itemId: ID!, $contractType: String!, $gasValue: Float, $energyValue: JSON) {
  CreateSelfServiceMeterReading_v2(
    ItemId: $itemId
    contractType: $contractType
    gasValue: $gasValue
    energyValue: $energyValue
  ) {
    id
  }
}
    `
export type CreateMeterReadingMutationFn = Apollo.MutationFunction<Types.CreateMeterReadingMutation, Types.CreateMeterReadingMutationVariables>;

/**
 * __useCreateMeterReadingMutation__
 *
 * To run a mutation, you first call `useCreateMeterReadingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateMeterReadingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createMeterReadingMutation, { data, loading, error }] = useCreateMeterReadingMutation({
 *   variables: {
 *      itemId: // value for 'itemId'
 *      contractType: // value for 'contractType'
 *      gasValue: // value for 'gasValue'
 *      energyValue: // value for 'energyValue'
 *   },
 * });
 */
export function useCreateMeterReadingMutation (baseOptions?: Apollo.MutationHookOptions<Types.CreateMeterReadingMutation, Types.CreateMeterReadingMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<Types.CreateMeterReadingMutation, Types.CreateMeterReadingMutationVariables>(CreateMeterReadingDocument, options)
}
export type CreateMeterReadingMutationHookResult = ReturnType<typeof useCreateMeterReadingMutation>;
export type CreateMeterReadingMutationResult = Apollo.MutationResult<Types.CreateMeterReadingMutation>;
export type CreateMeterReadingMutationOptions = Apollo.BaseMutationOptions<Types.CreateMeterReadingMutation, Types.CreateMeterReadingMutationVariables>;
export const GetContractsPaymentMethodsDocument = gql`
    query getContractsPaymentMethods($contractId: [ID]!) {
  Contracts(id: $contractId) {
    id
    PaymentMethodId
    Invoices {
      status
    }
  }
}
    `

/**
 * __useGetContractsPaymentMethodsQuery__
 *
 * To run a query within a React component, call `useGetContractsPaymentMethodsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContractsPaymentMethodsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContractsPaymentMethodsQuery({
 *   variables: {
 *      contractId: // value for 'contractId'
 *   },
 * });
 */
export function useGetContractsPaymentMethodsQuery (baseOptions: Apollo.QueryHookOptions<Types.GetContractsPaymentMethodsQuery, Types.GetContractsPaymentMethodsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<Types.GetContractsPaymentMethodsQuery, Types.GetContractsPaymentMethodsQueryVariables>(GetContractsPaymentMethodsDocument, options)
}
export function useGetContractsPaymentMethodsLazyQuery (baseOptions?: Apollo.LazyQueryHookOptions<Types.GetContractsPaymentMethodsQuery, Types.GetContractsPaymentMethodsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<Types.GetContractsPaymentMethodsQuery, Types.GetContractsPaymentMethodsQueryVariables>(GetContractsPaymentMethodsDocument, options)
}
export type GetContractsPaymentMethodsQueryHookResult = ReturnType<typeof useGetContractsPaymentMethodsQuery>;
export type GetContractsPaymentMethodsLazyQueryHookResult = ReturnType<typeof useGetContractsPaymentMethodsLazyQuery>;
export type GetContractsPaymentMethodsQueryResult = Apollo.QueryResult<Types.GetContractsPaymentMethodsQuery, Types.GetContractsPaymentMethodsQueryVariables>;
export const GetCountContractTypesDocument = gql`
    query getCountContractTypes {
  gasCount: countContracts(
    where: [{field: "type", operator: EQUAL, value: "gas"}]
  ) {
    value
  }
  energyCount: countContracts(
    where: [{field: "type", operator: EQUAL, value: "ee"}]
  ) {
    value
  }
}
    `

/**
 * __useGetCountContractTypesQuery__
 *
 * To run a query within a React component, call `useGetCountContractTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCountContractTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCountContractTypesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCountContractTypesQuery (baseOptions?: Apollo.QueryHookOptions<Types.GetCountContractTypesQuery, Types.GetCountContractTypesQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<Types.GetCountContractTypesQuery, Types.GetCountContractTypesQueryVariables>(GetCountContractTypesDocument, options)
}
export function useGetCountContractTypesLazyQuery (baseOptions?: Apollo.LazyQueryHookOptions<Types.GetCountContractTypesQuery, Types.GetCountContractTypesQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<Types.GetCountContractTypesQuery, Types.GetCountContractTypesQueryVariables>(GetCountContractTypesDocument, options)
}
export type GetCountContractTypesQueryHookResult = ReturnType<typeof useGetCountContractTypesQuery>;
export type GetCountContractTypesLazyQueryHookResult = ReturnType<typeof useGetCountContractTypesLazyQuery>;
export type GetCountContractTypesQueryResult = Apollo.QueryResult<Types.GetCountContractTypesQuery, Types.GetCountContractTypesQueryVariables>;
export const AttachLabelToContractDocument = gql`
    mutation attachLabelToContract($labelId: ID!, $contractId: ID!) {
  AttachLabelToContract_v1(labelId: $labelId, contractId: $contractId) {
    id
    Labels(sort: {field: "name", order: "asc"}) {
      ...LabelFragment
    }
  }
}
    ${LabelFragmentFragmentDoc}`
export type AttachLabelToContractMutationFn = Apollo.MutationFunction<Types.AttachLabelToContractMutation, Types.AttachLabelToContractMutationVariables>;

/**
 * __useAttachLabelToContractMutation__
 *
 * To run a mutation, you first call `useAttachLabelToContractMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAttachLabelToContractMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [attachLabelToContractMutation, { data, loading, error }] = useAttachLabelToContractMutation({
 *   variables: {
 *      labelId: // value for 'labelId'
 *      contractId: // value for 'contractId'
 *   },
 * });
 */
export function useAttachLabelToContractMutation (baseOptions?: Apollo.MutationHookOptions<Types.AttachLabelToContractMutation, Types.AttachLabelToContractMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<Types.AttachLabelToContractMutation, Types.AttachLabelToContractMutationVariables>(AttachLabelToContractDocument, options)
}
export type AttachLabelToContractMutationHookResult = ReturnType<typeof useAttachLabelToContractMutation>;
export type AttachLabelToContractMutationResult = Apollo.MutationResult<Types.AttachLabelToContractMutation>;
export type AttachLabelToContractMutationOptions = Apollo.BaseMutationOptions<Types.AttachLabelToContractMutation, Types.AttachLabelToContractMutationVariables>;
export const DetachLabelToContractDocument = gql`
    mutation detachLabelToContract($labelId: ID!, $contractId: ID!) {
  DetachLabelFromContract_v1(labelId: $labelId, contractId: $contractId) {
    id
  }
}
    `
export type DetachLabelToContractMutationFn = Apollo.MutationFunction<Types.DetachLabelToContractMutation, Types.DetachLabelToContractMutationVariables>;

/**
 * __useDetachLabelToContractMutation__
 *
 * To run a mutation, you first call `useDetachLabelToContractMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDetachLabelToContractMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [detachLabelToContractMutation, { data, loading, error }] = useDetachLabelToContractMutation({
 *   variables: {
 *      labelId: // value for 'labelId'
 *      contractId: // value for 'contractId'
 *   },
 * });
 */
export function useDetachLabelToContractMutation (baseOptions?: Apollo.MutationHookOptions<Types.DetachLabelToContractMutation, Types.DetachLabelToContractMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<Types.DetachLabelToContractMutation, Types.DetachLabelToContractMutationVariables>(DetachLabelToContractDocument, options)
}
export type DetachLabelToContractMutationHookResult = ReturnType<typeof useDetachLabelToContractMutation>;
export type DetachLabelToContractMutationResult = Apollo.MutationResult<Types.DetachLabelToContractMutation>;
export type DetachLabelToContractMutationOptions = Apollo.BaseMutationOptions<Types.DetachLabelToContractMutation, Types.DetachLabelToContractMutationVariables>;
export const GetFilteredCustomersDocument = gql`
    query getFilteredCustomers($where: [WhereClause]) {
  Customers(where: $where) {
    id
    status
    type
    BillingContact {
      id
      fullname
      type
      businessName: business_name
      addressFull: address_full
      postalCode: postal_code
      birthDate: birth_date
      birthPlace: birth_place
      firstName: first_name
      lastName: last_name
      socialSecurityNumber: social_security_number
      vatCode: vat_code
      ...ContractSummaryCustomerItemFragment
    }
    Contracts {
      id
      type
      status
      name
      start_date
      OwnerContact {
        address_short
      }
    }
  }
}
    ${ContractSummaryCustomerItemFragmentFragmentDoc}`

/**
 * __useGetFilteredCustomersQuery__
 *
 * To run a query within a React component, call `useGetFilteredCustomersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFilteredCustomersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFilteredCustomersQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetFilteredCustomersQuery (baseOptions?: Apollo.QueryHookOptions<Types.GetFilteredCustomersQuery, Types.GetFilteredCustomersQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<Types.GetFilteredCustomersQuery, Types.GetFilteredCustomersQueryVariables>(GetFilteredCustomersDocument, options)
}
export function useGetFilteredCustomersLazyQuery (baseOptions?: Apollo.LazyQueryHookOptions<Types.GetFilteredCustomersQuery, Types.GetFilteredCustomersQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<Types.GetFilteredCustomersQuery, Types.GetFilteredCustomersQueryVariables>(GetFilteredCustomersDocument, options)
}
export type GetFilteredCustomersQueryHookResult = ReturnType<typeof useGetFilteredCustomersQuery>;
export type GetFilteredCustomersLazyQueryHookResult = ReturnType<typeof useGetFilteredCustomersLazyQuery>;
export type GetFilteredCustomersQueryResult = Apollo.QueryResult<Types.GetFilteredCustomersQuery, Types.GetFilteredCustomersQueryVariables>;
export const GetInvoicesDocument = gql`
    query getInvoices($contractId: [ID]!) {
  Contracts(id: $contractId) {
    id
    name
    start_date
    status
    PaymentMethod {
      id
      name
      target
      stripeData: retrieveStripeData
      userActionRequired
    }
    Invoices(
      sort: {field: "endDate", order: "desc"}
      where: [{field: "status", values: ["open", "drafted", "payed", "not_payed"], operator: IN}]
    ) {
      ...ContractInvoices
    }
  }
}
    ${ContractInvoicesFragmentDoc}`

/**
 * __useGetInvoicesQuery__
 *
 * To run a query within a React component, call `useGetInvoicesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInvoicesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInvoicesQuery({
 *   variables: {
 *      contractId: // value for 'contractId'
 *   },
 * });
 */
export function useGetInvoicesQuery (baseOptions: Apollo.QueryHookOptions<Types.GetInvoicesQuery, Types.GetInvoicesQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<Types.GetInvoicesQuery, Types.GetInvoicesQueryVariables>(GetInvoicesDocument, options)
}
export function useGetInvoicesLazyQuery (baseOptions?: Apollo.LazyQueryHookOptions<Types.GetInvoicesQuery, Types.GetInvoicesQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<Types.GetInvoicesQuery, Types.GetInvoicesQueryVariables>(GetInvoicesDocument, options)
}
export type GetInvoicesQueryHookResult = ReturnType<typeof useGetInvoicesQuery>;
export type GetInvoicesLazyQueryHookResult = ReturnType<typeof useGetInvoicesLazyQuery>;
export type GetInvoicesQueryResult = Apollo.QueryResult<Types.GetInvoicesQuery, Types.GetInvoicesQueryVariables>;
export const GetInvoiceAndPaymentMethodsDocument = gql`
    query getInvoiceAndPaymentMethods($id: [ID]!) {
  Invoices(id: $id) {
    id
    amount
    balanceAmount
    dueDate
    enerp_ref
    userEnrichedStatus(version: v2022_03_22)
    Contract {
      id
      name
      type
      PaymentMethod {
        id
        target
      }
    }
  }
  PaymentMethods(
    where: [{field: "target", value: "credit_card", operator: EQUAL}]
  ) {
    id
    name
    target
    stripeData: retrieveStripeData
  }
}
    `

/**
 * __useGetInvoiceAndPaymentMethodsQuery__
 *
 * To run a query within a React component, call `useGetInvoiceAndPaymentMethodsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInvoiceAndPaymentMethodsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInvoiceAndPaymentMethodsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetInvoiceAndPaymentMethodsQuery (baseOptions: Apollo.QueryHookOptions<Types.GetInvoiceAndPaymentMethodsQuery, Types.GetInvoiceAndPaymentMethodsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<Types.GetInvoiceAndPaymentMethodsQuery, Types.GetInvoiceAndPaymentMethodsQueryVariables>(GetInvoiceAndPaymentMethodsDocument, options)
}
export function useGetInvoiceAndPaymentMethodsLazyQuery (baseOptions?: Apollo.LazyQueryHookOptions<Types.GetInvoiceAndPaymentMethodsQuery, Types.GetInvoiceAndPaymentMethodsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<Types.GetInvoiceAndPaymentMethodsQuery, Types.GetInvoiceAndPaymentMethodsQueryVariables>(GetInvoiceAndPaymentMethodsDocument, options)
}
export type GetInvoiceAndPaymentMethodsQueryHookResult = ReturnType<typeof useGetInvoiceAndPaymentMethodsQuery>;
export type GetInvoiceAndPaymentMethodsLazyQueryHookResult = ReturnType<typeof useGetInvoiceAndPaymentMethodsLazyQuery>;
export type GetInvoiceAndPaymentMethodsQueryResult = Apollo.QueryResult<Types.GetInvoiceAndPaymentMethodsQuery, Types.GetInvoiceAndPaymentMethodsQueryVariables>;
export const PayEntityNowDocument = gql`
    mutation payEntityNow($invoiceId: ID!, $paymentMethodRef: ID!, $paymentMethodFormat: PaymentMethodFormat = id) {
  PayEntityNow_v2(
    invoiceId: $invoiceId
    paymentMethodRef: $paymentMethodRef
    paymentMethodFormat: $paymentMethodFormat
  ) {
    amountToPay
    confirmationSecret
    status
    invoiceElements {
      amount
      type
    }
  }
}
    `
export type PayEntityNowMutationFn = Apollo.MutationFunction<Types.PayEntityNowMutation, Types.PayEntityNowMutationVariables>;

/**
 * __usePayEntityNowMutation__
 *
 * To run a mutation, you first call `usePayEntityNowMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePayEntityNowMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [payEntityNowMutation, { data, loading, error }] = usePayEntityNowMutation({
 *   variables: {
 *      invoiceId: // value for 'invoiceId'
 *      paymentMethodRef: // value for 'paymentMethodRef'
 *      paymentMethodFormat: // value for 'paymentMethodFormat'
 *   },
 * });
 */
export function usePayEntityNowMutation (baseOptions?: Apollo.MutationHookOptions<Types.PayEntityNowMutation, Types.PayEntityNowMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<Types.PayEntityNowMutation, Types.PayEntityNowMutationVariables>(PayEntityNowDocument, options)
}
export type PayEntityNowMutationHookResult = ReturnType<typeof usePayEntityNowMutation>;
export type PayEntityNowMutationResult = Apollo.MutationResult<Types.PayEntityNowMutation>;
export type PayEntityNowMutationOptions = Apollo.BaseMutationOptions<Types.PayEntityNowMutation, Types.PayEntityNowMutationVariables>;
export const GetInvoicesListDocument = gql`
    query getInvoicesList($scope: [ScopeInput], $limit: Int, $offset: Int) {
  count: countInvoices(scopes: $scope) {
    value
  }
  Invoices(
    scopes: $scope
    sort: {field: "startDate", order: "DESC"}
    limit: $limit
    offset: $offset
  ) {
    enerp_ref
    status
    amount
    date
    startDate
    endDate
    Files {
      id
      type
      s3_location
      getSignedObjectUrl
    }
  }
}
    `

/**
 * __useGetInvoicesListQuery__
 *
 * To run a query within a React component, call `useGetInvoicesListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInvoicesListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInvoicesListQuery({
 *   variables: {
 *      scope: // value for 'scope'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useGetInvoicesListQuery (baseOptions?: Apollo.QueryHookOptions<Types.GetInvoicesListQuery, Types.GetInvoicesListQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<Types.GetInvoicesListQuery, Types.GetInvoicesListQueryVariables>(GetInvoicesListDocument, options)
}
export function useGetInvoicesListLazyQuery (baseOptions?: Apollo.LazyQueryHookOptions<Types.GetInvoicesListQuery, Types.GetInvoicesListQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<Types.GetInvoicesListQuery, Types.GetInvoicesListQueryVariables>(GetInvoicesListDocument, options)
}
export type GetInvoicesListQueryHookResult = ReturnType<typeof useGetInvoicesListQuery>;
export type GetInvoicesListLazyQueryHookResult = ReturnType<typeof useGetInvoicesListLazyQuery>;
export type GetInvoicesListQueryResult = Apollo.QueryResult<Types.GetInvoicesListQuery, Types.GetInvoicesListQueryVariables>;
export const GetLabelsDocument = gql`
    query getLabels {
  Labels(sort: [{field: "name", order: "ASC"}]) {
    id
    name
    color
    type
    enabled
    contractsCount
  }
}
    `

/**
 * __useGetLabelsQuery__
 *
 * To run a query within a React component, call `useGetLabelsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLabelsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLabelsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetLabelsQuery (baseOptions?: Apollo.QueryHookOptions<Types.GetLabelsQuery, Types.GetLabelsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<Types.GetLabelsQuery, Types.GetLabelsQueryVariables>(GetLabelsDocument, options)
}
export function useGetLabelsLazyQuery (baseOptions?: Apollo.LazyQueryHookOptions<Types.GetLabelsQuery, Types.GetLabelsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<Types.GetLabelsQuery, Types.GetLabelsQueryVariables>(GetLabelsDocument, options)
}
export type GetLabelsQueryHookResult = ReturnType<typeof useGetLabelsQuery>;
export type GetLabelsLazyQueryHookResult = ReturnType<typeof useGetLabelsLazyQuery>;
export type GetLabelsQueryResult = Apollo.QueryResult<Types.GetLabelsQuery, Types.GetLabelsQueryVariables>;
export const GetEnabledLabelsDocument = gql`
    query getEnabledLabels {
  Labels(
    where: [{field: "enabled", value: "true", operator: EQUAL}]
    sort: [{field: "name", order: "ASC"}]
  ) {
    id
    name
    color
    type
    enabled
  }
}
    `

/**
 * __useGetEnabledLabelsQuery__
 *
 * To run a query within a React component, call `useGetEnabledLabelsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEnabledLabelsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEnabledLabelsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetEnabledLabelsQuery (baseOptions?: Apollo.QueryHookOptions<Types.GetEnabledLabelsQuery, Types.GetEnabledLabelsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<Types.GetEnabledLabelsQuery, Types.GetEnabledLabelsQueryVariables>(GetEnabledLabelsDocument, options)
}
export function useGetEnabledLabelsLazyQuery (baseOptions?: Apollo.LazyQueryHookOptions<Types.GetEnabledLabelsQuery, Types.GetEnabledLabelsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<Types.GetEnabledLabelsQuery, Types.GetEnabledLabelsQueryVariables>(GetEnabledLabelsDocument, options)
}
export type GetEnabledLabelsQueryHookResult = ReturnType<typeof useGetEnabledLabelsQuery>;
export type GetEnabledLabelsLazyQueryHookResult = ReturnType<typeof useGetEnabledLabelsLazyQuery>;
export type GetEnabledLabelsQueryResult = Apollo.QueryResult<Types.GetEnabledLabelsQuery, Types.GetEnabledLabelsQueryVariables>;
export const UpdateLabelDocument = gql`
    mutation updateLabel($id: ID!, $name: String!, $color: String!, $enabled: Boolean) {
  UpdateLabel_v1(id: $id, name: $name, color: $color, enabled: $enabled) {
    id
    name
    color
    type
    enabled
  }
}
    `
export type UpdateLabelMutationFn = Apollo.MutationFunction<Types.UpdateLabelMutation, Types.UpdateLabelMutationVariables>;

/**
 * __useUpdateLabelMutation__
 *
 * To run a mutation, you first call `useUpdateLabelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateLabelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateLabelMutation, { data, loading, error }] = useUpdateLabelMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *      color: // value for 'color'
 *      enabled: // value for 'enabled'
 *   },
 * });
 */
export function useUpdateLabelMutation (baseOptions?: Apollo.MutationHookOptions<Types.UpdateLabelMutation, Types.UpdateLabelMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<Types.UpdateLabelMutation, Types.UpdateLabelMutationVariables>(UpdateLabelDocument, options)
}
export type UpdateLabelMutationHookResult = ReturnType<typeof useUpdateLabelMutation>;
export type UpdateLabelMutationResult = Apollo.MutationResult<Types.UpdateLabelMutation>;
export type UpdateLabelMutationOptions = Apollo.BaseMutationOptions<Types.UpdateLabelMutation, Types.UpdateLabelMutationVariables>;
export const CreateLabelDocument = gql`
    mutation createLabel($name: String!, $color: String!) {
  CreateLabel_v1(name: $name, color: $color) {
    id
    name
    color
    type
    enabled
  }
}
    `
export type CreateLabelMutationFn = Apollo.MutationFunction<Types.CreateLabelMutation, Types.CreateLabelMutationVariables>;

/**
 * __useCreateLabelMutation__
 *
 * To run a mutation, you first call `useCreateLabelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateLabelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createLabelMutation, { data, loading, error }] = useCreateLabelMutation({
 *   variables: {
 *      name: // value for 'name'
 *      color: // value for 'color'
 *   },
 * });
 */
export function useCreateLabelMutation (baseOptions?: Apollo.MutationHookOptions<Types.CreateLabelMutation, Types.CreateLabelMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<Types.CreateLabelMutation, Types.CreateLabelMutationVariables>(CreateLabelDocument, options)
}
export type CreateLabelMutationHookResult = ReturnType<typeof useCreateLabelMutation>;
export type CreateLabelMutationResult = Apollo.MutationResult<Types.CreateLabelMutation>;
export type CreateLabelMutationOptions = Apollo.BaseMutationOptions<Types.CreateLabelMutation, Types.CreateLabelMutationVariables>;
export const DeleteLabelDocument = gql`
    mutation deleteLabel($id: ID!) {
  DeleteLabel_v1(id: $id) {
    id
  }
}
    `
export type DeleteLabelMutationFn = Apollo.MutationFunction<Types.DeleteLabelMutation, Types.DeleteLabelMutationVariables>;

/**
 * __useDeleteLabelMutation__
 *
 * To run a mutation, you first call `useDeleteLabelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteLabelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteLabelMutation, { data, loading, error }] = useDeleteLabelMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteLabelMutation (baseOptions?: Apollo.MutationHookOptions<Types.DeleteLabelMutation, Types.DeleteLabelMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<Types.DeleteLabelMutation, Types.DeleteLabelMutationVariables>(DeleteLabelDocument, options)
}
export type DeleteLabelMutationHookResult = ReturnType<typeof useDeleteLabelMutation>;
export type DeleteLabelMutationResult = Apollo.MutationResult<Types.DeleteLabelMutation>;
export type DeleteLabelMutationOptions = Apollo.BaseMutationOptions<Types.DeleteLabelMutation, Types.DeleteLabelMutationVariables>;
export const CreatePaymentMethodDocument = gql`
    mutation createPaymentMethod($name: String, $stripeId: String!, $target: PaymentMethodTarget!) {
  CreatePaymentMethod_v1(
    paymentMethod: {name: $name, stripeId: $stripeId, target: $target}
  ) {
    id
    name
    stripeId
    target
  }
}
    `
export type CreatePaymentMethodMutationFn = Apollo.MutationFunction<Types.CreatePaymentMethodMutation, Types.CreatePaymentMethodMutationVariables>;

/**
 * __useCreatePaymentMethodMutation__
 *
 * To run a mutation, you first call `useCreatePaymentMethodMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePaymentMethodMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPaymentMethodMutation, { data, loading, error }] = useCreatePaymentMethodMutation({
 *   variables: {
 *      name: // value for 'name'
 *      stripeId: // value for 'stripeId'
 *      target: // value for 'target'
 *   },
 * });
 */
export function useCreatePaymentMethodMutation (baseOptions?: Apollo.MutationHookOptions<Types.CreatePaymentMethodMutation, Types.CreatePaymentMethodMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<Types.CreatePaymentMethodMutation, Types.CreatePaymentMethodMutationVariables>(CreatePaymentMethodDocument, options)
}
export type CreatePaymentMethodMutationHookResult = ReturnType<typeof useCreatePaymentMethodMutation>;
export type CreatePaymentMethodMutationResult = Apollo.MutationResult<Types.CreatePaymentMethodMutation>;
export type CreatePaymentMethodMutationOptions = Apollo.BaseMutationOptions<Types.CreatePaymentMethodMutation, Types.CreatePaymentMethodMutationVariables>;
export const DeletePaymentMethodDocument = gql`
    mutation deletePaymentMethod($id: ID!) {
  DeletePaymentMethod_v2(id: $id)
}
    `
export type DeletePaymentMethodMutationFn = Apollo.MutationFunction<Types.DeletePaymentMethodMutation, Types.DeletePaymentMethodMutationVariables>;

/**
 * __useDeletePaymentMethodMutation__
 *
 * To run a mutation, you first call `useDeletePaymentMethodMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePaymentMethodMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePaymentMethodMutation, { data, loading, error }] = useDeletePaymentMethodMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeletePaymentMethodMutation (baseOptions?: Apollo.MutationHookOptions<Types.DeletePaymentMethodMutation, Types.DeletePaymentMethodMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<Types.DeletePaymentMethodMutation, Types.DeletePaymentMethodMutationVariables>(DeletePaymentMethodDocument, options)
}
export type DeletePaymentMethodMutationHookResult = ReturnType<typeof useDeletePaymentMethodMutation>;
export type DeletePaymentMethodMutationResult = Apollo.MutationResult<Types.DeletePaymentMethodMutation>;
export type DeletePaymentMethodMutationOptions = Apollo.BaseMutationOptions<Types.DeletePaymentMethodMutation, Types.DeletePaymentMethodMutationVariables>;
export const GetPaymentMethodsDocument = gql`
    query getPaymentMethods {
  PaymentMethods {
    id
    name
    stripeData: retrieveStripeData
    Contracts {
      id
      name
      type
      status
      OwnerContact {
        id
        fullname
      }
    }
  }
}
    `

/**
 * __useGetPaymentMethodsQuery__
 *
 * To run a query within a React component, call `useGetPaymentMethodsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPaymentMethodsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPaymentMethodsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetPaymentMethodsQuery (baseOptions?: Apollo.QueryHookOptions<Types.GetPaymentMethodsQuery, Types.GetPaymentMethodsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<Types.GetPaymentMethodsQuery, Types.GetPaymentMethodsQueryVariables>(GetPaymentMethodsDocument, options)
}
export function useGetPaymentMethodsLazyQuery (baseOptions?: Apollo.LazyQueryHookOptions<Types.GetPaymentMethodsQuery, Types.GetPaymentMethodsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<Types.GetPaymentMethodsQuery, Types.GetPaymentMethodsQueryVariables>(GetPaymentMethodsDocument, options)
}
export type GetPaymentMethodsQueryHookResult = ReturnType<typeof useGetPaymentMethodsQuery>;
export type GetPaymentMethodsLazyQueryHookResult = ReturnType<typeof useGetPaymentMethodsLazyQuery>;
export type GetPaymentMethodsQueryResult = Apollo.QueryResult<Types.GetPaymentMethodsQuery, Types.GetPaymentMethodsQueryVariables>;
export const StripeCreateSetupIntentDocument = gql`
    mutation stripeCreateSetupIntent {
  StripeCreateSetupIntent_v1
}
    `
export type StripeCreateSetupIntentMutationFn = Apollo.MutationFunction<Types.StripeCreateSetupIntentMutation, Types.StripeCreateSetupIntentMutationVariables>;

/**
 * __useStripeCreateSetupIntentMutation__
 *
 * To run a mutation, you first call `useStripeCreateSetupIntentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStripeCreateSetupIntentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [stripeCreateSetupIntentMutation, { data, loading, error }] = useStripeCreateSetupIntentMutation({
 *   variables: {
 *   },
 * });
 */
export function useStripeCreateSetupIntentMutation (baseOptions?: Apollo.MutationHookOptions<Types.StripeCreateSetupIntentMutation, Types.StripeCreateSetupIntentMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<Types.StripeCreateSetupIntentMutation, Types.StripeCreateSetupIntentMutationVariables>(StripeCreateSetupIntentDocument, options)
}
export type StripeCreateSetupIntentMutationHookResult = ReturnType<typeof useStripeCreateSetupIntentMutation>;
export type StripeCreateSetupIntentMutationResult = Apollo.MutationResult<Types.StripeCreateSetupIntentMutation>;
export type StripeCreateSetupIntentMutationOptions = Apollo.BaseMutationOptions<Types.StripeCreateSetupIntentMutation, Types.StripeCreateSetupIntentMutationVariables>;
export const GetContractPaymentMethodDocument = gql`
    query getContractPaymentMethod($id: [ID]!) {
  PaymentMethods {
    id
    name
    stripeData: retrieveStripeData
  }
  Contracts(id: $id) {
    id
    name
    PaymentMethod {
      id
    }
  }
}
    `

/**
 * __useGetContractPaymentMethodQuery__
 *
 * To run a query within a React component, call `useGetContractPaymentMethodQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContractPaymentMethodQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContractPaymentMethodQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetContractPaymentMethodQuery (baseOptions: Apollo.QueryHookOptions<Types.GetContractPaymentMethodQuery, Types.GetContractPaymentMethodQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<Types.GetContractPaymentMethodQuery, Types.GetContractPaymentMethodQueryVariables>(GetContractPaymentMethodDocument, options)
}
export function useGetContractPaymentMethodLazyQuery (baseOptions?: Apollo.LazyQueryHookOptions<Types.GetContractPaymentMethodQuery, Types.GetContractPaymentMethodQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<Types.GetContractPaymentMethodQuery, Types.GetContractPaymentMethodQueryVariables>(GetContractPaymentMethodDocument, options)
}
export type GetContractPaymentMethodQueryHookResult = ReturnType<typeof useGetContractPaymentMethodQuery>;
export type GetContractPaymentMethodLazyQueryHookResult = ReturnType<typeof useGetContractPaymentMethodLazyQuery>;
export type GetContractPaymentMethodQueryResult = Apollo.QueryResult<Types.GetContractPaymentMethodQuery, Types.GetContractPaymentMethodQueryVariables>;
export const GetPaymentMethodDocument = gql`
    query getPaymentMethod($id: [ID]!) {
  PaymentMethods(id: $id) {
    id
    target
    name
    stripeData: retrieveStripeData
    Contracts {
      id
      type
      name
      OwnerContact {
        address_short
      }
    }
  }
}
    `

/**
 * __useGetPaymentMethodQuery__
 *
 * To run a query within a React component, call `useGetPaymentMethodQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPaymentMethodQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPaymentMethodQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetPaymentMethodQuery (baseOptions: Apollo.QueryHookOptions<Types.GetPaymentMethodQuery, Types.GetPaymentMethodQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<Types.GetPaymentMethodQuery, Types.GetPaymentMethodQueryVariables>(GetPaymentMethodDocument, options)
}
export function useGetPaymentMethodLazyQuery (baseOptions?: Apollo.LazyQueryHookOptions<Types.GetPaymentMethodQuery, Types.GetPaymentMethodQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<Types.GetPaymentMethodQuery, Types.GetPaymentMethodQueryVariables>(GetPaymentMethodDocument, options)
}
export type GetPaymentMethodQueryHookResult = ReturnType<typeof useGetPaymentMethodQuery>;
export type GetPaymentMethodLazyQueryHookResult = ReturnType<typeof useGetPaymentMethodLazyQuery>;
export type GetPaymentMethodQueryResult = Apollo.QueryResult<Types.GetPaymentMethodQuery, Types.GetPaymentMethodQueryVariables>;
export const GetTariffDocument = gql`
    query getTariff($codeName: String!) {
  Prices(where: [{field: "codename", operator: EQUAL, value: $codeName}]) {
    name: getName
  }
}
    `

/**
 * __useGetTariffQuery__
 *
 * To run a query within a React component, call `useGetTariffQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTariffQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTariffQuery({
 *   variables: {
 *      codeName: // value for 'codeName'
 *   },
 * });
 */
export function useGetTariffQuery (baseOptions: Apollo.QueryHookOptions<Types.GetTariffQuery, Types.GetTariffQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<Types.GetTariffQuery, Types.GetTariffQueryVariables>(GetTariffDocument, options)
}
export function useGetTariffLazyQuery (baseOptions?: Apollo.LazyQueryHookOptions<Types.GetTariffQuery, Types.GetTariffQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<Types.GetTariffQuery, Types.GetTariffQueryVariables>(GetTariffDocument, options)
}
export type GetTariffQueryHookResult = ReturnType<typeof useGetTariffQuery>;
export type GetTariffLazyQueryHookResult = ReturnType<typeof useGetTariffLazyQuery>;
export type GetTariffQueryResult = Apollo.QueryResult<Types.GetTariffQuery, Types.GetTariffQueryVariables>;
export const GetPromoCodeDetailsDocument = gql`
    query getPromoCodeDetails($promoCode: String!) {
  CodeValidation(code: $promoCode) {
    code
    type
    amount
    split
    description
    configuration
    partner
    expireDate
  }
}
    `

/**
 * __useGetPromoCodeDetailsQuery__
 *
 * To run a query within a React component, call `useGetPromoCodeDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPromoCodeDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPromoCodeDetailsQuery({
 *   variables: {
 *      promoCode: // value for 'promoCode'
 *   },
 * });
 */
export function useGetPromoCodeDetailsQuery (baseOptions: Apollo.QueryHookOptions<Types.GetPromoCodeDetailsQuery, Types.GetPromoCodeDetailsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<Types.GetPromoCodeDetailsQuery, Types.GetPromoCodeDetailsQueryVariables>(GetPromoCodeDetailsDocument, options)
}
export function useGetPromoCodeDetailsLazyQuery (baseOptions?: Apollo.LazyQueryHookOptions<Types.GetPromoCodeDetailsQuery, Types.GetPromoCodeDetailsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<Types.GetPromoCodeDetailsQuery, Types.GetPromoCodeDetailsQueryVariables>(GetPromoCodeDetailsDocument, options)
}
export type GetPromoCodeDetailsQueryHookResult = ReturnType<typeof useGetPromoCodeDetailsQuery>;
export type GetPromoCodeDetailsLazyQueryHookResult = ReturnType<typeof useGetPromoCodeDetailsLazyQuery>;
export type GetPromoCodeDetailsQueryResult = Apollo.QueryResult<Types.GetPromoCodeDetailsQuery, Types.GetPromoCodeDetailsQueryVariables>;
export const CreateFirebaseCustomTokenDocument = gql`
    mutation createFirebaseCustomToken {
  CreateFirebaseCustomToken_v1
}
    `
export type CreateFirebaseCustomTokenMutationFn = Apollo.MutationFunction<Types.CreateFirebaseCustomTokenMutation, Types.CreateFirebaseCustomTokenMutationVariables>;

/**
 * __useCreateFirebaseCustomTokenMutation__
 *
 * To run a mutation, you first call `useCreateFirebaseCustomTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateFirebaseCustomTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createFirebaseCustomTokenMutation, { data, loading, error }] = useCreateFirebaseCustomTokenMutation({
 *   variables: {
 *   },
 * });
 */
export function useCreateFirebaseCustomTokenMutation (baseOptions?: Apollo.MutationHookOptions<Types.CreateFirebaseCustomTokenMutation, Types.CreateFirebaseCustomTokenMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<Types.CreateFirebaseCustomTokenMutation, Types.CreateFirebaseCustomTokenMutationVariables>(CreateFirebaseCustomTokenDocument, options)
}
export type CreateFirebaseCustomTokenMutationHookResult = ReturnType<typeof useCreateFirebaseCustomTokenMutation>;
export type CreateFirebaseCustomTokenMutationResult = Apollo.MutationResult<Types.CreateFirebaseCustomTokenMutation>;
export type CreateFirebaseCustomTokenMutationOptions = Apollo.BaseMutationOptions<Types.CreateFirebaseCustomTokenMutation, Types.CreateFirebaseCustomTokenMutationVariables>;
export const UpdateUserInfoDocument = gql`
    mutation updateUserInfo($firstName: String, $lastName: String) {
  UpdateUserInfo_v1(firstName: $firstName, lastName: $lastName) {
    id
    firstName
    lastName
  }
}
    `
export type UpdateUserInfoMutationFn = Apollo.MutationFunction<Types.UpdateUserInfoMutation, Types.UpdateUserInfoMutationVariables>;

/**
 * __useUpdateUserInfoMutation__
 *
 * To run a mutation, you first call `useUpdateUserInfoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserInfoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserInfoMutation, { data, loading, error }] = useUpdateUserInfoMutation({
 *   variables: {
 *      firstName: // value for 'firstName'
 *      lastName: // value for 'lastName'
 *   },
 * });
 */
export function useUpdateUserInfoMutation (baseOptions?: Apollo.MutationHookOptions<Types.UpdateUserInfoMutation, Types.UpdateUserInfoMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<Types.UpdateUserInfoMutation, Types.UpdateUserInfoMutationVariables>(UpdateUserInfoDocument, options)
}
export type UpdateUserInfoMutationHookResult = ReturnType<typeof useUpdateUserInfoMutation>;
export type UpdateUserInfoMutationResult = Apollo.MutationResult<Types.UpdateUserInfoMutation>;
export type UpdateUserInfoMutationOptions = Apollo.BaseMutationOptions<Types.UpdateUserInfoMutation, Types.UpdateUserInfoMutationVariables>;
export const UpdatePrivacySettingDocument = gql`
    mutation updatePrivacySetting($privacies: [PrivacyInput]!) {
  CreateOrUpdatePrivacy_v1(privacies: $privacies) {
    id
    policy
    accepted
  }
}
    `
export type UpdatePrivacySettingMutationFn = Apollo.MutationFunction<Types.UpdatePrivacySettingMutation, Types.UpdatePrivacySettingMutationVariables>;

/**
 * __useUpdatePrivacySettingMutation__
 *
 * To run a mutation, you first call `useUpdatePrivacySettingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePrivacySettingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePrivacySettingMutation, { data, loading, error }] = useUpdatePrivacySettingMutation({
 *   variables: {
 *      privacies: // value for 'privacies'
 *   },
 * });
 */
export function useUpdatePrivacySettingMutation (baseOptions?: Apollo.MutationHookOptions<Types.UpdatePrivacySettingMutation, Types.UpdatePrivacySettingMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<Types.UpdatePrivacySettingMutation, Types.UpdatePrivacySettingMutationVariables>(UpdatePrivacySettingDocument, options)
}
export type UpdatePrivacySettingMutationHookResult = ReturnType<typeof useUpdatePrivacySettingMutation>;
export type UpdatePrivacySettingMutationResult = Apollo.MutationResult<Types.UpdatePrivacySettingMutation>;
export type UpdatePrivacySettingMutationOptions = Apollo.BaseMutationOptions<Types.UpdatePrivacySettingMutation, Types.UpdatePrivacySettingMutationVariables>;
export const GetUserDocument = gql`
    query getUser {
  Me {
    id
    phone
    firstName
    lastName
    fullName
    email
  }
}
    `

/**
 * __useGetUserQuery__
 *
 * To run a query within a React component, call `useGetUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUserQuery (baseOptions?: Apollo.QueryHookOptions<Types.GetUserQuery, Types.GetUserQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<Types.GetUserQuery, Types.GetUserQueryVariables>(GetUserDocument, options)
}
export function useGetUserLazyQuery (baseOptions?: Apollo.LazyQueryHookOptions<Types.GetUserQuery, Types.GetUserQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<Types.GetUserQuery, Types.GetUserQueryVariables>(GetUserDocument, options)
}
export type GetUserQueryHookResult = ReturnType<typeof useGetUserQuery>;
export type GetUserLazyQueryHookResult = ReturnType<typeof useGetUserLazyQuery>;
export type GetUserQueryResult = Apollo.QueryResult<Types.GetUserQuery, Types.GetUserQueryVariables>;
export const GetAttributesDocument = gql`
    query getAttributes($where: [WhereClause]) {
  Attributes(where: $where) {
    key
    value
  }
}
    `

/**
 * __useGetAttributesQuery__
 *
 * To run a query within a React component, call `useGetAttributesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAttributesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAttributesQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetAttributesQuery (baseOptions?: Apollo.QueryHookOptions<Types.GetAttributesQuery, Types.GetAttributesQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<Types.GetAttributesQuery, Types.GetAttributesQueryVariables>(GetAttributesDocument, options)
}
export function useGetAttributesLazyQuery (baseOptions?: Apollo.LazyQueryHookOptions<Types.GetAttributesQuery, Types.GetAttributesQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<Types.GetAttributesQuery, Types.GetAttributesQueryVariables>(GetAttributesDocument, options)
}
export type GetAttributesQueryHookResult = ReturnType<typeof useGetAttributesQuery>;
export type GetAttributesLazyQueryHookResult = ReturnType<typeof useGetAttributesLazyQuery>;
export type GetAttributesQueryResult = Apollo.QueryResult<Types.GetAttributesQuery, Types.GetAttributesQueryVariables>;
export const GetPrivaciesDocument = gql`
    query getPrivacies($where: [WhereClause]) {
  Privacies(where: $where) {
    id
    policy
    accepted
    acceptedDate
  }
}
    `

/**
 * __useGetPrivaciesQuery__
 *
 * To run a query within a React component, call `useGetPrivaciesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPrivaciesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPrivaciesQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetPrivaciesQuery (baseOptions?: Apollo.QueryHookOptions<Types.GetPrivaciesQuery, Types.GetPrivaciesQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<Types.GetPrivaciesQuery, Types.GetPrivaciesQueryVariables>(GetPrivaciesDocument, options)
}
export function useGetPrivaciesLazyQuery (baseOptions?: Apollo.LazyQueryHookOptions<Types.GetPrivaciesQuery, Types.GetPrivaciesQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<Types.GetPrivaciesQuery, Types.GetPrivaciesQueryVariables>(GetPrivaciesDocument, options)
}
export type GetPrivaciesQueryHookResult = ReturnType<typeof useGetPrivaciesQuery>;
export type GetPrivaciesLazyQueryHookResult = ReturnType<typeof useGetPrivaciesLazyQuery>;
export type GetPrivaciesQueryResult = Apollo.QueryResult<Types.GetPrivaciesQuery, Types.GetPrivaciesQueryVariables>;
export const GetReferralInfoDocument = gql`
    query getReferralInfo {
  ReferralInformation_v2 {
    promoCode {
      id
      code
      banned
    }
    landingUrl
  }
  countContracts {
    value
  }
}
    `

/**
 * __useGetReferralInfoQuery__
 *
 * To run a query within a React component, call `useGetReferralInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetReferralInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetReferralInfoQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetReferralInfoQuery (baseOptions?: Apollo.QueryHookOptions<Types.GetReferralInfoQuery, Types.GetReferralInfoQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<Types.GetReferralInfoQuery, Types.GetReferralInfoQueryVariables>(GetReferralInfoDocument, options)
}
export function useGetReferralInfoLazyQuery (baseOptions?: Apollo.LazyQueryHookOptions<Types.GetReferralInfoQuery, Types.GetReferralInfoQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<Types.GetReferralInfoQuery, Types.GetReferralInfoQueryVariables>(GetReferralInfoDocument, options)
}
export type GetReferralInfoQueryHookResult = ReturnType<typeof useGetReferralInfoQuery>;
export type GetReferralInfoLazyQueryHookResult = ReturnType<typeof useGetReferralInfoLazyQuery>;
export type GetReferralInfoQueryResult = Apollo.QueryResult<Types.GetReferralInfoQuery, Types.GetReferralInfoQueryVariables>;
