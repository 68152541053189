import { Layout } from '../../../style/spacing'
import { MainScreenLayout } from '../../../layouts/mainScreen'
import { PageLayout } from '../../../layouts/page'
import { UserInfo } from '../../../widgets/UserInfo'
import { WidgetReferral } from '../../../widgets/widgetReferral'
import { isBusinessUsageTarget } from '../../../configuration/env'
import { translate } from '../../../lib/localization'
import React from 'react'
import person from '../../../images/icons/person.svg'
import personOutline from '../../../images/icons/outline/person.svg'

export const UserScreen = () => {
  return (
    <PageLayout>
      <MainScreenLayout showToolbox={false} sectionHeaderProps={{
        icon: isBusinessUsageTarget ? personOutline : person,
        path: `${translate('screens_title__account_settings')} /`,
        title: translate('user_screen__title')
      }}>
        <div css={[Layout.display_flex, Layout.ColumnGap.sm]}>
          <UserInfo />
          {
            !isBusinessUsageTarget && <WidgetReferral />
          }
        </div>
      </MainScreenLayout>
    </PageLayout>
  )
}
