import { css } from '@emotion/react'

export const primaryColor = '#3C00CF'
export const successGreen = '#2F9B6B'
export const dangerRed = '#E80048'

export const eerieBlack = {
  0: '#FFFFFF',
  50: '#F9F9F9',
  100: '#F5F5F5',
  200: '#EBEBEB',
  300: '#DEDEDE',
  400: '#D2D2D2',
  500: '#ADADAD',
  600: '#737373',
  700: '#494949',
  800: '#1C1C1C',
  900: '#000000'
}

export const turquoiseBlue = {
  50: '#E6F6FA',
  200: '#99D9E7',
  500: '#009FC4'
}

export const uaRed = {
  50: '#FDE6ED',
  500: '#E80048',
  600: '#AE0036'
}

export const violetBlue = {
  50: '#ECE6FB',
  100: '#D8CCF5',
  200: '#B199EC',
  300: '#8A66E2',
  500: '#3C00CF',
  600: '#2D009B'
}

export const darkMint = {
  50: '#ECFBF4',
  200: '#B2ECD2',
  500: '#3ECF8E',
  600: '#2F9B6A'
}

export const azul = {
  50: '#E8F1FE',
  200: '#A3C3F9',
  400: '#4687F2',
  500: '#1869EF',
  700: '#0C3578'
}

/**
 * @deprecated
 * Replaced by azul
 */
export const contractAzul = {
  50: '#E8F1FE',
  200: '#A3C3F9',
  400: '#4687F2',
  500: '#1869EF',
  700: '#0C3578'
}
export const royalPurple = {
  50: '#F1E6F3',
  200: '#C599CD',
  400: '#8A339A',
  500: '#6D0081',
  700: '#370041'
}

export const tartOrange = {
  200: '#FDBABA',
  500: '#FA5252'
}

export const marigold = {
  50: '#FFF7EA',
  200: '#FBDBA7',
  500: '#F5A623'
}

export const allColors = [
  contractAzul,
  darkMint,
  eerieBlack,
  marigold,
  royalPurple,
  tartOrange,
  turquoiseBlue,
  uaRed,
  violetBlue,
  azul
]

// TODO: Check with Mastro about proper naming
export const azzurrino = {
  50: '#E9FFFF',
  500: '#20EBEE'
}
export const verdolino = {
  50: '#EFFFEF',
  500: '#20EE5E'
}

export const Colors = {
  BackgroundColor: {
    azzurrino50: css`background-color: ${azzurrino['50']}`,
    contractAzul500: css`background-color: ${contractAzul['500']}`,
    darkMint50: css`background-color: ${darkMint['50']}`,
    darkMint500: css`background-color: ${darkMint['500']}`,
    eerieBlack0: css`background-color: ${eerieBlack['0']}`,
    eerieBlack50: css`background-color: ${eerieBlack['50']}`,
    eerieBlack100: css`background-color: ${eerieBlack['100']}`,
    eerieBlack500: css`background-color: ${eerieBlack['500']}`,
    eerieBlack600: css`background-color: ${eerieBlack['600']}`,
    eerieBlack700: css`background-color: ${eerieBlack['700']}`,
    eerieBlack800: css`background-color: ${eerieBlack['800']}`,
    gradientEnergy: css`background: linear-gradient(45deg, #124FB3 0%, #4687F2 100%)`,
    gradientGas: css`background: linear-gradient(45deg, #520061 0%, #8A339A 100%)`,
    marigold50: css`background-color: ${marigold['50']}`,
    none: css`background-color: transparent`,
    royalPurple500: css`background-color: ${royalPurple['500']}`,
    tartOrange200: css`background-color: ${tartOrange['200']}`,
    tartOrange500: css`background-color: ${tartOrange['500']}`,
    uaRed50: css`background-color: ${uaRed['50']}`,
    uaRed500: css`background-color: ${uaRed['500']}`,
    uaRed600: css`background-color: ${uaRed['600']}`,
    verdolino50: css`background-color: ${verdolino['50']}`,
    violetBlue50: css`background-color: ${violetBlue['50']}`,
    violetBlue100: css`background-color: ${violetBlue['100']}`,
    violetBlue500: css`background-color: ${violetBlue['500']}`,
    violetBlue600: css`background-color: ${violetBlue['600']}`
  },
  BorderColor: {
    contractAzul500: css`border-color: ${contractAzul['500']}`,
    darkMint500: css`border-color: ${darkMint['500']}`,
    eerieBlack0: css`border-color: ${eerieBlack['0']}`,
    eerieBlack200: css`border-color: ${eerieBlack['200']}`,
    eerieBlack300: css`border-color: ${eerieBlack['300']}`,
    eerieBlack500: css`border-color: ${eerieBlack['500']}`,
    eerieBlack600: css`border-color: ${eerieBlack['600']}`,
    eerieBlack700: css`border-color: ${eerieBlack['700']}`,
    eerieBlack800: css`border-color: ${eerieBlack['800']}`,
    marigold500: css`border-color: ${marigold['500']}`,
    none: css`border-color: transparent`,
    violetBlue500: css`border-color: ${violetBlue['500']}`

  },
  Color: {
    azzurrino500: css`color: ${azzurrino['500']}`,
    contractAzul200: css`color: ${contractAzul['200']}`,
    contractAzul500: css`color: ${contractAzul['500']}`,
    darkMint500: css`color: ${darkMint['500']}`,
    eerieBlack0: css`color: ${eerieBlack['0']}`,
    eerieBlack100: css`color: ${eerieBlack['100']}`,
    eerieBlack300: css`color: ${eerieBlack['300']}`,
    eerieBlack400: css`color: ${eerieBlack['400']}`,
    eerieBlack500: css`color: ${eerieBlack['500']}`,
    eerieBlack600: css`color: ${eerieBlack['600']}`,
    eerieBlack700: css`color: ${eerieBlack['700']}`,
    eerieBlack800: css`color: ${eerieBlack['800']}`,
    marigold500: css`color: ${marigold['500']}`,
    royalPurple200: css`color: ${royalPurple['200']}`,
    royalPurple500: css`color: ${royalPurple['500']}`,
    tartOrange200: css`color: ${tartOrange['200']}`,
    tartOrange500: css`color: ${tartOrange['500']}`,
    turquoiseBlue500: css`color: ${turquoiseBlue['500']}`,
    uaRed500: css`color: ${uaRed['500']}`,
    verdolino500: css`color: ${verdolino['500']}`,
    violetBlue300: css`color: ${violetBlue['300']}`,
    violetBlue500: css`color: ${violetBlue['500']}`
  },
  DropShadow: {
    violetBlue: css`box-shadow: 0px 0px 5px #D8CCF5, 0px 0px 5px #3C00CF`,
    white100: css`box-shadow: 0px 1px 2px rgba(28, 28, 28, 0.12), 0px 2px 8px rgba(28, 28, 28, 0.08);`
  },
  Hover: {
    BackgroundColor: {
      eerieBlack100: css`:hover {
        background-color: ${eerieBlack['100']}
      }`
    }
  },
  Transitions: {
    background: css`transition: background-color .1s`
  }
}
