import { BusinessDataSum, BusinessDataTrend, ContractType } from '../../lib/apollo/types'
import { Datum, DatumValue, Point, ResponsiveLine, Serie } from '@nivo/line'
import { LegendProps } from '@nivo/legends'
import { SymbolProps } from '@nivo/legends/dist/types/svg/symbols/types'
import { Tooltip } from '../businessTooltipChart'
import { contractAzul, eerieBlack, royalPurple } from '../../style/colors'
import { formatter } from '../../style/utils'
import { toCurrency } from '../../lib/localization'
import React, { useMemo } from 'react'
import dayjs from '../../lib/dayJs'

// LegendSymbol
const LegendSymbol: React.FC<SymbolProps> = ({
  x,
  y,
  size,
  fill,
  borderWidth,
  borderColor
}) => (
  <rect
    x={x}
    y={y}
    fill={fill}
    strokeWidth={borderWidth}
    stroke={borderColor}
    width={size}
    height={size}
    rx={size / 4}
    style={{ pointerEvents: 'none' }}
  />
)

// legends configuration
const legendsConfig: LegendProps[] = [
  {
    anchor: 'top',
    direction: 'row',
    itemDirection: 'left-to-right',
    itemHeight: 15,
    itemOpacity: 1,
    itemWidth: 50,
    itemsSpacing: 0,
    justify: false,
    symbolShape: LegendSymbol,
    symbolSize: 10,
    symbolSpacing: 4,
    translateY: -30
  }
]

interface CostsDatum extends Datum{
  delta: number
}

interface CostSerie extends Serie {
  data: CostsDatum[]
}

interface CostsPoint extends Point {
  data: {
    x: DatumValue
    xFormatted: string | number
    y: DatumValue
    yFormatted: string | number
    yStacked?: number
    delta: number
  }
}
const getPointData = ([y = 0, delta = 0]: number[], x) => ({ delta: (delta / 100), x, y })
const setMax = (serie, [value = 0]: number[]) => { if (serie.max < value) serie.max = value }

export const CostsChartTypesEnum = {
  ...ContractType,
  all: 'all'
}
export type CostsChartTypes = typeof CostsChartTypesEnum
type CostsChartTypesEnumKeys = keyof CostsChartTypes

export const getCostsChartType = (data: BusinessDataSum): typeof CostsChartTypesEnum[CostsChartTypesEnumKeys] => {
  const { energy_contracts: eeContracts, gas_contracts: gasContracts } = data
  if (gasContracts === 0) return CostsChartTypesEnum.Ee
  if (eeContracts === 0) return CostsChartTypesEnum.Gas
  return CostsChartTypesEnum.all
}
// Convert graphql data to data series
export const graphqlDataToSeries = (data: BusinessDataTrend[] = []): CostSerie[] => data
  .sort((a, b) => dayjs(a.date).unix() - dayjs(b.date).unix())
  .reduce(([total, gas, ee], { energy_invoices: eeInvoices, gas_invoices: gasInvoices, invoices, date }) => {
    const x = dayjs(date).format('YYYY-MM-DD')

    invoices && total.data.push(getPointData(invoices ?? [], x))
    eeInvoices && ee.data.push(getPointData(eeInvoices ?? [], x))
    gasInvoices && gas.data.push(getPointData(gasInvoices ?? [], x))

    setMax(total, invoices ?? [])
    setMax(ee, eeInvoices ?? [])
    setMax(gas, gasInvoices ?? [])
    return [total, gas, ee]
  }, [
    {
      data: [],
      id: 'Totale',
      max: 0
    },
    {
      data: [],
      id: 'Gas',
      max: 0
    },
    {
      data: [],
      id: 'Luce',
      max: 0
    }])

type CostsChartProps = {
  data: CostSerie[]
  width?: number
  type: typeof CostsChartTypesEnum[CostsChartTypesEnumKeys]
}

export const CostsChart: React.FC<CostsChartProps> = ({ data, width, type = CostsChartTypesEnum.all }) => {
  const COLORS: string[] = {
    [CostsChartTypesEnum.all]: [eerieBlack['500'], royalPurple['500'], contractAzul['500']],
    [CostsChartTypesEnum.Ee]: [contractAzul['500']],
    [CostsChartTypesEnum.Gas]: [royalPurple['500']]
  }[type]
  const chartSeries = useMemo(() => {
    if (type === CostsChartTypesEnum.Gas) return [data[1]]
    if (type === CostsChartTypesEnum.Ee) return [data[2]]
    return data
  }, [data, type])
  const marginLeft = 25 + (chartSeries[0].max.toFixed(0).toString().length - 2) * 10
  return (
    <ResponsiveLine
      margin={{ bottom: 30, left: marginLeft, right: 20, top: 30 }}
      data={chartSeries}
      colors={COLORS}
      axisTop={null}
      axisRight={null}
      theme={{ fontSize: 12, textColor: eerieBlack[600] }}
      xScale={{
        format: '%Y-%m-%d',
        max: 'auto',
        min: 'auto',
        precision: 'month',
        type: 'time',
        useUTC: false
      }}
      yScale={{
        max: 'auto',
        min: 'auto',
        nice: 5,
        type: 'linear'
      }}
      axisLeft={{
        tickPadding: 10,
        tickSize: 0,
        tickValues: 4
      }}
      axisBottom={{
        format: formatter,
        tickPadding: 10,
        tickSize: 0,
        tickValues: 'every month'
      }}
      enableGridX={false}
      enableGridY={false}
      lineWidth={3}
      pointSize={14}
      pointColor={'white'}
      pointBorderWidth={2}
      pointBorderColor={{ from: 'serieColor' }}
      useMesh={true}
      tooltip={({ point }: { point: CostsPoint }) => {
        const leftOffset = marginLeft + 24
        const left = 80 - leftOffset
        let translateX = 0
        if (point.x < left) translateX = left - point.x
        if (point.x + 36 > width - leftOffset) translateX = (width - leftOffset) - (point.x + 36)
        return <Tooltip data={point.data} translateX={translateX} date={dayjs(point.data.x).format('MMMM YYYY')} value={toCurrency(point.data.y)} change={point.data.delta}/>
      }
      }
      legends={legendsConfig}
    />)
}
