import { Spacing } from '../../style/spacing'
import { css } from '@emotion/react'
import { eerieBlack } from '../../style/colors'

export const style = css`
  .modal {
    position: fixed;
    top: 0;
    left: 0;
    width:100%;
    height: 100%;
    background: transparent;
    opacity: 1;
    display: block;
    z-index: 3;
    &[data-background="true"] {
      background: ${eerieBlack['800']};
      opacity: 0.5;
    }
  }

  .modal-main {
    ${Spacing.Padding.all_24}
    position: fixed;
    background: ${eerieBlack['0']};
    width: 66%;
    max-width: 640px;
    height: auto;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    z-index: 4;
    border-radius: 10px;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.04), 0px 8px 16px rgba(0, 0, 0, 0.04);
  }
`
