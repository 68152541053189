import { Button, Modal, Spacer } from '../../../components'
import { GetLabelsDocument, useDeleteLabelMutation } from '../../../lib/apollo/hooks'
import { Layout } from '../../../style/spacing'
import { Sizes } from '../../../style/sizes'
import { Typography } from '../../../style/typography'
import { css } from '@emotion/react'
import { logAndSendToErrorTracker } from '../../../services/errorTracker'
import { translate } from '../../../lib/localization'
import React from 'react'

export const DeleteLabel = ({ showModal, setShowModal, id }) => {
  const [createLabel, { loading }] = useDeleteLabelMutation({
    onCompleted: () => {
      setShowModal(false)
    },
    onError: error => {
      logAndSendToErrorTracker(error)
    },
    refetchQueries: [GetLabelsDocument],
    variables: {
      id: id
    }
  })
  if (!showModal) return null

  return (
    <Modal
      title={<div css={Typography.heading.h5_700}>{translate('modal__delete_label__title')}</div>}
      hideBackground={true}
      hideModal={() => setShowModal(false)}
      style={css`max-width: 420px;`}
    >
      <Spacer height={16}/>
      <div>{translate('modal__delete_label__subtitle')}</div>
      <div css={[Layout.flex_justify_end, Layout.ColumnGap.md, Layout.display_flex]}>
        <Button
          onClick={() => setShowModal(false)}
          style="no-background"
          size={Sizes.medium}
          title={translate('button_cancel')}
          block={true}
        />
        <Button
          loading={loading}
          onClick={createLabel}
          style={'alert'}
          size={Sizes.medium}
          title={translate('button_delete')}
          block={true}
        />
      </div>
    </Modal>
  )
}
