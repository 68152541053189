import { AddPaymentMethodScreen } from '../screens/shared/AddPaymentMethods'
import { AutoletturaScreen } from '../screens/private/Autolettura'
import { HomeScreen } from '../screens/private/Home'
import { InvoicesScreen } from '../screens/private/Invoices'
import { PayNowScreen } from '../screens/private/PayNow'
import { PaymentMethodHandlingScreen } from '../screens/private/PaymentMethodsHandling'
import { PaymentMethodsScreen } from '../screens/shared/PaymentMethods'
import { PrivateErrorBoundary } from '../components/privateErrorBoundary'
import { PrivateNavigatorParams, Screens } from './types'
import { ReferralScreen } from '../screens/private/Referral'
import { UserScreen } from '../screens/shared/User'
import { UtenzaScreen } from '../screens/private/Utenza'
import { VolturaScreen } from '../screens/private/Voltura'
import { createStackNavigator } from '@react-navigation/stack'
import React from 'react'

const PrivateStack = createStackNavigator<PrivateNavigatorParams>()

export const PrivateNavigator = () => {
  return (
    <PrivateErrorBoundary>
      <PrivateStack.Navigator
        screenOptions={{
          headerShown: false
        }}
        initialRouteName={Screens.Home}
      >
        <PrivateStack.Screen name={Screens.Home} component={HomeScreen}/>
        <PrivateStack.Screen name={Screens.Utenza} component={UtenzaScreen}/>
        <PrivateStack.Screen name={Screens.Consumi} component={InvoicesScreen}/>
        <PrivateStack.Screen name={Screens.AggiungiPagamenti} component={AddPaymentMethodScreen}/>
        <PrivateStack.Screen name={Screens.Autolettura} component={AutoletturaScreen}/>
        <PrivateStack.Screen name={Screens.Intestatario} component={VolturaScreen}/>
        <PrivateStack.Screen name={Screens.Pagamenti} component={PaymentMethodsScreen}/>
        <PrivateStack.Screen name={Screens.Profilo} component={UserScreen}/>
        <PrivateStack.Screen name={Screens.Referral} component={ReferralScreen}/>
        <PrivateStack.Screen name={Screens.GestisciMetodiPagamento} component={PaymentMethodHandlingScreen}/>
        <PrivateStack.Screen name={Screens.PagaOra} component={PayNowScreen}/>
      </PrivateStack.Navigator>
    </PrivateErrorBoundary>
  )
}
