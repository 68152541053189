export type GuestNavigatorParams = {
  Login: undefined
  MagicLink: {
    magicLink: any
    payload: string
  }
};

export type BusinessNavigatorParams = {
  BusinessHome: undefined
  BusinessContract: {
    id: string
  }
  BusinessAddPaymentMethod: undefined
  BusinessContracts?: {variable?: string}
  BusinessCustomer: { customerId: string }
  BusinessCustomers: undefined
  BusinessInvoices?: {variable?: string}
  BusinessLabel: undefined
  BusinessPaymentMethod: { paymentMethodid: string }
  BusinessPaymentMethods: undefined
  BusinessProfile: undefined
}

export type PrivateNavigatorParams = {
  Home: undefined
  Utenza: {
    contractId: string
  }
  Consumi: {
    contractId: string
  }
  Autolettura: {
    contractId: string
  }
  GestisciMetodiPagamento: {
    contractId: string
  }
  Intestatario: {
    contractId: string
  }
  Pagamenti: undefined
  PagaOra: {
    contractId: string
    invoiceId: string
  }
  AggiungiPagamenti: undefined
  Profilo: undefined
  Referral: undefined
};

export type RootNavigatorParams = {
  Guest: any
  Private: any
  Business: any
  NotFound: undefined
};

export enum Screens {
  // Shared
  Login = 'Login',
  NotFound = 'NotFound',
  MagicLink = 'MagicLink',

  // Private
  Home = 'Home',
  Utenza = 'Utenza',
  Consumi = 'Consumi',
  GestisciMetodiPagamento = 'GestisciMetodiPagamento',
  Autolettura = 'Autolettura',
  Intestatario = 'Intestatario',
  Pagamenti = 'Pagamenti',
  PagaOra = 'PagaOra',
  AggiungiPagamenti = 'AggiungiPagamenti',
  Profilo = 'Profilo',
  Referral = 'Referral',

  // Business
  BusinessHome = 'BusinessHome',
  BusinessAddPaymentMethod = 'BusinessAddPaymentMethod',
  BusinessContract = 'BusinessContract',
  BusinessContracts = 'BusinessContracts',
  BusinessCustomer = 'BusinessCustomer',
  BusinessCustomers = 'BusinessCustomers',
  BusinessInvoices = 'BusinessInvoices',
  BusinessLabel = 'BusinessLabel',
  BusinessPaymentMethod = 'BusinessPaymentMethod',
  BusinessPaymentMethods = 'BusinessPaymentMethods',
  BusinessProfile = 'BusinessProfile'
}
