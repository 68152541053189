import { Colors, eerieBlack, violetBlue } from '../../../../style/colors'
import { Icon } from '../../../icon'
import { ItemLayout } from '../../../../layouts/item'
import { Link, StackActions, useRoute } from '@react-navigation/native'
import { Sizes } from '../../../../style/sizes'
import { Spacing } from '../../../../style/spacing'
import { Typography } from '../../../../style/typography'
import { hoverClickerSideNav } from '../../../../style/responsive'
import React from 'react'

interface SidebarItemProps {
  path: string
  icon: string
  title: string
  tag?: string
}

export const SidebarItem: React.FC<SidebarItemProps> = ({
  path,
  icon,
  title,
  tag
}) => {
  const { name, params = {} } = useRoute<any>()
  const active = name === path && params?.tag === tag
  // Pay attention to the action push. if we pass an object with tag undefined that will add undefined ad url param
  const linkParams = tag ? { tag } : undefined
  return (
    <div css={[hoverClickerSideNav, { marginBottom: '2px' }]}>
      <Link to={{ params: linkParams, screen: path }} action={StackActions.push(path, linkParams)} >
        <ItemLayout
          css={[Spacing.Padding.vertical_12, active && Colors.BackgroundColor.violetBlue50, !active && Typography.Style.pointer, { borderRadius: 8 }]}
          leftElement={
            <Icon css={[Spacing.Padding.right_8, { paddingLeft: 32 }]} size={Sizes.small}
              color={active ? violetBlue['500'] : eerieBlack['800']} src={icon}
            />
          }
          firstTextRow={(
            <div
              css={[Typography.label.label3_600, Typography.Style.ellipses, Spacing.Padding.right_8, active ? Colors.Color.violetBlue500 : Colors.Color.eerieBlack700]}>
              {title}
            </div>
          )}
        />
      </Link>
    </div>
  )
}
