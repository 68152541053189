// Gatsby SSR safeguard
import { HSLToHex, hexToHSL } from '../lib/colors'
import { allColors, darkMint } from './colors'
import { css } from '@emotion/react'
import capitalize from 'lodash/capitalize'
import dayjs from '../lib/dayJs'

export const isBrowser = typeof window !== 'undefined'

// From bootstrap5 breakpoints
export const isXSmall = () => window.matchMedia && window.matchMedia('(max-width: 575.98px)').matches
export const isSmall = () => window.matchMedia && window.matchMedia('(max-width: 767.98px)').matches
export const isMedium = () => window.matchMedia && window.matchMedia('(max-width: 991.98px)').matches
export const isLarge = () => window.matchMedia && window.matchMedia('(min-width: 992px)').matches
export const isXLarge = () => window.matchMedia && window.matchMedia('(max-width: 1399.98px)').matches
export const isXXLarge = () => window.matchMedia && window.matchMedia('(min-width: 1400px)').matches

// Website breakpoint
export const isDesktop = () => isBrowser ? isLarge() : false // default to mobile
export const isDesktopConst = isBrowser ? isLarge() : false // default to mobile

export const MOBILE_MAX_WIDTH = '991.98px'
export const DESKTOP_MIN_WIDTH = '1140px'

export const cssOnlyMobile = (string) => {
  return css`
    @media (max-width: ${MOBILE_MAX_WIDTH}) {
      ${string}
    }
  `
}

export const cssOnlyDesktop = (string) => {
  return css`
    @media (min-width: ${DESKTOP_MIN_WIDTH}) {
      ${string}
    }
  `
}
export const isSafari = () => {
  if (!isBrowser) return false

  const ua = navigator.userAgent.toLowerCase()
  if (ua.indexOf('safari') !== -1) {
    if (ua.indexOf('chrome') !== -1) {
      return false
    } else {
      return true
    }
  }

  return false
}

export const getColorObject = (color: string) => {
  return allColors.find(colorObject => colorObject['500'] === `#${color}`) ?? darkMint
}

export const getStyleFromColor = (color: string) => {
  const hsl = hexToHSL(`#${color}`.toUpperCase())

  return {
    50: HSLToHex(hsl.h, hsl.s, 95),
    200: HSLToHex(hsl.h, hsl.s, 80),
    500: HSLToHex(hsl.h, hsl.s, 50)
  }
}

// bottom axis label formatter
export const formatter = value => {
  const date = dayjs(value)
  const strFormat = date.isBefore(dayjs(), 'year') ? 'MMM YY' : 'MMM'
  return capitalize(dayjs(value).format(strFormat))
}
