import { Button, HookFormInputText, Modal, Spacer } from '../../components'
import { Layout } from '../../style/spacing'
import { Sizes } from '../../style/sizes'
import { Typography } from '../../style/typography'
import { css } from '@emotion/react'
import { logAndSendToErrorTracker } from '../../services/errorTracker'
import { snackbarDurationDefault, useSnackbar } from '../../hooks/useSnackbar'
import { translate } from '../../lib/localization'
import { useForm } from 'react-hook-form'
import { useUpdateUserInfoMutation } from '../../lib/apollo/hooks'
import React from 'react'

export type UserNamesFormProps = {
  firstName: string
  lastName: string
  onClose: React.MouseEventHandler<HTMLDivElement> | any
};

export const UserNamesForm: React.FC<UserNamesFormProps> = ({ firstName, lastName, onClose }) => {
  const [openSnackbar] = useSnackbar()
  const [updateUserInfo, { loading }] = useUpdateUserInfoMutation({
    onCompleted: () => {
      openSnackbar(translate('snackbar_message__save__names_success'), snackbarDurationDefault)
      onClose()
    },
    onError: error => logAndSendToErrorTracker(error)
  })
  const {
    control,
    errors,
    getValues,
    formState,
    handleSubmit
  } = useForm({
    defaultValues: { firstName, lastName },
    mode: 'onBlur'
  })
  const onClick = handleSubmit(async () => {
    await updateUserInfo({
      variables: {
        firstName: getValues('firstName'),
        lastName: getValues('lastName')
      }
    })
  })
  return (
    <Modal
      title={<div css={Typography.heading.h5_700}>{translate('text__modify')}</div>}
      hideBackground={true}
      hideModal={onClose}
      style={css`max-width: 480px;`}
    >
      <Spacer height={32} />
      <HookFormInputText
        name='firstName'
        error={errors}
        control={control}
        rules={{
          required: translate('forms__text-input__error--required', {
            name: translate('forms__text-input__first-name__label')
          })
        }}
        inputProps={{
          inputMode: 'text',
          label: translate('input_text_label__first_name')
        }}
      />
      <Spacer height={16} />
      <HookFormInputText
        name='lastName'
        error={errors}
        control={control}
        rules={{
          required: translate('forms__text-input__error--required', {
            name: translate('forms__text-input__last-name__label')
          })
        }}
        inputProps={{
          inputMode: 'text',
          label: translate('input_text_label__last_name')
        }}
      />
      <Spacer height={56} />
      <div css={[Layout.display_flex, Layout.row_reverse]}>
        <Button
          onClick={onClick}
          style={formState.isValid ? 'primary' : 'disabled'}
          title={translate('button_save')}
          block={false}
          size={Sizes.medium}
          loading={loading}
        />
      </div>
    </Modal>
  )
}
