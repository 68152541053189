import { Button, Icon, Modal, Spacer } from '../../components'
import { Colors, eerieBlack, marigold, primaryColor, violetBlue } from '../../style/colors'
import { InvoiceStatus } from '../../lib/apollo/types'
import { ItemLayout } from '../../layouts/item'
import { Layout, Spacing } from '../../style/spacing'
import { Placeholder } from '../placeholder'
import { Sizes } from '../../style/sizes'
import { Typography } from '../../style/typography'
import { actionState } from '../../store/state'
import { css } from '@emotion/react'
import { getPaymentMethodItemSubtitle, getPaymentMethodSvgIcon } from '../../services/paymentMethod'
import { hoverClicker } from '../../style/responsive'
import { logAndSendToErrorTracker } from '../../services/errorTracker'
import { translate } from '../../lib/localization'
import { useActions, useAppState } from '../../store'
import { useGetContractsPaymentMethodsQuery, useGetPaymentMethodsQuery, useUpdateContractPaymentMethodMutation } from '../../lib/apollo/hooks'
import Checkmark from '../../images/icons/checkmark-circle.svg'
import React, { useEffect, useState } from 'react'
import radioButton from '../../images/icons/radio-button-off.svg'
import warningIcon from '../../images/icons/warning.svg'

const PlaceholderPaymentMethods = <div css={[Layout.flex_column, Layout.display_flex]}>
  <ItemLayout
    css={[{ flex: 1, width: '373.5px' }]}
    firstTextRow={<div><Placeholder width={'30%'} height={'16px'} css={[Spacing.Margin.top_8, Spacing.Margin.bottom_6]} /></div>}
    leftElement={<Placeholder width={'52px'} height={'52px'} radius={52} css={[Spacing.Margin.right_12]} />}
    secondTextRow={<Placeholder width={'50%'} height={'16px'} />} />
  <Spacer height={8} />
  <ItemLayout
    css={[{ flex: 1, width: '373.5px' }]}
    firstTextRow={<div><Placeholder width={'30%'} height={'16px'} css={[Spacing.Margin.top_8, Spacing.Margin.bottom_6]} /></div>}
    leftElement={<Placeholder width={'52px'} height={'52px'} radius={52} css={[Spacing.Margin.right_12]} />}
    secondTextRow={<Placeholder width={'50%'} height={'16px'} />} />
  <Spacer height={8} />
  <ItemLayout
    css={[{ flex: 1, width: '373.5px' }]}
    firstTextRow={<div><Placeholder width={'30%'} height={'16px'} css={[Spacing.Margin.top_8, Spacing.Margin.bottom_6]} /></div>}
    leftElement={<Placeholder width={'52px'} height={'52px'} radius={52} css={[Spacing.Margin.right_12]} />}
    secondTextRow={<Placeholder width={'50%'} height={'16px'} />} />
  <Spacer height={8} />
  <ItemLayout
    css={[{ flex: 1, width: '373.5px' }]}
    firstTextRow={<div><Placeholder width={'30%'} height={'16px'} css={[Spacing.Margin.top_8, Spacing.Margin.bottom_6]} /></div>}
    leftElement={<Placeholder width={'52px'} height={'52px'} radius={52} css={[Spacing.Margin.right_12]} />}
    secondTextRow={<Placeholder width={'50%'} height={'16px'} />} />
  <Spacer height={8} />
</div>

export const ChangePaymentMethod = () => {
  const { selectedContracts: data } = useAppState()

  const { data: dataContractPaymentMethods, loading: loadingContractPaymentMethods } = useGetContractsPaymentMethodsQuery({
    fetchPolicy: 'network-only',
    variables: {
      contractId: data.map(contract => contract.id)
    }
  })

  const { data: paymentMethods, loading: loadingPaymenthMethods } = useGetPaymentMethodsQuery({
    fetchPolicy: 'cache-and-network'
  })

  const [updateContractPaymentMethod] = useUpdateContractPaymentMethodMutation({
    onError: logAndSendToErrorTracker
  })

  const [activePaymentMethodId, setActivePaymentMethodId] = useState<number>(undefined)
  const [isWarning, setWarning] = useState<boolean>(false)
  const [isLoading, setLoading] = useState<boolean>(false)
  const { setAction } = useActions()

  const loading = loadingPaymenthMethods && loadingContractPaymentMethods
  const firstPaymentMethodId: number = dataContractPaymentMethods?.Contracts[0]?.PaymentMethodId

  useEffect(() => {
    if (dataContractPaymentMethods?.Contracts.every(contract => contract?.PaymentMethodId === firstPaymentMethodId)) {
      setActivePaymentMethodId(firstPaymentMethodId)
    }
    const hasOpenInvoices = dataContractPaymentMethods?.Contracts.some(contract => {
      return contract?.Invoices.some(invoice => invoice.status === InvoiceStatus.Open)
    })
    if (hasOpenInvoices) setWarning(true)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataContractPaymentMethods])

  const changePaymentMethodFunction = async () => {
    setLoading(true)
    const updates = await Promise.all(
      data.map(contract => updateContractPaymentMethod({ variables: { contractId: contract.id, paymentMethodId: String(activePaymentMethodId) } }))
    )
    updates.length && setAction(actionState.none)
    setLoading(false)
  }

  const body = () => paymentMethods?.PaymentMethods.map(paymentMethod => {
    const paymentMethodIdNumber = parseInt(paymentMethod.id)
    return (
      <div key={paymentMethod.id} css={Layout.border_bottom}>
        <div css={[Typography.Style.pointer, Spacing.Padding.vertical_8, hoverClicker, Spacing.Margin.top_16, Spacing.Margin.bottom_16]} onClick={() => setActivePaymentMethodId(paymentMethodIdNumber)}>
          <ItemLayout
            firstTextRow={<div css={[Typography.label.label2_500]}>{getPaymentMethodItemSubtitle(paymentMethod)}</div>}
            leftElement={<Icon src={getPaymentMethodSvgIcon(paymentMethod)} css={[Spacing.Padding.left_4, Spacing.Padding.right_16]} color={primaryColor} size={Sizes.large} />}
            secondTextRow={<div css={[Typography.Caption.caption1_400, Colors.Color.eerieBlack600]}>{paymentMethod?.name}</div>}
            rightElement={<div css={[Layout.display_flex, Spacing.Margin.right_12]}>
              <Icon src={activePaymentMethodId === paymentMethodIdNumber ? Checkmark : radioButton} css={[Typography.Style.pointer]} color={activePaymentMethodId === paymentMethodIdNumber ? violetBlue['500'] : eerieBlack['400']} />
            </div>} />
        </div>
      </div>)
  }
  )

  return (
    <Modal
      title={<div css={Typography.heading.h5_700}>{translate('business__popup__change_payment_method')}</div>}
      hideBackground={true}
      hideModal={() => setAction(actionState.none)}
      styleCustom={css`max-width: 420px; top: 50vh;`}
    >
      {
        loading && PlaceholderPaymentMethods
      }
      {
        !loading && <div>
          <Spacer height={16}/>
          <span css={[Colors.Color.eerieBlack700, Typography.paragraph.p2_400]}>{translate('business__popup__change_payment_method__subtitle')}</span>
          <Spacer height={24}/>
          {
            isWarning && <>
              <div css={[Colors.BackgroundColor.marigold50, Layout.border, Colors.BorderColor.marigold500, Layout.rounded_10, Spacing.Padding.all_12]}>
                <ItemLayout
                  leftElement={<Icon src={warningIcon} color={marigold['500']} size={Sizes.small} css={Spacing.Margin.right_8}/>}
                  firstTextRow={<div css={Typography.label.label2_700}>{translate('business__popup__change_payment_method_warning__title')}</div>}
                />
                <Spacer height={8}/>
                <div css={[Colors.Color.marigold500, Typography.paragraph.p2_400]}>
                  {translate('business__popup__change_payment_method_warning')}
                </div>
              </div>
              <Spacer height={16}/>
            </>
          }
          <div css={[Layout.scrollYEnabled, Layout.hide_scrollbar, { maxHeight: '320px' }]}>
            {body()}
          </div>
          <Spacer height={12}/>
          <div css={[Layout.flex_justify_end, Layout.ColumnGap.md, Layout.display_flex]}>
            <Button
              onClick={() => setAction(actionState.none)}
              style="no-background"
              size={Sizes.medium}
              title={translate('button_cancel')}
              block={true}
            />
            <Button
              loading={isLoading}
              onClick={() => {
                activePaymentMethodId && changePaymentMethodFunction()
              }}
              style={activePaymentMethodId ? 'primary' : 'disabled'}
              size={Sizes.medium}
              title={translate('button_save')}
              block={true}
            />
          </div>
        </div>
      }
    </Modal>
  )
}
