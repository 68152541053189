import { Card, GuestFooter } from '../../../components'
import { Colors } from '../../../style/colors'
import { Layout, Spacing } from '../../../style/spacing'
import { Theme } from '../../../configuration/theme'
import { Typography } from '../../../style/typography'
import React from 'react'
import contractsIcon from '../../../images/login/icons.svg'
import logoIcon from '../../../images/login/logo.svg'

export const NotFound: React.FC = () => {
  return (
    <div css={[Layout.display_flex, Layout.flex_column, Theme.Shared.backgroundColor, {
      alignItems: 'center',
      height: '100%'
    }]}>
      <div>
        <header css={[Layout.display_flex, {
          justifyContent: 'space-between',
          marginBottom: 40,
          maxWidth: '452px'
        }, Spacing.Padding.top_40]}>
          <img alt={'Logo Tate'} src={logoIcon}/>
          <img alt={'Luce e Gas'} src={contractsIcon}/>
        </header>

        <div css={{ width: '452px' }}>
          <Card>
            <div css={[Typography.heading.h5_700, Colors.Color.eerieBlack700]}>
              404
              <br/>Not Found
            </div>
          </Card>
        </div>

        <GuestFooter/>
      </div>
    </div>
  )
}
