import { Button, Card, Spacer } from '../index'
import { Colors } from '../../style/colors'
import { Layout, Spacing } from '../../style/spacing'
import { PageLayout } from '../../layouts/page'
import { Typography } from '../../style/typography'
import { css } from '@emotion/react'
import { logAndSendToErrorTracker } from '../../services/errorTracker'
import { translate } from '../../lib/localization'
import React from 'react'
import image from '../../images/error/error@3x.png'

type ErrorBoundaryProps = {
  children: React.ReactNode
};

export const ERROR_LEVEL_CRASH = 'crash'
export const ERROR_LEVEL_CRITICAL = 'critical'

export class PrivateErrorBoundary extends React.Component<ErrorBoundaryProps> {
  state = {
    errorCounter: 0,
    hasError: false
  }

  static getDerivedStateFromError () {
    return { hasError: true }
  }

  componentDidCatch (error) {
    // Increment error counter to detect a random crash from a recurring one
    this.setState({
      errorCounter: this.state.errorCounter + 1
    })
    logAndSendToErrorTracker(error)
  }

  render () {
    if (this.state.hasError) {
      const errorLevel = this.state.errorCounter === 1 ? ERROR_LEVEL_CRASH : ERROR_LEVEL_CRITICAL

      return (
        <PageLayout disableSide={true} css={[css`align-items: center`]}>
          <Card css={[Spacing.Margin.top_48, Layout.width_100, Layout.flex_column, Layout.vertical_center]}>
            <img src={image} width={300} alt={'Error'}/>
            <Spacer height={24}/>
            <div css={[Typography.heading.h5_700, Colors.Color.eerieBlack800]}>{translate(`error_boundary__title--${errorLevel}`)}</div>
            <Spacer height={8}/>
            <div
              css={[Typography.paragraph.p2_400, Colors.Color.eerieBlack600, Typography.Align.center, Layout.width_max_540]}>{translate(`error_boundary__subtitle--${errorLevel}`)}</div>
            <Spacer height={24}/>
            {errorLevel === ERROR_LEVEL_CRASH && <Button title={translate(`error_boundary__button--${errorLevel}`)} style={'primary'} onClick={() => this.retry()}/>}
            {errorLevel === ERROR_LEVEL_CRITICAL && <Button title={translate(`error_boundary__button--${errorLevel}`)} style={'primary'} onClick={() => this.reset()}/>}
          </Card>
        </PageLayout>
      )
    }

    return this.props.children
  }

  retry = () => {
    this.setState({
      hasError: false
    })
  }

  reset = () => {
    window.location.href = `https://${window.location.hostname}`
  }
}
