import { BusinessNavigatorParams, Screens } from '../main/types'
import { Contract, Invoice, Label } from '../lib/apollo/types'
import dayjs from '../lib/dayJs'

export enum authState {
  loading = 'loading',
  loggedIn = 'loggedIn',
  loggedOut = 'loggedOut'
}

export enum actionState {
  none = '',
  changePaymentMethod = 'changePaymentMethod',
  downloadCsv='downloadCsv'
}

interface PageFilters {
  tags:{
    and: Array<Label>
    or: Array<Label>
  }
  month:{
    start: string
    end: string
  }
}
export interface PagePagination {
  limit: number
  offset: number
}
export interface ToolBoxFilter {
  monthFilter?: {
    startMonth: string
    endMonth?: string
  }
  tagsFilter?: {
    andFilter?: Array<Label>
    orFilter?: Array<Label>
  }
}

export type State = {
  auth: authState
  action?: actionState
  branchSessionData?: any
  toolBox: {
    [Key in keyof BusinessNavigatorParams]?: ToolBoxFilter
  }
  filters: {
    [Key in keyof BusinessNavigatorParams]?: PageFilters
  }
  pagination: {
    [Key in keyof BusinessNavigatorParams]?: PagePagination
  }
  selectedContracts: Array<Contract>
  selectedInvoices: Array<Invoice>
  redirectUrl?: string
}
// End date should be first in local time and then in utc
export const INITIAL_TOOLBOX_STATE = {
  [Screens.BusinessHome]: {
    monthFilter: {
      endMonth: dayjs().subtract(1, 'month').endOf('month').format(),
      startMonth: dayjs().subtract(1, 'year').startOf('month').format()
    }
  },
  [Screens.BusinessInvoices]: {
    monthFilter: {
      endMonth: dayjs().subtract(1, 'month').endOf('month').format(),
      startMonth: dayjs().subtract(2, 'month').startOf('month').format()
    }
  }
}

export const state: State = {
  action: actionState.none,
  auth: authState.loading,
  filters: {},
  pagination: {},
  redirectUrl: undefined,
  selectedContracts: [],
  selectedInvoices: [],
  toolBox: INITIAL_TOOLBOX_STATE
}
