import { Icon, Spacer } from '../../../components'
import { Layout, Spacing } from '../../../style/spacing'
import { MainScreenLayout } from '../../../layouts/mainScreen'
import { NoContracts } from '../../../widgets/NoContracts'
import { PageLayout } from '../../../layouts/page'
import { Placeholder } from '../../../components/placeholder'
import { Screens } from '../../../main/types'
import { auth } from '../../../configuration/firebase'
import { translate } from '../../../lib/localization'
import { useGetContractsQuery } from '../../../lib/apollo/hooks'
import { useNavigation } from '@react-navigation/native'
import FlashFlameIcon from '../../../images/icons/flash-flame.svg'
import LogOutIcon from '../../../images/icons/log-out.svg'
import React, { useEffect, useState } from 'react'

export const PlaceholderHomeScreen = <div css={[Layout.width_100]}>
  <Spacer height={48}/>
  <Placeholder width={'52px'} height={'52px'} radius={52}/>
  <Spacer height={22}/>
  <div css={[Layout.display_flex]}>
    <Placeholder width={'100%'} height={'330px'} css={[Layout.flex_1]} padding={24} animated={false}>
      <Placeholder width={'100%'} height={'16px'}/>
      <Spacer height={12}/>
      <Placeholder width={'60%'} height={'16px'}/>
      <Spacer height={16}/>
      <Placeholder width={'100%'} height={'16px'}/>
    </Placeholder>
    <div css={{ width: 8 }}/>
    <Placeholder width={'100%'} height={'330px'} css={[Layout.flex_2]} padding={24} animated={false}>
      <Placeholder width={'100%'} height={'16px'}/>
      <Spacer height={12}/>
      <Placeholder width={'40%'} height={'16px'}/>
      <Spacer height={16}/>
      <Placeholder width={'80%'} height={'16px'}/>
      <Spacer height={12}/>
      <Placeholder width={'100%'} height={'16px'}/>
      <Spacer height={16}/>
      <Placeholder width={'50%'} height={'16px'}/>
    </Placeholder>
  </div>
  <Spacer height={8}/>
  <Placeholder width={'100%'} height={'120px'} css={[Layout.flex_2]} padding={24} animated={false}>
    <div css={[Layout.display_flex]}>
      <div css={[Layout.flex_1]}>
        <Placeholder width={'60%'} height={'16px'}/>
        <Spacer height={16}/>
        <Placeholder width={'30%'} height={'30px'}/>
      </div>
      <div css={[Layout.flex_1]}>
        <Placeholder width={'40%'} height={'16px'}/>
        <Spacer height={16}/>
        <Placeholder width={'70%'} height={'40px'}/>
      </div>
    </div>
  </Placeholder>
</div>

export const HomeScreen = () => {
  // Optimistic response
  const [hasContracts, setHasContracts] = useState<boolean>(true)
  const { data } = useGetContractsQuery()
  const navigation = useNavigation<any>()

  useEffect(() => {
    if (data) {
      if (data.Contracts.length) {
        setHasContracts(true)
        navigation.replace(Screens.Utenza, {
          contractId: data.Contracts[0].id
        })
      } else {
        setHasContracts(false)
      }
    }
  }, [data, navigation])

  return (
    <PageLayout>
      {
        hasContracts && PlaceholderHomeScreen
      }
      {
        !hasContracts && <MainScreenLayout sectionHeaderProps={{
          icon: FlashFlameIcon,
          path: `${translate('gestione-utenza__path')} /`,
          title: translate('screens_title__no_contracts')
        }}>
          <NoContracts
            ctaProps={{
              icon: <Icon src={LogOutIcon} css={Spacing.Padding.right_12} />,
              onClick: () => auth.signOut(),
              text: translate('no_contracts_widget__logout')
            }}
            firstText={translate('no_contracts_widget__first_text--home')}
            secondText={translate('no_contracts_widget__second_text--home')}
          />
        </MainScreenLayout>
      }
    </PageLayout>
  )
}
