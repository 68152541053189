import { BusinessNavigator } from './businessNavigator'
import { GuestNavigator } from './guestNavigator'
import { Header } from '../components/header'
import { NotFound } from '../screens/shared/NotFound'
import { PrivateNavigator } from './privateNavigator'
import { RootNavigatorParams, Screens } from './types'
import { authState } from '../store/state'
import { createStackNavigator } from '@react-navigation/stack'
import { isBusinessUsageTarget, isPrivateUsageTarget } from '../configuration/env'
import { useActions, useAppState } from '../store'
import React, { useEffect } from 'react'

const RootStack = createStackNavigator<RootNavigatorParams>()

export const RootNavigator: React.FC = () => {
  const state = useAppState()
  const actions = useActions()

  // if we are logged out, we save the current url to redirect the user after login
  useEffect(() => {
    if (state.auth === authState.loggedOut && !window.location.href.includes('login')) actions.setRedirectUrl({ value: window.location.href })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <RootStack.Navigator
      screenOptions={{
        header: () => <Header/>,
        headerMode: 'float'
      }}>
      {
        (state.auth === authState.loggedIn && isPrivateUsageTarget) &&
        <RootStack.Screen name={'Private'} component={PrivateNavigator}/>
      }
      {
        (state.auth === authState.loggedIn && isBusinessUsageTarget) &&
        <RootStack.Screen name={'Business'} component={BusinessNavigator}/>
      }
      {
        state.auth === authState.loggedOut &&
        <RootStack.Screen name={'Guest'} component={GuestNavigator} options={{ headerShown: false }}/>
      }

      <RootStack.Screen name={Screens.NotFound} component={NotFound} options={{ headerShown: false }}/>
    </RootStack.Navigator>
  )
}
