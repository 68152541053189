
import { Colors, eerieBlack } from '../../style/colors'
import { Layout, Spacing } from '../../style/spacing'
import { Typography } from '../../style/typography'
import { css } from '@emotion/react'

export const exitStyles = css`
  ${Layout.vertical_center}
  ${Typography.Style.pointer}
  ${Spacing.Margin.left_24}
  ${Spacing.Padding.all_12}
  background-color: transparent;
  transition: background-color .5s;
  border-radius: 8px;

  .exit-text {
    ${Spacing.Margin.right_12}
  }


  :hover{
    background-color: ${eerieBlack['100']};
    transition: background-color .5s;

    .exit-text {
        ${Colors.Color.eerieBlack800}
    }

    .exit-icon {
        .icon {
            ${Colors.BackgroundColor.eerieBlack800}
        }
    }
  }

`
