import { BusinessSideNav, SideNav } from '../../components/sideNav'
import { Colors } from '../../style/colors'
import { Layout as LayoutStyles, Spacing } from '../../style/spacing'
import { Responsive } from '../../style/responsive'
import { isBusinessUsageTarget, isPrivateUsageTarget } from '../../configuration/env'
import { layoutStyle } from './index.css'
import React from 'react'

interface PageLayoutProps {
  disableSide?: boolean
  fullWidth?: boolean
}

export const PageLayout: React.FC<PageLayoutProps> = ({ children, disableSide = false }) => (
  <div css={[layoutStyle, Colors.BackgroundColor.eerieBlack50]}>
    <div className="mainContainer">
      <section css={[Responsive.sizes.containerMaxWidth, LayoutStyles.flex_1, LayoutStyles.display_flex]}>

        {!disableSide && isPrivateUsageTarget && <SideNav/>}
        {!disableSide && isBusinessUsageTarget && <BusinessSideNav/>}

        <main css={[LayoutStyles.flex_1, Spacing.Padding.right_32]}>
          {children}
        </main>
      </section>
    </div>
  </div>
)
