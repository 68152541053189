import { ContractType } from '../../lib/apollo/types'
import { Layout, Spacing } from '../../style/spacing'
import React from 'react'
import blueFlashIcon from '../../images/icons/blue_flash.svg'
import redFlameIcon from '../../images/icons/red_flame.svg'

interface ContractIconProps {
  type: ContractType
}

export const ContractIcon: React.FC<ContractIconProps> = ({ type }) => {
  return {
    [ContractType.Ee]: <img src={blueFlashIcon} alt='Energia' width={14}
      css={[Layout.display_inline_block, Layout.relative, Layout.top_2, Spacing.Margin.right_8]}/>,
    [ContractType.Gas]: <img src={redFlameIcon} alt='Gas' width={14}
      css={[Layout.display_inline_block, Layout.relative, Layout.top_2, Spacing.Margin.right_8]}/>
  }[type] || null
}
