import { Colors, eerieBlack, violetBlue } from '../../style/colors'
import { Icon, Link } from '../../components'
import { NavigationProps } from '../../components/link'
import { Sizes } from '../../style/sizes'
import { Spacing } from '../../style/spacing'
import { Typography } from '../../style/typography'
import { hoverClickerArrowLink } from '../../style/responsive'
import LeftArrowIcon from '../../images/icons/arrow-forward.svg'
import React from 'react'

export interface ArrowLinkProps{
  navigationProps?: NavigationProps
  disabled?: boolean
  title: string
}

export const ArrowLink: React.FC<ArrowLinkProps> = ({ title, disabled = false, navigationProps }) => (
  <Link
    css={[Spacing.Padding.vertical_8, { borderRadius: 8 }, hoverClickerArrowLink]}
    navigationProps={navigationProps}
    leftElement={<Icon src={LeftArrowIcon}
      css={Spacing.Padding.right_24}
      size={Sizes.medium}
      color={disabled ? eerieBlack['400'] : violetBlue['500']} />}
    textRow={<div css={[Typography.label.label2_500, disabled ? Colors.Color.eerieBlack400 : Colors.Color.violetBlue500]}>{title}</div>}
  />
)
