import { InputText } from '..'
import { InputTextStatus } from '../inputText'
import { UseControllerOptions, useController } from 'react-hook-form'
import React, { useCallback } from 'react'

const defaultTransformOutput = (val: string): any => val
const defaultTransformInput = (val: any): string => val
interface HookFormInputTextProps extends UseControllerOptions{
  name: string
  defaultValue?: any
  inputProps: any
  error: any
  control: any
  rules: any
  transformOutput?(value: string): any
  transformInput?(value: any): string
}

export const HookFormInputText:React.FC<HookFormInputTextProps> = ({
  name,
  control,
  defaultValue = '',
  rules,
  inputProps,
  error,
  transformOutput = defaultTransformOutput,
  transformInput = defaultTransformInput
}) => {
  const {
    field: { onBlur, onChange, value },
    meta: { invalid, isTouched }
  } = useController({
    control: control,
    defaultValue: defaultValue,
    name: name,
    rules: rules
  })

  let status = InputTextStatus.None
  if (!invalid && isTouched) status = InputTextStatus.Valid
  if (invalid) status = InputTextStatus.Invalid
  if (inputProps.status === 'disabled') status = InputTextStatus.Disabled
  const onInputChange = useCallback((value) => onChange(transformOutput(value)), [onChange, transformOutput])
  return (
    <InputText
      {...inputProps}
      label={inputProps.label || ''}
      onBlur={onBlur}
      onValue={onInputChange}
      value={transformInput(value)}
      status={status}
      error={error?.message}
      errorType={error?.type}
    />
  )
}
