import { GuestNavigatorParams, Screens } from './types'
import { LoginScreen } from '../screens/shared/Login'
import { MagicLinkScreen } from '../screens/shared/MagicLink'
import { createStackNavigator } from '@react-navigation/stack'
import React from 'react'

const GuestStack = createStackNavigator<GuestNavigatorParams>()

export const GuestNavigator = () => {
  return (
    <GuestStack.Navigator
      screenOptions={{
        headerShown: false
      }}
      initialRouteName={Screens.Login}
    >
      <GuestStack.Screen name={Screens.Login} component={LoginScreen} options={{ headerShown: false }}/>
      <GuestStack.Screen name={Screens.MagicLink} component={MagicLinkScreen} options={{ headerShown: false }}/>
    </GuestStack.Navigator>
  )
}
