import { Colors } from '../../../style/colors'
import { Layout, Spacing } from '../../../style/spacing'
import { Link } from '@react-navigation/native'
import { Splash as SplashWidget } from '../../../widgets/Splash'
import { Theme } from '../../../configuration/theme'
import { Typography } from '../../../style/typography'
import { translate } from '../../../lib/localization'
import React from 'react'
import contractsIcon from '../../../images/login/icons.svg'
import logoIcon from '../../../images/login/logo.svg'

const FooterItem = ({ children, path }) => (
  <Link css={Layout.flex_1} to={path}>
    <span css={[Typography.label.label3_500, Typography.Style.pointer, Colors.Color.eerieBlack0]}>
      {children}
    </span>
  </Link>
)

export const SplashScreen = () => {
  return (
    <div css={[Layout.display_flex, Layout.flex_column, Theme.Shared.backgroundColor, { alignItems: 'center', height: '100%' }]}>
      <div>
        <header css={[Layout.display_flex, { justifyContent: 'space-between', marginBottom: 40, maxWidth: '452px' }, Spacing.Padding.top_40]}>
          <img alt={'Logo Tate'} src={logoIcon} />
          <img alt={'Luce e Gas'} src={contractsIcon} />
        </header>
        <SplashWidget />
        <footer css={[Layout.display_flex, Spacing.Margin.top_24, { justifyContent: 'center' }]}>
          <FooterItem
            path={{ screen: 'TODO' }}>
            {translate('text--privacy-policy')}
          </FooterItem>
          <div css={[{ width: 8 }, Spacing.Margin.horizontal_12, Colors.Color.violetBlue300]}>•</div>
          <FooterItem
            path={{ screen: 'TODO' }}>
            {translate('text--terms-conditions')}
          </FooterItem>
        </footer>
      </div>
    </div>
  )
}
