import { Colors } from '../../style/colors'
import { Typography } from '../../style/typography'
import { css } from '@emotion/react'

export const style = css`
  .pagination {
    padding: 8px 24px
  }

  table {
    width: 100%;
    border-spacing: 0;
    border: 0;

    tr:first-child {
      td {
        border-top: 1px solid #ebebeb;
      }
    }

    tr:last-child {
      td {
        border-bottom: 1px solid #ebebeb;
      }
    }

    tr {
      ${Colors.BackgroundColor.eerieBlack0}
      td {
        border-bottom: 1px solid #ebebeb;
      }
      :hover{
        ${Colors.BackgroundColor.eerieBlack100}
        ${Typography.Style.pointer}
      }

      :last-child {

        td {
          border-bottom: 0;
        }
      }
    }


    th:first-child, td:first-child {
      padding-left: 24px;
    }

    th:last-child, td:last-child {
      padding-right: 24px;
    }

    th {
      padding: 12px 0 8px;

      margin: 0;
      border-bottom: 0;
      border-right: 0;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      text-align: left;
      ${Typography.label.label3_500}
      ${Colors.BackgroundColor.eerieBlack0}
      color: #D2D2D2;

      :first-child {
        padding-left: 24px;
      }

      :last-child {
        border-right: 0;
      }
    }

    td {
      margin: 0;
      padding-bottom: 12px;
      padding-top: 12px;
      border-bottom: 0;
      border-right: 0;

      ${Typography.label.label3_500}
      :last-child {
        border-right: 0;
      }
    }
  }
`
