import * as actions from './actions'
import * as effects from './effects'
import { IContext, createOvermind } from 'overmind'
import {
  createActionsHook,
  createEffectsHook,
  createReactionHook,
  createStateHook
} from 'overmind-react'
import { isProduction } from '../configuration/env'
import { state } from './state'

export const globalStateConfig = {
  actions,
  effects,
  state
}

// Plain Overmind
export const overmindInstance = createOvermind(globalStateConfig, { devtools: !isProduction })

// Overmind React Hooks
export type Context = IContext<typeof globalStateConfig>;
export const useAppState = createStateHook<Context>()
export const useActions = createActionsHook<Context>()
export const useEffects = createEffectsHook<Context>()
export const useReaction = createReactionHook<Context>()

export const useOvermind = () => {
  const state = useAppState()
  const actions = useActions()
  return { actions, state }
}
