import { Sizes } from '../../style/sizes'
import { eerieBlack, primaryColor } from '../../style/colors'
import { iconStyle } from './index.css'
import React from 'react'

export interface IconProps {
  /**
   * Icon color
   */
  color?: any

  /**
   * What background color to use
   */
  backgroundColor?: string

  /**
   * How large should the icon be?
   */
  size?: 'xxsmall' | 'xsmall' | 'small' | 'medium' | 'large' | any

  /**
   * Icon src
   */
  src: string

  /**
   * OnClick handler
   */
   onClick?: Function

  /**
   * Class Name
   */
  className?: string

  /**
   * Class Name
   */
   type?: 'circle' | 'icon'

  raised?: boolean
}

/**
 * Primary UI component
 */
export const Icon: React.FC<IconProps> = ({
  color = primaryColor,
  size = Sizes.medium,
  backgroundColor = 'transparent',
  src,
  className = '',
  type = 'icon',
  raised = false
}) => {
  const targetColor = {
    default: eerieBlack['500'],
    primary: primaryColor
  }[color] || color

  return (
    <div className={className} css={[iconStyle, { backgroundColor }]} data-icon-type={type} data-size={ size } data-raised={raised}>
      <div className={'icon'} css={{ backgroundColor: targetColor, mask: `url(${src}) no-repeat center` }} data-flat-size={ size }/>
    </div>
  )
}
