import { GuestFooter } from '../../../components'
import { Layout, Spacing } from '../../../style/spacing'
import { Login as LoginWidget } from '../../../widgets/Login'
import { Theme } from '../../../configuration/theme'
import React from 'react'
import contractsIcon from '../../../images/login/icons.svg'
import logoIcon from '../../../images/login/logo.svg'

export const LoginScreen: React.FC = () => {
  return (
    <div css={[Layout.display_flex, Layout.flex_column, Theme.Shared.backgroundColor, { alignItems: 'center', height: '100%' }]}>
      <div>
        <header css={[Layout.display_flex, { justifyContent: 'space-between', marginBottom: 40, maxWidth: '452px' }, Spacing.Padding.top_40]}>
          <img alt={'Logo Tate'} src={logoIcon} />
          <img alt={'Luce e Gas'} src={contractsIcon} />
        </header>
        <LoginWidget />

        <GuestFooter/>
      </div>
    </div>
  )
}
