import { ApolloClient, DefaultOptions, InMemoryCache, from } from '@apollo/client'
import { BatchHttpLink } from 'apollo-link-batch-http'
import { apiHostname, isBusinessUsageTarget } from './env'
import { auth } from './firebase'
import { onError } from 'apollo-link-error'
import { setContext } from 'apollo-link-context'

const defaultOptions:DefaultOptions = {
  mutate: {
    fetchPolicy: 'no-cache'
  },
  query: {
    fetchPolicy: 'cache-first'
  },
  watchQuery: {
    fetchPolicy: 'cache-first'
  }
}

let clientInstance: ApolloClient<any>

const onErrorHandler = ({ networkError, operation, forward }) => {
  if (networkError) {
    if ([401, 403].includes(networkError.statusCode)) {
      auth.signOut()
    }
  }

  return forward(operation)
}

const withErrorLink = onError(onErrorHandler)

export const createApolloClient = (): ApolloClient<any> => {
  if (!clientInstance) {
    const authInjector:any = setContext(async () => {
      return {
        headers: {
          Authorization: await auth.currentUser.getIdToken(),
          'X-Tate-App-Type': isBusinessUsageTarget ? 'energy-business' : 'energy-clienti'
        }
      }
    })

    const batchHttpLink:any = new BatchHttpLink({ uri: `${apiHostname}/graphql` })

    clientInstance = new ApolloClient({
      cache: new InMemoryCache(),
      defaultOptions,
      link: from([
        authInjector,
        batchHttpLink,
        withErrorLink
      ])
    })
  }

  return clientInstance
}
