import { apiHostname } from '../configuration/env'
import React, { useEffect, useState } from 'react'

let configuration = null

export const getConfiguration = async () => {
  if (configuration) return configuration
  const response = await fetch(`${apiHostname}/configuration`)
  configuration = await response.json()
  return configuration
}

export const configurationContext = React.createContext({})
const { Provider } = configurationContext

export const Configuration = ({ children }) => {
  const [configuration, setConfiguration] = useState({})
  useEffect(() => {
    async function updateData () {
      const config = await getConfiguration()
      setConfiguration(config)
    }

    updateData()
  }, [])

  return <Provider value={configuration}>{children}</Provider>
}
