import { css } from '@emotion/react'
import { cssOnlyDesktop, cssOnlyMobile } from './utils'
import { eerieBlack } from './colors'

export const Layout = {
  ColumnGap: {
    lg: css`column-gap: 32px;`,
    md: css`column-gap: 16px;`,
    sm: css`column-gap: 8px;`
  },
  RowGap: {
    lg: css`row-gap: 32px;`,
    md: css`row-gap: 16px;`,
    sm: css`row-gap: 8px;`
  },
  border: css`border: 1px solid ${eerieBlack['200']};`,
  border_bottom: css`border-bottom: 1px solid ${eerieBlack['200']};`,
  border_bottom_radius_10: '0px 0px 10px 10px',
  border_box: css`box-sizing: border-box;`,
  border_left: css`border-left: 1px solid ${eerieBlack['200']};`,
  border_right: css`border-right: 1px solid ${eerieBlack['200']};`,
  border_top: css`border-top: 1px solid ${eerieBlack['200']};`,
  column_reverse: css`flex-direction: column-reverse`,
  content_box: css`box-sizing: content-box;`,
  display_flex: css`display: flex`,
  display_inline_block: css`display: inline-block`,
  flex_0: css`flex: 0;`,
  flex_1: css`flex: 1;`,
  flex_2: css`flex: 2;`,
  flex_column: css`flex-direction: column`,
  flex_grow_1: css`flex-grow: 1;`,
  flex_justify_end: css`justify-content: flex-end`,
  flex_justify_spaceBetween: css`justify-content: space-between`,
  flex_justify_start: css`justify-content: flex-start`,
  flex_row: css`flex-direction: row`,
  hide_scrollbar: css`
    ::-webkit-scrollbar {
      display: none;
    }

    -ms-overflow-style: none;
    scrollbar-width: none;
  `,
  horizontal_center: css`
    width: 100%;
    display: flex;
    justify-content: center;
  `,
  no_border: css`border: 0;`,
  relative: css`position: relative`,
  rounded_3: css`border-radius: 3px`,
  rounded_4: css`border-radius: 4px`,
  rounded_5: css`border-radius: 5px`,
  rounded_8: css`border-radius: 8px`,
  rounded_10: css`border-radius: 10px`,
  rounded_full: css`border-radius: 100%`,
  row_reverse: css`flex-direction: row-reverse`,
  scrollYEnabled: css`overflow-y: auto;`,
  top_2: css`top: 2px`,
  vertical_center: css`display: flex;
    align-items: center;`,
  width_100: css`width: 100%`,
  width_250: css`width: 250px`,
  width_max_540: css`max-width: 540px`
}

export const Spacing = {

  Margin: {
    bottom_0: css`margin-bottom: 0`,
    bottom_6: css`margin-bottom: 6px`,
    bottom_8: css`margin-bottom: 8px`,
    bottom_12: css`margin-bottom: 12px`,
    bottom_16: css`margin-bottom: 16px`,
    bottom_20: css`margin-bottom: 20px`,
    bottom_24: css`margin-bottom: 24px`,
    bottom_32: css`margin-bottom: 32px`,
    bottom_40: css`margin-bottom: 40px`,
    bottom_48: css`margin-bottom: 48px`,
    bottom_56: css`margin-bottom: 56px`,
    bottom_80: css`margin-bottom: 80px`,
    horizontal_4: css`margin-left: 4px;
      margin-right: 4px`,
    horizontal_6: css`margin-left: 6px;
      margin-right: 6px`,
    horizontal_8: css`margin-left: 8px;
      margin-right: 8px`,
    horizontal_12: css`margin-left: 12px;
      margin-right: 12px`,
    horizontal_16: css`margin-left: 16px;
      margin-right: 16px`,
    horizontal_40: css`margin-left: 40px;
      margin-right: 40px`,
    horizontal_center: css`margin-left: auto;
      margin-right: auto`,
    left_8: css`margin-left: 8px`,
    left_12: css`margin-left: 12px`,
    left_16: css`margin-left: 16px`,
    left_19: css`margin-left: 19px`,
    left_24: css`margin-left: 24px`,
    left_32: css`margin-left: 32px`,
    left_34: css`margin-left: 34px`,
    left_50: css`margin-left: 50px`,
    left_80: css`margin-left: 80px`,
    right_4: css`margin-right: 4px`,
    right_6: css`margin-right: 6px`,
    right_8: css`margin-right: 8px`,
    right_12: css`margin-right: 12px`,
    right_16: css`margin-right: 16px`,
    right_24: css`margin-right: 24px`,
    right_40: css`margin-right: 40px`,
    top_0: css`margin-top: 0`,
    top_2: css`margin-top: 2px`,
    top_4: css`margin-top: 4px`,
    top_8: css`margin-top: 8px`,
    top_12: css`margin-top: 12px`,
    top_16: css`margin-top: 16px`,
    top_24: css`margin-top: 24px`,
    top_32: css`margin-top: 32px`,
    top_40: css`margin-top: 40px`,
    top_48: css`margin-top: 48px`,
    top_56: css`margin-top: 56px`,
    top_64: css`margin-top: 64px`,
    top_80: css`margin-top: 80px`,
    vertical_0: css`margin-top: 0;
      margin-bottom: 0`,
    vertical_2: css`margin-top: 2px;
      margin-bottom: 2px`,
    vertical_24: css`margin-top: 24px;
      margin-bottom: 24px`,
    vertical_40: css`margin-top: 40px;
      margin-bottom: 40px`,
    vertical_negative_12: css`margin-top: -12px;
      margin-bottom: -12px`
  },
  Padding: {
    all_0: css`padding: 0px`,
    all_2: css`padding: 2px`,
    all_6: css`padding: 6px`,
    all_8: css`padding: 8px`,
    all_12: css`padding: 12px`,
    all_16: css`padding: 16px`,
    all_24: css`padding: 24px`,
    all_48: css`padding: 48px`,
    bottom_20: css`padding-bottom: 20px`,
    bottom_24: css`padding-bottom: 24px`,
    bottom_40: css`padding-bottom: 40px`,
    bottom_48: css`padding-bottom: 48px`,
    bottom_56: css`padding-bottom: 56px`,
    bottom_80: css`padding-bottom: 80px`,
    horizontal_4: css`padding-left: 4px;
      padding-right: 4px`,
    horizontal_6: css`padding-left: 6px;
      padding-right: 6px`,
    horizontal_8: css`padding-left: 8px;
      padding-right: 8px`,
    horizontal_12: css`padding-left: 12px;
      padding-right: 12px`,
    horizontal_16: css`padding-left: 16px;
      padding-right: 16px`,
    horizontal_24: css`padding-left: 24px;
      padding-right: 24px`,
    horizontal_40: css`padding-left: 40px;
      padding-right: 40px`,
    horizontal_54: css`padding-left: 54px;
      padding-right: 54px`,
    horizontal_90: css`padding-left: 90px;
      padding-right: 90px`,
    left_4: css`padding-left: 4px`,
    left_6: css`padding-left: 6px`,
    left_8: css`padding-left: 8px`,
    left_12: css`padding-left: 12px`,
    left_16: css`padding-left: 16px`,
    left_20: css`padding-left: 20px`,
    left_24: css`padding-left: 24px`,
    left_34: css`padding-left: 34px`,
    left_48: css`padding-left: 48px`,
    left_80: css`padding-left: 80px`,
    right_4: css`padding-right: 4px`,
    right_6: css`padding-right: 6px`,
    right_8: css`padding-right: 8px`,
    right_12: css`padding-right: 12px`,
    right_16: css`padding-right: 16px`,
    right_20: css`padding-right: 20px`,
    right_24: css`padding-right: 24px`,
    right_32: css`padding-right: 32px`,
    right_48: css`padding-right: 48px`,
    top_2: css`padding-top: 2px`,
    top_16: css`padding-top: 16px`,
    top_32: css`padding-top: 32px`,
    top_40: css`padding-top: 40px`,
    top_48: css`padding-top: 48px`,
    top_56: css`padding-top: 56px`,
    top_80: css`padding-top: 80px`,
    vertical_2: css`padding-top: 2px;
      padding-bottom: 2px`,
    vertical_6: css`padding-top: 6px;
      padding-bottom: 6px`,
    vertical_8: css`padding-top: 8px;
      padding-bottom: 8px`,
    vertical_12: css`padding-top: 12px;
      padding-bottom: 12px`,
    vertical_16: css`padding-top: 16px;
      padding-bottom: 16px`,
    vertical_24: css`padding-top: 24px;
      padding-bottom: 24px`,
    vertical_negative2: css`padding-top: -2px;
      padding-bottom: -2px`
  },
  Text: {
    center: css`text-align: center`,
    left: css`text-align: left`,
    right: css`text-align: right`
  }
}

export const Visibility = {
  block: css`display: block`,
  hidden: css`visibility: hidden`,
  none: css`display: none`,
  onlyDesktop: cssOnlyMobile('display: none'),
  onlyMobile: cssOnlyDesktop('display: none'),
  opacity0: css`opacity: 0`,
  opacity100: css`opacity: 100%`,
  visible: css`visibility: visible`
}
