import { Button, HookFormInputText, Modal, Spacer } from '../../../components'
import { Colors } from '../../../style/colors'
import { GetLabelsDocument, useUpdateLabelMutation } from '../../../lib/apollo/hooks'
import { Layout } from '../../../style/spacing'
import { Sizes } from '../../../style/sizes'
import { Typography } from '../../../style/typography'
import { css } from '@emotion/react'
import { getStyleFromColor } from '../../../style/utils'
import { logAndSendToErrorTracker } from '../../../services/errorTracker'
import { translate } from '../../../lib/localization'
import { useForm } from 'react-hook-form'
import React, { useState } from 'react'

export const ColorItem = ({ color, onPick, pickedColor }) => {
  const colorStyle = getStyleFromColor(color)

  return <div css={[pickedColor === color && Colors.DropShadow.violetBlue, Layout.rounded_full, Typography.Style.pointer]}>
    <div
      css={[Layout.rounded_full, Layout.display_flex, Layout.horizontal_center]}
      style={{
        backgroundColor: colorStyle['50'],
        border: `1px solid ${colorStyle['200']}`,
        color: colorStyle['500'],
        height: '22px',
        width: '22px'
      }} onClick={() => onPick(color)}>
      <span style={{ fontSize: '10px', paddingTop: '3px' }}>Aa</span>
    </div>
  </div>
}

export const ColorPicker = ({ onPick, pickedColor }) => {
  return (
    <div css={[Layout.flex_row, Layout.display_flex, Layout.ColumnGap.md]}>
      <ColorItem color={'3C00CF'} onPick={onPick} pickedColor={pickedColor}/>
      <ColorItem color={'1869EF'} onPick={onPick} pickedColor={pickedColor}/>
      <ColorItem color={'6D0081'} onPick={onPick} pickedColor={pickedColor}/>
      <ColorItem color={'3ECF8E'} onPick={onPick} pickedColor={pickedColor}/>
      <ColorItem color={'F5A623'} onPick={onPick} pickedColor={pickedColor}/>
      <ColorItem color={'0033FF'} onPick={onPick} pickedColor={pickedColor}/>
    </div>
  )
}

export const ModifyLabel = ({ showModal, setShowModal, id, name, colorTag }) => {
  const [color, setColor] = useState(colorTag.toUpperCase())
  const { control, errors, handleSubmit, formState, getValues } = useForm({
    defaultValues: {
      label_name: name
    },
    mode: 'onBlur'
  })
  const [createLabel, { loading }] = useUpdateLabelMutation({
    onCompleted: () => {
      setShowModal(false)
    },
    onError: error => {
      logAndSendToErrorTracker(error)
    },
    refetchQueries: [GetLabelsDocument],
    variables: {
      color: color.toLowerCase(),
      id: id,
      name: getValues('label_name')
    }
  })
  if (!showModal) return null

  return (
    <Modal
      title={<div css={Typography.heading.h5_700}>{translate('modal__modify_label_title')}</div>}
      hideBackground={true}
      hideModal={() => setShowModal(false)}
      style={css`max-width: 420px;`}
    >
      <Spacer height={16}/>
      <HookFormInputText
        name="label_name"
        control={control}
        rules={{
          maxLength: 24,
          minLength: 3,
          required: translate('input_required--name')
        }}
        inputProps={{
          inputMode: 'text',
          label: translate('modal__create_label__input-name')
        }} error={errors}
      />
      <Spacer height={16}/>
      <span css={[Colors.Color.eerieBlack500, Typography.label.label4_500]}>{translate('modal__label__tag_color')}</span>
      <Spacer height={8}/>
      <ColorPicker onPick={(value) => setColor(value)} pickedColor={color}/>
      <Spacer height={12}/>
      <div css={[Layout.flex_justify_end, Layout.ColumnGap.md, Layout.display_flex]}>
        <Button
          onClick={() => setShowModal(false)}
          style="no-background"
          size={Sizes.medium}
          title={translate('button_cancel')}
          block={true}
        />
        <Button
          loading={loading || formState.isSubmitting}
          onClick={handleSubmit(createLabel)}
          style={formState.isValid && color ? 'primary' : 'disabled'}
          size={Sizes.medium}
          title={translate('button_save')}
          block={true}
        />
      </div>
    </Modal>
  )
}
