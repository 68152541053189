import { css } from '@emotion/react'
import { eerieBlack, violetBlue } from '../../style/colors'

export const style = css`
  min-width: 272px;

  .available-month:hover {
    background-color: ${eerieBlack['100']}
  }

  .filter-box {
    border-radius: 8px;
    padding-bottom: 10px;
    padding-top: 10px;
  }

  .selected {
    background-color: ${violetBlue['50']}
    border: 1px solid ${violetBlue['500']}
  }

  .month-box {
    //border-radius: 4px;
  }

  .months-container {
    display: grid;
    grid-template-columns: auto auto auto;
  }
`
