import { Typography } from '../../style/typography'
import { ctaStyle } from './index.css'
import { translate } from '../../lib/localization'
import React, { ReactElement } from 'react'

export interface ButtonProps {
  /**
   * Button title
   */
  title?: string | ReactElement

  /**
   * OnClick handler
   */
  onClick?: Function

  /**
   * Asyncronous loading indicator
   */
  loading?: boolean

  /**
   * Color styling selector
   */
  style: 'primary' | 'secondary' | 'disabled' | 'border-only' | 'alert' | 'no-background'

  /**
   * Disabled text override
   */
  disabled?: boolean

  /**
   * Block
   */
  block?: boolean

  /**
   * Icon component
   */
  icon?: any

  /**
   * Box shadow to button
   */
  raised?: boolean

   /**
   * Size component
   */
  size?: 'small' | 'medium' | 'large' | any
}

export const Button: React.FC<ButtonProps> = ({
  title,
  onClick,
  loading,
  icon,
  style,
  disabled,
  raised = false,
  block = true,
  size
}) => {
  // Definitely not by Tommaso
  const styleOverride = disabled
    ? (style.includes('text'))
      ? 'text-disabled'
      : 'disabled'
    : style

  return (
    <div className={'cta'} onClick={() => {
      if (!loading && style !== 'disabled' && !disabled && onClick) return onClick()
    }} css={[ctaStyle, Typography.label.label1_600, !block && Typography.label.label4_600]}
    data-style={styleOverride}
    data-loading={loading}
    data-block={block}
    data-raised={raised}
    data-size={size}
    >
      {!loading && title}
      {(!loading && !!icon) && icon}
      {loading && translate('text__loading')}
    </div>
  )
}
