import {
  GetContractPaymentMethodDocument,
  GetInvoiceAndPaymentMethodsDocument,
  GetPaymentMethodsDocument,
  useCreatePaymentMethodMutation,
  useStripeCreateSetupIntentMutation
} from '../lib/apollo/hooks'
import { GraphQLError } from 'graphql'
import { IbanElement, useElements, useStripe } from '@stripe/react-stripe-js'
import { PaymentMethodTarget } from '../lib/apollo/types'
import { SharedEvents } from '@tate-it/tracking'
import { StripeError } from '@stripe/stripe-js'
import { logAndSendToErrorTracker } from '../services/errorTracker'
import { translate } from '../lib/localization'
import { useState } from 'react'
import { useTracking } from '../tracking/useTracking'

export const useCreateSepa = ():[createSepa: (billingDetails: { name: string; email:string })=> Promise<readonly GraphQLError[] | StripeError>, acceptMandates: ()=>Promise<[string, readonly GraphQLError[]]>, extra: {error: boolean; loading: boolean; stripeLoading: boolean; stripeError: boolean}] => {
  const [paymentMethodStripeId, setPaymentMethodStripeId] = useState<string>()
  const { track } = useTracking()
  const [createSepa] = useCreatePaymentMethodMutation()
  const [createSetupIntent] = useStripeCreateSetupIntentMutation()
  const stripe = useStripe()
  const elements = useElements()
  const [error, setError] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [stripeLoading, setStripeLoading] = useState<boolean>(false)
  const [stripeError, setStripeError] = useState<boolean>(false)

  const createPaymentMethod = async (billingDetails: { name: string; email:string }): Promise<readonly GraphQLError[] | StripeError> => {
    const stopLoadingAndLogError = (error):(readonly GraphQLError[] | StripeError) => {
      setStripeError(true)
      setStripeLoading(false)
      logAndSendToErrorTracker(error)
      return error
    }
    const iban = elements.getElement(IbanElement)
    setStripeLoading(true)

    // Create a setupIntent calling our backend
    const { data: { StripeCreateSetupIntent_v1: stripeSetupIntent }, errors: createSetupIntentErrors } = await createSetupIntent()

    if (createSetupIntentErrors) return stopLoadingAndLogError(createSetupIntentErrors)

    // Calls Stripe-SDK for confirming the setupIntent
    // a paymentMethod is automatically created for us
    const { error: confirmSepaDebitSetupError, setupIntent } = await stripe.confirmSepaDebitSetup(stripeSetupIntent.client_secret, {
      payment_method: {
        billing_details: {
          email: billingDetails.email,
          name: billingDetails.name
        },
        sepa_debit: iban
      }
    })

    if (confirmSepaDebitSetupError) return stopLoadingAndLogError(confirmSepaDebitSetupError)

    setPaymentMethodStripeId(setupIntent.payment_method)
    setStripeLoading(false)
    setStripeError(false)
    return null
  }

  const acceptSepaMandates = async (): Promise<[string, readonly GraphQLError[]]> => {
    // Save the paymentMethod in the backend
    const { data: { CreatePaymentMethod_v1: { id: paymentMethodId } }, errors } = await createSepa({
      awaitRefetchQueries: true,
      refetchQueries: [GetPaymentMethodsDocument, GetInvoiceAndPaymentMethodsDocument, GetContractPaymentMethodDocument],
      variables: {
        name: translate('payment_method--sepa'),
        stripeId: paymentMethodStripeId,
        target: PaymentMethodTarget.Sepa
      }
    })
    if (errors) {
      logAndSendToErrorTracker(errors)
      setError(true)
      setLoading(false)
      return ['', errors]
    }
    track({ name: SharedEvents.PaymentMethod.Created, payload: { target: PaymentMethodTarget.Sepa } })
    setError(false)
    setLoading(true)
    return [paymentMethodId, null]
  }

  return [createPaymentMethod, acceptSepaMandates, { error, loading, stripeError, stripeLoading }]
}
