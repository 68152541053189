import { Card, Spacer } from '../../components'
import { DEVICE_ID_KEY, decryptToken } from '../../services/magicLink'
import { ResendMagicLink } from './components/ResendMagicLink'
import { Screens } from '../../main/types'
import { SharedEvents } from '@tate-it/tracking'
import { StackNavigationProp } from '@react-navigation/stack'
import { Typography } from '../../style/typography'
import { auth } from '../../configuration/firebase'
import { decryptEmail } from './utils'
import { eerieBlack } from '../../style/colors'
import { isBusinessUsageTarget } from '../../configuration/env'
import { logAndSendToErrorTracker } from '../../services/errorTracker'
import { signInWithCustomToken } from 'firebase/auth'
import { translate } from '../../lib/localization'
import { useActions } from '../../store'
import { useNavigation, useRoute } from '@react-navigation/native'
import { useTracking } from '../../tracking/useTracking'
import React, { useEffect, useState } from 'react'
import dayjs from '../../lib/dayJs'

export interface ParamsRouting {
  /** indicates if the link is a magic link, it can be 'true' or 'false' */
  magicLink: 'true' | 'false'
  /** base64 encoded payload */
  payload: string
}

enum MagicLinkStatuses {
  pending,
  linkExpired,
  linkError,
  success,
  linkForAnotherDevice
}

interface DeepLinkData {
  type: 'magic_link'
  date: number // unix timestamp (in seconds)
  email: string
  customToken: string
  deviceId: string
  property: 'clienti' | 'business'
  redirectUrl?: string
}

export const MagicLinkWidget: React.FC = () => {
  const actions = useActions()
  const route = useRoute()
  const { track } = useTracking()
  const navigation = useNavigation<StackNavigationProp<Screens.MagicLink>>()
  const [pageStatus, setPageStatus] = useState<MagicLinkStatuses>(MagicLinkStatuses.pending)
  const [email, setEmail] = useState<string>('')

  const params = route.params as ParamsRouting

  useEffect(() => {
    const login = async () => {
      if (params.magicLink === 'true') {
        const base64Payload = params.payload
        // remove params from url
        navigation.setParams({ magicLink: undefined, payload: undefined })

        // Unpack the payload and decrypt the email
        const payload = window.atob(base64Payload)
        const magicLinkData = JSON.parse(payload) as DeepLinkData
        magicLinkData.email = await decryptEmail(JSON.parse(magicLinkData.email))
        setEmail(magicLinkData.email)

        const { customToken, date, deviceId: linkDeviceId, property, redirectUrl } = magicLinkData

        const target = isBusinessUsageTarget ? 'business' : 'clienti'
        if (property !== target) return setPageStatus(MagicLinkStatuses.linkError)

        const deviceId = localStorage.getItem(DEVICE_ID_KEY)
        if (linkDeviceId !== deviceId) return setPageStatus(MagicLinkStatuses.linkForAnotherDevice)

        const expirationDate = dayjs.unix(date).add(1, 'hour')
        if (expirationDate.isBefore(dayjs())) return setPageStatus(MagicLinkStatuses.linkExpired)

        try {
          const fbToken = await decryptToken(JSON.parse(customToken))
          await signInWithCustomToken(auth, fbToken)

          track({ name: SharedEvents.User.LoggedIn })
          setPageStatus(MagicLinkStatuses.success)
        } catch (error) {
        // Generic error because all the things are already checked
          setPageStatus(MagicLinkStatuses.linkError)
          logAndSendToErrorTracker(error)
        }

        if (redirectUrl) {
          actions.setRedirectUrl({ value: undefined })
          window.location.href = redirectUrl
        }
      } else {
        return setPageStatus(MagicLinkStatuses.linkError)
      }
    }

    login()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div css={{ width: '452px' }}>
      <Card>
        {
          pageStatus === MagicLinkStatuses.pending &&
          <>
            <div css={Typography.Title.bold}>
              {translate('magic_link_screen__title__pending')}
            </div>
            <div css={[Typography.Callout.medium, { color: eerieBlack['600'] }]}>
              {translate(('magic_link_screen__subtitle__pending'))}
            </div>
          </>
        }
        {
          pageStatus === MagicLinkStatuses.linkExpired &&
          <>
            <div css={Typography.Title.bold}>
              {translate('magic_link_screen__title__linkExpired')}
            </div>
            <div css={[Typography.Callout.medium, { color: eerieBlack['600'] }]}>
              {translate(('magic_link_screen__subtitle__linkExpired'))}
            </div>
            <Spacer height={24}/>
            <ResendMagicLink email={email}/>
          </>
        }
        {
          pageStatus === MagicLinkStatuses.linkForAnotherDevice &&
          <>
            <div css={Typography.Title.bold}>
              {translate('magic_link_screen__title__link-for-another-device')}
            </div>
            <div css={[Typography.Callout.medium, { color: eerieBlack['600'] }]}>
              {translate(('magic_link_screen__subtitle__link-for-another-device'))}
            </div>
            <Spacer height={24}/>
            <ResendMagicLink email={email}/>
          </>
        }
        {
          pageStatus === MagicLinkStatuses.linkError &&
          <>
            <div css={Typography.Title.bold}>
              {translate('magic_link_screen__title__linkError')}
            </div>
            <div css={[Typography.Callout.medium, { color: eerieBlack['600'] }]}>
              {translate(('magic_link_screen__subtitle__linkError'))}
            </div>
          </>
        }
        {
          pageStatus === MagicLinkStatuses.success &&
          <>
            <div css={Typography.Title.bold}>
              {translate('magic_link_screen__title__success')}
            </div>
            <div css={[Typography.Callout.medium, { color: eerieBlack['600'] }]}>
              {translate(('magic_link_screen__subtitle__success'))}
            </div>
          </>
        }
      </Card>
    </div>
  )
}
