import { css } from '@emotion/react'
import { eerieBlack } from '../../style/colors'

export const style = css`
    .icon-background{
        padding: 8px;
        border-radius: 20px;
        background: transparent;
    }

    .icon-background:hover {
        background: ${eerieBlack['100']};
    }
`
